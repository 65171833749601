import React, { useEffect } from 'react'
import { useState } from 'react'
import { Alert, Button, Card, Col, ListGroup, Modal, ProgressBar, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../../services/api/formService'
import {TextArea} from '../../../components/componentsForm/textarea/textarea'
import {InputFile} from '../../../components/inputFile/inputFile'
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText'
import { BsFillFilePdfFill } from 'react-icons/bs'
import { BASE_URL } from '../../../services/api/config'

export default function Semaforizacion() {
    const api=new FormService();
    const {state:{estudiante,curso}}=useLocation()
    const [Item,setItem]=useState();

    const [asignaturas,setAsignaturas]=useState(null);
    const [reprobadas,setReprobadas]=useState(0);
    const [modal,setModal]=useState();
    const [semestres,setSemestres]=useState([]);

    const [creditosAprobados, setCreditosAprobados] = useState(0);
    const [creditosTotales, setCreditosTotales] = useState(0);

    useEffect(() => {
      let totalCreditos = 0;
      let totalCreditosAprobados = 0;
  
      for (let semestre of semestres) {
          totalCreditos += Number(semestre.total_creditos);
          for (let asignatura of semestre.asignaturas) {
              if (asignatura.Estado === 'Matriculada' ) {
                  totalCreditosAprobados += Number(asignatura.Creditos);
              }
          }
      }
  
      setCreditosTotales(totalCreditos);
      setCreditosAprobados(totalCreditosAprobados);
  
  },);
  

    const grid={
      "display": "grid",
      "grid-template-columns": "repeat(auto-fit, minmax(300px, 1fr))"
    }
    useEffect(()=>{
      (async()=>{
        consultaCurso();
      })()
      
    },[])

    const consultaCurso=async()=>{
      const semestres=await api.getAll(`educacionIES/semaforizacion?IdUsuario=${estudiante.IdUsuario}&IdPlanEstudio=${curso.IdPlanEstudio ? curso.IdPlanEstudio : curso.IdPlandeEstudio}`)
      setSemestres(semestres)
      /* console.log(semestres) */
    }

    const graduar=async()=>{

    }

    const estadoAsignatura=(asignatura)=>{
      // verifiacar si esta matriculado
      if(asignatura.IdMallaAspirante){
        /* console.log(asignatura) */
        // verificar si la oferta esta cerrada
        if(asignatura.IdEstadoOferta==3){
          // verificar si aprobo o reprobo
          if(asignatura.estado == "Aprobó"){
            return "aprobada"
          }else{
            return "reprobada"
          }
        }else{
          return "enCurso"
        }

      }else{
        return "sinMatricular"
      }
    }


  return (
    <>
    <Card>
      <Card.Title>Progreso del Programa</Card.Title>
          <ProgressBar 
              now={(creditosAprobados / creditosTotales) * 100} 
              label={`${creditosAprobados}/${creditosTotales} Créditos Matriculados`} 
          />
    </Card>

    <Card>
        <Button
            style={{margin:2}}
            variant="primary"
            onClick={() => document.querySelector('.Asistenciaspdf').click()}
        >
          <DowloadFileText
            nameFile={"Semaforización.pdf"}
            name={"Exportar Semaforización.pdf"}
            colortext={"#ffffff"}
            icon={<BsFillFilePdfFill />}
            classname="Asistenciaspdf"
            endPoint={`${BASE_URL}/formatosIES/estudiante/exportarSemaforizacion?IdUsuario=${estudiante.IdUsuario}&IdPlanEstudio=${curso.IdPlanEstudio ? curso.IdPlanEstudio : curso.IdPlandeEstudio}`}
        />
        </Button>
    </Card>

    <Card>
      <div style={{display:"flex",justifyContent:"space-between"}}>
        <div style={{display:"flex",alignItems:"center"}}>
          <div style={{width:20,height:20,backgroundColor:"#68da3e",marginRight:10}}></div>
          <div>Aprobada</div>
        </div>
        <div style={{display:"flex",alignItems:"center"}}>
          <div style={{width:20,height:20,backgroundColor:"#ed6452",marginRight:10}}></div>
          <div>Reprobada</div>
        </div>
        <div style={{display:"flex",alignItems:"center"}}>
          <div style={{width:20,height:20,backgroundColor:"#ffff51",marginRight:10}}></div>
          <div>En Curso</div>
        </div>
        <div style={{display:"flex",alignItems:"center"}}>
          <div style={{width:20,height:20,backgroundColor:"#ebe6ea",marginRight:10}}></div>
          <div>Sin Matricular</div>
        </div>
    </div>
    </Card>
    {semestres && semestres.map((semestre,key)=>
      <Card key={key}>
        {/* semestre */}
        <div>Semestre: {semestre.semestre}</div>
        <div>Asignaturas: {semestre.total_asignaturas}</div>
        <div>Créditos: {semestre.total_creditos}</div>
              <div style={{textAlign:"center"}}>
                <b>Asignaturas</b>
              </div>
              <div style={grid}>
                  {semestre.asignaturas && semestre?.asignaturas.map((asignatura,key)=>
                    <div style={{padding:10}} key={key}>
                      <div 
                        className={`asignatura ${estadoAsignatura(asignatura)}`}
                      >
                        {asignatura.CodigoExt} - {asignatura.asignatura}
                        <div><strong>Tipo: </strong>{asignatura.TipoAsignatura}</div>
                        <div><strong>Créditos: </strong>{asignatura.Creditos}</div>
                        <div><strong>Estado: </strong>
                          {estadoAsignatura(asignatura)=="aprobada"&&
                            <>Aprobada</>
                          }
                          {estadoAsignatura(asignatura)=="reprobada"&&
                            <>Reprobada</>
                          }
                          {estadoAsignatura(asignatura)=="enCurso"&&
                            <>En Curso</>
                          }
                          {estadoAsignatura(asignatura)=="sinMatricular"&&
                            <>Sin Matricular</>
                          }
                        </div>
                        
                        {asignatura.Estado=="Matriculada"&&
                          <div><strong>Nota: </strong>{asignatura?.array_nota?.Definitiva}</div>
                        }
                        </div>
                    </div>
                  )}
              </div>
              <div>
                  {asignaturas?.IdOferta==Item?.IdOferta&&
                    <>
                    {reprobadas>0?
                    <>
                      <Alert variant='warning'>Faltan asignaturas por aprobar, no puede graduar al estudiante.</Alert>
                    </>
                      :
                      <>
                        {/* <Alert variant='success'>Todas las asignaturas esta aprobadas, puede graduar al estudiante.</Alert>
                        <Button 
                          onClick={()=>setModal(true)}
                        >Graduar</Button> */}
                      </>
                    }
                    </>  
                  }
              </div>
        </Card>
      )}

      {/* modal de graduar */}
      <Modal 
        show={modal}
        onHide={()=>setModal()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Graduar Estudiante</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextArea
            label="Observaciones"
          />
          <InputFile
            label="Adjunta documento si lo requiere"
          />
        
        </Modal.Body>
        <Modal.Footer>
          <Button 
            variant='secondary'
            onClick={()=>setModal()}
          >
            Cancelar
          </Button>
          <Button 
            onClick={()=>graduar()}
          >
            Graduar
          </Button>
        </Modal.Footer>
      </Modal>
      <style jsx>{`
        .asignatura{
          border:1px solid #ccc;
          padding:10px;
          color:black;
          border-radius:5px;
          margin-bottom:10px;
        }
        .aprobada{
          background:#68da3e;
        }
        .reprobada{
          background:#ed6452;
        }
        .enCurso{
          background:#ffff51;
        }
        .sinMatricular{
          background:#ebe6ea;
        }

      `}</style>
    </>
  )
}
