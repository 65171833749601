import { createSlice } from '@reduxjs/toolkit';

export const formSlice = createSlice({
  name: 'form',
  initialState: {
    error: [],
    isCreate: false,
    isOpenForm: false,
    isOpenDelete: false,
    itemSelected:null,
    currentIdForm:null,
    formSnapshot: null,
    refreshTableToggle:false,
    historyForm:[],
    fields:[],
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setErrorReset: (state, action) => {
      state.error = [];
    },
    setIsCreate: (state, action) => {
      state.isCreate = action.payload;
    },
    setIsOpenForm: (state, action) => {
      state.isOpenForm = action.payload;
    },
    setIsOpenDelete: (state, action) => {
      state.isOpenDelete = action.payload
    },
    onCloseModals: (state) => {
      state.isOpenForm = false
      state.isOpenDelete = false
    },
    setItemSelected: (state, action) => {
      state.itemSelected = action.payload; 
    },
    clearItemSelected: (state, action) => {
      state.itemSelected = null;
    },
    setForm: (state, action) => {
      state.formSnapshot = action.payload;
    },
    setHistoryForm: (state, action) => {
      state.historyForm = [...state.historyForm,action.payload];
    },
    clearHistoryForm:(state, action) => {
      state.historyForm = [];
    },
    setFieldsForm: (state,action) => {

      const inputsOrdered = action.payload.sort(function(a, b) {
        let newA = a.orden
        let newB = b.orden
        if(!a?.orden){newA='0'}
        if(!b?.orden){newB='0'}
        return  parseInt(newA) -  parseInt(newB);
      })

      // console.log("CAMPOS ORDENADOS--->",inputsOrdered)
      // inputsOrdered.forEach(element => {
      //   console.log(element.orden)
      // });
      state.fields = inputsOrdered; 
      // console.log("CAMPOS PAYLOAD--->",action.payload)
      // state.fields = action.payload; 
    },
    onRefreshTableToggle: (state,action) => {
      state.refreshTableToggle = !state.refreshTableToggle
    },
    onReset: (state, action) => {
      state.error = [];
      state.isCreate = false;
      state.isOpenForm = false;
      state.isOpenDelete = false;
      state.itemSelected = null;
      state.currentIdForm = null;
      state.formSnapshot = null;
      state.refreshTableToggle = false;
      state.fields = [];
      console.log('onReset', state)
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setError,
  setErrorReset,
  setIsCreate,
  setIsOpenForm,
  setIsOpenDelete,
  onCloseModals,
  setItemSelected,
  clearItemSelected, 
  setFieldsForm, 
  onRefreshTableToggle,
  setForm,
  onReset,
  setHistoryForm,
  clearHistoryForm
} = formSlice.actions
 
export default formSlice.reducer
