import React, { useState } from 'react'
import { Button, Col, Form, Row, Table } from 'react-bootstrap'
// importar datos de calendario
import DataCalendario  from './DataCalendario';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';
import { useLocation, useParams } from 'react-router-dom';

export default function FormCalendario() {
    const {id} = useParams();
    // obtener datos de calendario por useLocaion
    const location = useLocation();
    const calendario = location.state?.calendario;
    console.log(calendario);

    const api= new FormService();
    const [fechas, setFechas] = useState(calendario?JSON.parse(calendario.Datos):{});
    const [nombre, setNombre] = useState(calendario?calendario.Nombre:"");
    const [año, setAño] = useState(calendario?calendario.Año:"");


    const handleDateChange = (e) => {
        setFechas({...fechas, [e.target.name]:e.target.value});
    }

    const handleYearChange = (e) => {
        const maxYear = 2100; // Establecer el año máximo permitido (ejemplo: 9999)
        const enteredYear = parseInt(e.target.value, 10); // Convertir el valor a un número
        const selectedYear = Math.min(enteredYear, maxYear); // Limitar el año al máximo permitido
        setAño(selectedYear); // Actualizar el estado del año
      };

    const guardarCalendario = async() => {
        const data={
            datos:JSON.stringify(fechas),
            Nombre:nombre,
            Año:año
        }
        if(id){
            const response = await api.update("calendarios",data,id);
            if(response.success){
                alerta("Calendario actualizado correctamente");
            }else{
                alerta("Error al actualizar el calendario","error");
            }
        }else{
            const response = await api.create("calendarios",data);
            if(response.success){
                alerta("Calendario creado correctamente");
            }else{
                alerta("Error al crear el calendario","error");
            }
        }
        
    };
    // Función para formatear la fecha al formato de año "YYYY"
    const formatDateToYear = (dateString) => {
        const date = new Date(dateString);
        return date.getFullYear().toString();
    };

  return (
    <>
    <Row style={{ padding: '1rem' }}>
        <Col sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <b>Año del Calendario</b>
        </Col>
        <Col>
          <Form.Control
            type="number"
            name="Año"
            value={año}
            onChange={handleYearChange} // Utilizar el nuevo evento handleYearChange
            max={2100} // Establecer el valor máximo permitido
          />
        </Col>
      </Row>
    <Row
        style={{
            padding: "1rem",
        }}
    >
        <Col
            sm={4}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <b>Nombre del Calendario</b>
        </Col>
        <Col>
            <Form.Control
                type="text"
                name="Nombre"
                value={nombre}
                onChange={(e) => setNombre(e.target.value)}
            />
        </Col>
    </Row>
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem"
        }}
    >
    <Table striped bordered hover size="sm" 
        style={{
            width:"100%",
            border:"1px solid #ccc",
        }}
    >
        {DataCalendario.map((item,index)=>
            <tr key={index}
                style={{
                    padding:"1px",
                    backgroundColor: item[0]==="titulo"?"#005aa6":item[0]==="subtitulo"?"#2687d8":"#fff",
                    color: item[0]==="titulo"?"#fff":item[0]==="subtitulo"?"#fff":"#000",
                    textAlign: item[0]==="titulo"?"center":item[0]==="subtitulo"?"left":"left",	
                }}
            >
                {item.map((campo,index)=>(
                     index>0&&
                    <>
                    {item.length===2?
                    <td
                        key={index} 
                        colSpan={item.length===2?24:1}
                        style={{
                            padding:"10px",
                        }}
                    >
                        <b>{campo}</b>
                    </td>
                    :
                    <td key={index} style={{ padding: '3px' }}>
                        {campo === 'date' ? (
                        <>
                            <Form.Control
                                type="date"
                                name={`${item[1]}_${index>3?1+2:1}`}
                                value={fechas[`${item[1]}_${index>3?1+2:1}`] || ""}
                                onChange={(e) => handleDateChange(e)}
                            />
                            {item.length === 5 && (
                            <>
                                <Form.Control
                                    type="date"
                                    name={`${item[1]}_${index>3?2+2:2}`}
                                    value={fechas[`${item[1]}_${index>3?2+2:2}`] || ""}
                                    onChange={(e) => handleDateChange(e)}
                                />
                            </>
                            )}
                        </>
                        ) : (
                        campo
                        )}
                    </td> 
                    }
                    </>
                ))}   
            </tr>
        )}
    </Table>
    </div>
    <div
        style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "1rem",
        }}
    >
            <Button variant="primary"
                onClick={() => {
                    guardarCalendario();
                }}
            >
               {id?"Actualizar":"Guardar"}
            </Button>
    </div>
    </>

  )
}
