import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { alertaSinBoton } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'
import { insertExtraDataViaHistoryForm } from '../../../shared/util/form'
import { data } from '../../outlet/DashboardHome/rolesHomePages/admin/AdminDashboardHome'
import { onRefreshTableToggle, onCloseModals } from '../FormSlice'

export function useSubmitCreate(
    endPointSubmit,
    id,
    extraData,
    isContentTypeFormData,
    urlWithParams,
    // retroceder
) {
    const [statusCreate, setStatusCreate] = useState(' ')
    const [responseDataCreate, setDataCreate] = useState([])
    const dispatch = useDispatch()
    const location = useLocation()
    const formService = new FormService()
    const historyForm = useSelector((state) => state.form.historyForm)
    const fetchDataCreate = async (formData) => {
        setStatusCreate('Cargando...')

        let dataRaw = Object.fromEntries(formData.entries())
        try {
            const obj = insertExtraDataViaHistoryForm(
                dataRaw,
                historyForm,
                location.pathname
            )
            dataRaw = { ...dataRaw, ...obj }

            let data2 = null
            if (extraData) {
                if (isContentTypeFormData =='1') {
                    data2 = await formService.createWithFormData(
                        endPointSubmit,
                        { ...extraData, ...dataRaw },
                    )                     
                }
                if (isContentTypeFormData =='0') {
                    data2 = await formService.create(
                        endPointSubmit,
                        { ...extraData, ...dataRaw },
                        isContentTypeFormData
                    ) 
                }
            } else {
                if (isContentTypeFormData =='1') {
                    data2 = await formService.createWithFormData(
                        endPointSubmit,
                        dataRaw
                    )
                }
                if (isContentTypeFormData =='0') {
                    data2 = await formService.create(
                        endPointSubmit,
                        { ...dataRaw },
                        isContentTypeFormData
                    )
                }
            }
            setDataCreate(data2)
            setStatusCreate('Exito') 

            if(responseDataCreate?.respuesta||data2?.respuesta){
                if(data2.respuesta=="exito"||responseDataCreate?.respuesta=="exito"){
                    alertaSinBoton(data2.mensaje?data2.mensaje:responseDataCreate, 'success', 'bottom-end', 1500)
                    dispatch(onCloseModals())
                    dispatch(onRefreshTableToggle()) 
                }else{
                    alertaSinBoton(data2.mensaje?data2.mensaje:responseDataCreate, 'error', 'bottom-end', 1500)
                }
            }else{
                alertaSinBoton('Creado!', 'success', 'bottom-end', 1500)
                dispatch(onCloseModals())
                dispatch(onRefreshTableToggle()) 
            }
            
            
        } catch (err) {
            setDataCreate(err)
            setStatusCreate(
                'Error: ' +
                    err?.message +
                    '. LOG: ' +
                    err.response?.data?.message
            )
            // setStatusCreate("Error");
            console.log('el error:::::', err.response?.data?.message)
        }
    }
    return [fetchDataCreate, statusCreate, responseDataCreate]
}
