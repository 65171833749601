// import React from 'react'
import React, { useEffect, useState } from 'react'
import { Button } from '../../button/button'
import { Input } from '../../input/input'
import { Modal } from '../../modal/Modal'
import { Message } from '../../Message/Message'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../inputFile/inputFile'
import { ViewPDF } from './ViewPDF'
import { FormService } from '../../../services/api/formService'

import './Documents.css'
import { alerta } from '../../../helpers/alertas'
import { alignPropType } from 'react-bootstrap/esm/types'
import SeleccionarAsignaturas from '../../Student/SeleccionarAsignaturas/SeleccionarAsignaturas'

const Documents = ({
    loadingFull,
    documentos,
    oferta,
    applicant,
    isOpenModalDecline,
    onDecline,
    isOpenModalAccept,
    onAccept,
    isOpenModalUpload,
    onUpload,
    onEnroll,
    handleSubmitUpload,
    document,
    observation,
    handleChangeObservation,
    isEmpty,
    handleState,
    isOpenModalRechazar,
    onRechazar,
    mensajeRequisitos,
    handleChangeMensaje,
    rechazarAdmitido,
    isCompletedEnrollment,
    setIsCompletedEnrollment,
    editable
}) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const formService = new FormService()
    const [asignaturasAprobadas, setAsignaturasAprobadas] = useState()

    function filtrar(obj) {
        if (obj.RequisitoMatricula=='true') {
          return true;
        } else {
          return false;
        }
    }
    const endEnrollment = async () => {
        setLoading(true)
        const data = {
            IdAspirante: applicant.IdAspirante,
            IdOferta: applicant.IdOferta,
            IdUsuario: applicant.IdUsuario,
            IdDimension:applicant.IdDimension
        }
        try {
           const respuesta= await formService.create(
                'aspirantes/convetirEstudiante', 
                data
            )
            if(respuesta.respuesta=="exito"){
                setIsCompletedEnrollment(false)
                alerta(respuesta.mensaje, "success")
            }if (respuesta.respuesta=="error") {
                alerta(respuesta.mensaje,"error")
            }
        
            setLoading(false)
        } catch (err) {
            setError(err)
        }
    }
    return (
        <>
            {loadingFull 
                ? <LoadingSpinner /> 
                : (
                    <>
                    <SeleccionarAsignaturas 
                        admision 
                        aspirante={applicant} 
                        asignaturasAprobadas={asignaturasAprobadas}
                        setAsignaturasAprobadas={setAsignaturasAprobadas}
                    
                    />
                        <div className='documents__container'>
                            {
                                documentos.map((item, key) => (
                                    <ViewPDF 
                                        item={ item }
                                        key={ key }
                                        onAccept={ onAccept }
                                        onDecline={ onDecline }
                                        onUpload={ onUpload }
                                        editable={editable}
                                    />
                                ))
                            }
                            {
                                error && (
                                    <Message type='error'>
                                        { error.message }
                                    </Message>
                                )
                            }
                        </div>
                        
                        {isCompletedEnrollment && editable &&
                            <section className='documents__button-enroll'>
                            <Button 
                                    type='send'
                                    loading={ loading }
                                    onClick={ onRechazar }
                                >
                                    Rechazar admitido
                                </Button>
                                <Button 
                                    type='send'
                                    loading={ loading }
                                    onClick={ endEnrollment }
                                >
                                    Matricular admitido
                                </Button>
                            </section>
                        }
                        
                    </>
                )
            }
            <Modal isOpen={ isOpenModalDecline } close={ onDecline }>
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este documento?
                    </h5>
                    <Input 
                        label='Motivo'
                        placeholder='Explica el motivo de rechazo'
                        name='Observacion'
                        value={ observation }
                        onChange={ handleChangeObservation }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(2) }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onDecline }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalAccept } close={ onAccept }>
                <div className='documents_modal-accept'>
                    <h5>
                        ¿Estás seguro de aceptar este documento?
                    </h5>
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(1) }
                            loading={ loading }
                        >
                            Aceptar
                        </Button>
                        <Button 
                            type='danger' 
                            onClick={ onAccept }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalUpload } close={ onUpload }>
                <form onSubmit={ handleSubmitUpload }>
                    <InputFile 
                        label={ document.Descripcion }
                        name='Archivo'
                        accept='.pdf'
                    />
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        Subir
                    </Button>
                </form>
            </Modal>
            <Modal 
                isOpen={isOpenModalRechazar}
                close={onRechazar}
            >
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este admitido?
                    </h5>
                    <Input 
                        label='Describe el motivo'
                        placeholder='Describe el motivo de rechazo'
                        name='Observacion'
                        value={ mensajeRequisitos }
                        onChange={ handleChangeMensaje }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ rechazarAdmitido }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onRechazar }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
        </>
    )
}

export { Documents }