import React from 'react'
import { Accordion, Card, ListGroup } from 'react-bootstrap'
import { BASE_URL } from '../../../services/api/config' 
import {Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'react-bootstrap'
import { Input } from '../../../components/input/input'
import { useState } from 'react'
import DowloadFile from '../../../components/DowloadFileText/DowloadFileText'

export default function FormatosMEN() {
    const [fechaInicio, setFechaInicio] = useState()
    const [fechaFin, setFechaFin] = useState()
    const [modalExportar, setModalExportar] = useState()
    const [urlExportable, setURLExportable] = useState()
    const [nameFile, setNameFile] = useState()

    const limpiarFecha = () => {
        setFechaInicio('')
        setFechaFin('')
    }

  return (
    <>
        <Card>
            <Card.Title>Formatos MEN</Card.Title>
        </Card>
        
        <Accordion defaultActiveKey="0">
      {/* <Accordion.Item eventKey="0">
        <Accordion.Header>BIENESTAR</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/bienestar/Actividad_de_bienestar`}>
                        Actividad de Bienestar
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      {/* <Accordion.Item eventKey="1">
        <Accordion.Header>DERECHOS PECUNIARIOS</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/derechosPecunarios/Valores_de_matricula_IES_publicas_desde_2018`}>
                        Valores de Matrícula IES
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/derechosPecunarios/Otros_derechos_pecuniarios_ies_publicas_desde_2018`}>
                        Otros Derechos Pecuniarios IES
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item eventKey="2">
        <Accordion.Header>EXTENSIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Actividad_cultural`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Actividad_cultural`); setNameFile('Actividad cultural.xlsx'); limpiarFecha()}}>
                        Actividad Cultural
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Consultoria`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Consultoria`); setNameFile('Consultoria.xlsx'); limpiarFecha()}}>
                        Consultoría
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Cursos`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Cursos`); setNameFile('Programas.xlsx'); limpiarFecha()}}>
                        Cursos
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/extencion/Educacion_continua`}>
                        Educación Continua
                    </ListGroup.Item> */}
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Evento_cultural`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Evento_cultural`); setNameFile('Evento cultural.xlsx'); limpiarFecha()}}>
                        Evento Cultural
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Proyecto_de_extension`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Proyecto_de_extension`); setNameFile('Proyecto de extension.xlsx'); limpiarFecha()}}>
                        Proyecto de Extensión
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/extencion/Servicios_de_extension`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/extencion/Servicios_de_extension`); setNameFile('Servicios de extension.xlsx'); limpiarFecha()}}>
                        Servicios de Extensión
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>
{/* 
      <Accordion.Item eventKey="3">
        <Accordion.Header>INFORMACIÓN INSTITUCIONAL</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Calendario_academico`}>
                        Calendario Académico
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Informacion_complementaria_de_la_IES`}>
                        Información Complementaria de la IES
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Recurso_historico`}>
                        Recurso Histórico
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/informacionInstitucional/Unidad_organizacional`}>
                        Unidad Organizacional
                    </ListGroup.Item>
                </ListGroup>
            </div>  
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item eventKey="4">
        <Accordion.Header>INTERNACIONALIZACIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Convenios_internacionales`}>
                        Convenios Internacionales
                    </ListGroup.Item> */}
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_del_exterior_hacia_colombia`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_del_exterior_hacia_colombia`); setNameFile('Movilidad_de_docentes_del_exterior_hacia_colombia.xlsx'); limpiarFecha()}}>
                        Movilidad de Docentes del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_hacia_el_exterior`} */  onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_docentes_hacia_el_exterior`); setNameFile('Movilidad_de_docentes_hacia_el_exterior.xlsx'); limpiarFecha()}}>
                        Movilidad de Docentes hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_del_exterior_hacia_colombia`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_del_exterior_hacia_colombia`); setNameFile('Movilidad_de_estudiantes_del_exterior_hacia_colombia.xlsx'); limpiarFecha()}}>
                        Movilidad de Estudiantes del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_hacia_el_exterior`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_estudiantes_hacia_el_exterior`); setNameFile('Movilidad_de_estudiantes_hacia_el_exterior.xlsx'); limpiarFecha()}}>
                        Movilidad de Estudiantes hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_del_exterior_hacia_colombia`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_del_exterior_hacia_colombia`); setNameFile('Movilidad_de_personal_administrativo_del_exterior_hacia_colombia.xlsx'); limpiarFecha()}}>
                        Movilidad de Personal Administrativo del Exterior hacia Colombia
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_hacia_el_exterior`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Movilidad_de_personal_administrativo_hacia_el_exterior`); setNameFile('Movilidad_de_personal_administrativo_hacia_el_exterior.xlsx'); limpiarFecha()}}>
                        Movilidad del Personal Administrativo hacia el Exterior
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Programas_presenciales_ofrecidos_en_el_exterior`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/internacionalizacion/Programas_presenciales_ofrecidos_en_el_exterior`); setNameFile('Programas_presenciales_ofrecidos_en_el_exterior.xlsx'); limpiarFecha()}}>
                        Programas Presenciales Ofrecidos en el Exterior
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>
    
      {/* <Accordion.Item eventKey="5">
        <Accordion.Header>INVESTIGACIÓN</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Centros_de_investigacion`}>
                        Centro de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Grupos_de_investigacion`}>
                        Grupos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Otras_actividades_de_investigacion`}>
                        Otras Actividades de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Productos_de_investigacion`}>
                        Productos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Proyectos_de_investigacion`}>
                        Proyectos de Investigación
                    </ListGroup.Item>
                    <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/investigacion/Redes_de_investigacion`}>
                        Redes de Investigación
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item> */}

      <Accordion.Item eventKey="6">
        <Accordion.Header>PARTICIPANTE</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/participante/Participante`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/participante/Participante`); setNameFile('Participante.xlsx'); limpiarFecha()}}>
                        Participante
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/participante/Actualizar_documento_participante`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/participante/Actualizar_documento_participante`); setNameFile('Actualizar_documento_participante.xlsx'); limpiarFecha()}}>
                        Actualizar Documento Participante
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header>POBLACIÓN ESTUDIANTIL</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Admitidos`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Admitidos`); setNameFile('Admitidos.xlsx'); limpiarFecha()}}>
                        Admitidos
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Apoyos_financieros__academicos_u_otros`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Apoyos_financieros__academicos_u_otros`); setNameFile('Apoyos_financieros__academicos_u_otros.xlsx'); limpiarFecha()}}>
                        Apoyos Financieros Académicos u Otros
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Cupos_proyectados_y_matricula_esperada`}>
                        Cupos Proyectados y Matrícula Esperada
                    </ListGroup.Item> */}
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes__de_primer_curso`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes__de_primer_curso`); setNameFile('Estudiantes__de_primer_curso.xlsx'); limpiarFecha()}}>
                        Estudiantes de Primer Curso
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_de_articulacion`}>
                        Estudiantes de Articulación
                    </ListGroup.Item> */}
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_de_estrategias_de_cobertura`}>
                        Estudiantes de Estrategias de Cobertura
                    </ListGroup.Item> */}
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Estudiantes_primer_curso_masivo`}>
                        Estudiantes Primer Curso Masivo
                    </ListGroup.Item> */}
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados`); setNameFile('Graduados.xlsx'); limpiarFecha()}}>
                        Graduados
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados_masivo`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Graduados_masivo`); setNameFile('Graduados_masivo.xlsx'); limpiarFecha()}}>
                        Graduados Masivos
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscrito_programa`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscrito_programa`); setNameFile('Inscrito_programa.xlsx'); limpiarFecha()}}>
                        Inscritos Programa
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscritos_relacion_de_inscritos`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Inscritos_relacion_de_inscritos`); setNameFile('Inscritos_relacion_de_inscritos.xlsx'); limpiarFecha()}}>
                        Inscritos - Relación de Inscritos
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Materias_matriculado`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Materias_matriculado`); setNameFile('Materias_matriculado.xlsx'); limpiarFecha()}}>
                        Materias Matriculado
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Matriculados`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Matriculados`); setNameFile('Matriculados.xlsx'); limpiarFecha()}}>
                        Matriculados
                    </ListGroup.Item>
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Matriculados_masivo`}>
                        Matriculados Masivo
                    </ListGroup.Item> */}
                    {/* <ListGroup.Item action href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/Retiros_estudiantiles`}>
                        Retiros Estudiantes
                    </ListGroup.Item> */}
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/CaracterizacionEstudiantes`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/poblacionEstudiantil/CaracterizacionEstudiantes`); setNameFile('CaracterizacionEstudiantes.xlsx'); limpiarFecha()}}>
                        Caracterización de Estudiantes
                    </ListGroup.Item>
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header>RECURSO HUMANO</Accordion.Header>
        <Accordion.Body>
            <div style={{padding:20}}>
                <ListGroup>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Autoridades`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Autoridades`); setNameFile('Autoridades.xlsx'); limpiarFecha()}}>
                        Autoridades
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Directivos`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Directivos`); setNameFile('Directivos.xlsx'); limpiarFecha()}}>
                        Directivos
                    </ListGroup.Item>
                    <ListGroup.Item action /* href={`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Docentes_IES`} */ onClick={()=>{setModalExportar(true); setURLExportable(`${BASE_URL}/formatosIES/formatosMen/recursoHumano/Docentes_IES`); setNameFile('Docentes_IES.xlsx'); limpiarFecha()}}>
                        Docentes IES
                    </ListGroup.Item>
                     
                </ListGroup>
            </div>
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
    <Modal
        show={modalExportar}
        onHide={() => setModalExportar(false)}
    >
        <ModalHeader style={{backgroundColor: "#005AA6", color: "#fff"}} closeButton>
            <h3>Exportar por Período</h3>
        </ModalHeader>
        <ModalBody>
            <Row>
                <Col>
                    <Input
                        label={'Fecha Inicial'}
                        name={'FechaInicial'}
                        value={fechaInicio}
                        onChange={(e) => setFechaInicio(e)}
                        type={'date'}
                    />
                </Col>
                <Col>
                    <Input
                        label={'Fecha Final'}
                        name={'FechaFinal'}
                        value={fechaFin}
                        onChange={(e) => setFechaFin(e)}
                        type={'date'}
                    />
                </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
            <Button>
               <DowloadFile
                    endPoint={urlExportable+`?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}`}
                    name={'Exportar'}
                    nameFile={nameFile}
                    colortext={'#fff'}
               />
            </Button>
        </ModalFooter>
    </Modal>
    </>
  )
}
