import React, { useState, useEffect } from 'react'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../inputFile/inputFile'
import { Button } from '../../button/button'
import { Message } from '../../Message/Message'
import { CardPay } from '../../cardPay/CardPay'
import { FormService } from '../../../services/api/formService' 
import { DynamicForm } from '../../DynamicForm/DynamicForm'

import './MatriculaAspirante.css'
import SeleccionarAsignaturas from '../SeleccionarAsignaturas/SeleccionarAsignaturas'
import { alerta } from '../../../helpers/alertas'
import { Modal, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap'



const ESTADO_DOCUMENTO = {
    NO_SE_HA_CARGADO_NADA:'0',
    APROBADO:'1',
    RECHAZADO:'2'
}
const api= new FormService()

const MatriculaAspirante = ({
    registerPay,
    isloadingPay,
    loadingFull,
    onPay,
    urlBill,
    documents,
    onHandleSubmit,
    error,
    handleChange,
    loading,
    oferta,
    aspirante,
    consultarRespondio,
    respondio
}) => {

    
    const [modalPsicosocial, setModalPsicosocial ] = useState(true)
    const [asignaturasAprobadas, setAsignaturasAprobadas] = useState()

    const actualizar = async () => {
        setTimeout(() => {
            consultarRespondio()
        }, 3000)
        if(respondio == false){
            setModalPsicosocial(false)
        }
    }
    
    const cambiarEstado=()=>{
        console.log("cambiar estado")
    }



    return (
        <> 
                <div className='d-flex justify-content-around'>    
                    {oferta.EstadoEstudiante=="0" &&
                        <CardPay
                            title={'Matricula'}
                            price={registerPay?.Valor}
                            description={registerPay?.Descripcion}
                            isDownload
                        >
                            <Button
                                className={'p-3'}
                                type={'button send'}
                            >
                                <a 
                                    style={{color:'white'}} 
                                    href={ urlBill } 
                                    download
                                >
                                    Descargar Instructivo 
                                </a> 
                            </Button>
                        </CardPay>
                    }    
                </div>
                <SeleccionarAsignaturas 
                    aspirante={aspirante} 
                    IdOferta={oferta.IdOferta}
                    asignaturasAprobadas={asignaturasAprobadas}
                    setAsignaturasAprobadas={setAsignaturasAprobadas}
                />
                {/* {asignaturasAprobadas==1  && */}
                <div>
                {
                    loadingFull
                    ? <LoadingSpinner /> 
                    : !!documents && documents.map((document, key) => (
                        <form 
                            onSubmit={ e => onHandleSubmit(e, document) } 
                            className='estudiante-documento'
                            key={ key }
                        >
                            <div className='estudiante-documento__document'>
                                <h6 className='estudiante-documento__document-title'>
                                    { document.Nombre }
                                </h6>
                                <p>Adjunta un documento PDF de máximo 10 Mb</p>
                                { document?.requisito?.EstadoRequisito == undefined &&
                                    oferta.EstadoEstudiante=="0" &&
                                        <section className='estudiante-documento__update-document'>
                                            <InputFile 
                                                label={ document.Descripcion }
                                                name='Archivo'
                                                accept='image/*,.pdf'
                                                onChange={ handleChange }
                                            />
                                            <Button 
                                                type='send'
                                                loading={ loading }
                                            >
                                                Subir
                                            </Button>
                                        </section>
                                    
                                }
                                {
                                    document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.NO_SE_HA_CARGADO_NADA && 
                                    oferta.EstadoEstudiante=="0" &&
                                        <>
                                            <div className="alert alert-warning" role="alert">
                                                Tu documento está en proceso de revisión.
                                            </div>
                                            <section 
                                                className='estudiante-documento__update-document'
                                            >
                                                <InputFile 
                                                    label={ document.Descripcion }
                                                    name='Archivo'
                                                    accept='.pdf'
                                                    onChange={ handleChange }
                                                />
                                                <Button 
                                                    type='send'
                                                    loading={ loading }
                                                >
                                                    Actualizar documento
                                                </Button>
                                            </section>
                                        </>
                                }
                                
                                
                                {
                                    document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.APROBADO && (
                                        <>
                                            <Message type='ok'>
                                                Tu documento fue aprobado.
                                            </Message>
                                        </>
                                    )
                                }
                                {
                                    document?.requisito?.EstadoRequisito == ESTADO_DOCUMENTO.RECHAZADO && 
                                    oferta.EstadoEstudiante=="0" &&
                                        <>
                                            <Message type='error'>
                                                Tu documento fue rechazado.
                                            </Message>
                                            <h6>Sucedio por el siguiente motivo:</h6>
                                            <p>
                                                { document.requisito.Observacion }
                                            </p>
                                            <section className='estudiante-documento__update-document'>
                                                <InputFile 
                                                    label={ document.Descripcion }
                                                    name='Archivo'
                                                    accept='.pdf'
                                                    onChange={ handleChange }
                                                />
                                                <Button 
                                                    type='send'
                                                    loading={ loading }
                                                >
                                                    Actualizar documento
                                                </Button>
                                            </section>
                                        </>
                                }
                                
                            </div>
                        </form>
                    ))
                }
                </div>       
            {/* }             */}
            {
                error && (
                    <Message type='error'>
                        { error?.message }
                    </Message>
                )            
            }
        </>
    )
}

export { MatriculaAspirante }