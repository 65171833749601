import { useNavigate } from "react-router-dom"
import { Card } from "../../../components/card/Card";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { Title } from "../../../shared/components/title/Title"
import { BASE_URL } from "../../../services/api/config";

function CursosFinalizados () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
 
  const urlProgramas = `docente/cursosFinalizados?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();
  const urlExportar = `${BASE_URL}/exportarCursoFinalizados/docente?IdUsuario=${idUsuario}`

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}}); //to ListadoAsignatura.jsx
  }
  return (
    <>
    <Card>
      <Title>Asignaturas Finalizadas ES</Title>
    </Card>
    <DynamicTable
      titleColumn={['N°','Nombre','Fecha Incio','Fecha Final','Acción']}
      attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      // onActions={false}
      labelOptionClick={`Ver más`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
      exportar={urlExportar}
    ></DynamicTable>

    </>
  )
}
export { CursosFinalizados }