import { DynamicFormContainer } from "../../../../components/DynamicForm/DynamicFormContainer"
import { DynamicTable } from "../../../../components/DynamicForm/dynamicTable/DynamicTable";
import { DynamicSearch } from "../../../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { useLocation } from "react-router-dom"
import { useEffect } from "react";
import { useState } from "react";
import { FormService } from "../../../../services/api/formService";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setForm, setItemSelected } from "../../../../components/DynamicForm/FormSlice";
import Swal from "sweetalert2";
import { useFetchGet } from "../../../../hooks/useFetchGet";
import { Checkbox } from "../../../../components/componentsForm/checkbox/checkbox";
import { SelectDataList } from "../../../../components/SelectDataList/SelectDataList";

function RequisitosOffer () {
    const {state:{oferta}}=useLocation();
    const [campos, setCampos] = useState()
    const [endPoint, setEndPoint] = useState(`requisitos?IdOferta=${oferta?.IdOferta}`)
    const [modal, setModal] = useState(false)
    const api = new FormService()
    const dispach = useDispatch()
    const [tiposVinculacion, setTiposVinculacion] = useState([])
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const formSnapshot= useSelector((state) => state.form.formSnapshot)
    const fields = useSelector((state) => state.form.fields)
    const [Vinculaciones, setVinculaciones] = useState([])
    const [ApruebaFinanciera, setApruebaFinanciera] = useState(false)
    const [IdPago, setIdPago] = useState(null)

    const fetchCamposFormFilter = async () => {
        const camp = await api.getAll(`xcampos?id_formulario=40`)
        .then((res)=>{
            setCampos(res)
        })
    }

    const onSearch = (e) => {
        setEndPoint(`requisitos${e}IdOferta=${oferta?.IdOferta}`)
    }

    useEffect(() => {
        fetchCamposFormFilter()
        consultarTiposVinculacion()
    }, [])   

    const actualizar = () => {
        setEndPoint(`requisitos?IdOferta=${oferta?.IdOferta}&i=${Math.random()}	`)
        setModal(false)
    }

    // consultar los tipos de vinculacion
    const consultarTiposVinculacion = async () => {
        const res = await api.getAll(`tipoVinculacion`)

        setTiposVinculacion(res)
    }
    // agre


    // eliminar usando una alerta para confirmar
    const eliminar = async (e) => {
        const result = await Swal.fire({
            title: '¿Está seguro de eliminar este registro?',
            text: "No se podrá recuperar la información",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#6c757d',
            confirmButtonText: 'Si, eliminar',
            cancelButtonText: 'Cancelar'
        })

        // si la respuesta es true, eliminar
        if (result.isConfirmed) {
            const res = await api.delete(`requisitos`, {Estado:0},e.IdRequisito)
            if (res){
                actualizar()
                Swal.fire(
                    'Eliminado!',
                    'El registro ha sido eliminado.',
                    'success'
                )
                actualizar()
            }else{
                Swal.fire(
                    'Error!',
                    'El registro no ha sido eliminado.',
                    'error'
                )
            }
        }
    }    
    const vincular = (e, i) => {
        setVinculaciones((prevVinculaciones) => {
          const newVinculaciones = [...prevVinculaciones];
          newVinculaciones[i] = {
            ...newVinculaciones[i],
            EstadoVinculacion: e,
          };
          return newVinculaciones;
        });
      };
      
    useEffect(() => {
        const vinculaciones = tiposVinculacion.map((e) => ({
          IdTipoVinculacion: e.IdTipoVinculacion,
          EstadoVinculacion: false,
        }));
        setVinculaciones(vinculaciones);
    }, [tiposVinculacion]);
      

    const llenarEstadosVinculacion = (e) => {
        const vinculacionActuales = e.Vinculaciones || [];
        setVinculaciones((prevVinculaciones) =>
          prevVinculaciones.map((vinculacion) => {
            const vinculacionActual = vinculacionActuales.find(
              (item) => item.IdTipoVinculacion === vinculacion.IdTipoVinculacion
            );
            return {
              ...vinculacion,
              EstadoVinculacion: vinculacionActual ? vinculacionActual.EstadoVinculacion === 1 : false,
            };
          })
        );
      };
      

    return (
        <>
            <Card>
                <Row>
                    <Col>
                        <Card.Title>
                            Requisitos 
                        </Card.Title>
                    </Col>
                    <Col
                        className="d-flex justify-content-end"
                    >
                        <Button 
                            variant="primary"
                            onClick={()=>{
                                setModal(true)
                                dispach(setItemSelected({}))
                                dispach(setForm({llave_primaria:null,form_data:"0"}))
                                setApruebaFinanciera(false)
                                // recetear vinculaciones
                                setVinculaciones((prevVinculaciones) =>
                                    prevVinculaciones.map((vinculacion) => ({
                                    ...vinculacion,
                                    EstadoVinculacion: false,
                                    }))
                                );

                            }}
                        >
                            Agregar
                        </Button>
                    </Col>
                </Row>
            </Card>
        
            {campos &&
                <DynamicSearch camposForm={campos} onSearch={onSearch}/>
            }

            <DynamicTable
                endPoint={endPoint}
                titleColumn={["No", "Nombre", "Acción"]}
                attributes={["Nombre"]}
                elimimarExterno={(e)=>{eliminar(e)}}
                actualizarExterno={(e)=>{
                    setModal(true)
                    dispach(setItemSelected(e))
                    dispach(setForm({llave_primaria:"IdRequisito",form_data:"0"}))
                    llenarEstadosVinculacion(e)
                    setApruebaFinanciera(e.ApruebaFinanciera=="true"?true:false)
                }}
            />

            {/* modal para crear y actualizar */}
            <Modal show={modal} onHide={()=>{ setModal(false) }} >
                <DynamicForm
                    idForm={'40'}
                    title={'Requisitos'}
                    extraData={{
                        "Vinculaciones": Vinculaciones, 
                        "IdOferta": oferta?.IdOferta, 
                        "IdPago": IdPago,
                        "ApruebaFinanciera": ApruebaFinanciera==true?"true":"false"
                    }}
                    accionMysql={actualizar}
                    onClickCancelBtn={setModal}
                    endPointSubmit="requisitos"
                    isCreate={formSnapshot?.llave_primaria?false:true}
                    justDynamicForm={{flag: true, idForm: 40}}
                >               
                    <>   
                        <Checkbox
                            label="Aprueba Financiera"
                            checked={ApruebaFinanciera}
                            onChange={(e) => {
                                setApruebaFinanciera(e);
                            }}
                        >
                            Aprueba Financiera
                        </Checkbox><br></br>

                        {ApruebaFinanciera &&
                            // seleciona el pago
                            <SelectDataList 
                                label="Aprueba Financiera"
                                endPoinLastUrl={'pagos'}
                                optionName="Nombre"
                                optionValue="IdPago"
                                onChangeHandler={(e)=>{setIdPago(e)}}
                                value={itemSelected?.NombrePago||""}

                            />
                        }

                        <Card>
                            <Card.Title>
                                Tipos de Vinculación
                            </Card.Title>
                        </Card>
                        {tiposVinculacion.map((e, i)=>
                            <>
                            <Checkbox
                                key={i}
                                label={e.Descripcion}
                                checked={Vinculaciones[i]?.EstadoVinculacion} // Usa el índice i como clave
                                value={itemSelected?.Vinculaciones?.IdTipoVinculacion}
                                onChange={(e) => {
                                    vincular(e, i);
                                }}
                                >
                                {e.Descripcion}
                                </Checkbox><br></br>
                            </>                            
                        )}
                     </>
                </DynamicForm>
            </Modal>
        </>
    )
}

export {RequisitosOffer}