import React from 'react'

import { Button } from '../../../components/button/button'

import './ViewPDF.css'

import { BASE_SERVIDOR } from '../../../services/api/config'

const ViewPDF = ({
    item,
    onAccept,
    onDecline,
    onUpload,
    editable=true
}) => {

    // console.log(item)
    return (
        <div 
            className='view-pdf'
        >
            <Button 
                isAnchor={ item?.requisito?.Archivo ? true : false} 
                to={`${BASE_SERVIDOR}/pdf/${item?.requisito?.Archivo}`}
                className='view-pdf__button-view-pdf'
            >    
                <div className='view-pdf__info'>
                    <span>
                   { item?.requisito?.EstadoRequisito ==undefined && (
                        <i class="bi bi-file-earmark-x-fill fs-1"></i>
                   )}
                   { item?.requisito?.EstadoRequisito != undefined && (
                        <i className="bi bi-file-pdf fs-1"></i>
                   )}
                    </span>
                    <section>
                        <h6>
                            { item.Nombre }
                        </h6>

                        <section className='view-pdf__date-container'>
                            <p 
                                className='view-pdf__date-title'
                            >
                                {item.requisito?.FechaCreacion&&
                                (<>Fecha de creación</>)}
                            </p>
                            <p className='view-pdf__date'>
                                { item.requisito?.FechaCreacion }
                            </p>
                        </section>

                    </section>
                </div>
            </Button>
            <section className='view-pdf__control'>
                { item.requisito?.EstadoRequisito =='0' &&                       
                        <div className='view-pdf__control-buttons-container'>                            
                            { item.ApruebaFinanciera!="true" ?
                            <>
                            {editable &&  
                                <>                          
                                <Button 
                                    onClick={ () => onDecline(item) }
                                    className='view-pdf__button-decline'
                                >
                                    Rechazar
                                </Button>
                                <Button 
                                    onClick={ () => onAccept(item) }
                                    className='view-pdf__button-accept'
                                >
                                    Aceptar
                                </Button>
                                </>
                                }
                            </>
                             :(<div>En financiera¡</div>)
                            }
                        </div>
                }
                {item?.requisito?.EstadoRequisito =='1' && (
                        <div className='view-pdf__accepted-status'>
                            <h6>Estado</h6>
                            <h5 className='text-center'>Aceptado</h5>
                        </div>
                    )
                }
                {
                    item?.requisito?.EstadoRequisito =='2' && (
                        <div className='view-pdf__rejected-status'>
                            <h6>Estado</h6>
                            <h5 className='text-center'>Rechazado</h5>
                        </div>
                    )
                }
            </section>
        </div>
    )
}

export { ViewPDF }