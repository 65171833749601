
import { Card, Tab, Tabs, Button, Col, Row } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { AddButton } from "../../../components/componentsForm/addButton/addButton"
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../../services"
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { BASE_URL } from "../../../services/api/config";


function CursosActivosContainer () {
  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
  const navigate = useNavigate()

  const handleOptionClick = (item) => {
    navigate(`${item.IdOferta}/detalle_programa`,{state:{programa:item}})
  }

  return (<>
  <Card>
      <Row>
        <Col>
          <Card.Title>Mis Programas - Actividades Académicas Activas</Card.Title>
        </Col>
        <Col style={{textAlign:"right", marginRight:10}}>
          <Button
              variant='primary'
              onClick={() => document.querySelector('.programaspdf').click()}
          >
              <DowloadFileText
                  nameFile={"Actividades Académicas Activas.pdf"}
                  name={"Exportar"}
                  colortext={"#ffffff"}
                  icon={<BsFillFilePdfFill />}
                  classname="programaspdf"
                  endPoint={`${BASE_URL}/estudiante/cursosActivos/exportar?IdUsuario=${IdUsuario}`}
              />
          </Button>
        </Col>
      </Row>
  </Card>
  <DynamicTable
    titleColumn={['N°','Nombre','Acción']}
    attributes = { ['Nombre']}
    onUpdate={false}
    onDelete={false}
    handleOptionClick= {handleOptionClick}
    labelOptionClick= {'Ver Programa'}
    classNameOptionIcon={'bi bi-book'}
    endPoint={`estudiante/cursosActivos?IdUsuario=${IdUsuario}`}
  ></DynamicTable>
  </>)
}

export { CursosActivosContainer }