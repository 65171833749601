import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormService } from "../../../../../services/api/formService";
import { useParams } from "react-router-dom";
import { Doughnut, Bar } from 'react-chartjs-2';
import { Message } from "../../../../../components/Message/Message";
import { 
    Chart as ChartJS, 
    ArcElement, 
    Tooltip, 
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
} from 'chart.js';

import './Trimester.css';
import { Select } from "../../../../../components/select/Select";
import { Button, Card, Col, Form, ListGroup, Modal, Row } from "react-bootstrap";
import {BASE_URL} from "../../../../../services/api/config"
import DowloadFile from "../../../../../components/DowloadFile/DowloadFile";
import { useDispatch, useSelector } from "react-redux";
import { setItemSelected } from "../../../../../components/DynamicForm/FormSlice";
import { useFetchGet } from "../../../../../hooks/useFetchGet";


ChartJS.register(
    ArcElement, 
    Tooltip, 
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
);

const Trimester = () => {

    const [error, setError] = useState(false);
    const [metricData, setMetricData] = useState({});
    const [barData, setBarData] = useState([]);
    const dispatch= useDispatch();
    const itemSelected = useSelector((state) => state.form.itemSelected)

    // consultar los años diponibles
    const [yersdisponible]= useFetchGet(`yearsdisponible`);

    // rutas descargables cea
    const es_no_contestaron_xls=`${BASE_URL}/exportar/no_contestadas_usuarios?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const do_no_contestaron_xls=`${BASE_URL}/exportar/no_contestadas_docentes?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const co_no_contestaron_xls=`${BASE_URL}/exportar/no_contestadas_coordinadores?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const nu_instrumentos_xls=`${BASE_URL}/exportar/instrumentos_generados?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const ins_diligenciasdos_xls=`${BASE_URL}/exportar/instrumentosdiligenciados?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const es_no_contestaron=`${BASE_URL}/instrumento/no_contestadas_usuarios?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const do_no_contestaron=`${BASE_URL}/instrumento/no_contestadas_docentes?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const co_no_contestaron=`${BASE_URL}/instrumento/no_contestadas_coordinadores?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const nu_instrumentos=`${BASE_URL}/exportar/numeroInstrumentos?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
    const ins_diligenciasdos=`${BASE_URL}/exportar/instrumentosDiligenciados?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}`
  

    const [modal,setModal]=useState(false)

    const { id } = useParams()
    const navigate= new useNavigate();


    const filterData = (data) => {
        const  dataForBar= []
        data?.tipos_evaluaciones.map(tipo => {
            dataForBar.push(tipo.contador)
        })
        return dataForBar;
    }

    const fetchDataTrimester = async () => {
        const formService = new FormService();
        try {
            const response = await formService.getAll(
                `instrumento/metricas?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}&ies=${itemSelected?.ies}`
            )
            setMetricData(response.metricas)
            const dataForBar = filterData(response.metricas)
            setBarData(dataForBar)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        if(!itemSelected?.year){
            dispatch(setItemSelected(
                {
                    year:'2023',
                    year2:'2023',
                    trimestre:'01-01 00:00:00',
                    trimestre2:'12-31 00:00:00',
                    ies:true
                }
            ))
        }
        
        const fetchData = async () => {
            await fetchDataTrimester();
        }
        fetchData();
    }, [])

    const data = {
        labels: [`Evaluaciones Completadas ${metricData?.evaluaciones_completadas}`, `Evaluaciones Incompletas ${metricData?.evaluaciones_generadas - metricData?.evaluaciones_completadas || 0}`],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    metricData?.evaluaciones_completadas, 
                    (metricData?.evaluaciones_generadas - 
                    metricData?.evaluaciones_completadas) || 0
                ],
                backgroundColor: [
                    '#006eb7',
                    '#8c8f90',
                ],
                borderColor: [
                    '#005aa6',
                    '#8c8f90',
                ],
                borderWidth: 1,
            },
        ],
    };

    const data2 = {
        labels: [`Eventos Evaluados ${metricData?.eventos_evaluados} `, `Eventos sin Evaluar ${metricData?.eventos-metricData?.eventos_evaluados || 0}`],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    metricData?.eventos_evaluados, 
                    (metricData?.eventos - 
                    metricData?.eventos_evaluados) || 0
                ],
                backgroundColor: [
                    '#006eb7',
                    '#8c8f90',
                ],
                borderColor: [
                    '#005aa6',
                    '#8c8f90',
                ],
                borderWidth: 1,
            },
        ],
    };
    const data3 = {
        labels: [`Docentes evaluados ${metricData?.docentes_evaluados} `, `Docentes sin Evaluar ${metricData?.docentes-metricData?.docentes_evaluados || 0}`],
        datasets: [
            {
                label: '# of Votes',
                data: [
                    metricData?.eventos_evaluados, 
                    (metricData?.eventos - 
                    metricData?.eventos_evaluados) || 0
                ],
                backgroundColor: [
                    '#006eb7',
                    '#8c8f90',
                ],
                borderColor: [
                    '#005aa6',
                    '#8c8f90',
                ],
                borderWidth: 1,
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Total de Evaluaciones por Tipo',
            },
        },
    };

    const trimestre1Options = [
        { text: 'Trimestre 1', value: '01-01 00:00:00' },
        { text: 'Trimestre 2', value: '03-01 00:00:00' },
        { text: 'Trimestre 3', value: '06-01 00:00:00' },
        { text: 'Trimestre 4', value: '09-01 00:00:00' },
    ];

    const semestre1Options = [
        { text: 'Semestre 1', value: '01-01 00:00:00' },
        { text: 'Semestre 2', value: '07-01 00:00:00' },
    ];
  
    const trimestre2Options = [
        { text: 'Trimestre 1',value:"02-31 00:00:00" },
        { text: 'Trimestre 2',value:"05-31 00:00:00" },
        { text: 'Trimestre 3',value:"08-31 00:00:00" },
        { text: 'Trimestre 4',value:"12-31 00:00:00" },
    ];

    const semestre2Options = [
        { text: 'Semestre 1', value: '06-31 00:00:00' },
        { text: 'Semestre 2', value: '12-31 00:00:00' },
    ];

    const labels = [
        'Percepción servicio educativo',
        'Estudiantes egresados',
        'Estudiante - docente',
        'Auto evaluación',
        'Heteroevaluación Docente',
        'Co evaluación docente',
        'Seguimiento en aula'
    ];

    const dataVerticalBar = {
        labels,
        datasets: [
            {
                label: 'Número de Evaluaciones',
                data: barData,
                backgroundColor: '#009490',
            }
        ],
    }
    const onChange = (value,name) => {
        dispatch(setItemSelected({
            ...itemSelected,[name]:value
        }))
        console.log(itemSelected)
    }

    const irListado=(item)=>{
        if(item==1){
            navigate("docentes",{ state: {ies:itemSelected?.ies,FechaInicio:`${itemSelected?.year}-${itemSelected?.trimestre}`,FechaFin:`${itemSelected?.year2}-${itemSelected?.trimestre2}`} })
        }
        if(item==2){
            navigate("coordinadores",{ state: {ies:itemSelected?.ies,FechaInicio:`${itemSelected?.year}-${itemSelected?.trimestre}`,FechaFin:`${itemSelected?.year2}-${itemSelected?.trimestre2}`} })
        }
        if(item==3){
            navigate("eventos",{ state: {ies:itemSelected?.ies,FechaInicio:`${itemSelected?.year}-${itemSelected?.trimestre}`,FechaFin:`${itemSelected?.year2}-${itemSelected?.trimestre2}`} })
        }  
    }
    const consultar=async (ies)=>{
        let ies_s=true
        if(ies){
            ies_s=itemSelected?.ies?false:true
        }else{
            ies_s=itemSelected?.ies
        }
        const formService = new FormService();
        try {
            const response = await formService.getAll(
                `instrumento/metricas?FechaInicio=${itemSelected?.year}-${itemSelected?.trimestre}&FechaFin=${itemSelected?.year2}-${itemSelected?.trimestre2}&ies=${ies_s}`
            )
            setMetricData(response.metricas)
            const dataForBar = filterData(response.metricas)
            setBarData(dataForBar)
        } catch (err) {
            setError(err)
        }
    }
    return (
        <>
            <Card>
                <Row>
                    <Col sm="8"><Card.Title>Reportes por Período</Card.Title></Col>
                </Row>
            </Card>
            <Card>
                <div className="row">
                    <div className="col">
                        <p className="text-center">Período Inicial</p>
                        <div className="row">
                            <div className="col">
                                <Select
                                    value={itemSelected?.year}
                                    onChange={ onChange }
                                    name={`year`}
                                    optionDefault='2023'
                                    options={yersdisponible}
                                    optionName='year'
                                    optionValue='year'
                                ></Select>
                                </div>
                            <div className="col">
                                <Select
                                    value={itemSelected?.trimestre}
                                    name={`trimestre`} 
                                    onChange={onChange}
                                    optionDefault='Trimestre 1' 
                                    options={itemSelected?.ies?semestre1Options:trimestre1Options}
                                    optionName='text'
                                    optionValue='value'
                                >
                                </Select>
                            </div>
                        </div>                           
                    </div>
                    <div className="col">
                        <p className="text-center">Período Final</p>
                        <div className="row">
                            <div className="col">
                                <Select
                                    value={itemSelected?.year2}
                                    onChange={ onChange }
                                    name={`year2`}
                                    optionDefault='2023' 
                                    options={yersdisponible}
                                    optionName='year'
                                    optionValue='year'
                                ></Select>
                                </div>
                            <div className="col">
                                <Select 
                                    value={itemSelected?.trimestre2}
                                    onChange={onChange}
                                    name={`trimestre2`}
                                    optionDefault='Trimestre 1' 
                                    options={itemSelected?.ies?semestre2Options:trimestre2Options}                                       
                                    optionName='text'
                                    optionValue='value'
                                    >
                                </Select>
                                </div>
                        </div>
                    </div>
                    <div className="col">
                        <div style={{marginTop:40}}>
                            <Button onClick={()=>consultar()}>
                                Consultar
                            </Button>
                        </div>                                                            
                    </div>
                </div>
                
            </Card>
            {
                error && (
                    <Message type='error'>
                        { error.message }
                    </Message>
                )
            }
            <div className="trimester__container">
                    <div className="trimester__card">
                        <div onClick={()=>irListado(1)}>                            
                        <Card className='text-center m-3' >
                            <h2>
                                { metricData.docentes_evaluados || 0 }
                            </h2>
                            <p className='m-0'>Docentes</p>
                        </Card>
                        </div>
                        {/* <div onClick={()=>irListado(2)}>
                            <Card className='text-center m-3'>
                                <h2>
                                    { metricData.coordinadores || 0 }
                                </h2>
                                <p className='m-0'>Coordinadores</p>
                            </Card>
                        </div> */}
                        <div onClick={()=>irListado(3)}>
                            <Card className='text-center m-3'>
                                <h2>
                                    { metricData.eventos_evaluados || 0 }
                                </h2>
                                <p className='m-0'>Programas Académicos</p>
                            </Card>
                        </div>
                    </div>
                    <div className='trimester__buttons'>
                        <Button onClick={()=>setModal(true)}>
                            Generar Reportes
                        </Button>
                    </div>
                    <Card className='trimester__bar'>
                        <Bar
                            options={ options }
                            data={ dataVerticalBar }
                        />
                    </Card>
                    <Card className='trimester__doughnut'>
                        <h6>
                            Total de Evaluaciones 
                            Generadas { metricData.evaluaciones_generadas || 0 }
                        </h6>
                        <Doughnut
                            data={ data }
                        />
                    </Card>
                    {!itemSelected?.ies &&
                        <Card className='trimester__doughnut'>
                            <h6>
                                Actividades con programación académica durante el periodo  {`(${metricData.eventos || 0})` }
                                
                            </h6>
                            <Doughnut
                                data={ data2 }
                            />
                        </Card>
                    }
                    
                    <Card className='trimester__doughnut'>
                        <h6>
                        Docentes con participación durante el periodo {`(${metricData.docentes || 0})` }
                            
                        </h6>
                        <Doughnut
                            data={ data3 }
                        />
                    </Card>
                </div>
                
            <Modal
                show={modal}
                onHide={()=>setModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Generar Reportes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile 
                                endPoint={ es_no_contestaron}
                                name={`Estudiantes que no Contestaron.pdf`}
                                nameFile={'Estudiante que no Contestaron.pdf'}
                            />
                            <DowloadFile 
                                endPoint={ es_no_contestaron_xls}
                                name={`Estudiantes que no Contestaron.xlsx`}
                                nameFile={'Estudiante que no Contestaron.xlsx'}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={ do_no_contestaron}
                                name={`Docentes que no Contestaron.pdf`}
                                nameFile={`Docentes que no Contestaron.pdf`}
                            />
                            <DowloadFile
                                endPoint={ do_no_contestaron_xls}
                                name={`Docentes que no Contestaron.xlsx`}
                                nameFile={`Docentes que no Contestaron.xlsx`}
                            />
                        </ListGroup.Item>
                        {/* <ListGroup.Item>
                            <DowloadFile
                                endPoint={ co_no_contestaron}
                                name={`Coordinadores que no Contestaron.pdf`}
                                nameFile={`Coordinadores que no Contestaron.pdf`}
                            />
                            <DowloadFile
                                endPoint={ co_no_contestaron_xls}
                                name={`Coordinadores que no Contestaron.xlsx`}
                                nameFile={`Coordinadores que no Contestaron.xlsx`}
                            />
                        </ListGroup.Item> */}
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={ nu_instrumentos}
                                name={`Número de Instrumentos Generados.pdf`}
                                nameFile={`Número de Instrumentos Generados.pdf`}
                            />
                            <DowloadFile
                                endPoint={ nu_instrumentos_xls}
                                name={`Número de Instrumentos Generados.xlsx`}
                                nameFile={`Número de Instrumentos Generados.xlsx`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile
                                endPoint={ ins_diligenciasdos}
                                name={`Instrumentos Diligenciados.pdf`}
                                nameFile={`Instrumentos Diligenciados.pdf`}
                            />
                            <DowloadFile
                                endPoint={ ins_diligenciasdos_xls}
                                name={`Instrumentos Diligenciados.xlsx`}
                                nameFile={`Instrumentos Diligenciados.xlsx`}
                            />
                        </ListGroup.Item>
                    </ListGroup>               
                </Modal.Body>
            </Modal>
        </>
    )
}

export { Trimester }