import React, { useState } from 'react'
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile';
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { BASE_URL, servidorActual } from '../../../services/api/config'
import { Select } from '../../../components/select/Select';
import ModalFormatosAdmision from './ModalFormatosAdmision';

export default function FormatosCursoIES({IdOferta,oferta,fasesLista,full=true}) {
    const URL_EXPORT = `${BASE_URL}/exportar/`
    const [modalDescargar,setModalDescargar]=useState();
    const [fechaInicial,setFechaInicial]=useState(oferta.FechaInicioCurso);
    const [fechaFinal,setFechaFinal]=useState(oferta.FechaFinCurso);
    const [modalHorario,setModalHorario]=useState();
    const [horario, setHorario] = useState();
    const [modalAsistencia,setModalAsistencia]=useState();
    const [malla, setMalla] = useState({})
    const [modalCalificaciones,setModalCalificaciones]=useState();
    const [tipoArchivo,setTipoArchivo]=useState();
    // sumar 6 dias ala fecha inicial
    const sumarDias = (FechaInicioCurso, dias) => {
        // Convertir fecha de MySQL a fecha de JavaScript
        const fecha = new Date(FechaInicioCurso.replace(/-/g, '/'));
        fecha.setDate(fecha.getDate() + dias);
        fecha.setHours(0, 0, 0, 0); // Establecer horas, minutos, segundos y milisegundos en 0
        // Convertir fecha de JavaScript a fecha de MySQL
        const fechaMySQL = fecha.toISOString().slice(0, 10);
        return fechaMySQL;
      }

  return (
    <>
        <Row>
                        <Col>
                            <Card>
                                <Button 
                                    variant="outline-primary"
                                    onClick={()=>setModalAsistencia(true)}
                                >
                                    Asistencias
                                </Button>
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <Button
                                    variant="outline-primary"
                                    onClick={()=>setModalCalificaciones(true)}
                                    >
                                        Exportar Socialización de Notas
                                    </Button>
                            </Card>
                        </Col>
                        {full &&
                            <Col>
                                <Card>
                                    <DowloadFile 
                                    nameFile={"Inscritos.xlsx"}
                                    name={"Inscritos"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}
                                    />
                                </Card>
                            </Col>        
                        }
                    </Row>
                    <Row>
                        <Col>
                        <Card>
                        <Button 
                            variant="outline-primary"
                            onClick={()=>setModalHorario(true)}
                        >
                            Horario del Curso
                        </Button>
                        </Card>
                        </Col>
                        {full &&
                            <Col>
                                <Card>
                                <DowloadFile
                                    nameFile={"Inscritos Eliminados.xlsx"}
                                    name={"Inscritos Eliminados"}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}
                                />
                                </Card>
                            </Col>
                        }                        
                        <Col>
                            <Card>
                            <DowloadFile 
                            nameFile={"Estudiantes.xlsx"}
                            name={"Estudiantes"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}
                        />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Card>
                            <DowloadFile
                                nameFile={"Promedios del Programa.pdf"}
                                name={"Promedios del Programa"}
                                endPoint={`${BASE_URL}/formatosIES/promedioCurso?IdOferta=${IdOferta}&Ubicacion=${servidorActual}`}
                            />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <DowloadFile
                                    nameFile={"Estudiantes Becados.pdf"}
                                    name={"Estudiantes Becados"}
                                    endPoint={`${BASE_URL}/formatosIES/estudiantesBecados?IdOferta=${IdOferta}`}
                                />
                            </Card>
                        </Col>
                        <Col>
                            <Card>
                                <DowloadFile
                                    nameFile={"Estudiantes notas no regulares.xlsx"}
                                    name={"Estudiantes notas no regulares"}
                                    endPoint={`${BASE_URL}/formatosIES/estudiantesNotasNoRegulares?IdOferta=${IdOferta}`}
                                />
                            </Card>
                        </Col>
                    </Row>
                    {full &&
                        <Row>
                            <Col>
                            <Card>
                                <Button onClick={()=>setModalDescargar(true)}>
                                    Otros Formatos
                                </Button>
                                
                            </Card>
                            </Col>
                            <Col>
                            </Col>
                        </Row>
                    }
            <ModalFormatosAdmision
                modalDescargar={modalDescargar}
                setModalDescargar={setModalDescargar}
                IdOferta={IdOferta}
                fasesLista={fasesLista}
                oferta={oferta}
            />

            {/* filtra descarga del horario por fecha inicial y fecha fina en un modal    */}
            <Modal
                show={modalHorario}
                onHide={()=>setModalHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Horario del Curso</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* seleccionar si desea dercar excel o pdf */}
                    <Select
                        id="TipoArchivo"
                        name="TipoArchivo"
                        onChange={ (e) => setTipoArchivo(e) }
                        value={ tipoArchivo || null }
                        label="Seleccionar Tipo de Archivo"
                        required={true}
                        optionValue="IdTipoArchivo"
                        optionName="Nombre"
                        options={[
                            {IdTipoArchivo:1,Nombre:"PDF"},
                            {IdTipoArchivo:2,Nombre:"Excel"}
                        ]}
                    />
                        <Form.Group>
                            <Form.Control
                                type="date"
                                id="FechaInicial"
                                name="FechaInicial"
                                value={fechaInicial}
                                onChange={ (e) => setFechaInicial(e.target.value) }
                                label="Fecha Inicial"
                                required={true}
                            />
                        </Form.Group>
                        <br></br>
                        <Form.Group>
                            <Form.Control
                                type="date"
                                id="FechaFinal"
                                name="FechaFinal"
                                value={fechaFinal}
                                onChange={ (e) => setFechaFinal(e.target.value) }
                                label="Fecha Final"
                                required={true}
                            />
                        </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    {tipoArchivo && fechaInicial && fechaFinal &&  
                        <>
                            {tipoArchivo==2
                                ?
                                <DowloadFile
                                    nameFile={"Horario del Curso.xlsx"}
                                    name={"Descargar Horario"}
                                    endPoint={`${BASE_URL}/excel/exportarHorarioDetalladoExcel?IdOferta=${IdOferta}&IdTipoArchivo=${tipoArchivo}&fechaInicio=${fechaInicial}&fechaFin=${fechaFinal}`}
                                />
                                :
                                <DowloadFile
                                    nameFile={"Horario del Curso.pdf"}
                                    name={"Descargar Horario"}
                                    endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}&IdTipoArchivo=${tipoArchivo}&fechaInicio=${fechaInicial}&fechaFin=${fechaFinal}`}
                                />
                            }
                        </>
                    }                        
                </Modal.Footer>
            </Modal>
            {/* modal para descargar asistencias de la asigantura */}
            <Modal
                show={modalAsistencia}
                onHide={()=>setModalAsistencia(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`cursos/asignaturasAsistencias?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {malla &&
                        <>
                        <DowloadFile 
                            nameFile={"Asistencias.pdf"}
                            name={"Asistencias"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>
            {/* modal para descargar las calificaciones de la asignatura */}
            <Modal
                show={modalCalificaciones}
                onHide={()=>setModalCalificaciones(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Socialización de Notas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                        <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`cursos/asignaturasCalficaciones?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {malla &&
                        <>
                        <DowloadFile
                            nameFile={"Socialización de Notas.pdf"}
                            name={"Calificaciones"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>

    </>
  )
}
