import React, { useEffect, useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { BsAward } from "react-icons/bs";
import { useParams } from 'react-router-dom';
import { useForm } from '../../hooks/useForm' 
import { BASE_URL } from '../../services/api/config';
import { FormService } from '../../services/api/formService' 

const ValidaCertificado = () => {

    const {setLoading} = useForm()
    const [color, setColor] = useState("red");
    const [autentico, setAutentico] = useState(false);
    const [datosDocumento, setDatosDocumento] = useState();
    const {valor} = useParams(false);
    const urlValidacion = `validacionCertificados?valor=${valor}`
    const api = new FormService()

    const fecthDataSelect = async () => {
        const respuesta = await api.getAll(urlValidacion);

        if(respuesta.respuesta === "1") {
            setColor("green");
            setAutentico(true);
            setDatosDocumento(respuesta.registro)
            console.log(datosDocumento.Nombre)
        } else {
            setColor("red");
            setAutentico(false);
        }
    };

    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            await fecthDataSelect();
            setLoading(false);
        };
        fecthData();
    }, []);

    return (
        <>
            <Modal 
                show={ true }
                onHide={ false }
            >
                <Modal.Body>
                    <div style={{textAlign: 'center', marginBottom: '70px'}}>
                        <p style={{marginBottom: '20px'}}>
                            Validación de certificados CEA
                        </p>
                        <div style={{marginBottom: '50px'}}>
                            <BsAward style={{color: color, fontSize: '100px'}}></BsAward> 
                        </div>

                        {autentico &&
                        <h5 style={{textAlign: 'justify', fontFamily: 'Lato', padding: '10px', fontSize: '20px'}}> 
                            <p> La plataforma Halcón ERP®: </p>
                            <br />
                            <p>
                            Certifica que el documento fue generado de acuerdo 
                            a la información reportada en las bases de datos.
                            </p>
                            <p>
                            Para validar el contenido del presente documento, puede comprobar que los siguientes datos
                            coinciden con los que aparecen en el documento que está verificando.
                            </p>
                            <br />
                            {datosDocumento.Nombre &&
                                <>                            
                                    Nombre(s) y Apellido(s): <p style={{fontFamily: "Cambria"}}>{datosDocumento?.Nombre}</p>                        
                                    Número de Identificación Personal: <p style={{fontFamily: "Cambria"}}>{datosDocumento?.NumeroDocumento}</p>
                                </>
                            }
                            
                            Programa Académico: <p style={{fontFamily: "Cambria"}}>{datosDocumento?.actividadPrograma}</p>
                            <br />

                            Para mayor información comunicarse al Centro de 
                            Estudios Aeronáuticos, Grupo Admisión, Registro y Asuntos Jurídicos. 
                            
                            <br />
                            <br />
                            <br />
                            <p style={{fontSize: "11px"}}><b>Tecnología Blockchain.</b></p>
                        </h5>
                        }   
                        {!autentico &&
                        <h5 style={{textAlign: 'justify', fontFamily: 'Lato', padding: '10px', fontSize: '20px' }}> 
                            <p>
                                La plataforma Halcón ERP®: 
                                <br />
                                No reconoce este documento en las bases de datos.
                            </p>
                            <br />
                            <p>
                                Para mayor información comunicarse al Centro de 
                                Estudios Aeronáuticos, Grupo Admisión, Registro y Asuntos Jurídicos.
                            </p>
                            <br />
                            <br />
                            <p style={{fontSize: "11px"}}>
                                <b>
                                    Tecnología Blockchain.
                                </b>
                            </p>
                        </h5>
                        }
                    </div>
                </Modal.Body>                    
                    <Modal.Footer>
                    </Modal.Footer>
            </Modal>          
        </>
    )
}

export { ValidaCertificado }