import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";

import { AdmissionContainer } from "../../../Student/Admission/AdmissionContainer";
import { DocumentUploadContainer } from "../../../Student/DocumentUpload/DocumentUploadContainer";
import { MatriculaAspiranteContainer } from "../../../Student/MatriculaAspirante/MatriculaAspiranteContainer";
import { FormService } from '../../../../services/api/formService';
import { EnrollmentProcess } from "../../estudiante/EnrollmentProcess/EnrollmentProcess";
import ActualizarDatosAspirante from './ActualizarDatosAspirante';

function AspiranteProcesoInscripcion ({
  matricula = true
}) {

  const [respondio, setRespondio] = useState(true);
  const [respondioBienestar, setRespondioBienestar] = useState(true);
  const location = useLocation();
  const { state: { offer,applicant } } = location;
  const api = new FormService();

  const consultarRespondio=async()=>{
    const respuesta = await api.getAll(`aspirante/respondio?IdAspirante=${offer.IdAspirante}&IdUsuario=${offer.IdUsuario}`)
    if(respuesta.respuesta=="error"){
      setRespondio(false)
    }else{
      setRespondio(true)
    }
  }

  const consultaRespondioBienestar=async()=>{
    const api = new FormService()
    const respuesta =await api.getAll(`estudiante/respondio?IdOferta=${offer.IdOferta}&IdAspirante=${applicant.IdAspirante}&IdUsuario=${applicant.IdUsuario}`)
    if(respuesta.respuesta==="exito"){
      setRespondioBienestar(false)
    }else{
      setRespondioBienestar(true)
    }
}

  useEffect(() => {
    consultarRespondio();
    consultaRespondioBienestar();
  });

  return (
    <>
      {/* {respondio ?
        <ActualizarDatosAspirante setRespondio={setRespondio}/>
      : */}
      <EnrollmentProcess
        isApplicant={ true } 
        aspirante={applicant}
        item={offer}
        respondioBienestar={respondioBienestar}
        consultaRespondioBienestar={consultaRespondioBienestar}
        matricula={matricula}
      >
        <DocumentUploadContainer 
          offer={ offer.IdOferta }
          item={ offer }
        />
        
        <AdmissionContainer
          item={ offer }
        />

        <MatriculaAspiranteContainer 
          offer={ offer.IdOferta }
          item={ offer }
          aspirante={applicant}
        />
      </EnrollmentProcess>
      {/* } */}
    </>
  )
}
export { AspiranteProcesoInscripcion }