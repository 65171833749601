import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import { DocumentsContainer } from '../../Applicant/Documents/DocumentsContainer'
import { AdmissionContainer } from '../../Applicant/Admission/AdmissionContainer'
import { Enrollment } from '../../Applicant/Documentos2/Enrollment'
import { EnrollmentProcess } from '../estudiante/EnrollmentProcess/EnrollmentProcess'

import { Documentos2Container } from '../../Applicant/Documentos2/Documentos2Container'

import './Applicant.css'
import { useEffect } from 'react'

const Applicant = ({
    full,
    editable=true,
    matricula=true
}) => {

    const [isCompletedDocuments, setIsCompletedDocuments] = useState(true)
    const [isCompletedAdmission, setIsCompletedAdmission] = useState(true)
    const [isCompletedEnrollment, setIsCompletedEnrollment] = useState(true)
    const { state:{ applicant, offer } }  = useLocation()

    useEffect(()=>{
        if(applicant.EstadoRequisitos!=0){
            setIsCompletedDocuments(false)
        }
        if(applicant.EstadoAdmision!=0 || applicant.EstadoRequisitos!=1){
                setIsCompletedAdmission(false)
        }
        if(applicant.EstadoEstudiante!=0 || applicant.EstadoRequisitos!=1 ||applicant.EstadoAdmision!=1){
            setIsCompletedEnrollment(false)
        }
    })
    return (
        <>
           <EnrollmentProcess 
                isApplicant={ false }
                offer={ offer }
                full={ full }
                matricula={ matricula }
            >
                <DocumentsContainer 
                    isCompletedDocuments={ isCompletedDocuments }
                    setIsCompletedDocuments={ setIsCompletedDocuments }
                    editable={editable}
                    oferta={offer}
                />
                    <AdmissionContainer 
                        isCompletedAdmission={ isCompletedAdmission }
                        setIsCompletedAdmission={ setIsCompletedAdmission }
                        aspirante={applicant}
                        full={full}
                        editable={editable}
                        oferta={offer}
                    />               
                { offer.modo_full == 1 && 
                    <Documentos2Container 
                        isCompletedEnrollment={ isCompletedEnrollment }
                        setIsCompletedEnrollment={setIsCompletedEnrollment}
                        applicant={applicant} oferta={offer} 
                        editable={editable}
                    />
                }
           </EnrollmentProcess>
        </>
    )
}

export { Applicant }