import React from 'react'
import { Button, Card } from 'react-bootstrap'
import TablePaginate from '../../../components/TablePaginate/TablePaginate'
import { useNavigate } from 'react-router';

export default function Programas() {
    const navigate = useNavigate();
  return (
    <>
        <Card>
            <Card.Title> Programas </Card.Title>
        </Card>

        <TablePaginate
            apiUrl={"analitica/programas"}
            tableHeaders={[
                {title:"Programa", attribute:"NombrePlanEstudio"},
                {title:"Código", attribute:"Codigo"},
                {title:"N° PlanEstudio", attribute:"NumeroPlan"},
            ]}
            rowActions={[
                ({rowData})=>(
                    <Button
                        variant="primary"
                        size="sm"
                        onClick={()=>{navigate(`${rowData.IdPlandeEstudio}`,{state:{programa:rowData}})}}
                    >
                        Graficas
                    </Button>
                ),
            ]}
        />
    </>
  )
}
