// import React from 'react'
// import { BASE_URL } from '../../../services/api/config'

// export default function FormatosEstudianteIES({
//   IdOferta,
//   IdEstudiante,
//   IdUsuario
// }) {

//   const urlCertificadoNotas=`${BASE_URL}/formatosIES/estudiante/certificadoNotas?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}`
//   const urlCertificadoAsistencia=`${BASE_URL}/formatosIES/estudiante/constancia?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}`
//   const urlDiploma=`${BASE_URL}/estudiante/certificadoDiploma?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}`
//   return (
//     <>    
//        {/* <a
//           className="button"
//           target={'_blank'}
//           rel="noreferrer"
//           href={urlDiploma}  
//         >
//             <i class="bi bi-file-earmark-text"/>
//             Diploma
//         </a> */}
  
//       <a 
//         className="button"
//         target={'_blank'}
//         rel="noreferrer"
//         href={urlCertificadoNotas} 
//         >
//           <i class="bi bi-file-earmark-text"/>
//           Certificado de Notas
//       </a>
//       <a 
//         className="button"
//         target={'_blank'}
//         rel="noreferrer"
//         href={urlCertificadoAsistencia} 
//         ><i class="bi bi-file-earmark-text"/>
//         Certificado de constancia
//       </a>
//     </>
//   )
// }


import React from 'react'
import DowloadFile from '../../../components/DowloadFile/DowloadFile'
import { BASE_URL } from '../../../services/api/config'

export default function FormatosEstudianteIES({
  IdOferta,
  IdEstudiante,
  IdUsuario
}) {

  const URLActual = window.location.origin
  const urlCertificadoNotas=`${BASE_URL}/formatosIES/estudiante/certificadoNotas?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}&Ubicacion=${URLActual}`
  const urlCertificadoAsistencia=`${BASE_URL}/formatosIES/estudiante/constancia?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}&Ubicacion=${URLActual}`
  const urlDiploma=`${BASE_URL}/estudiante/certificadoDiploma?IdOferta=${IdOferta}&IdEstudiante=${IdEstudiante}`
  const urlFormatoInscripcion = `${BASE_URL}/exportar/inscripcion?IdEstudiante=${IdEstudiante}&IdUsuario=${IdUsuario}&IdOferta=${IdOferta}`
  
  return (
    <>    
       {/* <a
          className="button"
          target={'_blank'}
          rel="noreferrer"
          href={urlDiploma}  
        >
            <i class="bi bi-file-earmark-text"/>
            Diploma
        </a> */}
  
      <DowloadFile 
          nameFile={"Certificado Notas.pdf"}
          name={"Certificado Notas"}
          endPoint={urlCertificadoNotas}
      />
      <DowloadFile
          nameFile={"Certificado de Estudios.pdf"}
          name={"Certificado de Estudios"}
          endPoint={urlCertificadoAsistencia}
      />
      <DowloadFile 
          nameFile={"Formato de Inscripción.pdf"}
          name={"Formato Inscripción"}
          endPoint={urlFormatoInscripcion}
      />
    </>
  )
}
