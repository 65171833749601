
import { useLocation } from "react-router-dom"
import { OfferHeader } from "../../../../components/OfferHeader/OfferHeader"
import { useFetchGet} from "../../../../hooks/useFetchGet"
import { Badge } from "../../../../components/Badge/Badge"
import { Card } from "../../../../components/card/Card"
import { Input } from "../../../../components/input/input"
import { Modal } from "../../../../components/modal/Modal"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import {ID_FORM} from "../../../../constants/idForm"
import { Button } from "../../../../components/button/button"
import { useState } from "react"
import { FormService } from "../../../../services/api/formService"
import { alertaSinBoton } from "../../../../helpers/alertas"

function CursoFinalizado (){
  
  const { state:{programa} } = useLocation()
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
  const [estudiantes,isLoading,error] = useFetchGet(`estudiantesCurso?IdOferta=${programa.IdOferta}`)
  const [loading,setLoading] = useState(false)
  const enviarNotificaciones = () => {
    setLoading(true)
    const api = new FormService()
    api.getAll(`gestionInstrumentos/notificarEstudiantes?IdOferta=${programa.IdOferta}`).then(resp=>{
      alertaSinBoton("Se han enviado con exito!",'success','bottom-end',1500)
      setLoading(false)
    }).catch(err=>{
      
      alertaSinBoton("ERROR!",'warning','bottom-end',1500)
      setLoading(false)
    })
  }
    const cerrarModal=()=>{
      setTimeout(() => {
        setIsOpenModalAsignarEvaluacion(false)
      }, 3000);
    }
  return(
    <>
    {/* {JSON.stringify(programa) */}
    <Card>

      <OfferHeader
        offerName={programa.Nombre}
        img={programa.Repositorio}
        IdOferta={programa.IdOferta}
        horas={programa.horas}
        oferta={programa}
        isCursoFinalizado={true}
        offerDescription={programa.Observaciones}
        isFinally={true}
        />
    </Card>
    <Card>
      <div className="d-flex justify-content-around">
        <div className="d-flex">
          

        <Button
        hasButtonCss={false}
        className={'btn btn-outline-primary mr-1'}
        onClick={()=>setIsOpenModalAsignarEvaluacion(true)}
        >Asignar evaluación</Button>
      
        
        <Button
        loading={loading}
        hasButtonCss={false}
                className={'btn btn-outline-primary ml-1'}
        onClick={enviarNotificaciones}
        >Notificar evaluaciones</Button>
        </div>
        
        <Input 
        className={'w-100'}
        type='search'
        placeholder= 'Busca por nombre o cédula del estudiante'
        // value={ inputSearch }
        // onChange={ onSearch }
        />
      </div>
    </Card>
    
    {
      Array.isArray(estudiantes) && (
        <div className='students-list__list-container'>
            {
                estudiantes.map((student, index) => (
                    <Badge 
                        to={ 
                            `estudiante/${student?.IdEstudiante}` 
                        }
                        state={{ estudiante:student,programa }}
                        img={ student?.Foto }
                        alt='Foto de perfil de estudiante'
                        user={ student }
                        key={ index }
                    />
                ))
            }
        </div>
    )

}
<Modal
isOpen={isOpenModalAsignarEvaluacion}
close={()=>setIsOpenModalAsignarEvaluacion(false)}
>
  <DynamicForm
    endPointSubmit={'gestionInstrumentos/asignarEvaluacionOferta'}
    isCreate={true}
    extraData={{IdOferta:programa.IdOferta,Reporte:programa.IdDimension==1?"2":"1"}}
    ventanaFormName={'Gestión instrumentos'}
    justDynamicForm={{ flag:true, idForm:ID_FORM.GESTION_INSTRUMENTOS }}
    onClickCancelBtn={setIsOpenModalAsignarEvaluacion}
    accionMysql={()=>cerrarModal()}
  ></DynamicForm>
</Modal>
    
    </>
  )
}

export { CursoFinalizado }

