import React, { useEffect, useState } from 'react';

import ApiDashboard from '../../../../services/api/dashboard';
import { alertaSinBoton, alertaTimer } from '../../../../helpers/alertas';
import { Table } from '../../../../components/componentsForm/table/Table';
import { Select } from '../../../../components/select/Select';
import { Input } from '../../../../components/input/input';
import { BASE_URL } from '../../../../services/api/config';
import { FormService } from '../../../../services/api/formService';
import {ID_FORM} from '../../../../constants/idForm'
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { useNavigate } from 'react-router-dom';
import DowloadFileText from '../../../../components/DowloadFileText/DowloadFileText';
import { BsArrowReturnRight,BsFillFilePdfFill,BsFillFileExcelFill } from 'react-icons/bs';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { LocalStorage } from '../../../../services';
// modal type : create-delete-update
const DIMENSION={
    IES:'1',
    CIA:'2',
    TRAINAIR:'3',
    CEA:'4',
    ASTC:'5',
    CFBA:'6'
}

const AsignaturaFormContainer = () => {
    const [description, setDescription] = useState('');
    const [codigo, setCodigo] = useState('');
    const [IdDimension,setIdDimension] = useState(1)
    const [IdNucleoConocimiento,setIdNucleoConocimiento] = useState('')
    const [IdTipoAsignatura,setIdTipoAsignatura] = useState('')
    const [IdClase, setIdClase] = useState('');
    const [OrdenAsig, setOrdenAsig] = useState('');
    const api = new ApiDashboard();
    const [status, setStatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isOpenModalCreate, setIsOpenModalCreate] = useState(false);
    const [isOpenModalDelete, setIsOpenModalDelete] = useState(false);
    const [isOpenModalUpdate, setIsOpenModalUpdate] = useState(false);
    const [itemSelected, setItemSelected] = useState({});
    const [camposForm, setCamposForm] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [modalType, setModalType] = useState('');

    const storage=new LocalStorage();
    const dimension=storage.getItem("dimension");
    const urlasignaturas = dimension=='"true"'?
                            `${BASE_URL}/exportarAsignaturas?IdDimension=${DIMENSION.IES}`   
                            :
                            `${BASE_URL}/exportarAsignaturas?IdDimension=${DIMENSION.CIA}`
    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
        )
    const endPointLastUrl = 'asignatura'; /// url for service

    const [list, setList] = useState([]);

    const fetchCamposFormForFilter = ()=>{
        
            const api = new FormService()
            api.getAll(`xcampos?id_formulario=${ID_FORM.ASIGNATURAS}`)
            .then((res)=>{
                setCamposForm(res)
      
            })
          
    }
    const Navigate=useNavigate()
    //! LOAD_DATA_API */
    useEffect(() => {
        

        const fecthData = async () => {
            setLoading(true);
            await fetchDataTable();
            //fecthDataSelect();
            setLoading(false);
        };
        fecthData();
        fetchCamposFormForFilter()
    }, []);


    //! SUBMIT CREATE OR UPDATE
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        if (modalType === '/create') {
            createService();
        }
        if (modalType === '/update') {
            updateService();
        }
        setLoading(false);
    };

    //! VALIDACION DE TOKEN
    const fetchDataTable = async (endpointPagination=null) => {
        try {
            
            let endpointPag = endpointPagination
            if(endpointPag) endpointPag = endpointPagination.replaceAll(BASE_URL+'/','')
            console.log({endpointPag})
            const data = await api.getData(endpointPag || endPointLastUrl);
            if (!!data.data) {
                setDataTable(data.data);
                setPagination({
                    current_page:data.current_page,
                    prev_page_url:data.prev_page_url,
                    next_page_url:data.next_page_url,
                    per_page:data.per_page,
                    total:data.total
                })
            }
            console.log('dataxxx', data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
        } catch (e) {
            console.log('#Error fetching dataTable');
            //  setDataTable([])
        }
    };

    //! LIMPIAR FORMULARIO
    const cleanFormValues = () => {
        setDescription('');
        setCodigo('')
        setIdDimension('');
        setIdNucleoConocimiento('');
        setIdTipoAsignatura('');
        setIdClase('');

    };
    /* console.log(dataTable); */

    //! CLICK EVENTS
    const onCreate = () => {
        cleanFormValues();
        setIsOpenModalCreate(true);
        setModalType('/create');
       /*  console.log(list); */
    };
    const onDelete = (item) => {
        setItemSelected(item);
        // console.log(item, 'on delete selected:');
        setIsOpenModalDelete(true);
        setModalType('/delete');
    };
    const onUpdate = (item) => {
        setItemSelected(item);
        console.log('update item->', item);
        setDescription(item.Descripcion);
        setCodigo(item.CodigoExt)
        setIdDimension(item.IdDimension);
        setIdNucleoConocimiento(item.IdNucleoConocimiento);
        setIdTipoAsignatura(item.IdTipoAsignatura);
        setIdClase(item.IdClase);
        setOrdenAsig(item.Orden);
        setModalType('/update');
        setIsOpenModalUpdate(true);
    };

    //!CLOSE ALL MODALES
    const handleCloseModal = () => {
        setIsOpenModalCreate(false);
        setIsOpenModalUpdate(false);
        setIsOpenModalDelete(false);
    };

    //! SERVICE CREATE
    const createService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.createData(
                {
                    Descripcion: description,
                    CodigoExt: codigo,
                    IdTipoAsignatura: IdTipoAsignatura,
                    IdNucleoConocimiento: IdNucleoConocimiento.length === 0 ? null : IdNucleoConocimiento,
                    IdClase: IdClase,
                    Estado: 1,
                    Orden: OrdenAsig
                },
                endPointLastUrl
            );
            console.log('data create', data);

            if (!!data.length) await setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesión ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            if (data?.respuesta === "error") {
                alertaTimer(data?.mensaje, 'info', '4000');
                console.log('data exp', data);
            }else {
                setIsOpenModalCreate(false);
                alertaSinBoton(
                    'Se ha creado con éxito',
                    'success',
                    'center',
                    '2000'
                );
                fetchDataTable();
                cleanFormValues();
            }
        } catch (e) {}
    };

    //! SERVICE DELETE
    const deleteService = async () => {
        try {
            console.log('datain delete serivce exp', itemSelected);
            const api = new ApiDashboard();
            const data = await api.deleteData(
                {
                    Estado: 0,
                },
                itemSelected.IdAsignatura,
                endPointLastUrl
            );
            // console.log("data response delete",data,itemSelected.IdPaises)
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                // console.log("data exp",data)
            }
            setIsOpenModalCreate(false);
            handleCloseModal();
            alertaSinBoton(
                'Se ha eliminado con Éxito',
                'success',
                'center',
                '2000'
            );

            fetchDataTable();
            cleanFormValues();
            setItemSelected({});
        } catch (e) {
            console.log('error delete');
        }
    };

    //! SERVICE UPDATE
    const updateService = async () => {
        try {
            const api = new ApiDashboard();
            const data = await api.updateData(
                {
                    Descripcion: description,
                    IdTipoAsignatura: IdTipoAsignatura,
                    CodigoExt:codigo,
                    IdNucleoConocimiento: IdNucleoConocimiento,
                    IdClase:IdClase,
                    Orden: OrdenAsig
                },
                itemSelected.IdAsignatura,
                endPointLastUrl
            );
            if (!!data.length) setDataTable(data);
            if (data?.status === 'Token is Expired') {
                alertaTimer('La sesion ha expirado', 'info', '4000');
                console.log('data exp', data);
            }
            if (data?.respuesta === "error") {
                alertaTimer(data?.mensaje, 'info', '4000');
                console.log('data exp', data);
            }else {
                setIsOpenModalUpdate(false);
                alertaSinBoton(
                    'Se ha actualizado con Éxito',
                    'success',
                    'center',
                    '2000'
                );
            }
            fetchDataTable();
            setItemSelected({});
        } catch (error) {}
    };
    
    const onSearch = (urlsParams) => {
        fetchDataTable('asignatura'+urlsParams)
    }

    const Form = () => {
        return (
            <>
                
            </>
        );
    };

    return (

        <div>
            <Card>
             <Row>
                 <Col >
                     <Card.Title>Asignaturas ES</Card.Title>
                 </Col>
                 <Col
                        style={{textAlign:"right"}}
                    >
                    <Button
                        style={{margin:2}}
                        variant="primary"
                        onClick={() => document.querySelector('.exportar').click()}
                    >
                        <DowloadFileText
                        nameFile={"Asignaturas.xlsx"}
                        name={"Exportar.xlsx"}
                        colortext={"#ffffff"}
                        icon={<BsFillFileExcelFill />}
                        classname="exportar"
                        endPoint={`${urlasignaturas}`}
                    />
                    </Button>
                    <Button
                        onClick={onCreate}
                        style={{marginRight:5}}
                    >
                        Crear
                    </Button>    
                    </Col>
                </Row>
            </Card>
            {
                camposForm &&
            <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
            }         
            <Table
                titleColumn={[
                    'No',
                    'Código',
                    'Nombre de la Asignatura',                    
                    'Carácter de la Asignatura',
                    'Orden',
                    'Acción',
                ]}
                data={dataTable}
                attributes={['CodigoExt','Descripcion', 'TipoAsignatura', 'Orden']} //codigo
                onDelete={onDelete}
                onUpdate={onUpdate}
                pagination={pagination}
                fetchDataTable={fetchDataTable}
                // titleDetails={'Prerrequisitos'}
                // onDetails={()=>irPrerrequsitos()}

            ></Table>
        {/* Modal de creación */}
        <Modal show={isOpenModalCreate} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Crear Asignatura</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
          <Modal.Body>
                    <div className="container">
                        <div className="row">
                            <Input
                                id="floatingTextarea"
                                label="Nombre"
                                type="text"
                                name="description"
                                value={description}
                                onChange={setDescription}
                            />
                             <Input
                                id="floatingTextarea"
                                label="Código"
                                type="text"
                                name="CodigoExt"
                                value={codigo}
                                onChange={setCodigo}
                            />
                            <Select
                                id="IdNucleoConocimiento"
                                name="IdNucleoConocimiento"
                                onChange={setIdNucleoConocimiento}
                                value={IdNucleoConocimiento}
                                label="Núcleo Básico de Conocimiento "
                                required={true}
                                optionValue="IdNucleoConocimiento"
                                optionName="Nombre"
                                urlOptionsService = {'nucleosConocimiento'}
                            />
                            <Select
                                id="IdTipoAsignatura"
                                name="IdTipoAsignatura"
                                onChange={setIdTipoAsignatura}
                                value={IdTipoAsignatura}
                                label="Carácter de la Asignatura"
                                required={true}
                                optionValue="IdTipoAsignatura"
                                optionName="Descripcion"
                                urlOptionsService = {'tipoAsignatura'}
                                
                            />                        
                            {/* <Select
                                id="Clase"
                                name="Clase"
                                onChange={setIdClase}
                                value={IdClase}
                                label="Clase"
                                required={true}
                                optionValue="IdClase"
                                optionName="Descripcion"
                                options={
                                    [
                                        { IdClase: 3, Descripcion: 'Básica' },
                                        { IdClase: 2, Descripcion: 'Específica' },
                                    ]
                                }
                            /> */}   
                            <Input
                                id="Orden"
                                label="Orden"
                                placeholder={'1'}
                                type="number"
                                name="Orden"
                                value={OrdenAsig}
                                onChange={setOrdenAsig}
                                required={true}
                            />                         
                        </div>
                    </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cerrar
            </Button>
            <Button variant="primary" type="submit">
              Crear
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
  
        <Modal show={isOpenModalUpdate} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Actualizar Asignatura</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
          <Modal.Body>
                <div className="container">
                    <div className="row">
                        <Input
                            id="floatingTextarea"
                            label="Nombre"
                            type="text"
                            name="description"
                            value={description}
                            onChange={setDescription}
                        />
                            <Input
                            id="floatingTextarea"
                            label="Código"
                            type="text"
                            name="CodigoExt"
                            value={codigo}
                            onChange={setCodigo}
                        />
                        <Select
                            id="IdNucleoConocimiento"
                            name="IdNucleoConocimiento"
                            onChange={setIdNucleoConocimiento}
                            value={IdNucleoConocimiento}
                            label="Núcleo Básico de Conocimiento"
                            required={true}
                            optionValue="IdNucleoConocimiento"
                            optionName="Nombre"
                            urlOptionsService = {'nucleosConocimiento'}
                        />
                        <Select
                            id="IdTipoAsignatura"
                            name="IdTipoAsignatura"
                            onChange={setIdTipoAsignatura}
                            value={IdTipoAsignatura}
                            label="Carácter de la Asignatura"
                            required={true}
                            optionValue="IdTipoAsignatura"
                            optionName="Descripcion"
                            urlOptionsService = {'tipoAsignatura'}
                            
                        />                        
                        {/* <Select
                            id="Clase"
                            name="Clase"
                            onChange={setIdClase}
                            value={IdClase}
                            label="Clase"
                            required={true}
                            optionValue="IdClase"
                            optionName="Descripcion"
                            options={
                                [
                                    { IdClase: 3, Descripcion: 'Básica' },
                                    { IdClase: 2, Descripcion: 'Específica' },
                                ]
                            }
                        /> */}
                        <Input
                            id="Orden"
                            label="Orden"
                            type="number"
                            name="Orden"
                            value={OrdenAsig}
                            onChange={setOrdenAsig}
                            required={true}
                        />                          
                    </div>
                </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cerrar
            </Button>
            <Button variant="primary" type="submit">
              Actualizar
            </Button>
          </Modal.Footer>
          </form>
        </Modal>
  
        {/* Modal de eliminación */}
        <Modal show={isOpenModalDelete} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Eliminar Asignatura</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>¿Estás seguro de que deseas eliminar esta asignatura?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Cancelar
            </Button>
            <Button variant="danger" onClick={deleteService}>
              Eliminar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
};

export { AsignaturaFormContainer };
