import { Route, Routes } from "react-router-dom"
import { DynamicFormContainer } from "../../components/DynamicForm/DynamicFormContainer"
import { Actividades } from "./Actividades"
import { AlertasAsignaturas } from "./AlertasAsignaturas"
import { AlertasInasistencias } from "./AlertasInasistencia"
import { AlertasSalud } from "./AlertasSalud"
import { AlertasSocioeconomicas } from "./AlertasSocioeconomicas"
import { AlertasEstudiantes } from "./AlertasEstudiantes"
import { Reportes } from "./Reportes"
import { Documentos } from "./Documentos"
import { SeguiminetoAlerta } from "./SeguiminetoAlerta"
import { FormularioPsicosocial } from "./FormularioPsicosocial"
import TutoriasAsesorias from "./TutoriasAsesorias"
import Vinculados from "./Vinculados"


const Bienestar=()=>{
    return(
        <Routes>
            <Route  path="actividades" element={<Actividades/>} />
            <Route  path="alertas_estudiantes" element={<AlertasEstudiantes/>} />
            <Route  path="alertas_estudiantes/seguimiento_alerta" element={<SeguiminetoAlerta cerrar/>} />
            <Route  path="actividades/documentos" element={<Documentos/>} />
            <Route  path="alertas_asignaturas" element={<AlertasAsignaturas/>} />
            <Route  path="alertas_inasistencias" element={<AlertasInasistencias/> } />
            <Route  path="alertas_salud" element={<AlertasSalud/> } />
            <Route  path="alertas_socioeconomicas" element={<AlertasSocioeconomicas/> } />
            <Route  path="alertas_asignaturas/seguimiento_alerta" element={<SeguiminetoAlerta cerrar/>} />
            <Route  path="alertas_inasistencias/seguimiento_alerta" element={<SeguiminetoAlerta cerrar/>} />
            <Route  path="alertas_salud/seguimiento_alerta" element={<SeguiminetoAlerta cerrar/>} />
            <Route  path="alertas_socioeconomicas/seguimiento_alerta" element={<SeguiminetoAlerta cerrar/>} />
            <Route  path="reportes" element={<Reportes/>}/>
            <Route  path="formulario_psicosocial" element={<FormularioPsicosocial/>}/>
            <Route  path="talleres_tutorias_y_asesorias" element={<TutoriasAsesorias/>}/>
            <Route  path="talleres_tutorias_y_asesorias/vinculados" element={<Vinculados/>} />
            <Route  path="*" element={<DynamicFormContainer />} />
        </Routes>
    )
}

export {Bienestar}