import React, { useState } from 'react';
import { Card, Table, Form, Button, Col, Row, Dropdown, Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import TablePaginate from '../../components/TablePaginate/TablePaginate';
import { setSearchFields } from '../../shared/store/table/tableSlice';
import Swal from 'sweetalert2';
import { DynamicTable } from '../../components/DynamicForm/dynamicTable/DynamicTable';
import { BASE_URL } from '../../services/api/config';
import DowloadFile from '../../components/DowloadFileText/DowloadFileText';
import { FormService } from '../../services/api/formService';
import { alerta } from '../../helpers/alertas';

export default function EmisionGrados() {
  const dispatch = useDispatch();
  const searchFieldsRedux = useSelector(state => state.table.searchFields);
  const [searchFields2, setSearchFields2] = useState({...searchFieldsRedux});
  const [showModal, setShowModal] = useState(false);
  const [modalGrados, setModalGrados] = useState(false)
  const [rowDataGrade, setRowDataGrade] = useState({})
  const ubicacion = window.location.host;
  const api = new FormService()

  const handleSearchChange = event => {
    setSearchFields2({ ...searchFields2, [event.target.name]: event.target.value });
  };

  const handleSearch = () => {
    console.log(searchFields2);
    dispatch(setSearchFields({ ...searchFields2 }));
  };

  const graduarEstudiante  = async (d) => {
    const respuesta = await api.getAll(`convocatoriaGrados/graduarAprobados?IdEstudiante=${d.IdEstudiante}`)

    if(respuesta.respuesta == 'exito') {
      handleSearch()
    } else {
      alerta("algo salio mal", "warning")
      handleSearch()
    }

  }

    const handleAction1 = (rowData) => {
        if(rowData.Graduado == 0 || rowData.Graduado == null) {
          Swal.fire({
              title: '¿Estás seguro?',
              text: 'Esta acción no se puede deshacer',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Aceptar',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                // El usuario ha aceptado la confirmación
                // Realiza la acción deseada
                graduarEstudiante(rowData)
                return console.log('Confirmación aceptada');
                
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // El usuario ha cancelado la confirmación
                console.log('Confirmación cancelada');
              }
            });   
        } else {
          setModalGrados(true) 
          setRowDataGrade(rowData)
        }        
    };
    
      // Acciones personalizadas
      const rowActions = [
        ({ rowData }) => (
          <Button variant="primary" 
            onClick={() => handleAction1(rowData)}
          >
            {rowData.Graduado == 0 ||  rowData.Graduado == null?
              "Graduar"
              :
              "Exportables de Grado"
            }
          </Button>
          
        )
      ];

      {/* <Button variant="primary" onClick={() => handleAction1(rowData)}>
        Graduar
      </Button> */}

  return (
    <>
      <Card>
        <Card.Title>Emisión de grados</Card.Title>
      </Card>
      <div
        style={{
            padding:10
        }}
      >
        <Row>
            <Col>
                <Form.Group controlId="searchName">
                <Form.Label>Nombre:</Form.Label>
                <Form.Control
                    type="text"
                    name="NombreCompleto"
                    value={searchFields2.name}
                    onChange={handleSearchChange}
                />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group controlId="searchEmail">
                <Form.Label>Numero de documento:</Form.Label>
                <Form.Control
                    type="text"
                    name="NumeroDocumento"
                    value={searchFields2.email}
                    onChange={handleSearchChange}
                />
                </Form.Group>
            </Col>
            <Col style={{ display: 'flex', alignItems: 'flex-end'}}>
                <Button variant="primary" onClick={handleSearch}>
                    Buscar
                </Button>
            </Col>
        </Row>
      </div>
        <TablePaginate
          apiUrl="convocatoriaGrados/inscritosAprovados"
          tableHeaders={[
            { title: 'Nombre', attribute: 'Nombre' },
            { title: 'Programa', attribute: 'Curso' }
          ]}
          rowActions={rowActions}
        />

        {/* Modal certificaciones de grado */}
        <Modal
          show={modalGrados}
          onHide={() => setModalGrados(false)}
        >
          <ModalHeader closeButton>
            <h2>
              Generar Exportables de Grado
            </h2>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col>
                
                <Button variant='primary'>
                  <DowloadFile
                    endPoint={`${BASE_URL}/formatosIES/estudiante/certificadoDiploma?IdOferta=${rowDataGrade?.IdOferta}&IdEstudiante=${rowDataGrade?.IdEstudiante}&ubicacion=${ubicacion}`}
                    name={'Descargar Diploma'}
                    nameFile={'Diploma.pdf'}
                    colortext={'#fff'}
                  />  
                </Button>
              </Col>
              <Col style={{textAlign: 'right'}}> 
                <Button variant='primary'>
                  <DowloadFile
                    endPoint={`${BASE_URL}/formatosIES/exportar/actaGrado?IdOferta=${rowDataGrade?.IdOferta}&IdEstudiante=${rowDataGrade?.IdEstudiante}&ubicacion=${ubicacion}&FechaGrado=${rowDataGrade.Fecha}`}
                    name={'Descargar Acta Grado'}
                    nameFile={'Acta de Grado.pdf'}
                    colortext={'#fff'}
                  />
                </Button>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{textAlign: 'right'}}>
              <Button variant='secondary' onClick={() => setModalGrados(false)}>
                Cancelar
              </Button>
            </div>
          </ModalFooter>
        </Modal>
    </>
  );
}
