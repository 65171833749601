import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"
import Accordion from 'react-bootstrap/Accordion';
import { useEffect } from "react";
import Table from 'react-bootstrap/Table';
import { useFetchGet } from "../../../hooks/useFetchGet";
import { Button } from "../../../components/button/button";
import { BASE_URL, BASE_URL_REPOSITORIO } from "../../../services/api/config";
import { Modal } from "../../../components/modal/Modal";
import { DynamicForm} from '../../../components/DynamicForm/DynamicForm';
import { SubTitle } from "../../../shared/components/subtitle/SubTitle";
import { Title } from "../../../shared/components/title/Title";
import { ExportList } from "../../../components/ExportList/ExportList";
import { FormService } from "../../../services/api/formService";
import { alertaSinBoton } from "../../../helpers/alertas";
import './EstudianteDetalleCurso.css'
import { Card } from "../../../components/card/Card";
import { AddButton } from "../../../components/componentsForm/addButton/addButton";

function EstudianteDetalleCurso () {

  const {state:{programa}} = useLocation()
  const [key, setKey] = useState('notas');
  const [DataestudianteDiploma,loadingDiploma,errorDiploma] = useFetchGet(`diplomaEstudiante?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`)
  const [showModalPrograma, setShowModalPrograma] = useState(false);
  const [showDetallesEstudiante, setShowDetallesEstudiante] = useState(false);
  const [estudianteSelected, setEstudianteSelected] = useState({notas:[],asignatura:''});
  const [urlDownloadDiploma, setUrlDownloadDiploma] = useState({notas:[],asignatura:''});
  const [showBtnDiploma, setShowBtnDiploma] = useState(false);
  const api = new FormService()
  const navigate = useNavigate()
  const [notas,loading,error] = useFetchGet(`estudiante/notas?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`)
  const [average,setAverage] = useState(0)
  const [estadoCurso,setEstadoCurso]=useState('...')
  
  const onSeeAsistencia = (row) => {
    navigate(`${row.IdMalla}/asistencia`,{state:{oferta:row,programa}})
    
  }
  const onEvaluarPrograma = () => {
    navigate(`${programa.IdOferta}/evaluaciones`,{state:{programa}})
  }
  const onEvaluarDocente = (row) => {
    navigate(`${row.IdMalla}/evaluacion_docente`,{state:{asignatura:row,programa}})
  }
  const onSolicitar = (url) => {
    api.getAll(url).then(res=>{
      alertaSinBoton("Se há enviado la solicitud!",'success','bottom-end',1500)
    })
  }
  const onVerDetalles=(estudiante)=>{
    setEstudianteSelected(estudiante)
    setShowDetallesEstudiante(true)
  }
  const onSolicitarDiploma = async () => { 
    
}

  const calcularNotaFinal = (notasArray) => {
    if(notasArray.length===0){return '-'}
    let final = notasArray.reduce(
      (prev, curr)=>{
        let currentNota = curr?.Nota || null
       if(curr.Nota===null)currentNota='0'
        const porcentaje = (parseFloat(curr.Porcentaje)/100).toFixed(3)
        const nota = parseFloat(currentNota).toFixed(3)
        return prev + (nota*porcentaje)
      },0)
    return final
  }
    useEffect(() => {
      consultarAprobacion()
      if(DataestudianteDiploma?.respuesta==='true' && DataestudianteDiploma.diploma?.IdDiploma) {
        const url = `${BASE_URL}/estudiante/diploma?IdDiploma=${DataestudianteDiploma.diploma.IdDiploma}`
        setUrlDownloadDiploma(url)
        setShowBtnDiploma(true)
      }
      
      if(notas.length>0){
        const avg = notas.reduce(
              (acc,cur) => {
               return (parseFloat(acc) + parseFloat(cur?.nota || '0')/notas.length )
              } 
            ,0) 
        setAverage(avg.toFixed(2))
      }    
    },[notas,DataestudianteDiploma])

    // compronbar si el estudiante paso el curso
    const consultarAprobacion=()=>{
      setEstadoCurso('Aprobo')
      for (let i = 0; i < notas.length; i++) {
        const element = notas[i];
        if (element.estado=="Perdio"){
          setEstadoCurso('Perdio')
        }
        

      }
    }
     
  console.log(programa)
  
  return (
  <>
  {/* <AddButton></AddButton> */}
  <Card>

  <div className="d-flex mt-3 ml-2 mb-1">

  {/* <img width={'150px'} style={{borderRadius:'20px'}} className="d-flex justify-content-center" src={BASE_URL_REPOSITORIO+programa.Repositorio} alt=""/> */}
  <h1 className="d-flex ml-2 justify-content-center"><Title>{programa.Nombre}</Title></h1>
  </div>
    <div className=''>
      <div className="row">
      <div><span style={{fontWeight:"bold",width:"auto"}}>Estado: </span>{estadoCurso}</div>
      </div>
      
      <div>
      </div>
      <div className='d-flex justify-content-end'>

      {programa.verevaluaciones==0 &&
        <>Esperando calificaiones</>
      }
      {programa.verevaluaciones==1 && programa.vernotas==0 &&
        <>Completa las auto evaluaciones</>
      }

      {showBtnDiploma && 
      <Button
      type={'send'}
      >


        <a 
        target={'_blank'}
        rel="noreferrer"
        style={{color:'white'}}
        onClick={()=>onSolicitarDiploma} 
        href={urlDownloadDiploma} 
        // href={`${BASE_URL}/diploma/estudiante?IdOferta=${programa.IdOferta}&IdEstudiante=${programa.IdEstudiante}`} 
        download
        ><i class="bi bi-file-earmark-text"></i>  Solicitar Diploma</a>
      </Button>
      }
      </div>
    </div>
      

  </Card>
  
  {/* <Notas
  item={item}
  ></Notas> */}
  
 <Card>

  <Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Asignatura</th>
      <th>Docente</th>
      <th>Asistencias</th>
      {/* <th>Evaluar Docente</th> */}
      <th>Nota final</th> 
      <th>Estado</th>
      {/* <th>Ver detalles</th> */}
    </tr>
  </thead>
  <tbody>
          {!!notas && notas.map((element,index)=>{
            return(
              <tr key={index}>
                <td>{index+1}</td>
                <td>{element.asignatura}</td>
                <td>{element?.Docente}</td>
                <td>
                  <Button
                  onClick={()=>onSeeAsistencia(element)}
                  ><i title={'ver asistencia :'+(index+1)} class="bi bi-calendar3"></i></Button>
                </td>
                <td>
                  <Button
                  onClick={()=>onVerDetalles(element)}
                  >{element.definitiva}
                    <i title={'ver detalles :'+(index+1)} class="bi bi-eye"></i></Button>
                </td>
                <th>{element.estado}</th>
              </tr>
            )
          })}
    </tbody>
  </Table>

    </Card>
    
   

    <Modal
    isOpen={showDetallesEstudiante}
    close={()=>setShowDetallesEstudiante(false)}
    >
      <SubTitle>{estudianteSelected && estudianteSelected?.asignatura}</SubTitle>
      <div className="notas-container">

     
      {estudianteSelected && estudianteSelected?.notas.map((nota,index)=>{
        return(
          <div className="nota-item">
          <div><b>{nota?.Nombre} </b></div>
          <div> {nota?.Nota || '-'} </div>
          <div className="porcentaje-nota"> {nota?.Porcentaje}% </div>
          
        </div>
        )
      })}
       </div>
       <hr></hr>
      {<ul className="nota-definitiva"><b>Definitiva </b>:  {estudianteSelected.definitiva}</ul>}
    </Modal>




    <Modal
    title={'Evaluar curso'}
    isOpen={showModalPrograma}
    hasButton
    className='w-65'
    >
      <DynamicForm  
      justDynamicForm={{flag:true,idForm:"51" }}
      endPointSubmit='null'
      isCreate={true}
      extraData={{IdEstudiante:20,IdOferta:10}}
      />
      <Button
      type={'send'}
      onClick={()=>{setShowModalPrograma(false)}}
      >
        Cerrar
      </Button>
    </Modal>
  </>
  )
}

export { EstudianteDetalleCurso }