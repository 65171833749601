import PropTypes from 'prop-types';
import React from 'react';
import { Card, Table } from 'react-bootstrap';

const TablaCreditos = ({ data }) => {

  if (!data) {
    return null;
  }

  return (
    <Card>
      <Card.Title>
          Número de estudiantes por creditos cursados y edad
      </Card.Title>
    <Table>
      <thead>
        <tr>
          <th rowSpan="2"
            style={{
              textAlign: 'center',
              verticalAlign: 'middle'
            }}
          >Creditos</th>
          <th colSpan="8"
            style={{
              textAlign: 'center'
            }}
          >Edad</th>
        </tr>
        <tr>
          <th>16</th>
          <th>17</th>
          <th>18</th>
          <th>19</th>
          <th>20</th>
          <th>20 -24</th>
          <th>25 -30</th>
          <th>{`>30`}</th>
        </tr>
      </thead>
      <tbody>
        {data.slice(1).map((fila, index) => (
          <tr key={fila[0]}>
            {fila.map((valor, index) => (
              <td key={index} style={{fontWeight: index===0?'bold':'normal'}}>{valor}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
    </Card>
  );
};

TablaCreditos.propTypes = {
  data: PropTypes.array
};

export default TablaCreditos;

