
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Modal, NavDropdown, Table,Card , Col, Row } from "react-bootstrap";
// import { Card } from "../../../../components/card/Card";
import { BASE_SERVIDOR, BASE_URL} from "../../../../services/api/config";
import { FormService } from "../../../../services/api/formService"
import { Title } from "../../../../shared/components/title/Title";
import { BsThreeDotsVertical } from "react-icons/bs";
import { alerta } from "../../../../helpers/alertas";
import { Input } from "../../../../components/input/input";
import { Select } from "../../../../components/select/Select";
import DowloadFileText from '../../../../components/DowloadFileText/DowloadFileText';

const Aspirantes=()=>{
    const api=new FormService();

    const[pagos,setpagos]=useState([])
    const[modal,setModal]=useState(false)
    const[modal2,setModal2]=useState(false)
    const [fechaPago, setFechaPago] = useState()
    const [NumeroReciboPago, setNumeroReciboPago] = useState()
    const [tipoConvenio, setTipoConvenio] = useState()
    const [convenio, setConvenio] = useState()
    const[descripcion,setDescripcion]=useState()
    const[pago,setPago]=useState();
    const [fechaI, setFechaI] = useState()
    const [fechaII, setFechaII] = useState()
    const [modalExportable, setModalExportable] = useState()
    const [valorConcepto, setValorConcepto] = useState()
    const [pagoEstudiante, setPagoEstudiante] = useState()
    const [porcentajeConvenio, setPorcentajeConvenio] = useState()
    const [valorAprobado, setValorAprobado] = useState()

    const linkPdf=`${BASE_SERVIDOR}/pdf/`
    // const Exportar=`${BASE_URL}/financiero/pagosPendientes/pdf`
    
    useEffect(()=>{

       const  fetchData=async()=>{
            consultarPagos()
        }
        fetchData();
    },[])


    const consultarPagos=async()=>{
        const pagos=await api.getAll("financiero/pagosPendientes")
        setpagos(pagos);
    }

    const aprobarPago=async()=>{

        if(fechaPago != undefined)
        {
            if(NumeroReciboPago != undefined) {
                var data={
                    "Observacion":descripcion,
                    "EstadoRequisito":"1",
                    "IdRequisitoAspirante":pago.IdRequisitoAspirante,
                    "FechaPago": fechaPago,
                    "NumeroReciboPago": NumeroReciboPago,
                    "IdTipoConvenio": tipoConvenio, 
                    "ValorConcepto": valorConcepto,
                    "ValorPagoEstudiante": pagoEstudiante,
                    "ValorAprobado": valorAprobado
                }
                const respuesta=await api.create('financiero/aprobarPago',data);

                if(respuesta.respuesta=="exito"){
                    setModal(false)
                    setDescripcion(null)
                    consultarPagos()
                }else{
                    alerta("Fallo al aprobar pago")
                }
            } else {
                alerta("Los campos con * son obligatorios")
            }
        } else {
            alerta("Los campos con * son obligatorios")
        }

    }

    const rechazarPago=async()=>{
        /* console.log(descripcion) */

        if(descripcion==""){ 
            alerta("Escribe el motivo del rechazo")
            return
        }
        var data={
            "Observacion":descripcion,
            "EstadoRequisito":"2",
            "IdRequisitoAspirante":pago.IdRequisitoAspirante
        }
        const respuesta=await api.create('financiero/aprobarPago',data);
        if(respuesta.respuesta=="exito"){
            setModal(false)
            setModal2(false)
            consultarPagos()
            setDescripcion(null)
        }else{
            alerta("Fallo al rechazar pago")
        }

    }
    const exportar=()=>{
        window.open(`${BASE_URL}/financiero/pagosPendientes/pdf`,"_blank",)
    }

    return(
        <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Pagos Pendientes por Aprobar</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={() => setModalExportable(true)}
                    >
                        Exportar Reporte de Pagos
                    </Button>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        variant='primary'
                        // href={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${oferta?.IdOferta}&IdHorario=${horario.IdHorario}`}
                    >
                        <DowloadFileText
                            nameFile={"Pagos Pendientes por Aprobar.xlsx"}
                            name={"Exportar"}
                            colortext={"#ffffff"}
                            endPoint={`${BASE_URL}/financiero/pagosPendientes/Excel`}
                        />
                    </Button>
                    {/*<Button
                        onClick={()=>exportar()}
                    >
                        Exportar
                    </Button>*/}
                </Col>
            </Row>
        </Card>
        <Card>
            <Table>
                <thead>
                    <th> Nombre </th>
                    <th> Programa </th>
                    <th style={{textAlign: 'center'}}>Tipo de Pago</th>
                    <th style={{textAlign: 'center'}}>Concepto</th>
                    <th style={{textAlign: 'center'}}> Convenio</th>
                    <th style={{textAlign: 'center'}}>PDF</th>
                    <th style={{textAlign: 'center'}}>Fecha</th>
                    <th style={{textAlign: 'center'}}>Acción</th>
                </thead>
                <tbody>
                    {
                        pagos.map((pago,key)=>
                            <tr key={key}>  
                                <td>{pago.Nombre}</td>
                                <td>{pago.NombrePlanEstudio}/{pago.codigo_grupo}</td>
                                <td>{pago.IdTipoRequisito == "1" && "Pago Inscripción"}  {pago.IdTipoRequisito == "2" && "Pago Matrícula"}</td>
                                <td>
                                    {pago.Concepto}
                                </td>
                                <td>{pago.Convenio}</td>                          
                                <td><a target='_blank' href={`${linkPdf}${pago.Archivo}`}>Ver pdf</a></td>
                                <td>{pago.FechaCreacion}</td>
                                <td>
                                <NavDropdown  title={<BsThreeDotsVertical/>} >
                                    <NavDropdown.Item 
                                        onClick={()=>{
                                            setModal(true)
                                            setPago(pago)
                                        }}
                                        eventKey="4.1">Aprobar
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={()=>{
                                            setModal2(true)
                                            setPago(pago)
                                        }}
                                        eventKey="4.2">Rechazar
                                    </NavDropdown.Item>
                                </NavDropdown>
                                </td>
                            </tr>
                        )
                    }

                </tbody>
            </Table>
        </Card>
        <Modal 
            show={modal}
            onHide={() => setModal(false)}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Aprobar recibo de pago
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Input
                    type={"text"}
                    label="Valor del Concepto *"
                    required={true}
                    name={"ValorConcepto"}
                    onChange={(e) => {setValorConcepto(e)}}
                    max={15}
                    min={1}
                />
                <Input
                    type={"text"}
                    label="Valor Pagado por Estudiante *"
                    required={true}
                    name={"ValorPagoEstudiante"}
                    onChange={(e) => {setPagoEstudiante(e)}}
                    max={15}
                    min={1}
                />
                <Form.Label>Descripción</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder=""
                    style={{ height: '100px' }}
                    value={descripcion}
                    onChange={(e)=>setDescripcion(e.target.value)}
                />
                <Input
                    type={"date"}
                    label="Fecha de Pago *"
                    required={true}
                    name={"FechaPago"}
                    onChange={(e) => {setFechaPago(e)}}
                />
                <Input
                    type={"text"}
                    label="Número de Recibo de Pago *"
                    required={true}
                    name={"NumeroReciboPago"}
                    onChange={(e) => {setNumeroReciboPago(e)}}
                    max={15}
                    min={1}
                />
                <br />
                <label htmlFor="">¿ Convenio ?</label>
                <Form.Check 
                    type="switch"
                    name="Convenio"
                    id="Convenio"
                    label="Sí"
                    checked = {convenio}
                    value={convenio}
                    onChange={ (e) => {
                        setConvenio(e.target.checked)
                    }}
                />
                <br />
                {convenio &&
                    <>
                    <Select
                        id="IdTipoConvenio"
                        name="IdTipoConvenio"
                        onChange={ (e) => setTipoConvenio(e) }
                        value={ tipoConvenio || null }
                        label="Convenio *"
                        required={true}
                        optionValue="IdConvenio"
                        optionName="Descripcion"
                        urlOptionsService={`convenio`}
                    />
                    {/* <Input
                        type={"text"}
                        label="Porcentaje del Convenio (%)*"
                        required={true}
                        name={"PorcentajeConvenio"}
                        onChange={(e) => {setPorcentajeConvenio(e)}}
                        max={15}
                        min={1}
                    /> */}
                    </>
                }
                <Input
                    type={"text"}
                    label="Valor Aprobado *"
                    required={true}
                    name={"ValorAprobado"}
                    onChange={(e) => {setValorAprobado(e)}}
                    max={15}
                    min={1}
                />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>aprobarPago()}>
            Aprobar pago
          </Button>
        </Modal.Footer>
        </Modal>
        <Modal 
            show={modalExportable}
            onHide={() => setModalExportable(false)}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Exportar Reporte General de Pagos
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Input
                type={"date"}
                label="Fecha I"
                required={true}
                name={"FechaI"}
                onChange={(e) => {setFechaI(e)}}
            />
            <Input
                type={"date"}
                label="Fecha II"
                required={true}
                name={"FechaII"}
                onChange={(e) => {setFechaII(e)}}
            />
        </Modal.Body>
        <Modal.Footer>
        <Button
            variant='success'
        >
            <DowloadFileText
                nameFile={"Reporte General de Pagos.xlsx"}
                name={"Exportar Excel"}
                colortext={"#ffffff"}
                endPoint={`${BASE_URL}/financiero/reportepagos?FechaI=${fechaI}&FechaII=${fechaII}`}
            />
        </Button>
        </Modal.Footer>
        </Modal>

        <Modal 
            show={modal2}
            onHide={() => setModal2(false)}
        >
        <Modal.Header closeButton>
          <Modal.Title>
            Rechazar recibo de pago
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <Form.Label>Describe el motivo</Form.Label>
                <Form.Control
                    as="textarea"
                    placeholder=""
                    style={{ height: '100px' }}
                    value={descripcion}
                    onChange={(e)=>setDescripcion(e.target.value)}
                    />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={()=>rechazarPago()}>
            Rechazar
          </Button>
        </Modal.Footer>
        </Modal>
        </>
    )

}

export {Aspirantes}