import React, { useState } from 'react'
import { Button, Card, Col, Row, } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../services/api/config'
import { Input } from '../../../components/input/input';
import { alerta } from '../../../helpers/alertas';
import { LocalStorage } from '../../../services';
import { Select } from '../../../components/select/Select';
import DowloadFile from '../../../components/DowloadFile/DowloadFile';

function ConsultarCarga() {
    const store= new LocalStorage();
    const Navigate=useNavigate();
    const [fecha1,setFecha1]=useState(null);
    const [fecha2,setFecha2]=useState(null);
    const [curso, setCurso] = useState();

    const usuario=JSON.parse(store.getItem("usuario"))
    const IdUsuario=usuario.usuario.IdUsuario
    const Dimension=JSON.parse(store.getItem("dimension"))
    const URLActual = window.location.origin
    console.log(URLActual)
    const consultarAsignacion=()=>{
        if (fecha1!=null && fecha2!=null) {
            Navigate("carga",{state:{IdUsuario:IdUsuario,fecha1:fecha1,fecha2:fecha2}})
        }else{
            alerta("Ingres las dos fechas")
        }
    }

  return (
    <>
        {Dimension=="false"?
        <>
            <Card>
                <p>Indique el Periodo para Consultar su Asignación</p>
            </Card>
            <Card>
                 <Row>
                     <Col>
                         <Input
                             label="Fecha Inicial"
                             type={'date'}
                             value={fecha1}
                             onChange={(e)=>setFecha1(e)}
                         />
                     </Col>
                     <Col>
                         <Input
                             label="Fecha Final"
                             type={'date'}
                             onChange={(e)=>setFecha2(e)}
                             value={fecha2}
                         />
                     </Col>
                </Row>
                <div style={{textAlign:"right",paddingTop:30}}>
                <Button
                        onClick={()=>consultarAsignacion()}
                    >
                        Consultar Asignación
                    </Button>
                </div>
            </Card>
        </>
        :
        <>
        <Card>
            <p>Indique el Programa para Consultar el Horario</p>
        </Card>
        <Card>
            <Row>
                <Col>
                    <Select
                        id="ActividadAcademica"
                        name="ActividadAcademica"
                        onChange={ (e) => setCurso(e) }
                        value={ curso || null }
                        label="Programa *"
                        required={true}
                        optionValue="IdOferta"
                        optionName="Curso"
                        urlOptionsService={`docente/cursosAsignados?IdUsuario=${IdUsuario}`}
                    />
                </Col>
                {/* <Col>
                    <DowloadFile
                        nameFile={"Horario.pdf"}
                        name={"Descargar Horario"}
                        endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumidoDocente?IdOferta=${curso}&IdUsuario=${IdUsuario}`}
                    />
                </Col> */}
                </Row>
                {/* <Row>
                    <Col>
                        <Input
                            label="Fecha Inicial"
                            type={'date'}
                            value={fecha1}
                            onChange={(e)=>setFecha1(e)}
                        />
                    </Col>
                    <Col>
                        <Input
                            label="Fecha Final"
                            type={'date'}
                            onChange={(e)=>setFecha2(e)}
                            value={fecha2}
                        />
                    </Col>
                </Row> */}
                <div style={{textAlign:"center",paddingTop:30}}>
                    <Col>
                        <DowloadFile
                            nameFile={"Horario.pdf"}
                            name={"Descargar Horario"}
                            endPoint={`${BASE_URL}/formatosIES/exportar/horarioResumidoDocente?IdOferta=${curso}&IdUsuario=${IdUsuario}&Ubicacion=${URLActual}`}
                        />
                    </Col>
                    {/* <Button
                        onClick={()=>consultarAsignacion()}
                    >
                        Consultar Asignación
                    </Button> */}
            </div>
            </Card>
        </>
        }
    </>
  )
}

export default ConsultarCarga