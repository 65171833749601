import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FooterHome } from '../../components/Inicio/FooterHome/FooterHome';
import { Arrow } from '../../components/arrow/Arrow';

import '../inicio/Inicio.css';

import { Col, Modal, Nav, Row, Tab } from 'react-bootstrap';
import { FormService } from '../../services/api/formService';
import Cursos from './Cursos';
import CursosContinuada from './CursosContinuada';
import { LocalStorage } from '../../services';
import { HeaderHome } from '../../components/headerHome/HeaderHome';

const Portafolio = () => {
    const api=new FormService();
    const storage= new LocalStorage();
    const usuario=JSON.parse(storage.getItem("usuario"))
    const [dimensiones, setDimensiones] = useState([]);
    const [nombreDimension, setNombreDimension] = useState('');
    const [usuarioLS, setUsuarioLS] = useState(usuario?true:false);
    const [idUsuario, setIdUsuario] = useState('');
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [itemSelected, setItemSelected] = useState('');
    const dimension_actual=storage.getItem('dimension')

    useEffect(() => {
        (async()=>{
            consultarDimensiones()
        })()
    }, []);



    const onClickInscripcion = async (item) => {
        setIsOpenModal(true);
        setItemSelected(item);
    };


    const consultarDimensiones=async()=>{
        const dimensiones=await api.getAll("listadoCursos");
        console.log(dimensiones)
        setDimensiones(dimensiones)
    }

    return (
        <>
            <HeaderHome />
            <main
                className="content"
                style={{
                    maxWidth: 1200,
                    margin: '0 auto',
                }}
            >

                <div className="row">
                    <h1> {nombreDimension || ''} </h1>
                </div>
                <div className="row">
                    <h1 className="text-center">Portafolio Académico</h1>
                </div>
                { !usuarioLS &&
                <div className='informacion-ingreso'>
                    <p>
                        <i class="bi bi-exclamation-octagon"></i>
                        <a href='/login'> Inicie sesión </a>
                         o <a href='/sign-up'>regístrese </a>  para incribirse a una oferta
                    </p>
                </div>
                }
                    <div style={{marginBottom:40}}>
                        {dimensiones.map((dimension,key)=>                                                                
                            <Cursos
                                key={key} 
                                cursos={dimension.cursos}
                                IdDimension={dimension.IdDimension}
                                eventKey={`dim-${dimension.IdDimension}`} 
                                onClickInscripcion={onClickInscripcion}
                            />                                                                     
                        )}
                    </div>
            </main>
            <footer className="inicio__footer">
                <FooterHome type="dark" />
            </footer>
        </>
    )


}

export default Portafolio;
