
import React, { useEffect } from 'react'
import { useState } from 'react';
import { Alert, Button, Modal, Spinner,Form, Container, Row, Col } from 'react-bootstrap';
import { alerta } from '../../../helpers/alertas';
import { useFetchGet } from '../../../hooks/useFetchGet';
import { FormService } from '../../../services/api/formService';
import { servidorActual } from '../../../services/api/config';

export default function ModalIncripcion({
    itemSelected
}) {

    const api=new FormService();
    const [datos,setDatos]=useState({})
    const [modal,setModal]=useState(false)
    const [paisesExp,erro1,loading1 ]=useFetchGet(`paises`)
    const [deptosExp,erro2,loading2 ]=useFetchGet(`departamentos?IdPaises=${1}`)
    const [ciudades,erro,loading ]=useFetchGet(`ciudad?IdDepartamento=${datos?.IdDeptoExpedicion}`)
    const [ciudadRes,erro3,loading3 ]=useFetchGet(`ciudad?IdDepartamento=${datos?.IdDeptoResidencia}`)
    const [tiposIdentidad, error, loadings ]=useFetchGet(`tipoIdentidad`)
    const [medioConoceCEA, setMedioConoceCEA] = useState()
    const [prensa, setPrensa] = useState()
    const [internet, setInternet] = useState()
    const [radio, setRadio] = useState()
    const [television, setTelevision] = useState()
    const [feria, setFeria] = useState()
    const [revista, setRevista] = useState()
    const [redSocial, setRedSocial] = useState()
    const [familiar, setFamiliar] = useState()
    const [otroMedio, setOtroMedio] = useState()
    const [politicaPrivacidad, setPoliticaPrivacidad] = useState(false)
    const [noPoliticaPrivacidad, setNoPoliticaPrivacidad] = useState()
    const [dataNew, setDataNew] = useState({})

    const handleInputChange=(e)=>{
        setDatos({
            ...datos,
            [e.target.name]:e.target.value
        })
    }

    // consultar preinscrito
    const handleBlur=async(e)=>{
        console.log(e.target.value);
        const {name,value}=e.target
        if(name=="NumeroDocumento"){
            const respuesta= await api.getAll(`usuario/userByDocumento?NumeroDocumento=${value}`)
            if(respuesta.success){
                let usuario=respuesta.data
                console.log("Hola Mundo");
                datos.PrimerNombre = usuario.PrimerNombres
                datos.SegundoNombre = usuario.SegundoNombre
                datos.PrimerApellido = usuario.PrimerApellidos
                datos.SegundoApellido = usuario.SegundoApellidos
                datos.Email = usuario.email
                datos.Celular = usuario.TelefonoContacto
                datos.NumeroDocumento = usuario.NumeroDocumento
                datos.IdTipoIdentidad = usuario.IdTipoIdentidad
                datos.IdPaisExpedicion = usuario.IdPaisDocumento
                datos.IdDeptoExpedicion = usuario.IdDepDocumento
                datos.IdCiudad = usuario.IdMuniDocumento
                datos.IdPaisResidencia = usuario.IdPaisDir
                datos.IdDeptoResidencia = usuario.IdDepDir
                datos.IdCiudadResidencia = usuario.IdMuniDir

                setDataNew(usuario)
            }
        }
    }

    // guardar preinscripcion   
    const guardarPreinscripcion=async()=>{
        if(datos?.PrimerNombre == null || datos?.PrimerNombre == "") {
            return alerta("Los campos con * son obligatorios1", "warning")
        }
        if(datos?.PrimerApellido == null || datos?.PrimerApellido == "") {
            return alerta("Los campos con * son obligatorios2", "warning")
        }
        if(datos?.IdTipoIdentidad == null || datos?.IdTipoIdentidad == "" || datos?.IdTipoIdentidad == undefined || datos?.IdTipoIdentidad == 0) {
            return alerta("Los campos con * son obligatorios3", "warning")
        }
        if(datos?.NumeroDocumento == null || datos?.NumeroDocumento == "") {
            return alerta("Los campos con * son obligatorios4", "warning")
        }
        /* if(datos?.IdCiudad == null || datos?.IdCiudad == "" || datos?.IdCiudad == undefined || datos?.IdCiudad == 0) {
            return alerta("Los campos con * son obligatorios5", "warning")
        } */
        /* if(datos?.IdCiudadResidencia == null || datos?.IdCiudadResidencia == "" || datos?.IdCiudadResidencia == undefined || datos?.IdCiudadResidencia == 0) {
            return alerta("Los campos con * son obligatorios6", "warning")
        } */
        if(datos?.Email == null || datos?.Email == "") {
            return alerta("Los campos con * son obligatorios7", "warning")
        }
        if(datos?.Celular == null || datos?.Celular == "") {
            return alerta("Los campos con * son obligatorios8", "warning")
        }
        if(politicaPrivacidad == null || politicaPrivacidad == "" || politicaPrivacidad == undefined || politicaPrivacidad == 0 || politicaPrivacidad == false) {
            return alerta("Aún no acepta nuestra política de privacidad", "warning")
        }
        
        const data={
            "IdOferta": itemSelected.IdOferta,
            "Prensa": prensa,
            "Internet": internet,
            "Radio": radio,
            "Television": television,
            "Feria": feria,
            "Revista": revista,
            "RedSocial": redSocial,
            "Familiar": familiar,
            "OtroMedio": otroMedio,
            "ApruebaPoliticaPrivacidad": politicaPrivacidad,
            // "NoApruebaPoliticaPrivacidad": politicaPrivacidad,
            ...datos
        }
        const res=await api.create("aspirantes/guardarPreinscrito",data)
        if(res.respuesta="exito"){
            alerta(res.mensaje)
            setModal(false)
        }else{
            alerta(res.mensaje)
        }
        setTimeout(() => {
 
            window.location.href ="http://"+servidorActual+"/sign-up"
        }, 3000);
    }

return (
    <>
        <Modal 
            show={modal} 
            onHide={()=>setModal(false)} 
            size="lg"
            
        >
        <Form
            onSubmit={(e) => {
                e.preventDefault()
                guardarPreinscripcion()
            }}
        >
        <Modal.Header closeButton style={{backgroundColor: "#005AA6", color: "#fff"}}>
            <Modal.Title>Formulario de Preinscripción</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{backgroundColor: "#f7f7f7", textAlign: 'justify'}}>
            {/* texto de Habeas Data */}
            <p>
                “Al realizar el registro manifiesta bajo la gravedad del juramento que la información
                suministrada en el presente formato de registro es verídica, autoriza la confirmación de
                la misma, en caso de ser necesario.” Ley 1581 del 2012: La Información recolectada a
                través del presente formulario será procesada y almacenada a fin de gestionar su solicitud
                de registro al Sistema de Información Académica del CEA a programas de Educación Superior o alguna de las capacitaciones
                y/o formación brindadas por el Centro de Estudios Aeronáuticos, así como las actividades
                derivadas del proceso de enseñanza- aprendizaje que busca formar integralmente a los
                estudiantes. Puede conocer la Política de Protección de Datos Personales y ejercer
                sus derechos como titular de la información a actualizar, rectificar, conocer y
                oponerse a través de la página web <a target={"_blank"} href='https://www.aerocivil.gov.co/politica-privacidad' >https://www.aerocivil.gov.co/politica-privacidad</a>
            </p>

            <Row style={{ marginBottom: "10px"}}>
                <Form.Group>
                    <Form.Label>Acepta la política de protección de datos personales *</Form.Label>
                    <Form.Check
                        type="radio"
                        name="PoliticaPrivacidad"
                        id="PoliticaPrivacidad"
                        label="Sí"
                        value={politicaPrivacidad}
                        onChange={(e) => {
                            setPoliticaPrivacidad(true)
                        }}
                        required={true}
                    />
                    
                    <Form.Check
                        type="radio"
                        name="PoliticaPrivacidad"
                        id="NoPoliticaPrivacidad"
                        label="No"
                        value={politicaPrivacidad}
                        onChange={(e) => {
                            setPoliticaPrivacidad(false)
                        }}
                        required={true}
                    />
                </Form.Group>
            </Row>
            <hr />
            {politicaPrivacidad == true &&

            <Container>
                <Row>
                    <Col>
                    <Form.Group>
                        <Form.Label>Número de Documento *</Form.Label>
                        <Form.Control
                            type="text"
                                onKeyDown={(e) => {
                                    if (e.key === "." || e.key === ",") {
                                        e.preventDefault();
                                    }   
                                }
                            }
                            placeholder="Ingrese su número de documento"
                            name="NumeroDocumento"
                            value={datos?.NumeroDocumento || ''}
                            onChange={handleInputChange}
                            required={true}
                            onBlur={ handleBlur }
                            tabIndex={1}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Primer Nombre *</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Ingrese su primer nombre" 
                        name="PrimerNombre"
                        value={datos?.PrimerNombre || ''}
                        onChange={handleInputChange}
                        required={true}
                        tabIndex={3}
                        />
                    </Form.Group>
                    
                    <Form.Group>
                        <Form.Label>Primer Apellido *</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Ingrese su primer apellido" 
                            name="PrimerApellido"
                            value={datos?.PrimerApellido || ''}
                            onChange={handleInputChange}
                            required={true}
                            tabIndex={5}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>País de Expedición *</Form.Label>
                        {/* mostar el listado de paises que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder=""
                            name="IdPaisExpedicion"
                            value={datos?.IdPaisExpedicion || ''}
                            onChange={handleInputChange} 
                            required={true}
                            tabIndex={7}
                        >
                            <option value="" >Seleccionar</option>
                            {paisesExp.map((item, index) => (
                                <option key={index} value={item.Idpaises}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {datos?.IdPaisExpedicion == 1 &&
                    <Form.Group>
                        <Form.Label>Ciudad de Expedición *</Form.Label>
                        {/* mostar el listado de ciudad que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder="Ingrese su ciudad"
                            name="IdCiudad"
                            value={datos?.IdCiudad || ''}
                            onChange={handleInputChange} 
                            required={true}
                        >
                            <option value="">Seleccionar</option>
                            {ciudades.map((item, index) => (
                                <option key={index} value={item.Idciudad}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    {datos?.IdPaisResidencia == 1 &&
                    <Form.Group>
                        <Form.Label>Departamento de Residencia *</Form.Label>
                        {/* mostar el listado de departamento que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder=""
                            name="IdDeptoResidencia"
                            value={datos?.IdDeptoResidencia || ''}
                            onChange={handleInputChange} 
                            required={true}
                        >
                            <option value="">Seleccionar</option>
                            {deptosExp.map((item, index) => (
                                <option key={index} value={item.IdDepartamento}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>Dirección de Residencia *</Form.Label>
                        <Form.Control
                        type="text"
                        placeholder="Ingrese su dirección"
                        name="DireccionResidencia"
                        value={datos?.DireccionResidencia || ''}
                        onChange={handleInputChange}
                        required={true}
                        tabIndex={9}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Número de Celular *</Form.Label>
                        <Form.Control
                        type="number"
                        placeholder="Ingrese su número de celular"
                        name="Celular"
                        value={datos?.Celular || ''}
                        onChange={handleInputChange}
                        onKeyDown={(e) => {
                            if (e.key === "." || e.key === ",") {
                                e.preventDefault();
                            }
                            }
                        }
                        required={true}
                        tabIndex={11}
                        />
                    </Form.Group>
                    </Col>
                    <Col>
                    <Form.Group>
                        <Form.Label>Tipo de Documento *</Form.Label>
                        {/* mostar el listado de ciudad que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder="Ingrese su Tipo Documento"
                            name="IdTipoIdentidad"
                            value={datos?.IdTipoIdentidad || ''}
                            onChange={handleInputChange} 
                            required={true}
                            tabIndex={2}
                        >
                            <option key={0} value={0}>
                                {'Seleccionar'}
                            </option>
                            {tiposIdentidad.map((item, index) => (
                                
                                <option key={index} value={item.IdTipoIdentidad}>
                                    {item.Abr + " - " + item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Segundo Nombre</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Ingrese su segundo nombre" 
                        name="SegundoNombre"
                        value={datos?.SegundoNombre || ''}
                        onChange={handleInputChange}
                        tabIndex={4}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Segundo Apellido</Form.Label>
                        <Form.Control 
                            type="text" 
                            placeholder="Ingrese su segundo apellido"
                            name="SegundoApellido"
                            value={datos?.SegundoApellido || ''}
                            onChange={handleInputChange}
                            tabIndex={6}
                        />
                    </Form.Group>
                    
                    {datos?.IdPaisExpedicion == 1 &&
                    <Form.Group>
                        <Form.Label>Departamento de Expedición *</Form.Label>
                        {/* mostar el listado de departamento que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder=""
                            name="IdDeptoExpedicion"
                            value={datos?.IdDeptoExpedicion || ''}
                            onChange={handleInputChange} 
                            required={true}
                        >
                            <option value="">Seleccionar</option>
                            {deptosExp.map((item, index) => (
                                <option key={index} value={item.IdDepartamento}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>País de Residencia *</Form.Label>
                        {/* mostar el listado de paises que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder=""
                            name="IdPaisResidencia"
                            value={datos?.IdPaisResidencia || ''}
                            onChange={handleInputChange} 
                            required={true}
                            tabIndex={8}
                        >
                            <option value=""> Seleccionar </option>
                            {paisesExp.map((item, index) => (
                                <option key={index} value={item.Idpaises}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    {datos?.IdPaisResidencia == 1 &&
                    <Form.Group>
                        <Form.Label>Ciudad de Residencia *</Form.Label>
                        {/* mostar el listado de ciudad que viene de la base de datos */}

                        <Form.Control
                            as="select"
                            type="text"
                            placeholder=""
                            name="IdCiudadResidencia"
                            value={datos?.IdCiudadResidencia || ''}
                            onChange={handleInputChange} 
                            required={true}
                        >
                            <option value="">Seleccionar</option>
                            {ciudadRes.map((item, index) => (
                                <option key={index} value={item.Idciudad}>
                                    {item.Descripcion}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    }
                    <Form.Group>
                        <Form.Label>E-mail *</Form.Label>
                        <Form.Control
                        type="email"
                        placeholder="Ingrese su email"
                        name="Email"
                        value={datos?.Email || ''}
                        onChange={handleInputChange}
                        required={true}
                        tabIndex={10}
                        />
                    </Form.Group>
                    
                    </Col>
                </Row>
                <Row>
                    <div>
                        <hr />
                    </div>
                    <h6>De las siguientes opciones seleccione el medio por el cuál conoció el CEA</h6>
                </Row>
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Prensa</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Prensa"
                                id="Prensa"
                                label="Sí"
                                value={prensa}
                                onChange={(e) => {
                                    setPrensa(e.target.checked)
                                }}
                                tabIndex={12}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Internet</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Internet"
                                id="Internet"
                                label="Sí"
                                value={internet}
                                onChange={(e) => {
                                    setInternet(e.target.checked)
                                }}
                                tabIndex={14}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Radio</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Radio"
                                id="Radio"
                                label="Sí"
                                value={radio}
                                onChange={(e) => {
                                    setRadio(e.target.checked)
                                }}
                                tabIndex={16}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Televisión</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Television"
                                id="Television"
                                label="Sí"
                                value={television}
                                onChange={(e) => {
                                    setTelevision(e.target.checked)
                                }}
                                tabIndex={18}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Feria</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Feria"
                                id="Feria"
                                label="Sí"
                                value={feria}
                                onChange={(e) => {
                                    setFeria(e.target.checked)
                                }}
                                tabIndex={20}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group>
                            <Form.Label>Revista</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Revista"
                                id="Revista"
                                label="Sí"
                                value={revista}
                                onChange={(e) => {
                                    setRevista(e.target.checked)
                                }}
                                tabIndex={13}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Redes sociales</Form.Label>
                            <Form.Check
                                type="switch"
                                name="RedSocial"
                                id="RedSocial"
                                label="Sí"
                                value={redSocial}
                                onChange={(e) => {
                                    setRedSocial(e.target.checked)
                                }}
                                tabIndex={15}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Familiar</Form.Label>
                            <Form.Check
                                type="switch"
                                name="Familiar"
                                id="Familiar"
                                label="Sí"
                                value={familiar}
                                onChange={(e) => {
                                    setFamiliar(e.target.checked)
                                }}
                                tabIndex={17}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Otro</Form.Label>
                            <Form.Check
                                type="switch"
                                name="OtroMedio"
                                id="OtroMedio"
                                label="Sí"
                                value={otroMedio}
                                onChange={(e) => {
                                    setOtroMedio(e.target.checked)
                                    if(e.target.checked){
                                        setMedioConoceCEA(true)
                                    } else {
                                        setMedioConoceCEA(false)
                                    }
                                }}
                                tabIndex={19}
                            />
                        </Form.Group>
                        {medioConoceCEA && 
                        <>
                            <Form.Group>
                                <Form.Label>Cúal</Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Ingrese Otro Medio"
                                name="CualMedio"
                                value={datos?.CualMedio}
                                onChange={handleInputChange}
                                />
                            </Form.Group>
                        </>
                        }
                    </Col>
                </Row>
                </Container>
                }           
         </Modal.Body>
         <Modal.Footer style={{backgroundColor: "#f7f7f7"}}>
            <Button
                variant='secondary'
                onClick={()=>setModal(false)}
            >
                Cancelar
            </Button>
            {politicaPrivacidad &&
                // botn para envar el formulario
                <Button
                    variant='primary'
                    type='submit'
                    // onClick={handleSubmit}
                >
                    Enviar
                </Button>

            }
            
         </Modal.Footer>
         </Form>
        </Modal>
        <Button onClick={()=>setModal(true)}>Preinscribirse</Button>
    </>
    )
}
