import { useLocation } from 'react-router-dom'
import { Checkbox } from '../../../../components/componentsForm/checkbox/checkbox'
import { useState } from 'react'
import { FormService } from '../../../../services/api/formService'
import { alertaSinBoton } from '../../../../helpers/alertas'
import { optionsDate } from '../../../../shared/util/date'
import { ICON } from '../../../../constants/icons'
import { Button, Card, Col, Row, Table, Modal, ModalHeader, ModalBody, ModalFooter, ModalTitle } from 'react-bootstrap'
import { TextArea } from '../../../../components/componentsForm/textarea/textarea'

function Asistencias({ canEdit }) {
    const [modalObservacion, setModalObservacion] = useState()
    const [itemEstudiante, setItemEstudiante] = useState()
    const [observacion, setObservacion] = useState()
    const api = new FormService()

    const {
        state: { estudiantes, asignatura, evento, IdUsuario},
    } = useLocation()
    const [estudiantesAsistencia, setEstudiantesAsistencia] = useState(
        estudiantes || []
    )

    const [selectAll, setSelectAll] = useState(false)

    const onUpdateAsistenciaEstudiante = (value, name, estudiante) => {
        let updateEstudiante = {
            ...estudiante,
            Asistencia: value == false ? '0' : '1',
        }

        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteAsistencia == estudiante.IdEstudianteAsistencia)
                return updateEstudiante
            return es
        })

        setEstudiantesAsistencia([...newEstudianteList])
    }

    const onSelectAll = (value) => { // Función para marcar todos los checkboxes
        setSelectAll(value)
        const newEstudianteList = estudiantesAsistencia.map((estudiante) => ({
            ...estudiante,
            Asistencia: value ? '1' : '0'
        }))
        setEstudiantesAsistencia(newEstudianteList)
    }

    const onSave = () => {
        const api = new FormService()
        const data = estudiantesAsistencia.map((item) => {
            return {
                Asistencia: item.Asistencia,
                IdEstudianteAsistencia: item.IdEstudianteAsistencia,
                Observacion: item?.Observacion
            }
        })
        api.create('docente/guardarAsistencias', { asistencias: data,IdDocente:IdUsuario }).then(
            (res) => {
                console.log(
                    'se ha guardado con exito',
                    estudiantesAsistencia,
                    res
                )
                alertaSinBoton('Guardado!', 'success', 'bottom-end', 1500)
                // retornar a la pagina anterior 
                window.history.back()
            }
        )
    }
    const addObservacion = (estudiante, e)=>{
        console.log(e)
        let updateEstudiante = {
            ...estudiante,
            Observacion: e,
        }

        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteAsistencia == estudiante.IdEstudianteAsistencia)
                return updateEstudiante
            return es
        })

        setEstudiantesAsistencia([...newEstudianteList])
    }
    
    return (
        <>
            <Card>
                <Row>
                    <Col>
                        <Card.Title>Asistencias</Card.Title>
                        <p>{asignatura?.Descripcion}</p>
                        <div>
                            {new Date(evento.FechaInicio).toLocaleDateString('es-co', optionsDate)} {'  de '}
                            {new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {' a '} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}
                        </div>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                        }}
                    >
                        {canEdit && ( // Botón para seleccionar todos los checkboxes
                            <Checkbox
                                checked={selectAll}
                                value={selectAll}
                                onChange={onSelectAll}
                            >
                                Seleccionar todos
                            </Checkbox>

                        )}
                    </Col>
                </Row>
                
            </Card>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    Estudiante
                                </th>
                                <th>
                                    Asistió
                                </th>
                                <th>
                                    Observación
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!estudiantesAsistencia &&
                                estudiantesAsistencia.map(
                                    (estudiante, index) => (
                                        <tr key={index}>
                                            <td>
                                                {/* <div className="content-student">
                                                    <img
                                                        src={
                                                            estudiante?.Foto ||
                                                            user
                                                        }
                                                        alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                                                        className="applicant-list__img-user student"
                                                    />
                                                    <span> */}
                                                        {
                                                            estudiante.Nombre
                                                        }
                                                    {/* </span>
                                                </div> */}
                                            </td>
                                            <td>
                                                {canEdit ? (
                                                    <Checkbox
                                                        checked={ estudiante.Asistencia=='0'?false:true}
                                                        label="Asistió"
                                                        value={ estudiante.Asistencia=='0'?false:true }
                                                        onChange={( value, name ) =>
                                                            onUpdateAsistenciaEstudiante(
                                                                value,
                                                                name,
                                                                estudiante
                                                            )
                                                        }
                                                    ></Checkbox>
                                                ) : (
                                                    <div>
                                                        {estudiante.Asistencia ==
                                                        '0'
                                                            ? ICON.UNCHECKED
                                                            : ICON.CHECKED}
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <TextArea 
                                                    name = {"ObservacionEstudiante"}
                                                    value = {estudiante.Observacion}
                                                    onChange = { (e) => addObservacion(estudiante, e)}
                                                />
                                                {/* <Button 
                                                    onClick={() => { 
                                                        setModalObservacion(true);
                                                        setItemEstudiante(estudiante)
                                                    }}
                                                >
                                                        Agregar 
                                                </Button> */}
                                            </td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </Table>
                    {canEdit && (
                        <div style={{textAlign:"right",marginBottom:50}}>
                            <Button
                                type={'send'}
                                onClick={() => {
                                    onSave()
                                }}
                            >
                                Guardar
                            </Button>
                        </div>
                    )}

            <Modal
                show={modalObservacion}
                onHide={setModalObservacion}
            >
                <ModalHeader closeButton>
                    <ModalTitle>
                        Agregar Observación
                    </ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <TextArea
                        name = {"ObservacionEstudiante"}
                        value = {observacion}
                        onChange = { (e) => setObservacion(e) }
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => {
                                addObservacion(itemEstudiante)
                                setModalObservacion(false)
                            }}
                    > 
                        Guardar 
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export { Asistencias }
