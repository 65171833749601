import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { Card } from "../../components/card/Card";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../services"
import { Title } from "../../shared/components/title/Title";
import { BASE_URL } from "../../services/api/config";


function Alertas () {

  const api = new LocalStorage();
  const IdUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
 

  const Navigate=useNavigate()

  const verMas=(e)=>{
      Navigate("seguimiento_alerta",{state:{alerta:e}})
  }

  
  return (
    <>
    <Card>
     <Title>Alertas ES</Title>
    </Card>

    <Tabs
      defaultActiveKey="home"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Inasistencia">
          <DynamicTable 
              endPoint={`alertas/inasistenciaDocente?IdUsuario=${IdUsuario}`}
              titleColumn={["No","Estudiante","Programa","Asignatura","Acciones"]}
              attributes={["Estudiante","Curso","Asignatura"]}
              onDelete={false}
              onUpdate={false}
              labelOptionClick='Ver más'
              handleOptionClick={verMas}
              exportar={`${BASE_URL}/exportarInasistenciasDocente?IdUsuario=${IdUsuario}`}
          />
      </Tab>
      <Tab eventKey="profile" title="Asignatura">
          <DynamicTable 
              endPoint={`alertas/asignaturasDocente?IdUsuario=${IdUsuario}`}
              titleColumn={["No","Estudiante","Programa","Asignatura","Acciones"]}
              attributes={["Estudiante","Curso","Asignatura"]}
              onDelete={false}
              onUpdate={false}
              labelOptionClick='Ver más'
              handleOptionClick={verMas}
              exportar={`${BASE_URL}/exportarAsignaturasDocente?IdUsuario=${IdUsuario}`}
          />
      </Tab>
      <Tab eventKey="contact" title="Salud">
          <DynamicTable 
              endPoint={`alertas/saludDocente?IdUsuario=${IdUsuario}`}
              titleColumn={["No","Estudiante","Programa","Asignatura","Acciones"]}
              attributes={["Estudiante","Curso","Asignatura"]}
              onDelete={false}
              onUpdate={false}
              labelOptionClick='Ver más'
              handleOptionClick={verMas}
              exportar={`${BASE_URL}/exportarSaludDocente?IdUsuario=${IdUsuario}`}
          />
      </Tab>
    </Tabs>
    </>
  )
}
export { Alertas }