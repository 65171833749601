import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { LocalStorage } from "../../services"

function CursosInscripciones () {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const dimension=api.getItem("dimension");
 
  const urlProgramas = `coordinadoresAcademico/cursosInscripciones?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }

  const goHorario=(programa)=>{
    navigate(`${programa.IdOferta}/horarios`,{state:{oferta:programa,idUsuario}});
  }
  const goAsistencias=(programa)=>{
    navigate(`${programa.IdOferta}/asistencias_docentes`,{state:{programa,idUsuario}});
  }

  const calenAcademico=(programa)=>{
    navigate(`${programa.IdOferta}/calendario`,{state:{programa,idUsuario}});
  }
  
  return (
    <>
    <Card>
     <Card.Title>
      {/* Programa - Actividades Académicas en Inscripciones */}
      {dimension=='"true"'?
        <h3>Cohortes en Inscripciones ES</h3>
        :
        <h3>Actividades Académicas en Inscripciones</h3>
      }
      
      </Card.Title>
    </Card>
    <DynamicTable
      titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
      attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      // labelOptionClick={`Ver más`}
      // handleOptionClick={onNavigate}
      // labelOptionClick2={`Horario general`}
      // handleOptionClick2={goHorario}
      labelOptionClick3={`Horario`}
      handleOptionClick3={calenAcademico}
      // classNameOptionIcon={`bi bi-view-list`}
    ></DynamicTable>

    </>
  )
}
export { CursosInscripciones }