import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { AddButton } from '../../../../components/componentsForm/addButton/addButton';
import { DynamicForm } from '../../../../components/DynamicForm/DynamicForm';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { ID_FORM } from '../../../../constants/idForm';
import { alertaSinBoton, alertaTimer } from '../../../../helpers/alertas';
import { BASE_URL } from '../../../../services/api/config';
import ApiDashboard from '../../../../services/api/dashboard';
import { FormService } from '../../../../services/api/formService';


const Coordinadores=()=>{
    const urlCodinadores = 'instrumento/coordinadores';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
    const [campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCodinadores);

    const api = new ApiDashboard();
    
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            fetchCamposFormForFilter()
            setLoading(false);
        };
        fecthData();
    }, []);


    const instrumentos=(coordinador)=>{
        navigate(`${coordinador.IdUsuario}/evaluaciones`,{state:{coordinador}})
    }

    const enviarNotificaciones = () => {
        setLoading(true)
        const api = new FormService()
        api.getAll(`gestionInstrumentos/notificarCoordinadores`).then(resp=>{
          alertaSinBoton("Se han enviado con exito!",'success','bottom-end',1500)
          setLoading(false)
        }).catch(err=>{
          
          alertaSinBoton("ERROR!",'warning','bottom-end',1500)
          setLoading(false)
        })
    }

    const cerrarModal=()=>{
        setTimeout(() => {
          setIsOpenModalAsignarEvaluacion(false)
        }, 3000);
    
    }

    const fetchCamposFormForFilter = ()=>{   
        const api = new FormService()
            api.getAll(`xcampos?id_formulario=60`)
        .then((res)=>{
            setCampos(res)
        })
    }
    const buscarCoordinadores=(e)=>{
        const nuevoEndPoint=`${urlCodinadores}${e}`
        setEndPoint(nuevoEndPoint)
    }
    return (
        <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>
                        Coordinadores
                    </Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    {/* <Button
                        onClick={()=>setIsOpenModalAsignarEvaluacion(true)}
                    >
                        Asignar evaluación
                    </Button> */}
                    <Button
                        onClick={enviarNotificaciones}
                    >
                        Notificar evaluaciones
                    </Button>
                </Col>
            </Row>
        </Card>
        {campos&&
        <DynamicSearch  onSearch={buscarCoordinadores} camposForm={campos} ></DynamicSearch>
        }
            <DynamicTable
                titleColumn={[ 'N°', 'Cédula','Nombre', 'Acciones', ]}
                endPoint={endPoint}
                attributes={['NumeroDocumento','Nombre']}
                handleOptionClick={instrumentos}
                labelOptionClick={"Evaluaciones"}
                onDelete={false}
                onUpdate={false}
            ></DynamicTable>

            <Modal
                show={isOpenModalAsignarEvaluacion}
                onHide={()=>setIsOpenModalAsignarEvaluacion(false)}
            >
                <DynamicForm
                    endPointSubmit={'gestionInstrumentos/asignarEvaluacionCoordinador'}
                    isCreate={true}
                    //   extraData={{IdOferta:programa.IdOferta}}
                    ventanaFormName={'Gestión instrumentos'}
                    justDynamicForm={{ flag:true, idForm:ID_FORM.EVALUACION_PARA_COORDINADORES }}
                    onClickCancelBtn={setIsOpenModalAsignarEvaluacion}
                    accionMysql={()=>cerrarModal()}
                ></DynamicForm>
            </Modal>
        </>
    );
}


export{Coordinadores};