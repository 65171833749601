import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { FormService } from '../../../services/api/formService'
import { useUpdate } from '../../../hooks/useUpdate'
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate'
import { Documents } from './Documents'
import { alerta } from '../../../helpers/alertas'
const DocumentsContainer = ({
    setIsCompletedDocuments,
    oferta, 
    isCompletedDocuments,
    editable
}) => {
    const [applicant, setApplicant] = useState([])
    const [document, setDocument] = useState({})
    const [observation, setObservation] = useState('')
    const [mensajeRequisitos, setMensajeRequisitos]=useState('');
    const [loading, setLoading] = useState(false)
    const [loadingFull, setLoadingFull] = useState(true)
    const [error, setError] = useState(null)
    const [isEmpty, setIsEmpty] = useState(false)
    const { idApplicantList,idApplicant } = useParams()
    const ENDPOINT = 'requisitosAspirante'
    const ENDPOINT_APPLICANT = 'aspirantes'
    const [update, errorUpdate] = useUpdate(ENDPOINT)
    const [isOpenModalDecline, setIsOpenModalDecline] = useState(false)
    const [isOpenModalAccept, setIsOpenModalAccept] = useState(false)
    const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
    const [isOpenModalRechazar, setIsOpenModalRechazar] = useState(false)
    const ENDPOINT_REQUEST = `requisitosAspirante?IdOferta=${idApplicantList}&IdAspirante=`
    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'requisitosAspirante', 
        null, 
        null,
        '1'
    )
    const fetchDataApplicant = async () => {
        try {
            const formService = new FormService()
            setLoadingFull(true)
            const data = await formService.getAll(`${ENDPOINT_REQUEST}${idApplicant}&IdTipoRequisito=1`)
            setApplicant(data)
            setLoadingFull(false)
        } catch (err) {
            setError(err)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await fetchDataApplicant()
        }
        fetchData()
    }, [loading])

    const onAccept = async (item) => {
        setIsOpenModalAccept(!isOpenModalAccept)
        setDocument(item)
        setIsEmpty(false)
        setObservation('')
    }

    const onDecline = (item) => {
        setIsOpenModalDecline(!isOpenModalDecline)
        setDocument(item)
        setIsEmpty(false)
        setObservation('')
    }

    const onUpload = (item) => {
        setIsOpenModalUpload(!isOpenModalUpload)
        setDocument(item)
        setIsEmpty(false)
        setObservation('')
    }

    const onRechazar = (item) => {
        setIsOpenModalRechazar(!isOpenModalRechazar)
        setDocument(item)
        setIsEmpty(false)
        setObservation('')
    }

    const handleSubmitUpload = async e => {
        e.preventDefault()
        try {
            setLoading(true)
            const formData = new FormData(e.currentTarget)
            formData.set('IdAspirante', idApplicant)
            formData.set('IdRequisito', document?.IdRequisito)
            await fetchDataCreate(formData)
            setIsOpenModalUpload(!isOpenModalUpload)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setError(err)
        }
        
    }

    const handleState = async (state) => {
        if (state === 2 && observation.length < 1) {
            alerta("Escribe el motivo")
            setIsEmpty(true)
            return
        }
        const dataLocal = {
            ...document.requisito,
            EstadoRequisito: state,
        }
        if (state === 2) {
            dataLocal.Observacion = observation
        }
        const keyDelete = [
            'Archivo', 
            'Estado', 
            'FechaCreacion', 
            'IdRequisitoAspirante',
            'IdRequisito',
            'IdAspirante'
        ]
        try {
            setLoading(true)
            await update(dataLocal, 'IdRequisitoAspirante', keyDelete)
            setLoading(false)
            if (state === 1) setIsOpenModalAccept(!isOpenModalAccept)
            if (state === 2) setIsOpenModalDecline(!isOpenModalDecline)
        } catch(err) {
            if (state === 1) setIsOpenModalAccept(!isOpenModalAccept)
            if (state === 2) setIsOpenModalDecline(!isOpenModalDecline)
            setLoading(false)
            setLoadingFull(false)
            setError(err)
        }
    }



    const handleChangeObservation = (value) => {
        setIsEmpty(false)
        setObservation(value)
    }

    const handleChangeMensaje =(value)=>{   
        setMensajeRequisitos(value)
    }

    const onEnroll = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const respuesta=  await formService.update(
                ENDPOINT_APPLICANT, 
                { EstadoRequisitos: "1" }, 
                idApplicant 
            )            
            setLoadingFull(false)
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje, "success")
                setIsCompletedDocuments(false)
            }else if (respuesta.respuesta=="error") {
                alerta(respuesta.mensaje, "error")
            }else{
                alerta("Fallo desconocido")
            }
           
        } catch (err) {
            setError(err)
            setLoadingFull(false)
        }
        
    }

    const rechazarAspirante = async () => {
        setLoadingFull(true)
        try {
            const formService = new FormService()
            const respuesta=  await formService.update(
                ENDPOINT_APPLICANT, 
                { EstadoRequisitos: "2", mensajeRequisitos: mensajeRequisitos}, 
                idApplicant 
            )

            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje, "success")
                setIsCompletedDocuments(false)
            }else if (respuesta.respuesta=="error") {
                alerta(respuesta.mensaje, "error")
            }else{
                alerta("Fallo desconocido")
            }
            setLoadingFull(false)
            setIsOpenModalRechazar(false)
            setMensajeRequisitos('')
        } catch (err) {
            setError(err)
            setLoadingFull(false)
        }
    }


    return (
        <Documents
            oferta={oferta}
            isCompletedDocuments={isCompletedDocuments}
            documentos={applicant}
            loading={loading}
            loadingFull={ loadingFull }
            applicant={ applicant }
            error={ error }
            isOpenModalDecline={ isOpenModalDecline }
            onDecline={ onDecline }
            isOpenModalAccept={ isOpenModalAccept }
            onAccept={ onAccept }
            isOpenModalUpload={ isOpenModalUpload }
            onUpload={ onUpload }
            onEnroll={ onEnroll }
            handleSubmitUpload={ handleSubmitUpload }
            document={ document }
            observation={ observation }
            handleChangeObservation={ handleChangeObservation }
            isEmpty={ isEmpty }
            handleState={ handleState}
            isOpenModalRechazar={isOpenModalRechazar}
            onRechazar={onRechazar}
            mensajeRequisitos={mensajeRequisitos}            
            handleChangeMensaje={handleChangeMensaje}
            rechazarAspirante={rechazarAspirante}
            editable={editable}
        />
    )
}

export { DocumentsContainer }