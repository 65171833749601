import React from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config'

export default function FormatosMen({
    Calendario=null,
    Guia=null,
    ArchivoMalla=null
}) {

    const urlCalendarioAcademico=`${BASE_URL}/formatosIES/descargar/calendarioAcademico`
    const urlReglamentoEstudiantil=`${BASE_URL}/formatosIES/descargar/reglamentoEstudiantil`
    const urlRes=`${BASE_URL}/formatosIES/descargar/res`
  return (
    <>
        <Row>
            <Col>
                <Card>
                    <a style={{textAlign:'center'}} 
                        href={`${BASE_SERVIDOR}/pdf/${Calendario}`}
                        target={'_blank'}
                    >
                        <i className="bi bi-arrow-down"></i><br/>
                        Calendario Académico                               
                        </a>
                </Card>
            </Col>
            <Col>
                <Card>
                    <a style={{textAlign:'center'}} 
                        href={urlReglamentoEstudiantil} 
                        target={'_blank'}
                    >
                        <i className="bi bi-arrow-down"></i><br/>
                        Reglamento Estudiantil                                    
                    </a>
                </Card>
            </Col>
            <Col>
                <Card>
                    <a style={{textAlign:'center'}} 
                        href={urlRes}
                        target="_blank"
                    >
                        <i className="bi bi-arrow-down"></i><br/>
                        Resolución programa                                    
                        </a>
                  </Card>
            </Col>
        </Row>
        <Row>
            <Col>
                <Card>
                    <a style={{textAlign:'center'}} 
                        href={`${BASE_SERVIDOR}/pdf/${Guia}`}
                        target="_blank"
                    >
                        <i className="bi bi-arrow-down"></i><br/>
                        Guía para preinscripción e inscripción al programa                             
                        </a>
                </Card>
            </Col>
            <Col>
                <Card>
                    <a style={{textAlign:'center'}} 
                        href={`${BASE_SERVIDOR}/pdf/${ArchivoMalla}`}
                        target="_blank"
                    >
                        <i className="bi bi-arrow-down"></i><br/>
                        Malla  Curricular                 
                        </a>
                </Card>
            </Col>
        </Row>
    </>
  )
}
