import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FormService } from '../../../../services/api/formService';
import TablePaginate from '../../../../components/TablePaginate/TablePaginate';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchFields } from '../../../../shared/store/table/tableSlice';

const CursosFinalizados=()=>{
    const urlCursos = 'instrumento/cursosFinalizados';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const api = new FormService();
    const dispatch = useDispatch();
    const searchFieldsRedux = useSelector(state => state.table.searchFields);
    const [searchFields2, setSearchFields2] = useState({...searchFieldsRedux});


    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            setLoading(false);
            fetchCamposFormForFilter();
        };
        fecthData();
    }, []);
    const onNavigateDetails = (programa)=>{
        console.log(programa)
        navigate(`${programa.IdCurso}`,{state:{programa}})
    }

    const fetchCamposFormForFilter = ()=>{   
        api.getAll(`xcampos?id_formulario=97`)
        .then((res)=>{
            setCampos(res)
        })
      }
      const buscarCursos=(e)=>{
        const nuevoEndPoint=`${urlCursos}${e}`
        setEndPoint(nuevoEndPoint)
      }

    const handleSearchChange = event => {
        setSearchFields2({ ...searchFields2, [event.target.name]: event.target.value });
    };
    
    const handleSearch = () => {
        console.log(searchFields2);
        dispatch(setSearchFields({ ...searchFields2 }));
    };
    return (
        <>
            <Card>
                <Card.Title>
                   Programas Académicos Finalizados
                </Card.Title>
            </Card>
            <div 
                style={{
                    padding:'10px'
                }}  
            >
                <Row>
                    <Col>
                        <Form.Label>Nombre</Form.Label>
                        <Form.Control
                            type="text"
                            onChange={handleSearchChange}
                            name="Nombre"
                        />
                    </Col>
                    <Col
                        style={{display:'flex',alignItems:'flex-end'}}
                    >
                        <Button
                            variant="primary"
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>       
                    </Col>
                </Row>

            </div>
            {/* {campos&&
                <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            } */}
            <TablePaginate
                apiUrl={endPoint}
                tableHeaders={[
                    {title:'Nombre',attribute:'Nombre'},
                    {title:'Código Grupo',attribute:'codigo_grupo'},
                ]}
            />

        </>
    );
}


export{CursosFinalizados};