import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Form, Modal, ModalHeader, NavDropdown, Table,Card , Col, Row } from "react-bootstrap";
import { TextArea } from '../../../components/componentsForm/textarea/textarea';
import { alerta } from '../../../helpers/alertas';
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config';
import { FormService } from '../../../services/api/formService'
import DowloadFileText from '../../../components/DowloadFileText/DowloadFileText';
import { Select } from '../../../components/select/Select';
import { Input } from '../../../components/input/input';


export default function PagoSolicitudes() {
    const api=new FormService();
    const urlDocumentos=`${BASE_SERVIDOR}`
    const [pagos,setPagos]=useState([]);
    const [modalAceptar,setModalAceptar]=useState();
    const [modalRechazar,setModalRechazar]=useState();
    const [motivoRechazo,setMotivoRechazo]=useState();
    const [pago,setPago]=useState();
    const [convenio, setConvenio] = useState();
    const [tipoConvenio, setTipoConvenio] = useState();
    const [valorConcepto, setValorConcepto] = useState();
    const [pagoEstudiante, setPagoEstudiante] = useState();
    const [porcentajeConvenio, setPorcentajeConvenio] = useState();
    const [valorAprobado, setValorAprobado] = useState();

    const exportar=()=>{
        window.open(`${BASE_URL}/solicitudes/pagosSolicitudes/PDF`,"_blank",)
    }

    useEffect(()=>{
        (async()=>{
            consultarPagos()
        })()

    },[])

    const consultarPagos=async()=>{
        const pagos=await api.getAll("solicitudes/pagosSolicitudes")
        setPagos(pagos)
    }
    const aceptarPago=async()=>{
        const data={
            "EstadoPago":"1",
            "ValorConcepto": valorConcepto,
            "ValorPagoEstudiante": pagoEstudiante,
            "ValorAprobado": valorAprobado,
            "IdConvenio": tipoConvenio,
            "PorcentajeConvenio": porcentajeConvenio

        }
        const respuesta=await api.update("solicitudes",data,pago.IdSolicitud);
        if(respuesta.respuesta=="exito"){
            consultarPagos()
            alerta(respuesta.mensaje)
            setModalAceptar()
        }else{
            alerta("Fallo desconocido")
        }

    }

    const rechazarPago=async()=>{
        const data={
            "EstadoPago":"2",
            "MotivoPagoRechazo":motivoRechazo
        }
        const respuesta=await api.update("solicitudes",data,pago.IdSolicitud);
        if(respuesta.respuesta=="exito"){
            consultarPagos()
            alerta(respuesta.mensaje)
            setModalRechazar()
        }else{
            alerta("Fallo desconocido")
        }
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Pago de Solicitudes</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        variant='primary'
                        // href={`${BASE_URL}/formatosIES/exportar/horarioResumido?IdOferta=${oferta?.IdOferta}&IdHorario=${horario.IdHorario}`}
                    >
                        <DowloadFileText
                            nameFile={"Pago de Solicitudes.xlsx"}
                            name={"Exportar"}
                            colortext={"#ffffff"}
                            endPoint={`${BASE_URL}/solicitudes/pagosSolicitudes/PDF`}
                        />
                    </Button>
{/*                    <Button
                        onClick={()=>exportar()}
                    >
                        Exportar
                    </Button>*/}
                </Col>
            </Row>
        </Card>
        <Card>
            <Table>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Solicitud</th>
                        <th>Valor</th>
                        <th>PDF</th>
                        <th>Fecha</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {pagos.map((pago,key)=>
                        <tr>
                            <td>{pago.Solicitante}</td>
                            <td>{pago.Solicitud}</td>
                            <td>{pago.ValorSolicitud}</td>
                            <td>
                                <a target="_blank" href={`${urlDocumentos}/pdf/${pago.ComprobantePago}`}>Ver PDF</a>
                            </td>
                            <td>{pago.FechaPago}</td>
                            <td>
                                <Button
                                    variant='danger'
                                    onClick={()=>{
                                        setModalRechazar(true)
                                        setPago(pago)
                                    }}
                                >
                                    Rechazar
                                </Button>
                                <Button
                                 onClick={()=>{
                                    setModalAceptar(true)
                                    setPago(pago)
                                }}
                                >
                                    Aceptar
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Card>

        <Modal 
            show={modalAceptar}
            onHide={()=>setModalAceptar()}
        >
            <ModalHeader>
                <h2>¿Desea aceptar este pago?</h2>
            </ModalHeader>
            <Modal.Body>
                <Input
                    type={"text"}
                    label="Valor del Concepto *"
                    required={true}
                    name={"ValorConcepto"}
                    onChange={(e) => setValorConcepto(e)}
                    value={valorConcepto}
                    max={15}
                    min={1}
                />
                <Input
                    type={"text"}
                    label="Valor Pagado por Estudiante *"
                    required={true}
                    name={"ValorPagoEstudiante"}
                    onChange={(e) => {setPagoEstudiante(e)}}
                    value={pagoEstudiante}
                    max={15}
                    min={1}
                />
                <br />
                <label htmlFor="">¿ Convenio ?</label>
                <Form.Check 
                    type="switch"
                    name="Convenio"
                    id="Convenio"
                    label="Sí"
                    checked = {convenio}
                    value={convenio}
                    onChange={ (e) => {
                        setConvenio(e.target.checked)
                    }}
                />
                <br />
                {convenio &&
                    <>
                    <Select
                        id="IdTipoConvenio"
                        name="IdTipoConvenio"
                        onChange={ (e) => setTipoConvenio(e) }
                        value={ tipoConvenio || null }
                        label="Convenio *"
                        required={true}
                        optionValue="IdConvenio"
                        optionName="Descripcion"
                        urlOptionsService={`convenio`}
                    />
                    <Input
                        type={"text"}
                        label="Porcentaje del Convenio (%)*"
                        required={true}
                        name={"PorcentajeConvenio"}
                        onChange={(e) => {setPorcentajeConvenio(e)}}
                        max={15}
                        min={1}
                    />
                    </>
                }
                <Input
                    type={"text"}
                    label="Valor Aprobado *"
                    required={true}
                    name={"ValorAprobado"}
                    onChange={(e) => {setValorAprobado(e)}}
                    max={15}
                    min={1}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalAceptar()}
                >
                    Cancelar
                </Button>
                <Button
                onClick={()=>aceptarPago()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal 
            show={modalRechazar}
            onHide={()=>setModalRechazar()}
        >
            <Modal.Body>
                <TextArea
                    label="Describe el motivo"
                    value={motivoRechazo}
                    onChange={(e)=>setMotivoRechazo(e)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalRechazar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant='danger'
                    onClick={()=>rechazarPago()}
                >
                    Rechazar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
