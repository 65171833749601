import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { AddButton } from '../componentsForm/addButton/addButton'
import { LoadingSpinner } from '../LoadingSpinner/LoadingSpinner'
import { ErrorHandling } from '../errorHandling/ErrorHandling'
import { DeleteDialog } from './DeleteDialog'
import { DynamicForm } from './DynamicForm'
import { DynamicTable } from './dynamicTable/DynamicTable'
import {
    setError,
    setErrorReset,
    setIsCreate,
    setIsOpenForm,
    onCloseModals,
    clearItemSelected,
    setFieldsForm,
    setHistoryForm,
    setForm,
    clearHistoryForm,
} from './FormSlice'

import { DynamicSearch } from './DynamicSearch/DynamicSearch'
import { Card, Modal, Row, Col, Button } from 'react-bootstrap'
import { BASE_URL } from '../../services/api/config'

export function DynamicFormContainer({
    idForm,
    children,
    showSubHeaderAdd = true,
    showTable = true,
    showFilters = true,
    accion,
    idPadre=null,
    idPadre2=null,
    handleOptionClick,
    labelOptionClick,
    extraData=[],
    onActions=true,
    exportar,
    accionMysql
}) {
    
    const location = useLocation()
    const accionFromTable = location?.state?.accion || null

    const itemSelectedFromTable = location.state?.item || null
    const previusFormSnapshotFromTable = location.state?.formSnapshot || null
    const [formSnapshot, setFormSnapshot] = useState({})
    const [previusParentPrimaryField, setPreviusParentPrimaryField] = useState(null)
    const [columnsTitleTable, setColumnsTitleTable] = useState([])
    const [attributesTable, setAttributesTable] = useState([])
    const [loading, setLoading] = useState(true)
    const formSelector = useSelector((state) => state.form) 
    const error = useSelector((state) => state.form.error)
    const dispatch = useDispatch()
    const api = new FormService()
    const [parametros,setParametros]=useState('')
    const [busqueda,setBusqueda] = useState()

    const [endpoint, setEndpoint] = useState(''
        // accionFromTable?.endpoint
        //     ?
        //      `${accionFromTable?.endpoint}?${
        //           previusFormSnapshotFromTable?.id,"___"
        //       }=${
        //           itemSelectedFromTable[
        //               previusFormSnapshotFromTable?.id,"___"
        //           ]
        //       }`
        //     : formSnapshot?.endpoint
    )

    useEffect(() => {
        const fetchData = async () => {
            await fetchForm()
        }
        fetchData()
    }, [
        idForm || accionFromTable?.id,
        location?.state?.accion?.idmalla,
        endpoint,
        accionFromTable,
        itemSelectedFromTable,
    ])

    const fetchForm = async () => {
        setLoading(true)
        if (accionFromTable == null && !accionFromTable) {
            dispatch(clearHistoryForm())
            dispatch(setErrorReset())
            dispatch(clearItemSelected())
        }
        try {
            const [data, dataDynamicFields] = await Promise.all([
                api.getAll(
                    `xformularios/${
                        idForm ||
                        accionFromTable?.id ||
                        'IdForm_&_accionFromTableId_IsEmpty'
                    }`
                ),
                api.getAll(
                    `xcampos?id_formulario=${
                        idForm ||
                        accionFromTable?.id ||
                        'IdForm_&_accionFromTableId_IsEmpty'
                    }`
                ),
            ])
            setFormSnapshot(data)
            dispatch(setForm(data))


            if (formSelector.itemSelected)
                dispatch(setHistoryForm(formSelector.itemSelected))
            if (previusFormSnapshotFromTable) {
                setPreviusParentPrimaryField(
                    {[previusFormSnapshotFromTable?.llave_primaria]:
                            itemSelectedFromTable[
                                previusFormSnapshotFromTable?.llave_primaria
                            ],
                    }
                )
            }
            dispatch(setFieldsForm(dataDynamicFields))
            buildColumnsAndAttributesTable(dataDynamicFields)

            setEndpoint(accionFromTable?.endpoint ? 
                    `${accionFromTable?.endpoint}?${
                          previusFormSnapshotFromTable?.llave_primaria
                      }=${
                          itemSelectedFromTable[
                              previusFormSnapshotFromTable?.llave_primaria
                          ]
                      }${parametros}`
                    : `${data?.endpoint}${idPadre?idPadre:''}${parametros}`
            )
            
            console.log(accionFromTable+endpoint)
            setLoading(false)
        } catch (err) {
            dispatch(setError([...error, err]))
            setLoading(false)
        }
    }

    const buildColumnsAndAttributesTable = (dataDynamicFields) => {
        const columns = dataDynamicFields.filter((item) => {
            if (item.render_tabla == '1') return item
            return false
        })
        const columnsName = columns.map((item) => item.label)
        const renderValue = columns.map((item) => item.identificador)
        if(onActions) {
            setColumnsTitleTable(['N°', ...columnsName, 'Acción'])
        } else {
            setColumnsTitleTable(['N°', ...columnsName])
        }
        setAttributesTable(renderValue)
    }

    const onClickCreate = async () => {
        dispatch(setIsCreate(true))
        dispatch(onCloseModals())
        dispatch(clearItemSelected())
        dispatch(setIsOpenForm(true))
    }

    const onSearch = (paramsUrls) => {
        const newEndPointWithParamsSearch = endpoint + paramsUrls
        setEndpoint(newEndPointWithParamsSearch)
        setBusqueda(paramsUrls)
        setParametros(paramsUrls)
    }
    return (
        <>
            {showSubHeaderAdd && (
                <AddButton buttonTitle="Crear"  createModal={onClickCreate}/>
            )}

            {showFilters && (
                <DynamicSearch onSearch={onSearch}/>
            )}
            
            {loading && <LoadingSpinner />}
            {children ?
                <div className="dynamic-form-children">{children}</div>
             : null
             }

            {error.length <= 0 && showTable && endpoint.length>0&& 
                <DynamicTable
                    exportar={exportar}
                    titleColumn={columnsTitleTable}
                    attributes={attributesTable}
                    endPoint={endpoint}
                    busqueda={busqueda}
                    refreshTableToggle={formSelector.refreshTableToggle}
                    accionesArray={formSnapshot?.hijos}
                    handleOptionClick={handleOptionClick}
                    labelOptionClick={labelOptionClick}
                    onActions={onActions}
                />
            }
            <Modal 
                show={formSelector.isOpenForm}
                onHide={()=>dispatch(onCloseModals())}
            >
                <DynamicForm
                    extraData={previusParentPrimaryField || extraData}
                    ventanaFormName={formSnapshot?.nombre_form}
                    title={
                        formSelector.itemSelected?.Nombre ||
                        formSelector.itemSelected?.Descripcion ||   
                        formSnapshot?.nombre
                    }
                    endPointSubmit={formSnapshot?.endpoint}
                    keySubmit={formSnapshot?.llave_primaria}
                    isContentTypeFormData={formSnapshot?.form_data}
                    isCreate={formSelector.isCreate}
                    handleOptionClick={handleOptionClick}
                    labelOptionClick={labelOptionClick}
                    accionMysql={accionMysql}
                />
            </Modal>

            <Modal 
                show={formSelector.isOpenDelete}
                onHide={()=>dispatch(onCloseModals())}
            >
                <DeleteDialog
                    parentField={previusParentPrimaryField || null}
                    endPoint={formSnapshot?.endpoint}
                    keySubmit={formSnapshot?.llave_primaria}
                    isContentTypeFormData={formSnapshot.form_data}
                    handleOptionClick={handleOptionClick}
                    labelOptionClick={labelOptionClick}
                />
            </Modal>
            {error && <ErrorHandling bugs={error} />}
        </>
    )
}
