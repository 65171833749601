import React, { useEffect, useState } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import SeleccionarAsignaturas from '../../components/Student/SeleccionarAsignaturas/SeleccionarAsignaturas'
import { useLocation } from 'react-router-dom'
import { FormService } from '../../services/api/formService'
import { alerta } from '../../helpers/alertas'
import { useParams } from 'react-router-dom'
import { Message } from '../../components/Message/Message'
import { ViewPDF } from '../../components/Applicant/Documents/ViewPDF'
import { useUpdate } from '../../hooks/useUpdate'

export default function AsignaturasAntiguos() {
    const api= new FormService()
    const { oferta, aspirante } = useLocation().state
    const URL = `requisitosAspirante?IdOferta=${oferta.IdOferta}&IdAspirante=`
    const [asignaturasAprobadas, setAsignaturasAprobadas] = useState(aspirante.AsignaturasAprobadas)
    const [matriculado, setMatriculado] = useState(aspirante?.EstadoEstudiante)
    const [documents, setDocuments] = useState([])
    const [document, setDocument] = useState({})
    const [observation, setObservation] = useState('')
    const [loadingFull, setLoadingFull] = useState(true)
    const [error, setError] = useState(null)
    const [isEmpty, setIsEmpty] = useState(false)
    const { idApplicantList,idApplicant } = useParams()
    const ENDPOINT = 'requisitosAspirante'
    const ENDPOINT_APPLICANT = 'aspirantes'
    const [update, errorUpdate] = useUpdate(ENDPOINT)
    const [isOpenModalDecline, setIsOpenModalDecline] = useState(false)
    const [isOpenModalAccept, setIsOpenModalAccept] = useState(false)
    const [isOpenModalUpload, setIsOpenModalUpload] = useState(false)
    const ENDPOINT_REQUEST = `requisitosAspirante?IdOferta=${idApplicantList}&IdAspirante=`

    const ESTADO_DOCUMENTO = {
        NO_SE_HA_CARGADO_NADA:'0',
        APROBADO:'1',
        RECHAZADO:'2'
    }
    
    // matricular estudiante
    const matricularEstudiante = async() => {
        const data = {
            IdAspirante: aspirante.IdAspirante,
            IdOferta: oferta.IdOferta,
            IdUsuario: aspirante.IdUsuario,
            IdDimension: 1,
            antiguo: 1,
        }
        const response = await api.create('aspirantes/convetirEstudiante', data)
        if(response.respuesta=="exito"){
              alerta(response.mensaje)
              setMatriculado(1)
        }else{
              alerta(response.mensaje)
        }
      }
    useEffect(()=>{
      fetchDataDocuments()
    },[])

    const onEnroll = async () => {
      setLoadingFull(true)
      try {
          const formService = new FormService()
          const respuesta=await formService.update(
              ENDPOINT_APPLICANT, 
              { EstadoRequisitos: "1" }, 
              idApplicant 
          )
          
          setLoadingFull(false)
          if(respuesta.respuesta=="exito"){
              alerta(respuesta.mensaje, "success")
              console.log("enviado")
              // setIsCompletedEnrollment(false)
          }else if (respuesta.respuesta=="error") {
              alerta(respuesta.mensaje, "error")
          }else{
              alerta("Fallo desconocido")
          }
          console.log(respuesta)
      } catch (err) {
          setError(err)
          setLoadingFull(false)
      }
  }

  const onAccept = async (item) => {
      setIsOpenModalAccept(!isOpenModalAccept)
      setDocument(item)
      setIsEmpty(false)
      setObservation('')
  }

  const onDecline = (item) => {
      setIsOpenModalDecline(!isOpenModalDecline)
      setDocument(item)
      setIsEmpty(false)
      setObservation('')
  }

  const onUpload = (item) => {
      setIsOpenModalUpload(!isOpenModalUpload)
      setDocument(item)
      setIsEmpty(false)
      setObservation('')
  }
    
  // consultar requsitos de matricula del estudiante
  const fetchDataDocuments = async () => {
      setLoadingFull(true)
      try {
          const formService = new FormService()
          const response = await formService.getAll(`${URL}${aspirante?.IdAspirante}&IdTipoRequisito=2`)
          setLoadingFull(false)
          setDocuments(response)
      } catch (error) {
          setLoadingFull(false)
          setError(error)
      }
  }

  return (
    <>
        <Card>
            <Card.Title>{aspirante.Nombre}</Card.Title>
            <p>Asignaturas Antiguos</p>
        </Card>
        <div className='documents__container'>
            {
                documents.map((item, key) => (
                    <ViewPDF 
                        item={ item }
                        key={ key }
                        onAccept={ onAccept }
                        onDecline={ onDecline }
                        onUpload={ onUpload }
                        // editable={editable}
                    />
                ))
            }
            {
                error && (
                    <Message type='error'>
                        { error.message }
                    </Message>
                )
            }
        </div>
        {/* motrar los requisitos de matricula del estudiante */}
        <SeleccionarAsignaturas 
            aspirante={aspirante}
            IdOferta={oferta.IdOferta}
            admision={true}
            asignaturasAprobadas={asignaturasAprobadas}
            setAsignaturasAprobadas={setAsignaturasAprobadas}
        />
        {/* validar que el aspiraten no este matriculado */}
        {matriculado==0&&
          <>
            {asignaturasAprobadas==1&&
              <div 
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >              
                <Button 
                  onClick={matricularEstudiante}
                >
                  Matricular
                </Button>
              </div>
            }
          </>
        }
        {matriculado==1&&
          <Alert variant="success">
            Estudiante Matriculado
          </Alert>
        }
        {matriculado==2&&
          <Alert variant="danger">
            Estudiante No Matriculado
          </Alert>
        }
    </>
  )
}
