import { useLocation} from "react-router-dom"
import { ICON } from "../../../../constants/icons"
import Table from 'react-bootstrap/Table';
import { useState } from "react"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import { ID_FORM } from "../../../../constants/idForm"
import { Input } from "../../../../components/input/input"
import { alerta } from "../../../../helpers/alertas"
import { useEffect } from "react"
import { FormService } from "../../../../services/api/formService"
import { Button, Card, Col, Modal, Row } from "react-bootstrap";

function CoordinadorListadoEvaluacionesDocentes () {

  const {state:{coordinador}} = useLocation()
  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [isOpenModalEvaluacionDelete,setIsOpenModalEvaluacionDelete] = useState(false)
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
  const [ evaluaciones,setEvaluaciones] = useState([])
  const [ itemSelected,setItemSelected] = useState({})
  const [ dateRangeEvaluacion,setDateRangeEvaluacion] = useState({})
  
  const api = new FormService()
  useEffect(()=>{
    fetchEvaluaciones()
  },[])

  const handleEditClick = (item) => {
    console.log({item})
    console.log({coordinador},)

    setItemSelected(item)
    setDateRangeEvaluacion({FechaInactivo:item.FechaInactivo,FechaActivo:item.FechaActivo})
    setIsOpenModalEvaluacion(true)
  }
  const handleDeleteClick = (item) => {

    setItemSelected(item)
    setIsOpenModalEvaluacionDelete(true)
  }
  const onHandleChange =(value,name)=>{
    console.log(dateRangeEvaluacion)
    setDateRangeEvaluacion({...dateRangeEvaluacion,[name]:value})
  }
  const fetchEvaluaciones = async()=>{
    api.getAll(`gestionInstrumentos/evaluacionesCoordinador?IdUsuario=${coordinador.IdUsuario}`)
    .then(res=>{
      setEvaluaciones(res)
    })
  }
  
  const onUpdateDate =async ()=>{
    try {
      const res = await api.update(`gestionInstrumentos`,dateRangeEvaluacion,itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacion(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  const onDeleteEvaluacion =async ()=>{
    try {
      const res = await api.update(`gestionInstrumentos`,{Estado:0},itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacionDelete(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }

  const actualizarEvaluaciones=()=>{
    setTimeout(() => {
      fetchEvaluaciones()
      setIsOpenModalAsignarEvaluacion()
    }, 2000);
  }
  
  return (
    <>
    <Card>
      <Row>
        <Col>
          <Card.Title>{coordinador.Nombre}</Card.Title>
          <div>Evaluaciones asginadas</div>
        </Col>
        <Col style={{textAlign:"right"}}>
          <Button
            onClick={()=>setIsOpenModalAsignarEvaluacion(true)}
          >
            Asignar evaluación
          </Button>
        </Col>        
      </Row>
    </Card>
  
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>N°</th>
          <th>Docente</th>
          <th>N° documento</th>
          <th>Nombre del instrumento</th>
          <th>Plazo (A/M/D)</th>
          <th>Realizado</th>
          <th>Cambiar fecha</th>
        <th>Eliminar</th>
        </tr>
      </thead>
      <tbody>
        {!!evaluaciones && evaluaciones.map((item,index)=>{
          return(
            <tr key={index} >
              <td>{index+1}</td>
              <td>{item.PrimerNombres} {item.PrimerApellidos} {item.SegundoApellidos}</td>
              <td>{item.NumeroDocumento}</td>
              <td>{item.nombre}</td>
              <td> {item.FechaActivo} / {item.FechaInactivo}</td>
              <td>{item.EstadoInstrumento=='1'?` ${ICON.CHECKED}` :` ${ICON.UNCHECKED}`}</td>
              <td onClick={()=>handleEditClick(item)}> <i className="bi bi-pencil"></i></td>
            <td onClick={()=>handleDeleteClick({...item,index:(index+1)})} ><i className="bi bi-trash3"></i></td>
          
            </tr>
          )
        }

        )}
      </tbody>
    </Table>
 
    <Modal
      show={isOpenModalAsignarEvaluacion}
      onHide={()=>setIsOpenModalAsignarEvaluacion(false)}
    >
      <DynamicForm
        endPointSubmit={'gestionInstrumentos/asignarEvaluacionCoordinador'}
        isCreate={true}
        extraData={{IdUsuario2:coordinador.IdUsuario}}
        ventanaFormName={'Gestión instrumentos'}
        justDynamicForm={{ flag:true, idForm:ID_FORM.EVALUACION_PARA_COORDINADORES }}
        onClickCancelBtn={setIsOpenModalAsignarEvaluacion}
        accionMysql={actualizarEvaluaciones}
      ></DynamicForm>
    </Modal>

      <Modal
        show={isOpenModalEvaluacion}
        onHide={()=>setIsOpenModalEvaluacion(false)}
      >
      <Modal.Header closeButton>
        <Modal.Title>
          Actulizar la fecha de presentación
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
          <label>Fecha inicial</label>
          <Input
          name={'FechaActivo'}
          onChange={onHandleChange}
          value={dateRangeEvaluacion.FechaActivo || '' }
          type={'date'}
          ></Input>
          <br></br>
          <label>Fecha limite</label>
          <Input
          onChange={onHandleChange}
          name={'FechaInactivo'}
          value={dateRangeEvaluacion.FechaInactivo ||  '' }
          type={'date'}
          ></Input>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setIsOpenModalEvaluacion(false)}
          >
            Cancelar
          </Button>
          <Button 
            onClick={onUpdateDate}
          >
              Actualizar
          </Button>
        </Modal.Footer>

      </Modal>

      <Modal
      show={isOpenModalEvaluacionDelete}
      onHide={()=>setIsOpenModalEvaluacionDelete(false)}
      >
      <Modal.Body>
        ¿ Estas seguro que desea eliminar esta evaluación ?
      </Modal.Body>

      <Modal.Footer>
        <Button 
          variant="secondary"
          onClick={()=>setIsOpenModalEvaluacionDelete(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={onDeleteEvaluacion}
        >
          Eliminar
        </Button>
      </Modal.Footer>

      </Modal>
    </>
  )
}

export { CoordinadorListadoEvaluacionesDocentes}