import { Route, Routes } from "react-router-dom"
import { ActiveCourses } from "../../components/outlet/ActiveCourses/ActiveCourses"
import { ActiveOffers } from "../../components/outlet/ActiveOffers/ActiveOffers"
import { Applicant } from "../../components/outlet/Applicant/Applicant"
import { ApplicantListContainer } from "../../components/outlet/ApplicantList/ApplicantListContainer"
import { CompletedCourses } from "../../components/outlet/CompletedCourses/CompletedCourses"
import { GraduateList } from "../../components/outlet/GraduateList/GraduateList"
import { Student } from "../../components/outlet/Student/Student"
import { StudentsList } from "../../components/outlet/StudentsList/StudentsList"
import { SeguiminetoAlerta } from "../bienestar/SeguiminetoAlerta"
import { EstudianteAsistenciasCursoActivo } from "../estudiante/cursosActivos/EstudianteAsistenciasCursoActivo"
import { AlertasSocioeconomicas } from "./AlertasSocioeconomicas"
import { CalendarioAcademico } from "./CalendarioAcademico"
import Convocatorias from "../admin/convocatoria/Convocatorias"
import Fases from "../admin/convocatoria/Fases"
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import MatriculaAntiguos from "./MatriculaAntiguos"
import EstudiantesAntiguos from "./EstudiantesAntiguos"
import AsignaturasAntiguos from "./AsignaturasAntiguos"
import PeridosInscritos from "../registro/EducacionSuperior/PeridosInscritos"
import FormCalendario from "../calendario/FormCalendario"

function Admision () {
  return (
    <Routes>
      <Route path="inscripciones" element={<ActiveOffers full={false} />} />
      <Route path="inscripciones/lista-aspirante/:idApplicantList" element={<ApplicantListContainer Admision={true}/>} />
      <Route path="inscripciones/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant matricula={false} />} />      
      <Route path="alertas_socioeconomicas" element={<AlertasSocioeconomicas/>}/>
      <Route path="alertas_socioeconomicas/seguimiento_alerta" element={<SeguiminetoAlerta/>}/>
      <Route path="estudiantes" element={<ActiveCourses />} />
      <Route path="estudiantes/lista-estudiantes/:idCourse" element={<StudentsList />} />
      <Route path="estudiantes/lista-estudiantes/:idCourse/estado-estudiante/:idStudent" element={<Student />} />
      <Route path="cursos_finalizados" element={<CompletedCourses />} />
      <Route path="cursos_finalizados/lista-graduados/:idCourse" element={<GraduateList />} />
      <Route path="estudiantes/lista-estudiantes/:idCourse/estado-estudiante/:idStudent/asistencia" element={<EstudianteAsistenciasCursoActivo />} /> 
      <Route path="convocatorias" element={<Convocatorias/>} />
      <Route path="convocatorias/fases" element={<Fases/>} />
      <Route path="calendario_academico" element={<CalendarioAcademico/>} />
      <Route path="calendario_academico/crear" element={<FormCalendario/>} />
      <Route path="calendario_academico/:id" element={<FormCalendario/>} />
      {/* <Route path="calendario_academico/:calendario/sesiones" element={<EventosCalendario/>} /> */}
      <Route path="matricula_antiguos" element={<MatriculaAntiguos/>} />
      <Route path="matricula_antiguos/estudiantes_antiguos/:id" element={<EstudiantesAntiguos/>} />
      <Route path="matricula_antiguos/estudiantes_antiguos/:id/asignaturas" element={<AsignaturasAntiguos/>} />
      {/* periodos_inscritos */}
      <Route path='periodos_inscritos' element={<PeridosInscritos/>} />
      <Route path="periodos_inscritos/lista-aspirante/:idApplicantList" element={<ApplicantListContainer Admision={true}/>} />
      <Route path="periodos_inscritos/lista-aspirante/:idApplicantList/estado-aspirante/:idApplicant" element={<Applicant full={true}  matricula={false} />} />
      <Route path="*" element={<DynamicFormContainer/>} />
    </Routes>
  )
}
export { Admision }