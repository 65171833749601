import React, { useEffect, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import GraficoBarras from './GraficoBarras'
import EmbudoConversion from './EmbudoConversion';
import TablaCreditos from './TablaCreditos';
import { FormService } from '../../services/api/formService';


export default function Graficas() {
  const api= new FormService();
  const [dataTable, setDataTable]= useState(null);

  const getDataTable= async () => {
    const data= await api.getAll("graficas/creditosEstudiantes")
    setDataTable(data);
  }
  // consultar las inasistencias de docentes

  const inasistenciasDocentes= async () => {
    const data= await api.getAll("graficas/inasistenciasDocentes")
    setDataTable(data);
  }

  useEffect(() => {
    getDataTable();
    inasistenciasDocentes();
  }, [])
    
    const data = [
        {
          rango: '1 - 32',
          cantidad: 22
        },
        {
          rango: '33 - 64',
          cantidad: 1
        },
        {
          rango: '65 - 96',
          cantidad: 0
        },
        {
          rango: '97 - 128',
          cantidad: 0
        },
        {
          rango: '129 - 160',
          cantidad: 1
        }
      ];
              
  return (
    <>
        <Card>
            <Card.Title>
                Graficas
            </Card.Title>
        </Card>
        <Row>
            <Col>
                <TablaCreditos
                    data={dataTable}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <GraficoBarras
                 data={data} 
                  titulo="Inasistencias de Docentes"
                />
            </Col>
            <Col>
                <GraficoBarras 
                data={data} 
                titulo="Inasistencias de Estudiantes"
                />
            </Col>
        </Row>
        <Row>
          <Col>
                  <GraficoBarras 
                      data={data} 
                      titulo="Promedio Estudiantes"
                  />
          </Col>
            <Col>
                <EmbudoConversion />
            </Col>
        </Row>
    </>
  )
}
