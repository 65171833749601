import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { FormService } from '../../../services/api/formService';
import { useSubmitCreate } from '../../DynamicForm/hooks/useSubmitCreate';
import { Admission } from './Admission';
import { alerta, alertaSinBoton } from '../../../helpers/alertas';
import ApiDashboard from '../../../services/api/dashboard';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'react-bootstrap';

const AdmissionContainer = ({ 
    setIsCompletedAdmission, 
    full,
    isCompletedAdmission,
    editable,
    oferta,
    aspirante
}) => {
    const api = new FormService()
    const [loading, setLoading] = useState(false);
    const [loadingFull, setLoadingFull] = useState(true);
    const [error, setError] = useState(null);
    const [admissionType, setAdmissionType] = useState('');
    const [announcement, setAnnouncement] = useState([]);
    const [phase, setPhase] = useState({});
    const [isOpenModalDecline, setIsOpenModalDecline] = useState(false);
    const [isOpenModalAccept, setIsOpenModalAccept] = useState(false);
    const [isEmpty, setIsEmpty] = useState(false);
    const [observation, setObservation] = useState('');
    const [puntaje, setPuntaje] = useState('');
    const [score, setScore] = useState('');
    const [faseItem, setFaseItem] = useState('');
    const [curretUser, setCurretUser] = useState(0);
    const { idApplicant } = useParams();
    const ENDPOINT_APPLICANT = 'aspirantes';
    const ENDPOINT_PHASE = 'aspiranteFase';
    const [mensajeRequisitos, setMensajeRequisitos]=useState('');
    const [tipoConvenio, setTipoConvenio]=useState('');
    const [tipoAlumno, setTipoAlumno] = useState('');
    const [tipoAreaPIC, setTipoAreaPIC] = useState('');
    const [regional, setRegional] = useState('');
    const [isOpenModalRechazar, setIsOpenModalRechazar] = useState(false)
    const [isOpenModalAprobar, setIsOpenModalAprobar] = useState(false)
    const [nombreConvocatoria, setNombreConvocatoria] = useState('Convocatoria')

    const [tipoVinculacion, setTipoVinculacion] = useState(aspirante?.IdTipoVinculacion)
    const {
        state: { offer,setIsCompletedDocuments },
    } = useLocation();


    const URL = `aspiranteFase?IdAspirante=${idApplicant}&IdConvocatoria=`;    

    const [fetchDataCreate, statusCreate] = useSubmitCreate(
        'aspiranteFase',
        null,
        null,
        '1'
    );

    const DIRECT = 'Proceso de admisión directo';
    const AGREEMENT = 'Proceso de admisión por convenio';
    const ANNOUNCEMENT = 'Convocatoria';

    const fetchDataAnnouncement = async () => {
        const user = JSON.parse(localStorage.getItem('usuario'));
        const userLS = user.usuario.IdUsuario;

        try {
            /* console.log('fetchDataAnnouncement'); */
            setLoadingFull(true);
            const formService = new FormService();
            const data = await formService.getAll(
                full?`aspiranteFaseFull?IdAspirante=${idApplicant}&IdConvocatoria=${offer?.IdConvocatoria}`
                :`${URL}${offer?.IdConvocatoria}&IdUsuario=${userLS}`
                
            );

            setAnnouncement(data);
            setLoadingFull(false);
        } catch (err) {
            setError(err);
        }
    };

    const nombrarConvocatoria = async () => {
        const data = await api.getById(`convocatoria`, offer?.IdConvocatoria)
        setNombreConvocatoria(data?.Nombre)
    }

    const defineAdmissionType = async () => {
        setLoadingFull(false);
        if (['1', '2','5','6'].includes(offer?.IdTipoMecanismo)) {
            setAdmissionType(DIRECT);
        } else if (offer?.IdTipoMecanismo == '3') {
            setAdmissionType(AGREEMENT);
        }else if (offer?.IdTipoMecanismo == '4' ||offer?.IdTipoMecanismo == '7' || offer?.IdTipoMecanismo == '9') {
            await fetchDataAnnouncement();
            nombrarConvocatoria();
            setAdmissionType(ANNOUNCEMENT);
        }
    };

    //console.log(curretUser);
    useEffect(() => {
        defineAdmissionType();
    }, [loading]);

    const approve = async () => {
        if(tipoAlumno==''){
           return alertaSinBoton("Debe seleccionar un tipo de alumno")
        }
            
        try {
            setLoading(true);
            const formService = new FormService();
            const respuesta=await formService.update(
                ENDPOINT_APPLICANT,
                { EstadoAdmision: '1', IdConvenio: tipoConvenio, IdTipoAlumno:tipoAlumno, IdTipoAreaPic:tipoAreaPIC, IdRegional: regional},
                idApplicant
            );
            
            setLoading(false);
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje, "success")
                setIsCompletedAdmission(false);
                setIsOpenModalAprobar(false);
            }else if (respuesta.respuesta=="error") {
                alerta(respuesta.mensaje, "error")
            }else{
                alerta("Fallo desconocido")
            }
        } catch (err) {
            setLoading(false);
            setError(err);
        }
    };

    const handleSubmit = async (e, item) => {
        e.preventDefault();
        try {
            setLoading(true);
            const formData = new FormData(e.currentTarget);
            formData.set('IdAspirante', idApplicant);
            formData.set('IdConvocatoriaFase', item?.IdConvocatoriaFase);
            await fetchDataCreate(formData);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    };

    const onHandleChangeScore = (value) => {
        setScore(value);
    };

    const onHandleSubmitScore = async (item) => {
        const id = item?.IdConvocatoriaFase;
        const formService = new FormService();
        const data = {
            Puntaje: score,
            IdAspirante: idApplicant,
            IdConvocatoriaFase: id,
        };
        try {
            setLoading(true);
            await formService.create(ENDPOINT_PHASE, data);
            setLoading(false);
        } catch (err) {
            setError(err);
        }
    };
    
    const onAccept = async (item) => {
        setIsOpenModalAccept(!isOpenModalAccept);
        setPhase(item);
        setIsEmpty(false);
        setObservation('');
    };

    const onDecline = (item) => {
        setIsOpenModalDecline(!isOpenModalDecline);
        setPhase(item);
        setIsEmpty(false);
        setObservation('');
    };

    const handleState = async (state) => {
        if (state == 2 && observation.length < 10) {
            setIsEmpty(true);
            return;
        }

        const data = {
            IdAspirante: idApplicant,
            IdConvocatoriaFase: phase?.IdConvocatoriaFase,
            Aprobo: state,
            Puntaje: puntaje,
            Observaciones: observation,
        };

        if (state == 2) {
            data.Observaciones = observation;
            data.Puntaje = puntaje;
        }

        /* console.log('DATA ENVIADA--- ', data); */

        try {
            setLoading(true);
            const formService = new FormService();
            /* console.log('DATA ENVIADA--- ', data); */

            if (faseItem.fase != null) {
                // editando registro
                const api = new ApiDashboard();
                api.updateData(
                    data,
                    faseItem.fase.IdAspiranteFase,
                    ENDPOINT_PHASE
                );
            } else {
                // editando registro
                await formService.create(ENDPOINT_PHASE, data);
            }

            alertaSinBoton('Guardado!', 'success', 'bottom-end', 2000);
            setLoading(false);
            if (state == 1) setIsOpenModalAccept(!isOpenModalAccept);
            if (state == 2) setIsOpenModalDecline(!isOpenModalDecline);
            setPuntaje(0);
        } catch (err) {
            if (state == 1) setIsOpenModalAccept(!isOpenModalAccept);
            if (state == 2) setIsOpenModalDecline(!isOpenModalDecline);
            setLoading(false);
            /* setLoadingFull(false); */
            setError(err);
        }
        fetchDataAnnouncement();
    };

    const handleChangeObservation = (value) => {
        setIsEmpty(false);
        setObservation(value);
    };

    const rechazarAdmitido = async () => {
        
        setLoadingFull(true)
        try {
            const formService = new FormService()
           const respuesta= await formService.update(
                ENDPOINT_APPLICANT, 
                { EstadoAdmision: "2", mensajeAdmision: mensajeRequisitos}, 
                idApplicant 
            )
            if(respuesta.respuesta=="exito"){
                alerta(respuesta.mensaje, "success")
                setIsCompletedAdmission(false);
            }else if (respuesta.respuesta=="error") {
                alerta(respuesta.mensaje, "error")
            }else{
                alerta("Fallo desconocido")
            }
            setMensajeRequisitos('')
            setIsOpenModalRechazar(false)
            setLoadingFull(false)
            
        } catch (err) {
            setError(err)
            setLoadingFull(false)
        }
    }

    const onRechazar = (item) => {
        setIsOpenModalRechazar(!isOpenModalRechazar)
    }
    
    const onAprobar = (item) => {
        setIsOpenModalAprobar(!isOpenModalAprobar)
    }

    const handleChangeMensaje =(value)=>{   
        setMensajeRequisitos(value)
    }
    
    const handleChangeConvenio =(value)=>{   
        setTipoConvenio(value)
    }
    
    const handleChangeTipoAlumno =(value)=>{   
        setTipoAlumno(value)
    }
    
    const handleChangeAreaPIC =(value)=>{   
        setTipoAreaPIC(value)
    }
    
    const handleChangeRegional =(value)=>{   
        setRegional(value)
    }

    return (
        <>
        <Admission
            faseItem={faseItem}
            loadingFull={loadingFull}
            admissionType={admissionType}
            nombreConvocatoria={nombreConvocatoria}
            announcement={announcement}
            approve={approve}
            loading={loading}
            isOpenModalDecline={isOpenModalDecline}
            onDecline={onDecline}
            observation={observation}
            handleChangeObservation={handleChangeObservation}
            isOpenModalAccept={isOpenModalAccept}
            onAccept={onAccept}
            handleSubmit={handleSubmit}
            score={score}
            onHandleChangeScore={onHandleChangeScore}
            onHandleSubmitScore={onHandleSubmitScore}
            isEmpty={isEmpty}
            handleState={handleState}
            error={error}
            puntaje={puntaje}
            setPuntaje={setPuntaje}
            setFaseItem={setFaseItem}
            isOpenModalRechazar={isOpenModalRechazar}
            isOpenModalAprobar={isOpenModalAprobar}
            onRechazar={onRechazar}
            onAprobar={onAprobar}
            mensajeRequisitos={mensajeRequisitos}   
            tipoConvenio={tipoConvenio}         
            tipoAlumno={tipoAlumno}
            tipoAreaPIC={tipoAreaPIC}
            regional={regional}
            handleChangeMensaje={handleChangeMensaje}
            handleChangeConvenio={handleChangeConvenio}
            handleChangeTipoAlumno={handleChangeTipoAlumno}
            handleChangeAreaPIC={handleChangeAreaPIC}
            handleChangeRegional={handleChangeRegional}
            rechazarAdmitido={rechazarAdmitido}
            isCompletedAdmission={isCompletedAdmission}
            editable={editable}
            oferta={oferta}
            fetchDataAnnouncement={fetchDataAnnouncement}
            setTipoVinculacion={setTipoVinculacion}
            tipoVinculacion={tipoVinculacion}
        />
        </>
    );
};

export { AdmissionContainer };
