import React from 'react'
import { Card } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { LocalStorage } from '../../../services'

export default function PeridosInscritos() {
    const location = useLocation()
    const IdCurso = location.state?.IdCurso
     
    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
    const urlProgramas = `educacionIES/periodosInscritos?IdUsuario=${IdUsuario}&IdCurso=${IdCurso}`;
    const urlProgramas2 = `educacionIES/todosPeriodosInscritos?IdUsuario=${IdUsuario}`;

    const navigate = useNavigate()

    const onNavigateDetails = (item) => {
      navigate(
          `lista-aspirante/${item.IdOferta}`, 
          { state: { offer: item } }
      ) 
  }
  
  return (
    <>
      <Card>
        <Card.Title>Periodos Inscritos</Card.Title>
      </Card>
      <DynamicTable
            titleColumn={['N°','Nombre','Fecha Inicio','Fecha Fin', 'Acción']}
            attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
            endPoint={IdCurso ? urlProgramas : urlProgramas2}
            onDelete={false}
            onUpdate={false}
            labelOptionClick={`Ver más`}
            handleOptionClick={onNavigateDetails}
            classNameOptionIcon={`bi bi-view-list`}
      />
    </>
  )
}
