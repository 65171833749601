import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Select } from '../../../components/select/Select';
import { alerta } from '../../../helpers/alertas';
import { BASE_URL } from '../../../services/api/config';
import { FormService } from '../../../services/api/formService';

export default function SolicitarAsignaturas({
}) {
    const api=new FormService()
    const[asignaturas,setAsignaturas]=useState([]);
    const [modalSolicitar,setModalSolicitar]=useState();
    const [malla,setMalla]=useState()
    const [IdMalla,setIdMalla]=useState();
    const [modalPago,setModalPago]=useState()
    const [comprobante,setComprobante]=useState(null)

    const {state:{IdOferta,IdEstudiante,IdUsuario,IdAspirante}} = useLocation()
    const urlAsignaturas=`educacionIES/asignaturasSolicitadas?IdEstudiante=${IdEstudiante}`
    const urlInstructivo=`${BASE_URL}/educacionIES/instructivoPagoAsignatura?IdMalla=${malla?.IdMalla}`

    useEffect(()=>{
        const fetchData=async()=>{
            consultarAsignaturas()
        }

        fetchData()

    },[])


    const consultarAsignaturas=async()=>{
       const asignaturas= await api.getAll(urlAsignaturas)
       setAsignaturas(asignaturas)
    }

    const solicitarAsignatura=async()=>{
        const data={
            "IdMalla":IdMalla,
            "IdEstudiante":IdEstudiante,
            "IdUsuario":IdUsuario,
            "IdOferta":IdOferta,
        }
        const respuesta= await api.create("educacionIES/solicitarAsginatura",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModalSolicitar()
            consultarAsignaturas()
        }else{
            alerta(respuesta.mensaje)
        }
    }

    const enviarComprobante=async()=>{
        const data={
            "ComprobantePago":comprobante,
            "IdMallaAspirante":malla.IdMallaAspirante
        }

        const respuesta=await api.createWithFormData("educacionIES/ajuntarPagoAsginatura",data)
        console.log(respuesta)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModalPago()
            consultarAsignaturas()
        }else{
            alerta("Fallo desconocido")
        }
    }

  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Solicitar Asignaturas</Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={()=>setModalSolicitar(true)}
                    >
                        Solictar Asignatura
                    </Button>
                </Col>
            </Row>
            
        </Card>
        <Card>
            <Table>
                <thead>
                    <tr>
                        <th>Asignatura</th>
                        <th>Créditos</th>
                        <th>Peso</th>
                        <th>Estado</th>
                    </tr>
                </thead>
                <tbody>
                {asignaturas.map((asignatura,key)=>
                    <tr key={key}>
                        <td>{asignatura.Descripcion}</td>
                        <td>{asignatura.Creditos}</td>
                        <td>{asignatura.Peso}</td>
                        <td>
                            {asignatura.Aprobada==0&&
                                <>Solicitud rechazada</>
                            }                            
                            {asignatura.Aprobada==1&&
                                <>Aceptada</>
                            }
                            {asignatura.Aprobada==2&&
                                <>
                                {asignatura.EstadoPago==null&&
                                    <>
                                    Pago por realizar {' '}
                                    <Button
                                        onClick={()=>{
                                            setModalPago(true)
                                            setMalla(asignatura)
                                        }}
                                    >Pagar</Button>
                                    </>
                                }
                                {asignatura.EstadoPago==0&&
                                    <>Pago en revisión</>
                                }
                                {asignatura.EstadoPago==1&&
                                    <>Pago aceptado</>
                                }
                                {asignatura.EstadoPago==2&&
                                    <>
                                        Pago rechazado{' '}
                                    <Button
                                        onClick={()=>{
                                            setModalPago(true)
                                            setMalla(asignatura)
                                        }}
                                    >Pagar</Button>
                                    
                                    </>
                                }
                                </>
                            }
                            {asignatura.Aprobada==3&&
                                <>En revisión</>
                            }
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </Card>

    {/* modal de solicitar una nueva asignatura */}
    <Modal
        show={modalSolicitar}
        onHide={()=>setModalSolicitar()}
      >

        <Modal.Header closeButton>
          <Modal.Title>
            Solicitar asignatura
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Select
            value={IdMalla}
            onChange={setIdMalla}
            label="Asignaturas"
            optionValue="IdMalla"
            optionName="Descripcion"
            urlOptionsService={`educacionIES/asignaturasPeriodos?IdOferta=${IdOferta}&IdAspirante=${IdAspirante}&IdUsuario=${IdUsuario}`}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setModalSolicitar()}
          >
            Cancelar
          </Button>
          <Button
            onClick={()=>solicitarAsignatura()}
          >
            Solicitar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal para pagar */}
      <Modal 
        show={modalPago}
        onHide={()=>setModalPago()}
      >
        <Modal.Header closeButton>
            <Modal.Title>Adjunta el comprobante de pago</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <a target="_blank" href={urlInstructivo}>
            <div>Descargar instrutivo de pago</div>
            </a>
            
            <label style={{marginTop:30}}>Adjuntar comprobante</label>
            <input
                type="file"
                // value={comprobante}
                onChange={(e) => setComprobante(e.target.files[0])}
            />
        </Modal.Body>

        <Modal.Footer>
            <Button
                variant='secondary'
                onClick={()=>setModalPago()}
            >
                Cancelar
            </Button>
            <Button
                onClick={enviarComprobante}
            >
                Enviar
            </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
