import React, { useState, useEffect } from 'react'
import { FormService } from '../../../services/api/formService'
import { useForm } from '../../../hooks/useForm'
import { AperturaForm } from './AperturaForm'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { DynamicTable } from '../../DynamicForm/dynamicTable/DynamicTable'
import { alerta } from '../../../helpers/alertas'
import { DynamicSearch } from '../../DynamicForm/DynamicSearch/DynamicSearch'
import { Input } from '../../input/input'
import { BASE_URL } from '../../../services/api/config'


function AperturaContainer()  {

    const [selectList, setSelectList] = useState([])
    const {
        onCreate,
        onDelete,
        onUpdate,
        allData, 
        data, 
        isOpenForm, 
        isOpenDelete,
        isCreate,
        setIsCreate,
        handleSubmit,
        handleChange,
        handleClickDelete,
        handleClickAdd,
        deleteTuple,
        loading,
        setData,
        setLoading,
        loadAllData,
    } = useForm(
        'ofertas/aperturas', 
        'IdOferta' 
    )
    const api=new FormService()
    const[registroActual,setRegistroActual]=useState();
    const urlOfertas="ofertas/aperturas";
    const[endPoint,setEndPoint]=useState(urlOfertas);
    const[modalActualizar,setModalActualizar]=useState();
    const [fechaInicio, setFechaInicio] = useState();
    const [fechaFin, setFechaFin] = useState();
    const [urlExportar, setUrlExportar] = useState(`${BASE_URL}/exportarAperturasOferta`)
   

    useEffect(() => {
        setIsCreate(false)
       
        const fecthData = async () => {
            await fecthDataSelect()
        }
        fecthData()
    }, []);

    const fecthDataSelect = async () => {
        try {
            const formService = new FormService()
           
            const ofertas = await formService.getAll('ofertas/disponibles')
            const convenios = await formService.getAll('convenio')
            const usuarios = await formService.getAll('docente/full')
            /* console.log(usuarios) */

            const convocatorias = await formService.getAll('convocatoria')
            setSelectList({
               ofertas,
                usuarios,
                convenios,
                convocatorias,
            })
        } catch (err) {
            console.log(err)
        }
    }

    const onSearch = () => {
        /* fecthDataSelect(); */

        setEndPoint(`${urlOfertas}?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}`)
        setUrlExportar(`${BASE_URL}/exportarAperturasOferta?FechaInicio=${fechaInicio}&FechaFin=${fechaFin}`)
    }

    const titlesTable = [
        'ID',
        'Nombre',
        // 'Tipo Mecanismo',
        'Acción'
    ]

    const attributesTable = [
        'Nombre',
        // 'Mecanismo',
    ]

    const eliminarApertura=async()=>{
        const data={
            "IdEstadoOferta":0
        }
        const respuesta=await api.update("ofertas",data,registroActual.IdOferta)
        if(respuesta.respuesta=="exito"){
            alerta("Apertura eliminada")
            actulizarResultado()
            handleClickDelete()
        }else{
            alerta("Fallo al eliminar apertura","error")
        }
    }

    const actulizarResultado=(e)=>{
        let numeroAletaorio=Math.random()
        setEndPoint(`${urlOfertas}?v=${numeroAletaorio}`)
    }
    const cerrarModales=()=>{
        setModalActualizar()
        // handleClickAdd(false)
    }
   
    return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>
                        Ofertas Aperturadas Actualmente
                    </Card.Title>
                </Col>
                <Col>
                    <div style={{textAlign:'right'}}>
                    <Button 
                    onClick={()=>onCreate()}
                    >
                        Aperturar Oferta
                    </Button>
                    </div>
                </Col>
            </Row>
        </Card>
        <Card>
        <Row>
                    <Col>
                        <Input
                            label={"Fecha Inicial"}
                            type={"date"}
                            id={"FechaInicio"}
                            name={"FechaInicio"}
                            value={fechaInicio}
                            onChange={(e) => setFechaInicio(e)}
                        />
                    </Col>
                    <Col>
                        <Input
                            label={"Fecha Final"}
                            type={"date"}
                            id={"FechaFin"}
                            name={"FechaFin"}
                            value={fechaFin}
                            onChange={(e) => setFechaFin(e)}
                        />
                    </Col>
                    <Col>
                        <div style={{textAlign: 'right', marginTop: '30px'}}>
                            <Button onClick={onSearch} >
                                Buscar
                            </Button>
                        </div>
                    </Col>
                </Row>
        </Card>

        <Card>
                {/* <DynamicSearch onSearch={onSearch} /> */}
                <DynamicTable
                    titleColumn={titlesTable}
                    endPoint={endPoint}
                    attributes={attributesTable}
                    // exportar={urlExportar}
                    elimimarExterno={(e)=>{
                        onDelete()
                        setRegistroActual(e)
                    }}
                    actualizarExterno={(e)=>{
                        setModalActualizar(true)
                        setRegistroActual(e)
                    }}
                />
                <Modal 
                    show={ isOpenForm }
                    onHide={()=>handleClickAdd()}
                >
                <Modal.Header closeButton>
                    <Modal.Title>Aperturar</Modal.Title>
                </Modal.Header>
                    <AperturaForm 
                        handleSubmit={ handleSubmit }
                        handleChange={ handleChange }
                        handleClickAdd = {handleClickAdd}
                        loading={ loading }
                        setData={setData}
                        formData={ data }
                        loadDataTable = {()=>actulizarResultado()}
                        close={()=>cerrarModales() }
                        isCreate={ true }
                        selectList={ selectList }
                    />
                </Modal>
                <Modal 
                    show={ isOpenDelete } 
                    onHide={ handleClickDelete}
                >
                    <Modal.Body>
                        <h3>
                            ¿Estas seguro que deseas eliminar este registro?
                        </h3>
                    </Modal.Body>
                    <Modal.Footer>
                    <Button 
                            onClick={ handleClickDelete }
                            variant="secondary"    
                        >
                                cancelar
                        </Button>
                        <Button 
                            onClick={()=>eliminarApertura() }
                            loading={ loading }
                            variant='danger'>
                                Eliminar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Card>   
            {/*Modal para actulizar */}
            <Modal
                show={modalActualizar}
                onHide={()=>setModalActualizar()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Actualizar Apertura
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <AperturaForm 
                        handleSubmit={ handleSubmit }
                        handleChange={ handleChange }
                        handleClickAdd = {handleClickAdd}
                        loading={ loading }
                        setData={setData}
                        formData={ data }
                        loadDataTable = {()=>actulizarResultado()}
                        close={cerrarModales}
                        isCreate={ false }
                        selectList={ selectList }
                        data={registroActual}
                    />

                </Modal.Body>
            </Modal>
        </>
    )
}

export { AperturaContainer }