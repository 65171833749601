
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import { Checkbox } from '../../../../components/componentsForm/checkbox/checkbox';
import { Select } from '../../../../components/select/Select';
import { alerta } from '../../../../helpers/alertas';
import { FormService } from '../../../../services/api/formService';

export default function Requisitos() {
    const location= useLocation();
    const api= new  FormService();
    const malla =location.state.malla
    const planEstudio=location.state.planEstudio
    const [requisitos,setRequisitos]=useState([]);
    const [modal,setModal]=useState();
    const [aprobar,setAprobar]=useState(null);
    const [cursar,setCursar]=useState(null);
    const [simulatanea,setSimulatanea]=useState(null);
    const [idMalla,setIdMalla]=useState(null);
    const [modalEliminar,setModalEliminar]=useState();
    const [requisito,setRequisito]=useState();

    useEffect(()=>{
        (async()=>{
            consultarRequsitos()
        })()
    },[])

    const consultarRequsitos=async()=>{
        const requisitos=await api.getAll(`malla/requisitos?IdMalla=${malla.IdMalla}`)
        setRequisitos(requisitos)
    }
    const eliminar=async()=>{
        const data={
            "IdMallaRequisito":requisito.IdMallaRequisito
        }
        const respuesta=await api.create("malla/eliminarRequisito",data)
        if(respuesta.respuesta=="exito"){
            setModalEliminar()
            consultarRequsitos()
            alerta(respuesta.mensaje)
        }else{
            alerta("Fallo desconocido")
        }
    }

    const agergarRequsito=async()=>{

        if(idMalla==null||cursar==null){
            alerta("Completa los campos requeridos")
        }
        const data={
            "IdMalla2":idMalla,
            "IdMalla":malla.IdMalla,
            "Cursar":cursar,
            "Aprobar":aprobar,
            "Simultanea":simulatanea
        }
        
        const respuesta=await api.create("malla/agregarRequisito",data);
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModal()
            consultarRequsitos();
        }else if(respuesta.respuesta=="error"){
            alerta(respuesta.mensaje)
        }else{
            alerta("Fallo desconocido")
        }
    }
  return (
        <>
        <Card>
            <Row>
                <Col>
                <Card.Title>Prerrequisitos IES</Card.Title>
                <p>{malla?.Asignatura}</p>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={()=>setModal(true)}
                    >Agregar Requisito</Button>
                </Col>
            </Row>
        </Card>
        <Table>
            <thead>
                <tr>
                    <th>Asignatura</th>
                    <th>Requisito Cursar</th>
                    <th>Requisito Cursar y Aprobar</th>
                    <th>Simultánea	</th>
                    <th>Acciones</th>
                </tr>
            </thead>
                <tbody>
                    {requisitos.map((requisito,key)=>
                        <tr key={key}>
                            <td>{requisito.Asignatura}</td>
                            <td>
                                {requisito.Cursar==1?
                                    <>Si</>
                                    :
                                    <>No</>
                                }
                            </td>
                            <td>
                                {requisito.Aprobar==1?
                                    <>Si</>
                                    :
                                    <>No</>
                                }
                            </td>
                            <td>
                                {requisito.Simultanea==1?
                                    <>Si</>
                                    :
                                    <>No</>
                                }
                            </td>
                            <td>
                                <Button
                                    variant='danger'
                                    onClick={()=>{
                                        setModalEliminar(true)
                                        setRequisito(requisito)
                                    }}
                                >
                                    Eliminar
                                </Button>
                            </td>
                        </tr>
                    )}
                </tbody>
        </Table>
        <Modal
            show={modal}
            onHide={()=>setModal()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Agregar Requisito</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Select
                    label="Asignatura Requerida"
                    optionName={"Asignatura"}
                    optionValue={"IdMalla"}
                    value={idMalla}
                    onChange={(e)=>setIdMalla(e)}
                    urlOptionsService={`malla?IdPlandeEstudio=${planEstudio?.IdPlandeEstudio}`}
                />
                <Checkbox
                    value={cursar}
                    onChange={(e)=>setCursar(e)}
                >
                    Requisito Cursar
                </Checkbox>
                <Checkbox 
                    value={aprobar}
                    onChange={(e)=>setAprobar(e)}
                >
                    Requisito Cursar y Aprobar
                </Checkbox>
                <Checkbox
                    value={simulatanea}
                    onChange={(e)=>setSimulatanea(e)}
                >
                    Simultánea
                </Checkbox>

            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModal()}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>agergarRequsito()}
                >
                    Agregar
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal
            show={modalEliminar}
            onHide={()=>setModalEliminar()}
        >
            <Modal.Body>
                <b>¿Desea eliminar este requisito?</b>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant='secondary'
                    onClick={()=>setModalEliminar()}
                >
                    Cancelar
                </Button>
                <Button
                    variant='danger'
                    onClick={()=>eliminar()}
                >
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>
        </>
    )
}
