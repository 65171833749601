import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { FaUsers,FaEdit  } from 'react-icons/fa';   
import { useNavigate } from 'react-router-dom';

export default function Configuraciones() {
    const navigate = useNavigate();
  return (
    <>
        <Card>
            <Card.Title>Configuraciones de Autoevaluación</Card.Title>
        </Card>
        <Row
            style={{
                padding: '1rem',
                textAlign: 'center',
            }}
        >
            <Col>
                <Card
                    onClick={() => {navigate('porcente-indicadores')}}
                    style={{
                        cursor: 'pointer',
                    }}
                >
                    <FaUsers size={32}  style={{ margin:'auto'}}/>
                    <Card.Title>Porcenaje Indicadores Docente</Card.Title>
                </Card>
            </Col>
            <Col>
                <Card
                    // onClick={() => {alert()}}
                    style={{
                        cursor: 'pointer',  
                    }}
                >
                <FaEdit size={32} style={{ margin:'auto'}} />
                    <a target='_blank' href="https://instrumentos-autoevaluacion-ies.netlify.app/">
                    <Card.Title>Instrumentos</Card.Title>
                    </a>
                </Card>
            </Col>
        </Row>
    </>
  )
}
