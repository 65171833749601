import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { AiOutlineFile, AiOutlineFolder, AiOutlineArrowLeft } from 'react-icons/ai';
import { FaFilePdf, FaFileExcel, FaThList, FaThLarge } from 'react-icons/fa';
import { BASE_SERVIDOR } from '../../services/api/config';
import './FileExplorer.css'; // Importar el archivo de estilos

const FileExplorer = () => {
  const [fileStructure, setFileStructure] = useState([]);
  const [currentPath, setCurrentPath] = useState('');
  const [displayMode, setDisplayMode] = useState('list');
  const [loaded, setLoaded] = useState(false); // Estado para controlar la animación de carga

  useEffect(() => {
    fetchFileStructure('');
  }, []);

  const fetchFileStructure = (path) => {
    axios.get(`${BASE_SERVIDOR}/api/filemanager?path=${path}`)
      .then(response => {
        setFileStructure(response.data.files);
        setLoaded(true); // Cambiar el estado a true para activar la animación de carga
      })
      .catch(error => console.error(error));
  };

  const handleFolderClick = (folderName) => {
    setCurrentPath(currentPath + '/' + folderName);
    fetchFileStructure(currentPath + '/' + folderName);
  };

  const handleGoBack = () => {
    const lastIndex = currentPath.lastIndexOf('/');
    const parentPath = currentPath.substring(0, lastIndex);
    setCurrentPath(parentPath);
    fetchFileStructure(parentPath);
  };

  const handleDownloadFile = (filePath) => {
    window.open(`${BASE_SERVIDOR}/api/filemanager/download?filePath=${currentPath}/${filePath}`, '_blank');
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split('.').pop().toLowerCase();
    if (extension === 'pdf') {
      return <FaFilePdf className="file-icon" />;
    } else if (extension === 'xls' || extension === 'xlsx') {
      return <FaFileExcel className="file-icon" />;
    } else {
      return <AiOutlineFile className="file-icon" />;
    }
  };

  return (
    <div className={`file-explorer ${loaded ? 'loaded' : ''}`}>
      <button onClick={handleGoBack}><AiOutlineArrowLeft /> Back</button>
      <div className="view-buttons">
        <button onClick={() => setDisplayMode('list')}><FaThList /></button>
        <button onClick={() => setDisplayMode('grid')}><FaThLarge /></button>
      </div>

      {displayMode === 'list' ? (
        <ul>
          {fileStructure.map(item => (
            <li key={item.name} onDoubleClick={item.isFile ? () => handleDownloadFile(item.name) : () => handleFolderClick(item.name)}>
              {item.isFile ? getFileIcon(item.name) : <AiOutlineFolder className="folder-icon" />}
              <p>{item.name}</p>
            </li>
          ))}
        </ul>
      ) : (
        <div className="grid-view">
          {fileStructure.map(item => (
            <div key={item.name} onClick={item.isFile ? () => handleDownloadFile(item.name) : () => handleFolderClick(item.name)}>
              {item.isFile ? getFileIcon(item.name) : <AiOutlineFolder className="folder-icon" />}
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FileExplorer;
