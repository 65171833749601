import { Col, Row ,Card as Cardb, Accordion, ListGroup, Tabs, Tab} from "react-bootstrap"
import { Card } from "../../../../components/card/Card"
import { Select } from "../../../../components/select/Select"
import { Title } from "../../../../shared/components/title/Title"

import { FormService } from "../../../../services/api/formService"
import { useState } from "react"
import { useEffect } from "react"
import { BASE_SERVIDOR, BASE_URL } from "../../../../services/api/config"

import { BsFillFileEarmarkExcelFill, BsFillFileEarmarkPdfFill, BsFillFileXFill } from 'react-icons/bs';

import a2019_1 from "../../../../Luisa/2019/a2019_1.xls"
import a2019_2 from "../../../../Luisa/2019/a2019_2.xls"
import a2019_3 from "../../../../Luisa/2019/a2019_3.xls"
import a2019_4 from "../../../../Luisa/2019/a2019_4.xls"
import a2019_5 from "../../../../Luisa/2019/a2019_5.xlsx"
import a2019_6 from "../../../../Luisa/2019/a2019_6.xls"
import a2019_7 from "../../../../Luisa/2019/a2019_7.xlsx"
import a2019_8 from "../../../../Luisa/2019/a2019_8.xlsx"
import a2019_9 from "../../../../Luisa/2019/a2019_9.xls"
import a2019_10 from "../../../../Luisa/2019/a2019_10.xls"
import a2019_11 from "../../../../Luisa/2019/a2019_11.pdf"
import a2019_12 from "../../../../Luisa/2019/a2019_12.pdf"
import a2019_13 from "../../../../Luisa/2019/a2019_13.pdf"
import a2019_14 from "../../../../Luisa/2019/a2019_14.pdf"
// 2020
import a2020_1 from "../../../../Luisa/2020/a2020_1.xls"
import a2020_2 from "../../../../Luisa/2020/a2020_2.xls"
import a2020_3 from "../../../../Luisa/2020/a2020_3.xls"
import a2020_4 from "../../../../Luisa/2020/a2020_4.xls"
import a2020_5 from "../../../../Luisa/2020/a2020_5.xls"
import a2020_6 from "../../../../Luisa/2020/a2020_6.xls"
import a2020_7 from "../../../../Luisa/2020/a2020_7.xls"
import a2020_8 from "../../../../Luisa/2020/a2020_8.xls"
import a2020_9 from "../../../../Luisa/2020/a2020_9.pdf"
import a2020_10 from "../../../../Luisa/2020/a2020_10.pdf"
import a2020_11 from "../../../../Luisa/2020/a2020_11.pdf"
import a2020_12 from "../../../../Luisa/2020/a2020_12.pdf"
// 2021
import a2021_1 from "../../../../Luisa/2021/a2021_1.xls"
import a2021_2 from "../../../../Luisa/2021/a2021_2.xls"
import a2021_3 from "../../../../Luisa/2021/a2021_3.xls"
import a2021_4 from "../../../../Luisa/2021/a2021_4.xls"
import a2021_5 from "../../../../Luisa/2021/a2021_5.xls"
import a2021_6 from "../../../../Luisa/2021/a2021_6.xls"
import a2021_7 from "../../../../Luisa/2021/a2021_7.pdf"
import a2021_8 from "../../../../Luisa/2021/a2021_8.xls"
import a2021_9 from "../../../../Luisa/2021/a2021_9.pdf"
import a2021_10 from "../../../../Luisa/2021/a2021_10.xls"
import a2021_11 from "../../../../Luisa/2021/a2021_11.pdf"
import a2021_12 from "../../../../Luisa/2021/a2021_12.xls"
import a2021_13 from "../../../../Luisa/2021/a2021_13.pdf"
import FileExplorer from "../../../../components/FileExplorer/FileExplorer"

const ConsultaHistorico=()=>{
    const api= new FormService();
    const[IdPeriodo,setIdPeriodo]=useState();

    const actividadesEvaluadas=`${BASE_URL}/luisa/actividadesEvaluadas?IdPeriodo=${IdPeriodo}`
    const noContestadas=`${BASE_URL}/luisa/evaluacionesNoContestadas?IdPeriodo=${IdPeriodo}`
    const apreciacionesDocentes=`${BASE_URL}/luisa/apreciacionesDocentes?IdPeriodo=${IdPeriodo}`
    const cualitativasAutoevaluacionDocente=`${BASE_URL}/luisa/cualitativasAutoevaluacionDocente?IdPeriodo=${IdPeriodo}`
    const ApreciacionesActividades=`${BASE_URL}/luisa/ApreciacionesActividades?IdPeriodo=${IdPeriodo}`
    const docentesEvaluados=`${BASE_URL}/luisa/docentesEvaluados?IdPeriodo=${IdPeriodo}`
    const cualitativasHeteroevaluacion=`${BASE_URL}/luisa/cualitativasHeteroevaluacion?IdPeriodo=${IdPeriodo}`
    const rutaRepositorio = `repositorio/archivos`
    const [archivo,setArchivo] = useState([]);
    
    const carpeta =async()=>{
        const respuesta =await api.getAll(rutaRepositorio)
        setArchivo(respuesta)
        // respuesta.map((file)=>{console.log(file.Nombre)})
    }

     useEffect(()=>{

        carpeta()

    },[])

    const peridoActual=(e)=>{
        setIdPeriodo(e)
    }

    return(
        <FileExplorer />
    )
}
export {ConsultaHistorico}