import { Card } from "react-bootstrap";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { DynamicFormContainer } from "../../../components/DynamicForm/DynamicFormContainer";
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Row } from "react-bootstrap";
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import {setItemSelected,setForm } from "../../../components/DynamicForm/FormSlice";
import { useDispatch, useSelector } from "react-redux";
import { alerta } from "../../../helpers/alertas";

export function MovilidadDocentes() {
    const urlMovilidad = `movilidad_docentes`
    const [modal, setModal] = useState()
    const [modalUpdate, setModalUpdate] = useState()
    const [modalEliminar, setModalEliminar] = useState()
    const api = new FormService()
    const [endPoint, setEndPoint] = useState(urlMovilidad)
    const dispatch = useDispatch()
    const itemSelected = useSelector((state) => state.form.itemSelected)

    const eliminar=async()=>{
        const data={
            "Estado":0
        }
        const respuesta= await api.update(endPoint, data, itemSelected.IdMovilidadDocente)
        if(respuesta.respuesta=="exito"){
            actualizar()
            setModalEliminar()
            alerta(respuesta.mensaje)
        }else{
            alerta("Fallo desconocido")
        }
    }
    
    const actualizar=()=>{
        setTimeout(() => {
            /* setModal(false) */
            setModalUpdate()
            setEndPoint(`${urlMovilidad}?i=${Math.random()}`)
        }, 2000);
    }

    return (
        <>
            <Card>
                <h2>
                    Movilidad de Docentes
                    <div style={{textAlign: 'right'}}>
                        <Button onClick={() => setModal(true)}>
                            Crear
                        </Button>
                    </div>
                </h2>
            </Card>
            {/* <DynamicFormContainer idForm={'178'} showTable={false}/> */}
            <Card>
                <DynamicTable
                    titleColumn={["No", "Docente", "Institución de Procedencia", "Movilidad Hacia el:", "Días de Movilidad", "Valor Financiación Nacional", "Acción"]}
                    attributes={[
                        "Docente", "InstitucionExtranjera", "MovilidadHacia", "NumeroDiasMovilidad", 
                        "ValorFinanciacionNacional"
                    ]}
                    endPoint={endPoint}
                    onDelete={true}
                    onUpdate={true}
                    actualizarExterno={(e)=>{
                        setModalUpdate(true)
                        dispatch(setItemSelected(e))
                        dispatch(setForm({llave_primaria:"IdMovilidadDocente",form_data:"0"}))
                    }}
                    elimimarExterno={(e)=>{
                        setModalEliminar(true)
                        dispatch(setItemSelected(e))
                    }}
                />
            </Card>
            <Modal
                show={modal}
                onHide={()=>setModal(false)}
            >
                <DynamicForm
                    title={"Crear Movilidad Docente"}
                    justDynamicForm={{flag: true, idForm:'178'}}
                    endPointSubmit="movilidad_docentes"
                    isCreate={true}
                    accionMysql={()=>{
                        setModal(false)
                        setEndPoint(`${urlMovilidad}?i=${Math.random()}`)
                    }}
                    onClickCancelBtn={() => setModal(false)}
                />                
            </Modal>
            
            <Modal
                show={modalUpdate}
                onHide={()=>setModalUpdate(false)}
            >
                <DynamicForm
                    title={"Actualizar Movilidad Docente"}
                    justDynamicForm={{flag: true, idForm:'178'}}
                    endPointSubmit={"movilidad_docentes"}
                    isCreate={false}
                    accionMysql={() => {
                        setModalUpdate(false)
                        setEndPoint(`${urlMovilidad}?i=${Math.random()}`)
                    }}
                    onClickCancelBtn={() => setModalUpdate(false)}
                />                
            </Modal>
            <Modal
                show={modalEliminar}
                onHide={()=>setModalEliminar()}
            >
                <Modal.Body>
                    <b>¿Desea eliminar esta movilidad?</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={()=>setModalEliminar()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="danger"
                        onClick={()=>eliminar()}
                    >
                        Eliminar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );

}