import { useState } from "react"

import { Arrow } from "../arrow/Arrow"
import { Message } from "../Message/Message"
import { Input } from "../input/input"
import { ListContainer } from "../ListContainer/ListContainer"
import { Button } from "../button/button"
import { Badge } from "../Badge/Badge"
import { Card } from "../card/Card"
import { useSearch } from "../../hooks/useSearch"
import { OfferHeader } from "../OfferHeader/OfferHeader"
import { Modal } from "../modal/Modal"
import { FormService } from "../../services/api/formService"

export const ListByCourse = ({
    offer,
    children,
    convert,
    empresa,
    Admision
}) => {

    return (
        <>
            <header>
                <Arrow />
            </header>
            <main className='applicant-list__main'>
                <OfferHeader 
                    empresa={empresa}
                    img={ offer?.Repositorio }
                    offerName={ offer?.Nombre }
                    offerDescription={ offer?.Observaciones }
                    idOffer={ offer?.IdOferta }
                    oferta={offer}
                    type={ offer?.IdEstadoOferta }
                    mechanism={ offer.Mecanismo }
                    business={ offer.empresa }
                    convert={ convert }
                    IdOferta={offer.IdOferta}
                    horas={offer.horas}
                    Admision={Admision}
                />
                <Card>
                   { children }
                </Card>
            </main>
        </>
    )
}