import React, { useEffect, useState } from 'react'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { useLocation } from 'react-router';
import TablaCreditos from '../../../components/Graficas/TablaCreditos';
import { FormService } from '../../../services/api/formService';
import { Select } from '../../../components/select/Select';
import EmbudoConversion from '../../../components/Graficas/EmbudoConversion';
import GraficoBarras from '../../../components/Graficas/GraficoBarras';
import TablaMetrica from '../../../components/Graficas/TablaMetricas';

export default function Programa() {
    const {programa} = useLocation().state;
    const api= new FormService();
    const [idOferta, setIdOferta]= useState(programa.ofertas[0].IdOferta);
    const [inasistenciasDocentes, setInasistenciasDocentes]= useState([]);
    const [inasistenciasEstudiantes, setInasistenciasEstudiantes]= useState([]);
    const [embudoConversion, setEmbudoConversion]= useState([]);
    const [promedioEstudiantes, setPromedioEstudiantes]= useState([]);
    const [metricasGenerales, setMetricasGenerales]= useState([]);

    const [dataTable, setDataTable]= useState(null);

  const getDataTable= async () => {
    const data= await api.getAll(`graficas/creditosEstudiantes?IdPlanEstudio=${programa.IdPlandeEstudio}`)
    setDataTable(data);
  }
  const getMetricasGenerales= async () => {
    const data= await api.getAll(`analitica/metricasGenerales?IdPlanEstudio=${programa.IdPlandeEstudio}`)
    setMetricasGenerales(data);
}


  const getData= async () => {
    const data= await api.getAll(`analitica/dataOferta?IdOferta=${idOferta}`)
    console.log(data);
    setInasistenciasDocentes(data.inasistenciasDocentes);
    setInasistenciasEstudiantes(data.inasistenciasEstudiantes);
    setEmbudoConversion(data.embudoConversion);
    setPromedioEstudiantes(data.promedioEstudiantes);
  }

  const data=[]
  useEffect(() => {
    getDataTable();
    getData();
    getMetricasGenerales();
  }, [idOferta])

  return (
    <>
        <Card>
            <Card.Title> {programa.NombrePlanEstudio} </Card.Title>
            <p>N°: {programa.NumeroPlan}</p>
        </Card>
        
        <TablaCreditos data={dataTable} />
        <Card>
            <Card.Title>Métricas Generales</Card.Title>
            <div
                style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gap: '1rem',
                }}
            >
                {metricasGenerales.map((metrica, index) =>
                    <TablaMetrica  metrica={metrica} />
                )}            
            </div>
        </Card>
        <Card>
            <Row>
                <Col>
                    <Card.Title>Gráficas Por período</Card.Title>
                </Col>
                <Col
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Form.Control 
                        as="select"
                        name='IdOferta'
                        onChange={(e) => setIdOferta(e.target.value)}
                        value={idOferta}
                    >
                        {programa.ofertas.map((oferta, index) => 
                            <option key={index} value={oferta.IdOferta}>{oferta.codigo_grupo}</option>
                        )}
                    </Form.Control>
                </Col>
            </Row>
            <Row>
                <Col>
                    <GraficoBarras
                        data={inasistenciasDocentes}
                        titulo="Inasistencias de Docentes"
                        label='Docentes'
                    />
                </Col>
                <Col>
                    <GraficoBarras 
                    data={inasistenciasEstudiantes}
                    titulo="Inasistencias de Estudiantes"
                    label='Estudiantes'
                    />
                </Col>
            </Row>
            <Row>
            <Col>
                <GraficoBarras 
                    data={promedioEstudiantes}
                    titulo="Promedio Estudiantes"
                    label='Estudiantes'
                />
            </Col>
                <Col>
                    <EmbudoConversion data={embudoConversion}/>
                </Col>
            </Row>
        </Card>
    </>
  )
}
