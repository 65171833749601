import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { alertaSinBoton} from '../../../../helpers/alertas';
import ApiDashboard from '../../../../services/api/dashboard';
import { FormService } from '../../../../services/api/formService';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import TablePaginate from '../../../../components/TablePaginate/TablePaginate';
import { useDispatch,useSelector} from 'react-redux';
import { setSearchFields } from '../../../../shared/store/table/tableSlice';

const Docentes=()=>{
    const urlDocentes = 'instrumento/docentes'; /// urfor service
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[endPoint,setEndPoint]=useState(urlDocentes);
    const api = new ApiDashboard();
    const dispatch = useDispatch();
    const searchFieldsRedux = useSelector(state => state.table.searchFields);
    const [searchFields2, setSearchFields2] = useState({...searchFieldsRedux});
   

    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            setLoading(false);
        };
        fecthData();
    }, []);

    const instrumentos=(docente)=>{
        navigate(`${docente.IdUsuario}/evaluaciones`,{state:{docente}})
        
    }

    const enviarNotificaciones = () => {
        setLoading(true)
        const api = new FormService()
        api.getAll(`gestionInstrumentos/notificarDocentes`).then(resp=>{
          alertaSinBoton("Se han enviado con exito!",'success','bottom-end',1500)
          setLoading(false)
        }).catch(err=>{
          
          alertaSinBoton("ERROR!",'warning','bottom-end',1500)
          setLoading(false)
        })
    }
    
    const handleSearchChange = event => {
        setSearchFields2({ ...searchFields2, [event.target.name]: event.target.value });
    };
    
    const handleSearch = () => {
        console.log(searchFields2);
        dispatch(setSearchFields({ ...searchFields2 }));
    };

    return (
        <>
             <Card>
                <Row>
                    <Col>
                    <Card.Title>
                        Docentes
                    </Card.Title>
                    </Col>
                    <Col style={{textAlign:"right"}}>
                    <Button
                        onClick={enviarNotificaciones}
                    >
                        Notificar Evaluaciones
                    </Button>
                    </Col>
                </Row>
            </Card>
            <div 
                style={{
                    padding:10
                }}
            >
                <Row>
                    <Col>
                        <Form.Group>
                            <Form.Label>Buscar por Número de Documento</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={handleSearchChange}
                                name="NumeroDocumento"
                                value={searchFields2.NumeroDocumento}
                            />
                        </Form.Group>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'flex-end'}} >
                        <Button
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>
                    </Col>
                </Row>
            </div>
            <TablePaginate 
                apiUrl={endPoint}
                tableHeaders={[
                    {title:"Nombre",attribute:"Nombre"},
                    {title:"Cédula",attribute:"NumeroDocumento"}
                ]}
                rowActions={[
                    ({rowData})=>(
                        <Button
                            onClick={()=>instrumentos(rowData)}
                        >
                            Instrumentos
                        </Button>
                    )
                ]}
            />

                {/* <DynamicTable
                    endPoint={endPoint}
                    titleColumn={[ 'Id', 'Cédula', 'Nombre', 'Acciones', ]}
                    attributes={['NumeroDocumento','Nombre']} 
                    labelOptionClick="Instrumentos"
                    handleOptionClick={instrumentos}
                    onDelete={false}
                    onUpdate={false}
                    busqueda={busqueda}            
                ></DynamicTable> */}

            {/* <Modal
                show={isOpenModalAsignarEvaluacion}
                onHide={()=>setIsOpenModalAsignarEvaluacion(false)}
            >
                <DynamicForm
                endPointSubmit={'gestionInstrumentos/asignarEvaluacionDocente'}
                isCreate={true}
                //   extraData={{IdOferta:programa.IdOferta}}
                ventanaFormName={'Gestión instrumentos'}
                justDynamicForm={{ flag:true, idForm:ID_FORM.EVALUACION_PARA_DOCENTES }}
                onClickCancelBtn={setIsOpenModalAsignarEvaluacion}
                accionMysql={()=>cerrarModal()}
                ></DynamicForm>
            </Modal> */}
        </>
    );
}


export{Docentes};