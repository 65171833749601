import React from 'react'

import { Button } from '../../button/button'
import { Input } from '../../input/input'
import { Modal } from '../../modal/Modal'
import { Message } from '../../Message/Message'
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../inputFile/inputFile'
import { ViewPDF } from './ViewPDF'

import './Documents.css'

const Documents = ({
    documentos,
    loading,
    loadingFull,
    applicant,
    error,
    isOpenModalDecline,
    onDecline,
    isOpenModalAccept,
    onAccept,
    isOpenModalUpload,
    onUpload,
    onEnroll,
    handleSubmitUpload,
    document,
    observation,
    handleChangeObservation,
    isEmpty,
    handleState,
    onRechazar,
    isOpenModalRechazar,
    rechazarAspirante,
    handleChangeMensaje,
    mensajeRequisitos,
    isCompletedDocuments,
    editable,
    oferta
}) => {


    function filtrar(obj) {
        if (obj.RequisitoMatricula==='0') {
          return true;
        } else {
          return false;
        }
    }
    return (
        <>
            {
                loadingFull ? <LoadingSpinner /> : (
                    <>
                        <div className='documents__container'>
                            {documentos.map((item, key) => (
                                <>
                                <ViewPDF 
                                    item={ item }
                                    key={ key }
                                    onAccept={ onAccept }
                                    onDecline={ onDecline }
                                    onUpload={ onUpload }
                                    editable={editable}
                                    />
                                </>
                            ))
                            }
                            
                            {
                                error && (
                                    <Message type='error'>
                                        { error.message }
                                    </Message>
                                )
                            }
                        </div>
                        {oferta.modo_full==1&&
                        <>
                            {(isCompletedDocuments && editable) &&
                            <section className='documents__button-enroll'>
                                <Button 
                                    type='send'
                                    loading={ loading }
                                    onClick={ onRechazar }
                                >
                                    Rechazar aspirante
                                </Button>
                                <Button 
                                    type='send'
                                    loading={ loading }
                                    onClick={ onEnroll }
                                >
                                    Inscribir aspirante
                                </Button>
                            </section>
                            }    
                        </>
                        }
                                                
                    </>
                )
            }
            <Modal isOpen={ isOpenModalDecline } close={ onDecline }>
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este documento?
                    </h5>
                    <Input 
                        label='Motivo'
                        placeholder='Explica el motivo de rechazo'
                        name='Observacion'
                        value={ observation }
                        onChange={ handleChangeObservation }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(2) }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onDecline }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalAccept } close={ onAccept }>
                <div className='documents_modal-accept'>
                    <h5>
                        ¿Estás seguro de aceptar este documento?
                    </h5>
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ () => handleState(1) }
                            loading={ loading }
                        >
                            Aceptar
                        </Button>
                        <Button 
                            type='danger' 
                            onClick={ onAccept }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalUpload } close={ onUpload }>
                <form onSubmit={ handleSubmitUpload }>
                    <InputFile 
                        label={ document.Descripcion }
                        name='Archivo'
                        accept='.pdf'
                    />
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        Subir
                    </Button>
                </form>
            </Modal>
            <Modal 
                isOpen={isOpenModalRechazar}
                close={onRechazar}
            >
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este aspirante?
                    </h5>
                    <Input 
                        label='Describe el motivo'
                        placeholder='Describe el motivo de rechazo'
                        name='Observacion'
                        value={ mensajeRequisitos }
                        onChange={ handleChangeMensaje }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section className='d-flex'>
                        <Button 
                            type='send'
                            onClick={ rechazarAspirante }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            type='danger'
                            onClick={ onRechazar }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
        </>
    )
}

export { Documents }