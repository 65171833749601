import { useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../../../components/DynamicForm/DynamicForm'
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable'
import { Input } from '../../../../components/input/input'
import { alerta } from '../../../../helpers/alertas'
import { FormService } from '../../../../services/api/formService'
import { setItemSelected,setForm } from '../../../../components/DynamicForm/FormSlice'
import { useDispatch } from 'react-redux'

function Unidades() {
    const api=new FormService();
    const location = useLocation()
    const dispatch=useDispatch()

    const[openModal, isOpenModal]=useState(false)
    const[reload,isReload]=useState(false)
    const urlAsignaturas = `unidadesAsignaturas?IdMalla=${location.state.malla.IdMalla}`
    const[endPoint,setEndpoint]=useState(urlAsignaturas)
    const[modalEliminar,setModalEliminar]=useState();
    const[modalEditar,setModalEditar]=useState();
    const[seleccionado,setSeleccionado]=useState();
    const[Nombre,setNombre]=useState();
    const[Porcentaje,setPocentaje]=useState();
    const actualizar=()=>{
        // setEndpoint(false)
        setTimeout(() => {
            isOpenModal(false)
            isReload(true)
            setModalEditar()
            setEndpoint(`${urlAsignaturas}?${Math.random()}`)
        }, 2000);
    }

    const eliminarRegistro=async()=>{
        console.log(seleccionado)
        var data={
            "Estado":"0"
        }
        let respuesta=await api.delete("unidadesAsignaturas",data,seleccionado.IdUnidadAsignatura)
        if(respuesta.respuesta=="exito"){
            alerta("Registro eliminado")
            setModalEliminar();
            setEndpoint(`${urlAsignaturas}?a=${Math.random()}`)
        }else{
            alerta("Fallo al eliminar registro")
        }
    }

    const onSeleccionar=(e)=>{
        console.log(e)
        setSeleccionado(e)
        setNombre(e.Nombre);
        setPocentaje(e.Porcentaje)
    }

    return (
        <>
            <Card>
                <Row>
                    <Col>
                    <Card.Title>Unidades de Asignatura</Card.Title>
                      <p>{location.state.malla.Asignatura}</p>
                    </Col>
                    <Col>
                        <div style={{textAlign:'right'}}>
                            <Button onClick={()=>{
                                isOpenModal(true)
                                dispatch(setItemSelected([]))
                            }}
                            >
                                Crear
                            </Button>
                        </div>
                    </Col>
                </Row>

            </Card>
            <Card>
                <DynamicTable
                    titleColumn={['N°', 'Unidad', 'Porcentaje','Intensidad','Acción']}
                    attributes={['Nombre','Porcentaje','horas']}
                    endPoint={endPoint}
                    reaload={reload}
                    onDelete={true}
                    onUpdate={true}
                    classNameOptionIcon={`bi bi-view-list`}
                    elimimarExterno={(e)=>{
                        setModalEliminar(true)
                        onSeleccionar(e)
                    }}
                    actualizarExterno={(e)=>{
                        setModalEditar(true)
                        dispatch(setItemSelected(e))
                        dispatch(setForm({llave_primaria:"IdUnidadAsignatura",form_data:"0"}))
                    }}
                ></DynamicTable>
            </Card> 
            <Modal
                show={openModal}
                onHide={()=>isOpenModal()}
            >
               <DynamicForm
                  title={`Crear Unidad`}
                  onClickCancelBtn={()=>isOpenModal(false)} 
                  accionMysql={actualizar}
                  isCreate={true} 
                  justDynamicForm={{flag:true,idForm:"111"}}
                  endPointSubmit="unidadesAsignaturas"
                  extraData={{IdMalla:location.state.malla.IdMalla}}
                />
            </Modal>
            <Modal
                show={modalEliminar}
                onHide={()=>setModalEliminar()}
            >
                <Modal.Body>
                    <b>Deseas eliminar este registro?</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={()=>setModalEliminar()}
                    >Cancelar</Button>

                    <Button
                    variant="danger"
                    onClick={()=>eliminarRegistro()}
                    >
                        Elimnar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* modal para actualizar*/}
            <Modal
            show={modalEditar}
            onHide={()=>setModalEditar()}
            >
              <DynamicForm
                  title={`Actualizar Unidad`}
                  onClickCancelBtn={setModalEditar} 
                  accionMysql={actualizar}
                  isCreate={false} 
                  justDynamicForm={{flag:true,idForm:"111"}}
                  endPointSubmit="unidadesAsignaturas"
                //   extraData={{IdMalla:location.state.malla.IdMalla}}
                />
            </Modal>
        </>
    )
}

export default Unidades 
