import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom';
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { DynamicSearch } from '../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { Input } from '../../../components/input/input';

export default function Estudiantes() {
  const {state:{curso}}=useLocation();
  const navigate=useNavigate();
  const [documento, setDocumento] = useState()
  const [busqueda, setBusqueda] = useState()
  const [endPoint, setEndPoint] = useState(`educacionIES/estudiantes?IdCurso=${curso?.IdCurso}`)

  const onSearch= async()=>{
    setEndPoint(`educacionIES/estudiantes?IdCurso=${curso}&NumeroDocumento=${documento}`)
    setBusqueda(`&NumeroDocumento=${documento}&`)
  }

  return (
    <>
      <Card>
        <Card.Title>Estudiantes</Card.Title>
      </Card>

      <Card>
        <Row>
          <Col>
            <Input
              label={'Número Documento'}
              name={'NumeroDocumento'}
              value={documento}
              onChange={(e) => setDocumento(e)}
            />
          </Col>
         <Col>
         </Col> 
          <Col style={{textAlign: 'right'}}>
            <br />
            <br />
            <Button onClick={onSearch}>
              Buscar
            </Button>
          </Col>
        </Row>
      </Card>

      <DynamicTable
            endPoint={endPoint}
            titleColumn={["N°","Número Documento","Nombre","Acciones"]}
            attributes={["NumeroDocumento","NombreUsuario"]}
            handleOptionClick={(e)=>navigate("semaforizacion", {state:{estudiante:e, curso: curso}})}
            labelOptionClick={`Semaforización`}
            onDelete={false}
            onUpdate={false}
            busqueda={busqueda}
        />
    </>
  )
}
