import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { 
    setForm,
    setItemSelected 
} from '../../../components/DynamicForm/FormSlice'
import { Select } from '../../../components/select/Select'
import { alerta } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'
import { LocalStorage } from '../../../services'

export default function Fases() {
    const [modalEliminar, setModalEliminar] = useState(false)
    const [modalEditar, setModalEditar] = useState(false)
    const [modalCrear, setModalCrear] = useState(false)
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const storage = new LocalStorage()
    const dimension = storage.getItem("dimension")
    const api = new FormService();
        // recibir parametros por use location
        const location = useLocation();
        const Convocatoria=location.state;
    const url="convocatoriaFase?IdConvocatoria="+Convocatoria.IdConvocatoria;
    const [enpooint, setEnpoint] = useState(url)
    const [idFasePadre, setIdFasePadre] = useState(null)

    useEffect(() => {
        setIdFasePadre(itemSelected?.IdFasePadre)
    }, [itemSelected])

    const dispatch = useDispatch()

    const eliminarFase = async() => {
        const data={
            "Estado":0
        }
        const respuesta=await api.update("convocatoriaFase",data,itemSelected.IdConvocatoriaFase)
        if(respuesta.respuesta=="exito"){
            setModalEliminar()
            dispatch(setItemSelected(null))
            alerta(respuesta.mensaje)
            cerrarModales()
        }else{
            alerta("Fallo desconocido")
        }
    }
    const cerrarModales = () => {
        setModalEliminar(false)
        setModalEditar(false)
        setModalCrear(false)
        setIdFasePadre(null)
        setTimeout(() => {
            dispatch(setItemSelected(null))
            setEnpoint(`${url}?i${Math.random()}`)
        }, 3000);
    }
  return (
    <>
        <Card>
            <Row>
                <Col>
                    <Card.Title>
                        {Convocatoria.Nombre}
                    </Card.Title>
                    {dimension == '"true"' ?
                        <p>Etapas</p>
                    :
                        <p>Fases</p>
                    }

                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button  onClick={()=>{
                        setModalCrear(true)
                        dispatch(setItemSelected(null))
                        }}>Crear</Button>
                </Col>
            </Row>
           

        </Card>

        {/* tabla para mostras convocatorías */}
        <DynamicTable
            idTable="1"
            indice={false}
            endPoint={enpooint}
            titleColumn={["Nombre","Descripción","Acciones"]}
            attributes={["Nombre","Descripcion"]}
            showIndexColumn={false}
            elimimarExterno={(e)=>{
                setModalEliminar(true)
                dispatch(setItemSelected(e))
                setIdFasePadre(null)
            }}
            actualizarExterno={(e)=>{
                dispatch(setItemSelected(e))
                dispatch(setForm({llave_primaria:"IdConvocatoriaFase",form_data:"0"}))
                setModalEditar(true)
                setIdFasePadre(null)
            }}
        />
        {/* moodal eliminar */}
        <Modal show={modalEliminar} onHide={()=>setModalEliminar()}>
            <Modal.Header closeButton>
                <Modal.Title>Eliminar Fase</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                ¿Está seguro que desea eliminar la fase?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={()=>setModalEliminar()}>
                    Cerrar
                </Button>
                <Button variant="danger" onClick={()=>eliminarFase()}>
                    Eliminar
                </Button>
            </Modal.Footer>
        </Modal>

        {/* modal crear Fase */}
        <Modal show={modalCrear} onHide={()=>setModalCrear()}>
                <DynamicForm
                    title={dimension == '"true"'? "Crear Etapa" : "Crear Fase"}
                    // idForm={31}
                    justDynamicForm={{flag:true,idForm:dimension == '"true"'? 176 : 33}}
                    extraData={{IdConvocatoria:Convocatoria.IdConvocatoria,IdFasePadre:idFasePadre}}
                    endPointSubmit={"convocatoriaFase"}
                    onClickCancelBtn={setModalCrear}
                    idItem={null}
                    isCreate={true}

                    accionMysql={cerrarModales}
                >
                    <Select 
                        label={dimension == '"true"' ? "Etapa Padre" : "Fase Padre"}
                        required={false}
                        name="IdFasePadre"
                        urlOptionsService={`convocatoriaFase?IdConvocatoria=${Convocatoria.IdConvocatoria}`}
                        optionName="Nombre"
                        optionValue="IdConvocatoriaFase"
                        onChange={(e)=>setIdFasePadre(e)}
                        value={idFasePadre}

                    />
                </DynamicForm>
        </Modal>
        {/* modal actulizar */}
        <Modal show={modalEditar} onHide={()=>setModalEditar()}>
                <DynamicForm
                    title={dimension == '"true"' ? "Actualizar Etapa" : "Actualizar Fase"}
                    justDynamicForm={{flag:true,idForm:dimension == '"true"'? 176 : 33}}
                    isContentTypeFormData={false}
                    extraData={{IdFasePadre:idFasePadre}}
                    endPointSubmit={`convocatoriaFase`}
                    onClickCancelBtn={setModalEditar}
                    isCreate={false}
                    accionMysql={cerrarModales}
                >
                    <Select 
                        label={dimension == '"true"' ? "Etapa Padre" : "Fase Padre"}
                        name="IdFasePadre"
                        required={false}
                        urlOptionsService={`convocatoriaFase?IdConvocatoria=${Convocatoria.IdConvocatoria}`}
                        optionName="Nombre"
                        optionValue="IdConvocatoriaFase"
                        onChange={(e)=>{
                            setIdFasePadre(e.length==0?null:e);
                            // e.length==0&&itemSelected?.IdFasePadre:null

                        }}
                        value={idFasePadre}
                    />
                </DynamicForm>
        </Modal>

    </>
  )
}
