import React, { useState } from 'react'
import { useParams, useNavigate, Navigate } from 'react-router-dom'

import { useUpdate } from '../../hooks/useUpdate'
import { FormService } from '../../services/api/formService'
import { Message } from '../Message/Message'

import avatar from '../../assets/img/avatar.jpg'
import award from '../../assets/icon/award.png'
import './ProfileHeader.css'
import { Input } from '../input/input'
import { Button, Dropdown, DropdownButton, Modal, Table } from 'react-bootstrap'
import { TextArea } from '../componentsForm/textarea/textarea'
import { alerta } from '../../helpers/alertas'
import { BASE_SERVIDOR } from '../../services/api/config'

const ProfileHeader = ({
    profile,
    isStudent,
    actions=true,
    isGraduate,
    isModuloFull,
    oferta,
}) => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const { idApplicant } = useParams()
    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isOpenCancel, setIsOpenCancel] = useState(false)
    const [observation, setObservation] = useState('')
    const [update, errorUpdate] = useUpdate('aspirante')
    const [modalReservar,setModalReservar]=useState();
    const [mensajeReserva,setMensajeReserva]=useState();
    const [documentoReserva,setDocumentoReserva]=useState(null);

    const [modalActualizarPuntaje,setModalActulizarPuntaje]=useState(false);
    const [puntaje,setPuntaje]=useState(null);
    const [codigoIcfes,setCodigoIcfes]=useState(null);


    const navigate = useNavigate()
    const api = new FormService()

    const {
        PrimerNombres = '',
        SegundoNombre = '',
        PrimerApellidos = '',
        SegundoApellidos = '',
        email = '',
        TelefonoContacto = '',
        NumeroDocumento = '',
        Direccion = '',
        FechaNacimiento = '',
        FechaExpedicion = '',
        Foto, 
        IdEstudiante,
        IdUsuario,
        codigoSaber='',
        CodigoIcfes='',
        FechaPresentacionIcfes='',
        NumeroRegistroIcfes='',
        PuntajeGlobalIcfes='',
        TipoVinculacion='',
    } = profile

    const [fechaIcfes,setFechaIcfes]=useState(profile?.FechaPresentacionIcfes);
    const [numeroIcfes,setNumeroIcfes]=useState(profile?.NumeroRegistroIcfes);
    const [puntajeGlobalIcfes,setPuntajeGlobalIcfes]=useState(profile?.PuntajeGlobalIcfes);

    const Navigate=useNavigate();

    const handleOpenModalDetail = () => {
        setIsOpenDetail(!isOpenDetail)
    }

    const handleOpenModalDelete = () => {
        setIsOpenDelete(!isOpenDelete)
    
    }
    const handleOpenModalCancel = () => {
        setIsOpenCancel(!isOpenCancel)
    }

    const onDelete = async () => {
        const data = {
            Estado: '0'
        }
        try {
            setError(null)
            setLoading(true)
            await api.update(
                'aspirantes', 
                data, 
                idApplicant
            )
            setLoading(false)
            handleOpenModalDelete()
            navigate(-1)
        } catch (err) {
            setLoading(false)
            setError(err)
        }
        await update(data, 'IdAspirante')
    }
    const onCancel = async () => {
        try {
            setLoading(true)
            const data = {
                cancelar_matricula: '1',
                cancelar_observacion: observation
            }
            await api.update(
                'estudiante', 
                data, 
                IdEstudiante
            )
            setObservation('')
            handleOpenModalCancel()
            setLoading(false)
        } catch(err) {
            setError(err)
            setLoading(false)
        }
    }
    
    const guardarReserva=async()=>{

        const data={
            "IdAspirante":idApplicant,
            "MensajeReserva":mensajeReserva,
            "DocumentoReserva":documentoReserva
        }

        const respuesta=await api.createWithFormData("educacionIES/reservarCupo",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModalReservar()
            navigate(-1)
        }else{
            alerta("Fallo desconocido")
        }
    }
    // actualizar puntaje
    const actualizarPuntaje=async()=>{
        const data={
            "IdUsuario":IdUsuario,
            "Puntaje":puntaje,
            "CodigoIcfes":codigoIcfes,
            "FechaPresentacionIcfes":fechaIcfes,
            "NumeroRegistroIcfes":numeroIcfes,
            "PuntajeGlobalIcfes":puntajeGlobalIcfes
        }
        const respuesta = await api.create("educacionIES/actualizarPuntaje",data)
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            setModalActulizarPuntaje() 
            navigate(-1)
        }else{
            alerta("Fallo desconocido")
        }
    }


    return (
        <>
            <div className='profile-header'>
                <figure className='profile-header__img-container'>
                    <img 
                        src={Foto?BASE_SERVIDOR+'/imgUser/'+Foto:avatar}
                        alt='Foto de perfil'
                        className='profile-header__avatar'
                    />
                    {(isGraduate === '1' || isGraduate === true) && 
                            <img
                                src={ award }
                                alt='award'
                                className='profile-header__award'
                            />
                    }
                </figure>
                <div>
                    <h4 className='profile-header__name'>
                        {`${PrimerNombres} ${SegundoNombre?SegundoNombre:''} ${PrimerApellidos} ${SegundoApellidos?SegundoApellidos:''}`}
                    </h4>
                    <p className='profile-header__tag'>
                        {
                            isStudent ? 
                            'Estudiante' 
                            : isGraduate === '1'? 
                            'Graduado' 
                            : 'Aspirante'
                        }   
                       {' '} - {TipoVinculacion}
                    </p>
                    <div className='profile-header__info-user'>
                        <section>
                            <h6>Correo</h6>
                            <h5>{ email || ''}</h5>
                        </section>
                        <section>
                            <h6>Número celular</h6>
                            <h5>{ TelefonoContacto || '' }</h5>
                        </section>
                    </div>
                </div>
                {actions && 
                    <DropdownButton  
                        title=""
                    >
                        <Dropdown.Item
                            onClick={ handleOpenModalDetail }
                        >
                            Ver más
                        </Dropdown.Item> 
                        {isModuloFull === 1 &&
                                <Dropdown.Item 
                                onClick={ setModalReservar }
                            >
                                Reservar cupo
                            </Dropdown.Item>
                        }                       
                        {isModuloFull === 1 &&
                                <Dropdown.Item 
                                onClick={ handleOpenModalDelete }
                            >
                                Eliminar
                            </Dropdown.Item>
                        }
                    </DropdownButton>
                }
            </div>
            <Modal 
                show={ isOpenDelete } 
                onHide={ handleOpenModalDelete }
            >
                <Modal.Body>
                    <h5>¿Está seguro que desea eliminar este aspirante?</h5>
                </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant='secondary' 
                            onClick={ handleOpenModalDelete }
                        >
                            Cancelar
                        </Button>
                        <Button 
                            variant='danger'
                            onClick={ onDelete }
                        >
                            Eliminar
                        </Button>
                </Modal.Footer>
            </Modal>

            <Modal 
                show={ isOpenCancel } 
                onHide={ handleOpenModalCancel }
            >
                <Modal.Body>
                    <h5>¿Está seguro que desea cancelar la matrícula de este estudiante?</h5>
                    <Input 
                        label='Ingresa el motivo de cancelación de matrícula'
                        placeholder='Observación'
                        value={ observation }
                        onChange={ value => setObservation(value) }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant='danger'
                        onClick={ onCancel }
                    >
                        Cancelar matrícula
                    </Button>
                    <Button 
                        type='send'
                        onClick={ handleOpenModalCancel }
                    >
                        Volver
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal 
                show={ isOpenDetail } 
                onHide={ handleOpenModalDetail }
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Información del aspirante
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>
                        {`${PrimerNombres} ${SegundoNombre?SegundoNombre:''} ${PrimerApellidos} ${SegundoApellidos?SegundoApellidos:''}`}
                    </b>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Correo</td>
                                <td>{ email || ''}</td>
                            </tr>
                            <tr>
                                <td>Número celular</td>
                                <td>{ TelefonoContacto || '' }</td>
                            </tr>
                            <tr>
                                <td>Dirección</td>
                                <td>{ Direccion || '' }</td>
                            </tr>
                            <tr>
                                <td>Número de documento</td>
                                <td>{ NumeroDocumento || '' }</td>
                            </tr>
                            <tr>
                                <td>Fecha de nacimiento</td>
                                <td>{ FechaNacimiento || '' }</td>
                            </tr>
                            <tr>
                                <td>Fecha de expedición</td>
                                <td>{ FechaExpedicion || '' }</td>
                            </tr>
                            <tr>
                                <hr />
                            </tr>
                            {/* <tr>
                                <td>Puntaje ICFES </td>
                                <td>
                                    { codigoSaber || '' }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Código ICFES
                                </td>
                                <td>
                                    {CodigoIcfes || ''}
                                </td>
                            </tr> */}
                            <tr>
                                <td>
                                    Fecha de Presentación ICFES
                                </td>
                                <td>
                                    {FechaPresentacionIcfes || ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Número de Registro ICFES
                                </td>
                                <td>
                                    {NumeroRegistroIcfes || ''}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Puntaje Global ICFES
                                </td>
                                <td>
                                    {PuntajeGlobalIcfes || ''}
                                </td>
                            </tr>
                            <tr>
                                <td>

                                </td>
                                <td>
                                    <Button
                                        variant='primary'
                                        onClick={()=>setModalActulizarPuntaje(true) }
                                    >
                                        Actualizar Datos Icfes
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>               
                    </Modal.Body>
            </Modal>
            {
                error && (
                    <Message type='error'>
                        { error.message }
                    </Message>
                )
            }
            <Modal
                show={modalReservar}
                onHide={setModalReservar}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Reservar cupo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextArea
                        label="Escribe el motivo de la reserva"
                        value={mensajeReserva}
                        onChange={(e)=>setMensajeReserva(e)}
                    />
                    <label><b>Documento</b></label><br></br>
                    <input 
                        type="file"
                        // value={documentoReserva}
                        onChange={(e)=>setDocumentoReserva(e.target.files[0])}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant='secondary'
                        onClick={()=>setModalReservar()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={guardarReserva}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* modal para actualziar puntaje */}
            <Modal
                show={modalActualizarPuntaje}
                onHide={setModalActulizarPuntaje}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Actualizar Datos Icfes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Input
                        label="Escriba el Nuevo Puntaje"
                        value={puntaje}
                        onChange={(e)=>setPuntaje(e)}
                    />
                    <Input
                        label="Código ICFES"
                        type={'text'}
                        value={codigoIcfes}
                        onChange={(e)=>setCodigoIcfes(e)}
                    /> */}
                    <Input
                        label="Fecha Presentación de la Prueba"
                        type={'date'}
                        value={fechaIcfes}
                        onChange={(e)=>setFechaIcfes(e)}
                    />
                    <Input
                        label="Número de Registro ICFES"
                        type={'text'}
                        value={numeroIcfes}
                        onChange={(e)=>setNumeroIcfes(e)}
                        max={14}
                    />
                    <Input
                        label="Puntaje Global ICFES"
                        value={puntajeGlobalIcfes}
                        onChange={(e)=>setPuntajeGlobalIcfes(e)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant='secondary'
                        onClick={()=>setModalActulizarPuntaje()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={actualizarPuntaje}
                    >
                        Guardar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export {  ProfileHeader }