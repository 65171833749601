import React, { useEffect, useState } from 'react'
import { Button, Card, Col, ListGroup, Modal, Row, Table } from 'react-bootstrap';
import { BsArrowLeft } from 'react-icons/bs';
import { useLocation, useNavigate } from 'react-router-dom';
import { alerta } from '../../../helpers/alertas';
import { LocalStorage } from '../../../services';
import { BASE_SERVIDOR, BASE_URL } from '../../../services/api/config';
import { FormService } from '../../../services/api/formService';
import Header from './Componentes/Header';
import DowloadFile from '../../../components/DowloadFileText/DowloadFileText';
import { BiCaretRight } from 'react-icons/bi';
import { BiDownload } from 'react-icons/bi';
import { ACCESS_TOKEN } from '../../../services/api/config'; 
import fileDownload from 'js-file-download';
import axios from 'axios';

export default function MisSolicitudes() {
    const {state:{usuario}}=useLocation()
    const navigate=new useNavigate();
    const storage= new LocalStorage();
    const api=new FormService();
    const urlDocumentos=`${BASE_SERVIDOR}/pdf`
    // const usuario= JSON.parse(storage.getItem("usuario"))
    const [solicitudes,setSolicitudes]=useState([]);
    const [solicitud,setSolicitud]=useState();
    const [modalSeguimiento,setModalSeguimiento]=useState();
    const [modalPagar,setModalPagar]=useState();
    const [comprobante,setComprobante]=useState();
    const Ubicacion = window.location.host;  
    const urlInstructivo=`${BASE_URL}/solicitudes/instructivoPagoSolicitud?IdSolicitud=${solicitud?.IdSolicitud}`
    const getTokenLocal = async()=> localStorage.getItem(ACCESS_TOKEN)?localStorage.getItem(ACCESS_TOKEN):'empty token in localstorage'
    const [cargando,setCargando]=useState(false)

    const descargarSolicitud = async(solicitud) => {
        setCargando(true)
        return getTokenLocal()
        .then((token)=>{
            axios({
                url: `${BASE_URL}/exportar/solicitud?IdSolicitud=${solicitud.IdSolicitud}&IdUsuario=${solicitud.IdUsuario}&IdCurso=${solicitud.IdCurso}&Ubicacion=${Ubicacion}`,
                method: 'GET',
                responseType: 'blob',
                headers:{
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                // detecar si a ocurido un error
                console.log(response.data)
                if(response.data.type==='application/json'){
                    setCargando(false)

                        const reader = new FileReader();
                        reader.addEventListener('loadend', (e) => {
                            const text = e.srcElement.result;
                            const json = JSON.parse(text);
                            return alerta(json.mensaje,'error')
                        });

                        reader.readAsText(response.data);
                }else{
                    fileDownload(response.data, 'Recepción de solicitud.pdf');
                    setCargando(false)
                }

            });
        })
    }

   useEffect(()=>{
        (async()=>{
            consultarSolicitudes()
        })()

   },[])

   

    const consultarSolicitudes=async()=>{
        const respuestas=await api.getAll(`solicitudes/misSolicitudes?IdUsuario=${usuario.IdUsuario}`)
        setSolicitudes(respuestas)
    }

    const enviarPago=async()=>{
        const data={
            "ComprobantePago":comprobante,
            "IdSolicitud":solicitud.IdSolicitud
        }
        const respuesta=await api.createWithFormData("solicitudes/pagarMiSolicitud",data);
        if(respuesta.respuesta=="exito"){
            alerta(respuesta.mensaje)
            consultarSolicitudes()
            setModalPagar()
            setModalSeguimiento()
        }else{
            alerta("Fallo desconocido")
        }
    }
    
  return (
    <>
     <div style={{maxWidth:1000,margin:"0 auto",marginTop:40}}>
        <Header usuario={usuario}/>
        <Card>
           
            <Row>
                <Col>
                <Card.Title>
                    Mis solicitudes
                </Card.Title>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button 
                        onClick={()=>navigate("nueva_solicitud",{state:{usuario:usuario}})}
                    >
                        Nueva Solicitud
                    </Button>
                </Col>
            </Row>
        </Card>
        <Table>
            <thead>
                <tr>
                    <th>Programa</th>
                    <th>Solicitud</th>
                    <th>Estado</th>
                    <th>PDF</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                {solicitudes.map((solicitud,key)=>
                    <tr key={key}>
                        <td>{solicitud.Nombre}</td>
                        <td>{solicitud.Solicitud}</td>
                        <td>
                        {solicitud.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud.EstadoSolicitud==3&&
                            <>En revisión</>
                         }
                         {solicitud.EstadoSolicitud==2&&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         }
                        </td>
                        <td>
                            <a 
                                onClick={() => 
                                    descargarSolicitud(solicitud)
                                } 
                                target='_blank'
                            >
                                <BiDownload title='Descargar' style={{cursor: 'pointer', fontSize: '25px'}} >
                                </BiDownload>
                            </a>
                        </td>
                        <td>
                            <a
                                onClick={()=>{
                                    setModalSeguimiento(true)
                                    setSolicitud(solicitud)
                                }}
                                style={{cursor: 'pointer'}}
                            >
                                Ver más
                                <BiCaretRight style={{fontSize: '25px'}}></BiCaretRight>
                            </a>
                        </td>
                    </tr>
                )}
            </tbody>
        </Table>
        </div>
        <Modal
            size="lg"
            show={modalSeguimiento}
            onHide={()=>setModalSeguimiento()} 
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Seguimiento de mi solicitud
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Programa:</b></Col><Col>{solicitud?.Nombre}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Solicitud:</b></Col><Col>{solicitud?.Solicitud}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Row>
                        <Col xs={4}><b>Fecha de solicitud:</b></Col><Col>{solicitud?.FechaCreacion}</Col>
                    </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                    {solicitud?.DocumentoSolicitud&&
                    <Row>
                        <Col xs={4}><b>Documento Adjuntado:</b></Col>
                        <Col>
                            <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoSolicitud}`}>Ver documento</a>
                        </Col>
                    </Row>
                    }
                </ListGroup.Item>
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Estado:</b></Col>
                    <Col>
                        {solicitud?.EstadoSolicitud==0&&
                            <>Rechazada</>
                        }
                        {solicitud?.EstadoSolicitud==1&&
                            <>Aprobada</>
                        }
                         {solicitud?.EstadoSolicitud==3&&
                            <>En revisión</>
                         }
                         {solicitud?.EstadoSolicitud==2 &&
                            <>
                            {solicitud.EstadoPago ==null&&
                                <>Pago por realizar</>
                            }
                            {solicitud.EstadoPago==0&&
                                <>Validando pago</>
                            }
                            {solicitud.EstadoPago==1&&
                                <>Pago aprobado</>
                            }
                            {solicitud.EstadoPago==2&&
                                <>Pago rechazado</>
                            }
                            </>
                         }
                    </Col>
                </Row>
                </ListGroup.Item>
                {solicitud?.EstadoSolicitud==2&&
                (solicitud.EstadoPago ==null||solicitud.EstadoPago==2)&&
                    <Button
                        onClick={()=>setModalPagar(true)}
                    >
                        Realizar pago
                    </Button>
                }
                {solicitud?.MotivoRechazo&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Motivo de rechazo de la solicitud:</b></Col>
                    <Col>
                        {solicitud?.MotivoRechazo}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.MotivoPagoRechazo&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Motivo  de rechazado del pago:</b></Col>
                    <Col>
                        {solicitud?.MotivoPagoRechazo}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.ObservacionesRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Respuesta:</b></Col>
                    <Col>
                        {solicitud?.ObservacionesRespuesta}
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                {solicitud?.DocumentoRespuesta&&
                <ListGroup.Item>
                <Row>
                    <Col xs={4}><b>Documento de respuesta:</b></Col>
                    <Col>
                        <a target="_blank" href={`${urlDocumentos}/${solicitud?.DocumentoRespuesta}`}>Ver documento</a>
                    </Col>
                </Row>
                </ListGroup.Item>
                }
                
            </ListGroup>
            
            </Modal.Body>
        </Modal>
        <Modal 
            show={modalPagar}
            onHide={()=>setModalPagar()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Realizar el pago</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                    <a target="_blank" href={urlInstructivo}>
                    <div>Descargar instrutivo de pago</div>
                    </a>
                    
                    <label style={{marginTop:30}}>Adjuntar comprobante</label>
                    <input
                        type="file"
                        // value={comprobante}
                        onChange={(e) => setComprobante(e.target.files[0])}
                    />
            </Modal.Body>
             
            <Modal.Footer>
                <Button
                variant="secondary"
                onClick={()=>setModalPagar()}
                >
                    Cancelar
                </Button>
                <Button
                onClick={()=>enviarPago()}
                >
                    Aceptar
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  )
}
