import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../services";
import { DynamicTable } from "../../../components/DynamicForm/dynamicTable/DynamicTable";
import { Title } from "../../../shared/components/title/Title";
import { AddButton } from "../../../components/componentsForm/addButton/addButton";
import { Card } from "react-bootstrap";
import { BASE_URL } from "../../../services/api/config";


function CursosActivos() {

  const api = new LocalStorage();
  const idUsuario = api.getAsJsonItem("usuario")?.usuario?.IdUsuario || 'localstorage_empty'
  const urlProgramas = `docente/cursos?IdUsuario=${idUsuario}`;
  const navigate = useNavigate();
  const urlExportar = `${BASE_URL}/exportarCursoActivos/docente?IdUsuario=${idUsuario}`

  const onNavigate = (programa) => {
    navigate(`${programa.IdOferta}/asignaturas`,{state:{programa,idUsuario}});
  }

  return (
    <>
    <Card>
      <Card.Title>Asignaturas Activas ES</Card.Title>
    </Card>

    <DynamicTable
      titleColumn={['N°','Nombre','Fecha Inicio','Fecha Final', 'Acción']}
      attributes={['Nombre','FechaInicioCurso','FechaFinCurso']}
      endPoint={urlProgramas}
      onDelete={false}
      onUpdate={false}
      labelOptionClick={`Ver más`}
      handleOptionClick={onNavigate}
      classNameOptionIcon={`bi bi-view-list`}
      exportar={urlExportar}
    ></DynamicTable>
    </>
  )
}

export  { CursosActivos };