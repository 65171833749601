import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { DynamicTable } from '../../../../components/DynamicForm/dynamicTable/DynamicTable';
import { alertaSinBoton } from '../../../../helpers/alertas';
import { FormService } from '../../../../services/api/formService';

const CursosActivos=()=>{
    const urlCursos = 'instrumento/cursosActivos';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const api = new FormService();
    
    useEffect(() => {
        const fecthData = async () => {
            fetchCamposFormForFilter();
            setLoading(true);
            setLoading(false);
        };
        fecthData();
    }, []);
    const onNavigateDetails = (programa)=>{
        console.log(programa)
        navigate(`${programa.IdCurso}`,{state:{programa}})
    }
    const fetchCamposFormForFilter = ()=>{   
          api.getAll(`xcampos?id_formulario=97`)
        .then((res)=>{
            setCampos(res)
        })
    }
    
    const buscarCursos=(e)=>{
    const nuevoEndPoint=`${urlCursos}${e}`
    setEndPoint(nuevoEndPoint)
    }

    const enviarNotificaciones = () => {
        setLoading(true)
        const api = new FormService()
        api.getAll(`gestionInstrumentos/notificarEstudiantes`).then(resp=>{
          alertaSinBoton("Se han enviado con exito!",'success','bottom-end',1500)
          setLoading(false)
        }).catch(err=>{
          alertaSinBoton("ERROR!",'warning','bottom-end',1500)
          setLoading(false)
        })
    }

    return (
            <>
            <Card>
                <Row>
                    <Col>
                        <Card.Title>
                            Actividades y/o Programas Académicos Activos
                        </Card.Title>
                    </Col>
                    <Col>
                        <Button
                            onClick={()=>enviarNotificaciones()}
                        >
                            Notificar Evaluaciones
                        </Button>
                    </Col>
                </Row>
                
            </Card>
            {campos&&
                <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            }
            <DynamicTable
                titleColumn={[
                    'Id',
                    'Nombre',
                    'Horas',
                    // 'Días restantes',
                    'Acciones',
                ]}
                endPoint={endPoint}
                attributes={[
                    'Nombre',
                    'horas',
                    // 'dias_restantes'
                ]} 
                labelOptionClick='Ver Programa'
                handleOptionClick={onNavigateDetails}
                onDelete={false}
                onUpdate={false}
            />
        </>
    );
}


export{ CursosActivos };