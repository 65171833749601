import { useLocation } from "react-router-dom"
import { DynamicForm } from "../../../components/DynamicForm/DynamicForm"
import { ID_FORM } from "../../../constants/idForm"
import { Card, Col, Row } from "react-bootstrap"


function AutoEvaluacion (props) {
  const {state:{evaluacion}} = useLocation()

  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
  }

  return (
    <>
  
      <Card>
        <Row>
          <Col>
            <Card.Title>{evaluacion?.Nombre}</Card.Title>
          </Col>
          <Col style={{display:'flex',justifyContent:'flex-end'}} >
            IES
          </Col>
        </Row>
        <p><b>Asignaturas del docente: </b>
          {evaluacion?.Asignaturas?.map((item,index)=>{
            return(
              <span key={index}>
                {item.Asignatura} 
                {index+1<evaluacion?.Asignaturas?.length && ', '}
                </span>
            )
          })}

        </p>
      </Card>
      <DynamicForm
        parentField = { null}
        ventanaFormName={evaluacion.nombre }
        titleForm={evaluacion.nombre }
        extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
        endPointSubmit={'instrumento'}
        // keySubmit={'k'}
        isContentTypeFormData={'0'}
        isCreate={true}
        justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
        accionMysql={()=>retroceder()}
        retroceder={true}
      ></DynamicForm>


    </>
  )
}

export { AutoEvaluacion }