import { useNavigate } from "react-router-dom";
import { LocalStorage } from "../../../../services";
import { DynamicTable } from "../../../DynamicForm/dynamicTable/DynamicTable";

function AspiranteInscripciones () {

    const ls = new LocalStorage()
    const IdUsuario = ls.getAsJsonItem('usuario').usuario?.IdUsuario
    const navigate = useNavigate()
    // const urlOffersApplied = `estudiante/ofertas?IdUsuario=${ls.getAsJsonItem('usuario')?.usuario?.IdUsuario || 'localstorage_empty'}`
    const urlOffersApplied = `aspirantes/ofertas?IdUsuario=${IdUsuario || 'localstorage_empty'}`

    const titleColumnsTable = [
        'N°',
        'Programa',
        'Acción'
    ]

    const attributes = [
        'Nombre',
    ]

    const onNavigateDetails = (item) => {
        navigate(
            `${item.IdOferta}`, 
            {
                state: {
                    offer: item,
                    applicant:item,
                    oferta:item
                } 
            }
        ) //to AspiranteProcesoInscripcion.jsx
    }

    return (
        <>
            <DynamicTable
                titleColumn={ titleColumnsTable }
                attributes={ attributes }
                endPoint={ urlOffersApplied }
                onDelete={ false }
                onUpdate={ false }
                labelOptionClick={ 'Ver Detalles' }
                handleOptionClick={ onNavigateDetails }
                classNameOptionIcon='bi bi-view-list'
            />
        </>
    )
  
}

export { AspiranteInscripciones };