import React from 'react'

const Checkbox = ({
    id,
    title,
    children,
    checked,
    name,
    value,
    onChange,
    label,
    tabIndex,
}) => {
    document.addEventListener('DOMContentLoaded', e => {
        for (let checkbox of document.querySelectorAll('input[type=checkbox]')) {
            checkbox.value = checkbox.checked ? 1 : 0;
            checkbox.addEventListener('change', e => {
                    e.target.value = e.target.checked ? 1 : 0;
               });
            }
        });
        // convertir el estado de checked a 1 o 0
        

    return (
        <div className="form-check form-check-inline mt-3">
            {/* <span className="d-block">{ title }</span> */}
            <label className="form-check-label">
            <input type="hidden" name={ name } value="0" />
                <input
                    id={ id }
                    name={ name }
                    type='checkbox'
                    value={ value }
                    className="form-check-input"
                    checked={ checked }
                    tabIndex={tabIndex}
                    label={ label }
                    onChange={ e => onChange(e.target.checked, e.target.name) }
                />
                    <span>{ children }</span>
            </label>
        </div>
    )
}

export { Checkbox }