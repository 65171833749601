import { useLocation } from "react-router-dom";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { LocalStorage } from "../../../../services";
import { SubTitle } from "../../../../shared/components/subtitle/SubTitle";
import { Title } from "../../../../shared/components/title/Title";
import { Card, Col, Row } from "react-bootstrap";


function Evaluacion () {

  const storage= new LocalStorage();
  // const usuario=JSON.parse(storage.getItem(usuario))
  const {state:{evaluacion}} = useLocation()

  // console.log(usuario.usuario)
  const retroceder=()=>{
    setTimeout(() => { 
      window.history.go(-1)
    }, 3000);
    
  }

return (
  <>

  <Card>
      <Row>
        <Col><Card.Title>{evaluacion.Nombre}</Card.Title></Col>
        <Col style={{textAlign:'right'}}>IES</Col>
      </Row>
        <p><b>Asignaturas del docente: </b>
          {evaluacion.Asignaturas?.map((asignatura,index)=>
            <>
              {asignatura.Descripcion}
              {index+1<evaluacion.Asignaturas.length?', ':''}
            </>            
          )}

        </p>
  </Card>


  <Card>
 <DynamicForm
  parentField = { null}
  ventanaFormName={evaluacion.nombre }
  extraData={{IdGestionInstrumento:evaluacion.IdGestionInstrumento}}
  endPointSubmit={'instrumento'}
  // keySubmit={'k'}
  isContentTypeFormData={'0'}
  isCreate={true}
  justDynamicForm={{flag:true,idForm:evaluacion?.IdForm}}
  accionMysql={()=>retroceder()}
  retroceder={true}

 ></DynamicForm>

  </Card>
  </>
  )
}

export { Evaluacion }