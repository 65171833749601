import React from 'react'
import { Modal } from '../../../components/modal/Modal'
import { Message } from '../../../components/Message/Message'
import { LoadingSpinner } from '../../../components/LoadingSpinner/LoadingSpinner'
import { InputFile } from '../../../components/inputFile/inputFile'
import { ViewPDF } from './ViewPDF'

import './Documentos.css'
import { TextArea } from '../../../components/componentsForm/textarea/textarea'
import { Button } from 'react-bootstrap'

const Documentos = ({
    documentos,
    loading,
    loadingFull,
    applicant,
    error,
    isOpenModalDecline,
    onDecline,
    isOpenModalAccept,
    onAccept,
    isOpenModalUpload,
    onUpload,
    onEnroll,
    handleSubmitUpload,
    document,
    observation,
    handleChangeObservation,
    isEmpty,
    handleState,
    onRechazar,
    isOpenModalRechazar,
    rechazarAspirante,
    handleChangeMensaje,
    mensajeRequisitos,
    isCompletedDocuments,
    editable,
    oferta
}) => {

    
    return (
        <>
            {
                loadingFull ? <LoadingSpinner /> : (
                    <>
                        <div className='documents__container'>
                            {documentos.map((item, key) => (
                                <ViewPDF 
                                    item={ item }
                                    key={ key }
                                    onAccept={ onAccept }
                                    onDecline={ onDecline }
                                    onUpload={ onUpload }
                                    editable={editable}
                                />
                            ))
                            }
                            {
                                error && (
                                    <Message type='error'>
                                        { error.message }
                                    </Message>
                                )
                            }
                        </div>
                        <section className='documents__button-enroll'
                            style={{gap: '1rem'}}
                        >
                            {!isCompletedDocuments ?
                                <Button
                                    variant='danger'
                                    loading={loading}
                                    onClick={onRechazar}
                                >
                                    Cancelar solicitud
                                </Button>
                                :
                                <Button
                                    variant='danger'
                                    loading={loading}
                                    disabled={true}
                                >
                                    Cancelar solicitud
                                </Button>
                            }

                            {isCompletedDocuments ?
                                <Button
                                    loading={loading}
                                    onClick={ onEnroll }
                                    display
                                >
                                    Aprobar solicitud
                                </Button>
                            :
                                
                                <Button 
                                    loading={ loading }                                    
                                    isabled={true}
                                >
                                    Aprobar solicitud
                                </Button>
                            }
                        </section>                   
                    </>
                )
            }
            <Modal isOpen={ isOpenModalDecline } close={ onDecline }>
                <div>
                    <h5>
                        ¿Estás seguro de rechazar este documento?
                    </h5>
                    <TextArea 
                        label='Motivo'
                        placeholder='Explica el motivo de rechazo'
                        name='Observacion'
                        value={ observation }
                        onChange={ handleChangeObservation }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section 
                        style={{gap: '1rem'}}
                    >
                        <Button 
                            onClick={ () => handleState(2) }
                            loading={ loading }
                        >
                            Rechazar
                        </Button>
                        <Button 
                            variant='danger'
                            onClick={ onDecline }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalAccept } close={ onAccept }>
                <div className='documents_modal-accept'>
                    <h5>
                        ¿Estás seguro de aceptar este documento?
                    </h5>
                    <section
                        style={{
                            gap: '1rem',
                            display: 'flex',
                        }}
                    >
                        <Button 
                            onClick={ () => handleState(1) }
                            loading={ loading }
                        >
                            Aceptar
                        </Button>
                        <Button 
                            variant='danger' 
                            onClick={ onAccept }
                        >
                            Cancelar
                        </Button>
                    </section>
                </div>
            </Modal>
            <Modal isOpen={ isOpenModalUpload } close={ onUpload }>
                <form onSubmit={ handleSubmitUpload }>
                    <InputFile 
                        label={ document.Descripcion }
                        name='Archivo'
                        accept='.pdf'
                    />
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        Subir
                    </Button>
                </form>
            </Modal>
            <Modal 
                isOpen={isOpenModalRechazar}
                close={onRechazar}
            >
                <div>
                    <h5>
                        ¿Estás seguro de rechazar la solicitud?
                    </h5>
                    <TextArea
                        label='Describe el motivo'
                        placeholder='Describe el motivo de rechazo'
                        name='Observacion'
                        value={ mensajeRequisitos }
                        onChange={ handleChangeMensaje }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                    <section
                        style={{
                            gap: '1rem',
                            display: 'flex',
                        }}
                    >
                        <Button  onClick={rechazarAspirante}>Rechazar</Button>
                        <Button variant='danger' onClick={onRechazar}>Cancelar</Button>
                    </section>
                </div>
            </Modal>
        </>
    )
}

export { Documentos }