import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useFetchGet } from "../../../hooks/useFetchGet";
import user from '../../../assets/img/avatar.jpg';
import  './CursosActivos.css'
import { useState } from "react";
import { FormService } from "../../../services/api/formService";
import { optionsDate } from "../../../shared/util/date";

import { TextArea } from "../../../components/componentsForm/textarea/textarea";
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { Badge, Button, Card, Col, Dropdown, DropdownButton, ListGroup, Modal, Row, Table } from "react-bootstrap";
import { useEffect } from "react";
import { BASE_SERVIDOR, BASE_URL} from "../../../services/api/config";
import { Input } from "../../../components/input/input";
import DowloadFile from "../../../components/DowloadFile/DowloadFile";
import { Select } from "../../../components/select/Select";


function AsignaturaEstudiantes () {
  
  const location = useLocation();
  const navigate = useNavigate()
  const api = new FormService()
  const { programa,asignatura,IdUsuario } = location.state;
  // const [estudiantes,isLoadingEstudiantes,error] = useFetchGet(`docente/estudiantes?IdOferta=${programa.IdOferta}&IdMalla=${asignatura.IdMalla}`);
  const [estudiantes,setEstudiantes]=useState([])
  const [showModalDetails, setShowModalDetails] = useState(false);
  const [showModalAlerta, setShowModalAlerta] = useState(false);
  const [Descripcion,setDescripcion]=useState('');
  const [fechaAcontecimiento, setFechaAcontecimiento] = useState();
  const [showModalAsistencias, setShowModalAsistencias] = useState(false);
  const [showModalNotas, setShowModalNotas] = useState(false);
  const [modalHabilitar,setModalHabilitar]=useState(false);
  const [loadingEventAsistencia, setLoadingEventAsistencia] = useState(false);
  const [loadingEventoNota, setLoadingEventoNota] = useState(false);
  const [key, setKey] = useState('notas');
  const [estudianteSelected, setEstudianteSelected] = useState({});
  const urlPlan=`docente/planAsignatura?IdMalla=${asignatura.IdMalla}&IdUsuario=${asignatura.IdUsuario}&IdOferta=${programa.IdOferta}&IdUnidadAsignatura=${asignatura.IdUnidadAsignatura}`
  const [plan,setPlan]=useState();
  const [estudianteActual,setEstudianteActual]=useState();
  
  const [modalNotasExternas,setModalNotasExternas]=useState();
  const [documento,setDocumento]=useState();
  const [observaciones,setOservaciones]=useState();
  const [idMalla,setIdMalla]=useState(null)
  const [tipoNota,setTipoNota]=useState(null);
  const [Nota,setNota]=useState(null);
  const [modalObservaciones ,setModalObservaciones]=useState(false);

  
  const [eventoAsignatura,loadingAsistencias,errorAsistencias] = useFetchGet(`docente/eventosAsignatura?IdUsuario=${IdUsuario}&IdMalla=${asignatura.IdMalla}&IdOferta=${programa.IdOferta}&IdUnidadAsignatura=${asignatura.IdUnidadAsignatura}`)
  const [momentos,loadingNotas,errorNotas] = useFetchGet(`docente/notasAsignatura?IdUnidadAsignatura=${asignatura.IdUnidadAsignatura}&IdOferta=${programa.IdOferta}`)
  // const [eventoNotas,loadingNotas,errorNotas] = useFetchGet(`unidadesAsignaturas?IdMalla=${asignatura.IdMalla}`)
  const handleModalOpenDetails = (estudiante) => {
    setEstudianteSelected(estudiante);
    setShowModalDetails(true);
  }

    useEffect(()=>{
      (async()=>{
        planAsginatura()
        consultarEstudiantes()
      })()
    },[])
  
  // consultar estudiantes
  const consultarEstudiantes=async()=>{
    const  response= await api.getAll(`docente/estudiantes?IdOferta=${programa.IdOferta}&IdMalla=${asignatura.IdMalla}`)
    setEstudiantes(response)
  }
    


  const handleModalOpenAlerta = (estudiante) => {
    setEstudianteSelected(estudiante);
    setShowModalAlerta(true);
  }
 
  
  const onSelectEventoAsistencia=(evento,index)=>{

    setLoadingEventAsistencia(true)
    api.getAll('docente/planillaAsistencia?IdEventoAula='+evento.IdEventoAula).then(res=>{
      setLoadingEventAsistencia(false)
      navigate('asistencias',{
        state:{
          evento:{...evento,index},asignatura,IdUsuario,estudiantes:res}
        })
        
    }).catch(err=>{console.log("error getting event asis")})

  }
  const onSelectEventoNota=(evento,index)=>{
    setLoadingEventoNota(true)
    api.getAll('docente/planillaNota?IdNotaAsignatura='+evento.IdNotaAsignatura).then(res=>{
      setLoadingEventoNota(false)
      navigate('notas',{
        state:{
          evento:{...evento,index},asignatura,programa,IdUsuario,estudiantes:res}
        })  
    }).catch(err=>{console.log("error getting event asis")})
  }

  const goNotas=()=>{
    setLoadingEventoNota(true)
    api.getAll(`docente/planillaNota?IdUnidadAsignatura=${asignatura.IdUnidadAsignatura}&IdOferta=${programa.IdOferta}`).then(res=>{
      setLoadingEventoNota(false)
      navigate('notas',{
        state:{
          evento:null,asignatura,programa,IdUsuario,estudiantes:res}
        })  
    }).catch(err=>{console.log("error getting event asis")})
  }

  const enviarAlerta=(e)=>{
    var data={
      "IdOferta":programa.IdOferta,
      "IdDocente":IdUsuario,
      "IdMalla":asignatura.IdMalla,
      "IdEstudiante":estudianteSelected.IdEstudiante,
      "Descripcion":Descripcion,
      "IdTipo":4,
      "FechaAcontecio": fechaAcontecimiento
    }
    const respuesta= api.create('alertas',data)
    alertaSinBoton('Alerta generada', 'success', 'bottom-end', 1500)

    setDescripcion('')
    setShowModalAlerta(false)

  }

  const planAsginatura=async()=>{
    const respuesta=await api.getAll(`${urlPlan}`)
    setPlan(respuesta)
  }

  const habilitarAsignatura=async()=>{
    const datos={
      "IdMalla":asignatura.IdMalla,
      "IdEstudiante":estudianteActual.IdEstudiante,
      "IdOferta":programa.IdOferta,
      "Nota":Nota,
    }
    const respuesta=await api.create('estudiante/habilitar',datos)
    if(respuesta.respuesta="exito"){
      alerta(respuesta.mensaje, 'success', 'bottom-end', 1500)
    }else{
      alerta(respuesta.mensaje, 'error', 'bottom-end', 1500)
    }
    setModalHabilitar(false)
  }

  const guardarNotaExterna=async()=>{
    const data={
      "IdEstudiante":estudianteSelected.IdEstudiante,
      "IdMalla":asignatura.IdMalla,
      "IdOferta":programa.IdOferta,
      "Nota":Nota,
      "TipoNota":tipoNota,
      "Observaciones":observaciones,
      "Documento":documento
    }

    if(tipoNota==null||Nota==null){
      alerta("Completa la información del fomrulario")
      return
    }
    const respuesta=await api.createWithFormData("educacionIES/notaExterna",data)
    if(respuesta.respuesta=="exito"){
      alerta(respuesta.mensaje)
      setModalNotasExternas()
      consultarEstudiantes()
      
    }else if(respuesta.respuesta=="error"){
      alerta(respuesta.mensaje)
    }else{
      alerta("Fallo desconocido")
    }
  }

  // guardar observaciones
  const guardarObservaciones=async()=>{
    const data={
      "IdEstudiante":estudianteSelected.IdEstudiante,
      "IdMalla":asignatura.IdMalla,
      "Observaciones":observaciones,
    }

    if(observaciones==null){
      alerta("Completa la información del fomrulario")
      return
    }
    let respuesta
    if(!estudianteSelected.IdObsNotaFinal){
      respuesta=await api.create("educacionIES/observacionNotaFinal",data)
    }else{
      respuesta=await api.update("educacionIES/actualizarObservacion",data,estudianteSelected.IdObsNotaFinal)
    }
   
    if(respuesta.respuesta=="exito"){
      alerta(respuesta.mensaje)
      setModalObservaciones()
      consultarEstudiantes()

    }else if(respuesta.respuesta=="error"){
      alerta(respuesta.mensaje)
    }else{
      alerta("Fallo desconocido")
    }
  }

  return (
    <>
    <Card>
      <Row>
        <Col sm="8">
          <Card.Title>{programa.Nombre}</Card.Title>
          <p>{asignatura.Descripcion}{' -> '}
            {plan&&
              <a target="_blank" href={`${BASE_SERVIDOR}/pdf/${plan.Documento}`}>Plan de Asignatura</a>
            }
          </p>
          
        </Col>
        <Col style={{textAlign:"right"}}>
          {/* <Button
              className='btn btn-outline-primary pt-2 m-2'
              hasButtonCss={false}
              type={"send"}
              label={"Notas"}
              onClick={()=>goNotas()}
            >Notas</Button> */}

            <Button
              onClick={()=>setShowModalNotas(true)}
            >
              Notas
            </Button>
            {' '}
                <Button
                  onClick={()=>setShowModalAsistencias(true)}
                >
                  Asistencias
                </Button>
        </Col>
      </Row>
    </Card>
    <Table>
        <thead className="thead-student">
          <tr>
            <th><b>Estudiante</b></th>
            <th><b>Acciones</b></th>
          </tr>
        </thead>
        <tbody>
          {estudiantes.map((estudiante,index) => (
            <tr key={index} className="row-student" >              
              <td>
              {estudiante.Nombre}
                {/* <div className="content-student">
                  <img 
                      src={ estudiante?.Foto || user }
                      alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                      className='applicant-list__img-user student'
                      />
                  <span>{estudiante.Nombre}</span>                    
                </div>               */}
              </td>
              <td >
              <DropdownButton
                id="dropdownMenuButton2"
                title={<i className="bi bi-three-dots-vertical"></i>}
                variant="light"
              >
              <Dropdown.Item  
                onClick={() => handleModalOpenDetails(estudiante)}
              >
                  <i className="bi bi-pencil-square"></i>
                  Ver más
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() =>{
                  setEstudianteActual(estudiante)
                  setModalHabilitar(true)
                }}
              >
                    <i className="bi bi-pencil-square"></i>
                    Habilitar
              </Dropdown.Item>
              <Dropdown.Item 
                onClick={() => handleModalOpenAlerta(estudiante)}
              >
                    <i className="bi bi-pencil-square"></i>
                    Generar alerta
              </Dropdown.Item>
              {/* abrir modal para colocar una nota no tegular */}
              <Dropdown.Item
                onClick={() => {
                  setEstudianteSelected(estudiante)
                  setModalNotasExternas(true)
                }}
              >
                    <i className="bi bi-pencil-square"></i>
                    Nota no regular
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  setEstudianteSelected(estudiante)
                  setModalObservaciones(true)
                  setOservaciones(estudiante.Observaciones)
                }}
              >
                    <i className="bi bi-pencil-square"></i>
                    Observaciones nota final
              </Dropdown.Item>

            </DropdownButton>
                </td>
            </tr>
          ))}
          
        </tbody>
      </Table>

  <Modal
    show={showModalAsistencias}
    onHide={()=>setShowModalAsistencias(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>Asistencias </Modal.Title>
    </Modal.Header>
    
    <Modal.Body>
      {/* <Button>
        Crear Momento de Asistencia 
      </Button> */}
    {!!eventoAsignatura  && eventoAsignatura.map((evento,index)=>{
      return(
        <>
          <div onClick={()=>onSelectEventoAsistencia(evento,index)} className="evento-date-container">
          <div className="evento-date-container__evento-header">Fecha</div>
          <div className="evento-date-container__evento-asistencias">
          <div >{new Date(evento.FechaInicio).toLocaleDateString('es-co',optionsDate)}</div><br/>
          <div>{new Date(evento.FechaInicio).toLocaleTimeString('es-CO')} {new Date(evento.FechaFin).toLocaleTimeString('es-CO')}</div>
            {loadingEventAsistencia && <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">cargando...</span>
          </div>}
        </div>
        </div>
        </>
      )
    })}
    
  </Modal.Body>
  </Modal>
  {/* MODAL NOTAS */}
  <Modal
    show={showModalNotas}
    onHide={()=>setShowModalNotas(false)}
  >
    <Modal.Header closeButton>
      <Modal.Title>Momentos Evaluativos</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <ListGroup>
      {!!momentos.notasasignatura  && momentos?.notasasignatura.map((evento,index)=>{
        return(
          <>
            <ListGroup.Item
              key={index}
              onClick={momentos.calificable==1?()=>onSelectEventoNota(evento,index+1):()=>alerta("No puede calificar notas")} 
              as="li"
              className="d-flex justify-content-between align-items-start item-nota"
              
              
            >
              <div className="ms-2 me-auto">
                <div className="fw-bold">{evento.Nombre} </div>
                {evento.Corte}
              </div>
              <Badge bg="primary" pill>
                {evento?.Porcentaje}%
              </Badge>
            </ListGroup.Item>
          </>
        )
      })}
        <ListGroup.Item
          as="li"
          className="d-flex justify-content-between align-items-start"
        >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">Total</div>
                  {momentos.calificable==1?
                    <div style={{color:"green"}}>Puede calificar notas</div>
                    :
                    <div style={{color:"red"}}>No puede calificar notas</div>
                  }
                </div>
        </ListGroup.Item>
      </ListGroup>
      <ListGroup.Item 
        style={{textAlign:"right"}}
      >
        <DowloadFile
          endPoint={`${BASE_URL}/formatosIES/socializacionNotas?IdOferta=${programa.IdOferta}&IdMalla=${asignatura.IdMalla}`}
          name={'Socialización de notas'}
          nameFile="Socialización de notas.pdf"
        />


        {/* <Button 
          href={`${BASE_URL}/formatosIES/socializacionNotas?IdOferta=${programa.IdOferta}&IdMalla=${asignatura.IdMalla}`}
          variant="primary"
          target="_blank"
        >
          Socialización de notas
        </Button> */}
      </ListGroup.Item>
    </Modal.Body>

  </Modal>

  <Modal
    show={showModalDetails}
    onHide={()=>setShowModalDetails()}
  >
    <Modal.Body>
          <div className="content-student">

                      <img 
                          src={ estudianteSelected?.Foto || user }
                          alt={`foto estudiante ${estudianteSelected.PrimerNombres} ${estudianteSelected.PrimerApellidos}`}
                          className='applicant-list__img-user student'
                          style={{marginRight: "8px"}}
                          />
                      <span>{estudianteSelected.PrimerNombres} {estudianteSelected.PrimerApellidos} {estudianteSelected.SegundoApellidos}</span>                    
                      <div>{estudianteSelected.email}</div>
                    </div>
                    <div className="d-flex  justify-content-around">

                    <div>Teléfono: {estudianteSelected.TelefonoContacto}</div>
                    <div>N° Documento: {estudianteSelected.NumeroDocumento}</div>
                    </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showModalAlerta}
        onHide={()=>setShowModalAlerta()}
      >
        <Modal.Body>
          {console.log(estudianteSelected)}
        <h2>Genera una Alerta Sobre un Tema Psicológico o de Salud</h2>
        <TextArea 
          label={"Describe el Motivo de la Alerta"}
          onChange={(e)=>setDescripcion(e)}
          value={Descripcion}
        />
        <Input
          label={"Fecha del Acontecimiento"}
          type={'date'}
          value={fechaAcontecimiento}
          onChange={setFechaAcontecimiento}
        />
        <br />
        <Button
          className={'send'}
          onClick={()=>enviarAlerta()}
        >
          Enviar
        </Button>
        </Modal.Body>
      </Modal>
      {/* Modal para habilitar asignatura */}
      <Modal
        show={modalHabilitar}
        onHide={()=>setModalHabilitar()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Habilitar Asignatura</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Asignatura: {asignatura?.Descripcion}</p>
          <p>Estudiante: {estudianteActual?.Nombre}</p>
          <Input
            label="Nota de Habilitación"
            type="number"
            onChange={(e)=>setNota(e)}
            max={100}
            min={0}
          />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={()=>setModalHabilitar(false)}
            >
              Cancelar
            </Button>
            <Button 
              onClick={()=>habilitarAsignatura()}
            >
              Habilitar 
            </Button>
          </Modal.Footer>
      </Modal>

      {/* modal para notas no reguales */}

      <Modal 
        show={modalNotasExternas}
        onHide={()=>setModalNotasExternas()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Agregar nota no regular </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Select
            label="Tipo de nota"
            options={[
              {"Id":"2","Nombre":"Homologacion"},
              {"Id":"3","Nombre":"Suficiencia"},
              {"Id":"4","Nombre":"Intercambio"},
              {"Id":"5","Nombre":"Supletorio"},
            ]}
            optionName="Nombre"
            value={tipoNota}
            onChange={(e)=>setTipoNota(e)}
            optionValue="Id"
          />
          <Input
            label="Nota"
            type="number"
            min={0}
            max={programa.IdDimension==1?5:100}
            value={Nota}
            onChange={(e)=>setNota(e)}
          />
          <TextArea 
            label="Observaciones"
            value={observaciones}
            onChange={(e)=>setOservaciones(e)}
          />
          <label><b>Ajunte archivo si lo requiere</b></label>
          <input
            type="file"
            onChange={(e)=>setDocumento(e.target.files[0])}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setModalNotasExternas()}
          >
            Cancelar
          </Button>
          <Button
            onClick={()=>guardarNotaExterna()}
          >
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* modal para observaciones de la nota final */}
      <Modal
        show={modalObservaciones}
        onHide={()=>setModalObservaciones()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Observaciones de la nota final</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TextArea
            label="Observaciones"
            value={observaciones}
            onChange={(e)=>setOservaciones(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={()=>setModalObservaciones()}
          >
            Cancelar
          </Button>
            <Button
              onClick={()=>guardarObservaciones()}
            >
              {estudianteSelected?.IdObsNotaFinal?"Actualizar":"Guardar"}
          </Button>
          
        </Modal.Footer>
      </Modal>

    </>
  )
}

export  { AsignaturaEstudiantes };


