
import { Card } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useLocation } from "react-router-dom";
import { ICON } from '../../../constants/icons'; 
import { useFetchGet } from '../../../hooks/useFetchGet'; 

function EstudianteAsistencias () {

  const {state:{oferta,asignatura,estudiante }} = useLocation()
  console.log(asignatura)
  const Location=useLocation(); 
  console.log(Location)
  const [asistencias, loading, error] = useFetchGet(
    `estudiante/asistencia?IdMalla=${asignatura.IdMalla}&IdEstudiante=${estudiante.IdEstudiante}&IdOferta=${oferta.IdOferta}`
  )
  return (
    <>
    <Card>
      <Card.Title>Asistencias</Card.Title>
      <p>{oferta.asignatura}</p>  
    </Card>
    <Card>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>N°</th>
            <th>Fecha incial</th>
            <th>Fecha final</th>
            <th>Asistencia</th>
          </tr>
        </thead>
        <tbody>
          {!!asistencias && asistencias.map((item,index)=>{
            return(
              <tr key={index}>
                <td>{index+1}</td>
                <td>{item.FechaInicio}</td>
                <td>{item.FechaFin}</td>
                <td>{item.Asistencia==='1'?`Asistió ${ICON.CHECKED}` :`No asistió ${ICON.UNCHECKED}`}</td>
              </tr>
            )
          }

          )}
        </tbody>
      </Table>
    </Card>
    </>
  )
}

export { EstudianteAsistencias }