
import { useLocation } from "react-router-dom"
import { OfferHeader } from "../../../../components/OfferHeader/OfferHeader"
import { useFetchGet} from "../../../../hooks/useFetchGet"
import { Badge } from "../../../../components/Badge/Badge"
import { Card } from "../../../../components/card/Card"
import { Input } from "../../../../components/input/input"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import {ID_FORM} from "../../../../constants/idForm"
import { Button } from "../../../../components/button/button"
import { useState } from "react"
import { Modal } from "react-bootstrap"
function CursoActivo (){
  
  const { state:{programa} } = useLocation()
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
  const [estudiantes,isLoading,error] = useFetchGet(`estudiantesCurso?IdOferta=${programa.IdOferta}`)
  const cerrarModal=()=>{
    setTimeout(() => {
        setIsOpenModalAsignarEvaluacion(false)
    }, 3000);
}
  return(
    <>
    <Card>
    <OfferHeader
      offerName={programa.Nombre}
      img={programa.Repositorio}
      isCursoFinalizado={true}
      oferta={programa}
      horas={programa.horas}
      offerDescription={programa.Observaciones}
      isFinally={true}
      />
    </Card>
    <Card>
      <Button
      type={'send'}
      onClick={()=>setIsOpenModalAsignarEvaluacion(true)}
      >Asignar Evaluación</Button>
      <Input 
      type='search'
      placeholder= 'Buscar por Nombre o Cédula del Estudiante'
      />
    </Card>
    
    {
      Array.isArray(estudiantes) && (
        <div className='students-list__list-container'>
            {
                estudiantes.map((student, index) => (
                    <Badge 
                    to={ 
                      `estudiante/${student?.IdEstudiante}` 
                  }
                  state={{ estudiante:student,programa }}
                        img={ student?.Foto }
                        alt='Foto de perfil de estudiante'
                        user={ student }
                        key={ index }
                    />
                ))
            }
        </div>
    )

}
      <Modal
        show={isOpenModalAsignarEvaluacion}
        onHide={()=>setIsOpenModalAsignarEvaluacion(false)}
      >
          <DynamicForm
            onClickCancelBtn={()=>setIsOpenModalAsignarEvaluacion(false)}
            title={"Asignar Evaluaciones"}
            endPointSubmit={'gestionInstrumentos/asignarEvaluacionOferta'}
            isCreate={true}
            extraData={{IdOferta:programa.IdOferta,Reporte:programa.IdDimension==1?"2":"1"}}
            ventanaFormName={'Gestión instrumentos'}
            justDynamicForm={{ flag:true, idForm:ID_FORM.GESTION_INSTRUMENTOS }}
            accionMysql={()=>cerrarModal()}
          ></DynamicForm>
      </Modal>
    
    </>
  )
}

export { CursoActivo }