import { Card } from "../../components/card/Card"
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable"
import { BASE_URL } from "../../services/api/config"
import { Title } from "../../shared/components/title/Title"
import { useNavigate } from "react-router-dom" 
import { DynamicSearch } from "../../components/DynamicForm/DynamicSearch/DynamicSearch"
import { FormService } from "../../services/api/formService"
import { useEffect, useState } from "react"
const Docentes=()=>{
    const api= new FormService();
    const [campos,setCampos]=useState(null);
    const urlDocentes=`docente`;
    const Navigate= useNavigate();

    const[endpoint,setEndpoint]=useState(urlDocentes);

    useEffect(()=>{

        if(campos==null){
          fetchCamposFormForFilter();
        }
    })

    const verDocente=(e)=>{
        Navigate("docente",{state:{docente:e}})
    }

    const fetchCamposFormForFilter = ()=>{   
        const api = new FormService()
          api.getAll(`xcampos?id_formulario=60`)
        .then((res)=>{
            setCampos(res)
        })
      }

      const buscarCursos=(e)=>{
        console.log(e)
        const nuevoEnpoint=`${urlDocentes}${e}`
        setEndpoint(nuevoEnpoint)
        console.log(endpoint)
      }

    return(
        <>            
            <Card>
                <Title>
                    Docentes
                </Title>
            </Card>
            {campos&&
            <DynamicSearch  onSearch={buscarCursos} camposForm={campos} ></DynamicSearch>
            }
            <Card>
                <DynamicTable
                    exportar={`${BASE_URL}/exportar/docentes`}
                    titleColumn={["N°","Nombre","Número de Identificación","Acciones"]}
                    attributes={["Nombre","NumeroDocumento"]}
                    endPoint={endpoint}
                    labelOptionClick={'Ver más'}
                    handleOptionClick={(e)=>verDocente(e)}
                    onDelete={false}
                    onUpdate={false}
                />
            </Card>
            
        </>
    )
}

export {Docentes}