import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Card, Col, Modal, Row, Table } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import user from '../../../../assets/img/avatar.jpg'
import { InputFile } from '../../../../components/inputFile/inputFile'
import { alerta } from '../../../../helpers/alertas'
import { BASE_SERVIDOR } from '../../../../services/api/config'
import { FormService } from '../../../../services/api/formService'
function Notas({ canEdit }) {
    const { state: { estudiantes, evento, asignatura, programa, IdUsuario }, } = useLocation()
    const api = new FormService()
    const [estudiantesAsistencia, setEstudiantesAsistencia] = useState( estudiantes || [])
    const [calificado,setCalificado]=useState(false);
    const [modalActaNotas,setModalActaNotas]=useState(false)
    const [ActaNotas,setActaNotas]=useState();
    const [documentoActaNotas,setDocumentoActaNotas]=useState(null);

    const onUpdateAsistenciaEstudiante = (value, name, estudiante) => {
        let calificacion=0;
        if(asignatura.IdTipoCalificacion=="1"){
            calificacion=100
        }else{
            calificacion=5
        }

        if (parseFloat(value) > calificacion || parseFloat(value) < 0) return
        if(calificacion==5){
            if (value.toString().split('.').length > 1) {
                if (value.toString().split('.')[1].length > 1) return
            }
        }


        let updateEstudiante = { ...estudiante, Nota: value }

        const newEstudianteList = estudiantesAsistencia.map((es) => {
            if (es.IdEstudianteNota === estudiante.IdEstudianteNota)
                return updateEstudiante
            return es
        })
        setEstudiantesAsistencia([...newEstudianteList])
    }


    useEffect(() => {
        console.log(evento)

        if(estudiantes[0]?.FechaCreacion==estudiantes[0]?.FechaActualizacion){
            setCalificado(true)
        }else{
            setCalificado(false)
        }
        consultarActaNotas();
    }, [estudiantesAsistencia])

    const onSave = async() => {
        const data = estudiantesAsistencia.map((item) => {
            return {
                Nota: item.Nota,
                IdEstudianteNota: item.IdEstudianteNota,
            }
        })

       const respuesta= await api.create('docente/guardarNotas', { notas: data,IdDocente:IdUsuario })

       if(respuesta.respuesta=="exito"){
        alerta(respuesta.mensaje)
       }else{
        alerta("Fallo desconocido")
       }
    }
       // guardar acta de matricula
       const guardarActaNotas=async()=>{
        if(ActaNotas==null){
          alerta("Selecciona un archivo");
          return
        }
        const data={
          "ActaNotas":ActaNotas,
          "IdNotaAsignatura":evento.IdNotaAsignatura
        }
  
        const respuesta=await api.createWithFormData("notasAsignatura/guardarActaNotas",data)
          if(respuesta.respuesta=="exito"){
              setModalActaNotas()
              consultarActaNotas();
              alerta(respuesta.mensaje)
          }else{
              alerta(respuesta.mensaje)
          }
      }
      // consultar acta de matricula
      const consultarActaNotas=async()=>{
        const respuesta=await api.getAll(`notasAsignatura/documentoActaNotas?IdNotaAsignatura=${evento.IdNotaAsignatura}`)
          setDocumentoActaNotas(respuesta)
      }

    return (
        <>
            <div className="ml-2 nav-container">
                <Card>
                    <Row>
                        <Col>
                            <Card.Title>{programa.Nombre}</Card.Title>
                            <p>{asignatura.Descripcion} / {evento.Nombre}</p>
                        </Col>
                        <Col>
                            {/* boton modal acta de notas */}
                            {/* <Button
                                onClick={() => setModalActaNotas(true)}
                                className="btn btn-primary"
                                style={{ float: 'right' }}
                            >
                                Acta de notas
                            </Button> */}

                        </Col>
                    </Row>
                    
                </Card>

                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <b>
                                        <h5 style={{ marginLeft: '9px' }}>
                                            Estudiante
                                        </h5>
                                    </b>
                                </th>
                                <th>
                                    <b>
                                        <h5>Nota</h5>{' '}
                                    </b>{' '}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {!!estudiantesAsistencia &&
                                estudiantesAsistencia.map(
                                    (estudiante, index) => (
                                        <tr key={index} className="row-student">
                                            <td>
                                                <div className="content-student">
                                                    <img
                                                        src={estudiante?.Foto ||user}
                                                        alt={`foto estudiante ${estudiante.PrimerNombres} ${estudiante.PrimerApellidos}`}
                                                        className="applicant-list__img-user student"
                                                    />
                                                    <span>
                                                        {
                                                            estudiante.PrimerApellidos
                                                        }
                                                        {' '}
                                                        {
                                                            estudiante.SegundoApellidos
                                                        }
                                                        {' '}
                                                        {
                                                            estudiante.PrimerNombres
                                                        }
                                                        {' '}
                                                        {
                                                            estudiante.SegundoNombre
                                                        }
                                                    </span>
                                                </div>
                                            </td>
                                            <div style={{ width: '100px' }}>
                                                <div className="form-group m-1">
                                                    {/* {canEdit && calificado ? ( */}
                                                        <input
                                                        // pemitir solo un deciminal

                                                            value={ estudiante.Nota || '' }
                                                            onChange={(e) =>
                                                                onUpdateAsistenciaEstudiante(
                                                                    e.target
                                                                        .value,
                                                                    e.target
                                                                        .name,
                                                                    estudiante
                                                                )
                                                            }
                                                            onDoubleClick={(
                                                                e
                                                            ) =>
                                                                onUpdateAsistenciaEstudiante(
                                                                    '',
                                                                    e.target
                                                                        .name,
                                                                    estudiante
                                                                )
                                                            }
                                                            // onBlur={(e)=>onUpdateAsistenciaEstudiante('',e.target.name,estudiante)}
                                                            className="form-control "
                                                            type="number"
                                                            min="0"
                                                            max="100"
                                                            step="1"
                                                        ></input>
                                                    {/* ) : (
                                                        <div className=" ">
                                                            {estudiante.Nota || ''}
                                                        </div>
                                                    )} */}
                                                </div>
                                            </div>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </Table>
                {/* {canEdit && calificado && ( */}
                    <Button
                        type={'send'}
                        onClick={() => {
                            onSave()
                        }}
                    >
                        Guardar
                    </Button>
                {/* )} */}
            </div>
            {/* modal para cargar el acta de notas */}
            <Modal
                show={modalActaNotas}
                onHide={() => setModalActaNotas(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title>
                Acta de Notas
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {documentoActaNotas?
                <>
                    <p><b>Acta de Notas Cargada</b></p>
                    <a target="_blank" href={`${BASE_SERVIDOR}/pdf/${documentoActaNotas}`}>Ver Acta</a>
                </>
                :
                <>
                    <div>Adjunta el Acta de Notas</div>
                    <InputFile
                        label={"Adjuntar Acta de Matricula"}
                        onChange={(e)=>setActaNotas(e[0])}
                    />
                </>
                
                }
                </Modal.Body>
                <Modal.Footer>

                    <Button 
                    onClick={()=>setModalActaNotas()}
                    variant="secondary"
                    >
                    Cancelar
                    </Button>
                {!documentoActaNotas&&
                    <Button
                    onClick={()=>guardarActaNotas()}
                    >
                    Guardar
                    </Button>
                }
                
                </Modal.Footer>
            </Modal>

        </>
    )
}

export { Notas }
