import React from 'react'

import { Button } from '../button/button'
import { Checkbox } from '../componentsForm/checkbox/checkbox'

import avatar from '../../assets/img/avatar.jpg'
import award from '../../assets/icon/award.png'
import './Badge.css'
import { BASE_SERVIDOR, BASE_URL_REPOSITORIO } from '../../services/api/config'

const Badge = ({
    to,
    state,
    img,
    alt,
    user,
    onClick,
    isButton,
    isGraduate,
    tag,
    value,
    isCheck,
    handleChangeCheckbox,
}) => {
    return (
        <div className='badge'>
            {
                isCheck && (
                    <Checkbox
                        name={ tag }
                        checked={ value }
                        onChange={ handleChangeCheckbox }
                    />
                )
            }
            <Button
                className='badge__container'
                isLink={ !isButton }
                to={ to }
                state={ state }
                onClick={ onClick }
            >
                <figure className='badge-list__img-container'>
                    <img 
                        src={img?BASE_SERVIDOR+'/imgUser/'+img:avatar}
                        alt={ alt } 
                        className='badge-list__avatar'
                    />
                    {
                        isGraduate == '1' && (
                            <img
                                src={ award }
                                alt='award'
                                className='badge-list__award'
                            />
                        )
                    }
                </figure>
                <section>
                    {
                        isGraduate == '1' && (
                            <p
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    fontSize: '0.8rem',
                                    color: '#9eabaa',
                                    margin: '0 0 0.2rem',
                                }}
                            >
                                #Graduado
                            </p>
                        )
                    }
                    <h5 className='badge-list__username'>
                        {`${user.PrimerApellidos  || ''} ${user.SegundoApellidos||''} ${user.PrimerNombres || ''} ${user.SegundoNombre || ''}` }
                    </h5>
                    <h6 className='badge-list__id'>
                        { user.NumeroDocumento }
                    </h6>
                </section>
            </Button>
        </div>
    )
}

export { Badge }