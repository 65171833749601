import React, { useState,useEffect } from 'react'
import { Card, Col, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { DynamicTable } from '../../../components/DynamicForm/dynamicTable/DynamicTable'
import { FormService } from '../../../services/api/formService'
import { alertaSinBoton } from '../../../helpers/alertas'
import {BASE_URL} from "../../../services/api/config"
import FormatoCursoContinuada from '../Formatos/FormatoCursoContinuada'
import FormatosCursoIES from '../Formatos/FormatosCursoIES'
import FormatosMen from '../Formatos/FormatosMen'

function AsignaturasCurso({
    editable,
    gestionable=true,
    full=true,
}) {
    const api= new FormService();
    const location = useLocation()
    const navigate = useNavigate()
    // obtener dimnesion del localStorage
    const dimension = localStorage.getItem('dimension')


    const urlAsignaturas = `cursos/AsignaturasCursoRegistro?IdOferta=${location.state.programa.IdOferta}&IdUsuario=${location.state.idUsuario}`
    const urlEstudiantesIES=`docente/estudiantesIes?IdOferta=${location.state.programa.IdOferta}`
    const urlEstudiantes=`docente/estudiantes?IdOferta=${location.state.programa.IdOferta}`
    const [isOpenModalConvertToCourse, setIsOpenModalConvertToCourse] = useState(false)
    const [isOpenModalBusiness, setIsOpenModalBusiness] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [estudiantes,setEstudiantes]=useState([]);
   
    const IdOferta=location.state.programa.IdOferta
    const URL_EXPORT = `${BASE_URL}/exportar/`
    const [fasesLista, setFasesLista] = useState([]);
    const oferta=location.state.programa

    const onHandleClickToCourse = () => {
        setIsOpenModalConvertToCourse(!isOpenModalConvertToCourse)
    }

    useEffect(()=>{
        const fetData=async()=>{
            if(estudiantes.length==0){
                getEstudiantes() 
            }
            if(oferta.IdTipoMecanismo=="4" || oferta.IdTipoMecanismo=="7" || oferta.IdTipoMecanismo=="9"){
                fases(oferta.IdConvocatoria)
            }
        }
       fetData();
    },[])

    const onNavigate = (asignatura) => {
        navigate(`${asignatura.IdMalla}/asignatura_estudiantes`, {
            state: {
                programa: location.state.programa,
                asignatura,
                IdUsuario: location.state.idUsuario,
            },
        }) //to asignaturaEstudiantes.jsx
    }
    const onNavigate2 = (asignatura) => {
        navigate(`${asignatura.IdMalla}/momentos_evaluativos`, {
            state: {
                programa: location.state.programa,
                asignatura,
                IdUsuario: location.state.idUsuario,
            },
        }) //to asignaturaEstudiantes.jsx
    }
    const onNavigate3 = (asignatura) => {
        navigate(`${asignatura.IdMalla}/unidades_asignatura`, {
            state: {
                programa: location.state.programa,
                asignatura,
                IdUsuario: location.state.idUsuario,
            },
        }) //to asignaturaEstudiantes.jsx
    }

    const handleChangeToCourse = async () => {
        setError(null)
        try {
            const formService = new FormService()
            const data = {
                IdEstadoOferta: 3
            }
            setLoading(true)
            await formService.update('ofertas', data, location.state.programa.IdOferta)
            setIsOpenModalConvertToCourse(false)
            navigate(-1)
            alertaSinBoton('Curso establecido', 'success', 'bottom-end', 2000)
            setLoading(false)
        } catch (err) {
            setLoading(false)
            setError(err)
        }
    }
    const getEstudiantes=async()=>{
        const getEstudiantes= await api.getAll(dimension=='"true"'?urlEstudiantesIES:urlEstudiantes)
        setEstudiantes(getEstudiantes);
    }
    const handleOptionClick = (item) => {
        navigate(`${item.IdEstudiante}/detalle_programa`,{state:{estudiante:item,programa:location.state.programa}}) //go to Notas.jsx
    }

    const fases = async (IdConvocatoria) => {
        console.log(IdConvocatoria)
        try {
            const formService = new FormService()
            const data = await formService.getAll(`convocatoriaFase/fasesLista?IdConvocatoria=${IdConvocatoria}`)
            console.log(data)
            setFasesLista(data)
        } catch (err) {
            setError(err)
        }
    }

    return (
        <>
            <Card>
            <Row>
                <Col sm="8">
                    <Card.Title>{location.state.programa.Nombre}</Card.Title>
                </Col>
                <Col style={{textAlign:'right'}}>
                 {editable && 
                    <Button 
                            type='outline-primary'
                            onClick={ onHandleClickToCourse }
                            className='offer-header__button'
                        >
                            {location.state.programa.IdDimension==1?
                                'Finalizar Período'
                                :
                                'Finalizar Curso'

                            }
                        </Button>                                           
                }
                {/* Enlace para conusltar pruebas saber */}
                {/* {location.state.programa.IdDimension==1&&
                    <Button 
                        style={{marginTop:'5px'}}
                        href="https://www2.icfesinteractivo.gov.co/certificadoSaberPro/"
                        target='_blank'
                    >
                        Consultar Pruebas de Estado
                    </Button>
                } */}
                </Col>
            </Row>
                
                 
            </Card>            
            <Tabs
                defaultActiveKey="estudiantes"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                    <Tab eventKey="estudiantes"  title="Estudiantes">
                        {location.state.programa.IdDimension==1&&
                             <Table striped bordered hover>
                             <thead>
                                 <tr>
                                 <th>N°</th>
                                 <th>Semestre</th>
                                 <th>Nombre</th>
                                 <th>Documento</th>
                                 <th>Email</th> 
                                 <th>Acción</th>
                                 </tr>
                             </thead>
                             <tbody>
                                     {estudiantes.map((element,index)=>{
                                         return(
                                         <tr key={index}
                                             className={
                                                 (element.EstadoMatricula==1||element.EstadoMatricula==2)&&
                                                 'estudiante_inactivo'}
                                         >
     
                                             <td>{index+1}</td>
                                             <td>{element.Semestre}</td>
                                             <td>{element.Nombre}</td>
                                             <td>{element.NumeroDocumento}</td>
                                             <td>{element.email}</td>
                                             <td><Button onClick={()=>handleOptionClick(element)}>Ver más</Button></td>
                                         </tr>
                                         )
                                     })}
                                 </tbody>
                             </Table>
                        }
                        {location.state.programa.IdDimension!=1&&
                            <Table striped bordered hover>
                            <thead>
                                <tr>
                                <th>N°</th>
                                <th>Nombre</th>
                                <th>Documento</th>
                                <th>Email</th> 
                                <th>Acción</th>
                                </tr>
                            </thead>
                            <tbody>
                                    {estudiantes.map((element,index)=>{
                                        return(
                                        <tr key={index}
                                            className={
                                                (element.EstadoMatricula==1||element.EstadoMatricula==2)&&
                                                'estudiante_inactivo'}
                                        >
    
                                            <td>{index+1}</td>
                                            <td>{element.Nombre}</td>
                                            <td>{element.NumeroDocumento}</td>
                                            <td>{element.email}</td>
                                            <td><Button onClick={()=>handleOptionClick(element)}>Ver más</Button></td>
                                        </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        }
                    
                    </Tab>
                <Tab eventKey="asignaturas" title="Asignaturas">
                    {gestionable?

                        <DynamicTable
                            topHeadTitle={location.state.programa.Nombre}
                            titleColumn={['N°',  'Descripción', location.state.programa.Migrado!=1?'Acción':false]}
                            attributes={[ 'Descripcion']}
                            endPoint={urlAsignaturas}
                            onDelete={false}
                            onUpdate={false}
                            onActions={location.state.programa.Migrado!=1?true:false}
                            labelOptionClick={location.state.programa.IdDimension == 1 ? `Plan de Trabajo` : `Plan de Asignatura`}
                            handleOptionClick={(e)=>{
                                navigate(`${e.IdMalla}/plan_trabajo`, {
                                    state: {
                                        curso: location.state.programa,
                                        asignatura: e,
                                        IdUsuario: location.state.idUsuario,
                                    },
                                })
                            }}
                            // labelOptionClick2={`Momentos evaluativos`}
                            // handleOptionClick2={onNavigate2}
                            labelOptionClick3={`Unidades de asignatura`}
                            handleOptionClick3={onNavigate3}
                            classNameOptionIcon={`bi bi-view-list`}
                        ></DynamicTable>
                    :
                    <DynamicTable
                        topHeadTitle={location.state.programa.Nombre}
                        titleColumn={['N°',  'Descripción', 'Acción']}
                        attributes={[ 'Descripcion']}
                        endPoint={urlAsignaturas}
                        onDelete={false}
                        onUpdate={false}
                        classNameOptionIcon={`bi bi-view-list`}
                    ></DynamicTable>
                }
                    
                </Tab>
                <Tab eventKey="exportar"  title="Exportar">
                    {oferta.IdDimension==1?
                        <FormatosCursoIES
                            IdOferta={IdOferta}
                            oferta={oferta}
                            fasesLista={fasesLista}
                            full={full}
                        />
                        :
                        <FormatoCursoContinuada
                            IdOferta={IdOferta}
                            oferta={oferta}
                            fasesLista={fasesLista}
                        />
                    }
                    
                </Tab>
            </Tabs>

            <Modal 
                show={ isOpenModalConvertToCourse }
                onHide={ onHandleClickToCourse }
            >
                <Modal.Body>
                    <h4>
                    ¿Estás seguro de finalizar este curso?
                    </h4>
                </Modal.Body>                    
                    <Modal.Footer>
                        <Button 
                            variant='secondary'
                            onClick={ onHandleClickToCourse }
                        >
                            Cancelar
                        </Button>
                        <Button 
                            onClick={ handleChangeToCourse }
                            loading={ loading }
                        >
                            Finalizar
                        </Button>
                    </Modal.Footer>
            </Modal>          
        </>
    )
}

export { AsignaturasCurso }
