import { useLocation, useNavigate } from "react-router-dom"
import { ICON } from "../../../../constants/icons"
import { SubTitle } from "../../../../shared/components/subtitle/SubTitle"
import { Title } from "../../../../shared/components/title/Title"
import Table from 'react-bootstrap/Table';
import { useState } from "react"
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm"
import { ID_FORM } from "../../../../constants/idForm"
import { FormService } from "../../../../services/api/formService"
import { useEffect } from "react"
import { alerta } from "../../../../helpers/alertas"
import { Input } from "../../../../components/input/input"
import { Button, Card, Col, Modal, Row } from "react-bootstrap";

function ListadoEvaluacionesDocente () {

  const {state:{docente}} = useLocation()
  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)

  const [isOpenModalEvaluacionDelete,setIsOpenModalEvaluacionDelete] = useState(false)
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)
  const [ evaluaciones,setEvaluaciones] = useState([])
  const [ itemSelected,setItemSelected] = useState({})
  const [ dateRangeEvaluacion,setDateRangeEvaluacion] = useState({})
  const [ id_formulario,setIdFormulario] = useState(null)
  const datos={
    "IdUsuario":docente.IdUsuario,
    "evaluadores":1,
    "IdTipoEvaluacion":6,
    "IdForm":82

  }
  const datos2={
    "IdUsuario":docente.IdUsuario,
    "Reporte":2,
    "IdTipoEvaluacion":7,
    "IdForm":83,
    "evaluadores":3
  }
  
  const api = new FormService()
  const onModalCloseAsignarEvaluacion=(value=false)=>{
    setIsOpenModalAsignarEvaluacion(value)
  }

  useEffect(()=>{
    console.log(docente)
    fetchEvaluaciones()
  },[])

  const handleEditClick = (item) => {

    setItemSelected(item)
    setDateRangeEvaluacion({FechaInactivo:item.FechaInactivo,FechaActivo:item.FechaActivo})
    setIsOpenModalEvaluacion(true)
  }
  const handleDeleteClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacionDelete(true)
  }
  const onHandleChange =(value,name)=>{
    console.log(dateRangeEvaluacion)
    setDateRangeEvaluacion({...dateRangeEvaluacion,[name]:value})
  }
  const fetchEvaluaciones = async()=>{
    api.getAll(`gestionInstrumentos/evaluacionesDocentes?IdUsuario=${docente.IdUsuario}`)
    .then(res=>{
      setEvaluaciones(res)
    })
  }
  
  const onUpdateDate =async ()=>{
    
    try {
      const res = await api.update(`gestionInstrumentos`,dateRangeEvaluacion,itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacion(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }
  const onDeleteEvaluacion =async ()=>{
    try {
      const res = await api.update(`gestionInstrumentos`,{Estado:0},itemSelected.IdGestionInstrumento)
      console.log(res)
      setIsOpenModalEvaluacionDelete(false)
      fetchEvaluaciones()
    } catch (error) {
      alerta('error')
    }
  }

  const actulizarEvaluaciones=()=>{
    setTimeout(() => {
      onModalCloseAsignarEvaluacion()
      fetchEvaluaciones()
    }, 2000);
  }
  
  return (
    <>
    <Card>
      <Row>
        <Col>
          <Card.Title>{docente.Nombre}</Card.Title>
          <div>Evaluaciones Vinculadas</div>
        </Col>
        <Col style={{textAlign:"right"}}>
        <Button
            onClick={()=>{
              setIsOpenModalAsignarEvaluacion(true)
              setIdFormulario(130)
            }}
          >
            Heteroevaluar IES
          </Button>
          {' '}
          <Button
            onClick={()=>{
              setIsOpenModalAsignarEvaluacion(true)
              setIdFormulario(76)
            }}
          >
            Asignar Auto-Evaluación
          </Button>
        </Col>
      </Row>
    </Card>
<Card>
<Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Nombre del Instrumento</th>
      <th>Evalua</th>
      <th>Plazo (A/M/D)</th>
      <th>Realizado</th>
      <th>Cambiar Fecha</th>
      <th>Eliminar</th>
    </tr>
  </thead>
  <tbody>
    {!!evaluaciones && evaluaciones.map((item,index)=>{
      return(
        <tr key={index} >
          <td>{index+1}</td>
          <td>{item.Formulario}</td>
          <td>{item.Nombre}</td>
          <td> {item.FechaActivo} / {item.FechaInactivo}</td>
          <td>{item.EstadoInstrumento=='1'?` ${ICON.CHECKED}` :` ${ICON.UNCHECKED}`}</td>
          <td onClick={()=>handleEditClick(item)}> <i className="bi bi-pencil"></i></td>
          <td onClick={()=>handleDeleteClick({...item,index:(index+1)})} ><i className="bi bi-trash3"></i></td>
        
        </tr>
      )
    }

    )}
  </tbody>
</Table>
</Card>

  <Modal
    show={isOpenModalAsignarEvaluacion}
    onHide={onModalCloseAsignarEvaluacion}
  >
    <DynamicForm
      title={"Asignar Evaluación"}
      endPointSubmit={'gestionInstrumentos/asignarEvaluacionDocente'}
      isCreate={true}
      extraData={id_formulario===76?datos:datos2}
      ventanaFormName={'Gestión instrumentos'}
      justDynamicForm={{ flag:true, idForm:id_formulario }}
      onClickCancelBtn={onModalCloseAsignarEvaluacion}
      accionMysql={actulizarEvaluaciones}
    ></DynamicForm>
</Modal>

  <Modal
    show={isOpenModalEvaluacion}
    onHide={()=>setIsOpenModalEvaluacion(false)}
  >
      <Modal.Header closeButton>
        <Modal.Title>
          Actulizar Fecha de Presentación
        </Modal.Title>
      </Modal.Header>
    <Modal.Body>
      <h3> Plazo para Presentar</h3>
      <label>Fecha Inicial</label>
        <Input
          name={'FechaActivo'}
          onChange={onHandleChange}
          value={dateRangeEvaluacion.FechaActivo || '' }
          type={'date'}
        ></Input>
      <br></br>
      <label>Fecha LÍmite</label>
        <Input
          onChange={onHandleChange}
          name={'FechaInactivo'}
          value={dateRangeEvaluacion.FechaInactivo ||  '' }
          type={'date'}
        ></Input>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={()=>setIsOpenModalEvaluacion(false)}
        >
          Cancelar
        </Button>
        <Button 
        onClick={onUpdateDate}
        >
          Actualizar
        </Button>
      </Modal.Footer>
  </Modal>

  <Modal
    show={isOpenModalEvaluacionDelete}
    onHide={()=>setIsOpenModalEvaluacionDelete(false)}
    >
    <Modal.Body>
      ¿ Estas Seguro que Desea Eliminar Esta Evaluación?
    </Modal.Body>

    <Modal.Footer>
      <Button
      variant="secondary"
        onClick={()=>setIsOpenModalEvaluacionDelete(false)}
        >
          Cancelar
        </Button>
        <Button
          variant="danger"
          onClick={onDeleteEvaluacion}
        >
          Eliminar
        </Button>
      </Modal.Footer>
  </Modal>
    </>
  )
}

export { ListadoEvaluacionesDocente }