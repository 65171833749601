import { useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { Badge } from "../../../../components/Badge/Badge";
import { DynamicForm } from "../../../../components/DynamicForm/DynamicForm";
import { useFetchGet } from "../../../../hooks/useFetchGet";

const Estudiantes=()=>{
    const {state:{IdOferta,IdMalla,asignatura,IdDimension,programa,docentes}}=useLocation()
    const [estudiantes,isLoading,error] = useFetchGet(`curso/estudiantesAsignatura?IdOferta=${IdOferta}&IdMalla=${IdMalla}`)
    const [modal,setModal] = useState(false)

    const cerrarMoldal=()=>{
        setTimeout(() => { setModal(false) }, 3000);
    }
    
    return(
        <>
        <Card>
            <Row>
                <Col sm="8">
                    <Card.Title>{programa.Nombre}</Card.Title>
                    <div>{asignatura}</div>
                </Col>
                <Col style={{textAlign:"right"}}>
                    <Button 
                        onClick={()=>setModal(true)}
                    >
                        Asignar Evaluación
                    </Button>
                </Col>
            </Row>
            <Row>
                {/* mostrar docentes */}
                {docentes.map((docente,index)=>
                    <Col key={index} sm="6">
                        <div>{docente.Nombre}</div>
                        {/* <div>{docente.Correo}</div> */}
                    </Col>
                )}
            </Row>
        </Card>

        {
        Array.isArray(estudiantes) && 
            <div className='students-list__list-container'>
                {estudiantes.map((student, index) => 
                        <Badge 
                            to={ 
                                `estudiante/${student?.IdEstudiante}` 
                            }
                            state={{ estudiante:student,programa:programa }}
                            img={ student?.Foto }
                            alt='Foto de perfil de estudiante'
                            user={ student }
                            key={ index }
                        />
                    )
                }
            </div>
        }

    <Modal
      show={modal}
      onHide={()=>setModal(false)}
    >
      <DynamicForm
        title={`Asignar Evaluaciones`}
        endPointSubmit={'gestionInstrumentos/asignarEvaluacion360'}
        isCreate={true}
        extraData={{
            IdOferta:IdOferta,
            IdMalla:IdMalla,
            IdTipoEvaluacion:4
        }}
        ventanaFormName={'Gestión instrumentos'}
        justDynamicForm={{ flag:true, idForm:120}}
        onClickCancelBtn={()=>setModal()}
        accionMysql={()=>cerrarMoldal()}
      ></DynamicForm>
    </Modal>
        </>
    )
}


export {Estudiantes};