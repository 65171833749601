import React, { useState } from 'react'

import { Select } from '../../select/Select'
import { Input } from '../../input/input'
import { FormService } from '../../../services/api/formService'
import { alertaSinBoton } from '../../../helpers/alertas'
import { SelectDataList } from '../../SelectDataList/SelectDataList'
import { Button, Modal } from 'react-bootstrap'
import { useEffect } from 'react'
import { Checkbox } from '../../componentsForm/checkbox/checkbox'
import { InputFile } from '../../inputFile/inputFile'
import { LocalStorage } from '../../../services'

const AperturaForm = props => {

    const {
        setData,
        loading,
        close,
        isCreate,
        selectList,
        loadDataTable,
        handleClickAdd,
        data=[],
    } = props


    const { ofertas, convenios, convocatorias } = selectList

    const [renderConvenio,setRenderConvenio] = useState(false)
    const [renderConvocatoria,setRenderConvocatoria] = useState(false)
    const [formData,setFormData] = useState({...data});
    const [itemOferta,setItemOferta]=useState();
    const [busqueda, setBusqueda] = useState()
    const storage=new LocalStorage()
    const dimension=storage.getItem('dimension')


    const handleSubmit = async (e) => {
        e.preventDefault()
        const api = new FormService()
        try {
            delete formData["Nombre"]
            delete formData["Docente"]
            delete formData["Mecanismo"]
            delete formData["IdDimension"]
            delete formData["DocumentoCalendario"]
            delete formData["DocumentoGuia"]
            const x = await api.updateWithFormData('ofertas',formData,formData?.IdOferta)
            if(isCreate){
                handleClickAdd()
            }else{
                close();
            }
            
            alertaSinBoton('Completado','success','bottom-end','1500')
            loadDataTable()
        } catch (error) {
            
        }
    }
    const handleChange =(value,name)=>{
        setFormData({...formData,[name]:value})
        console.log(formData) 
    }
    useEffect(()=>{
        console.log(selectList)
        if(!isCreate){
            onLocalHandleChange(data?.IdOferta,"IdOferta")
        }
    },[])

    const onLocalHandleChange=(value,name)=>{
        handleChange(value,name)
    }

    return (
       <>
        {console.log(data)}
       <form onSubmit={ handleSubmit } >
            <Modal.Body>
                {isCreate&&
                    <Select
                    name="IdOferta"
                    onChange={ onLocalHandleChange  }
                    value={ formData?.IdOferta || '' }
                    label={'Programa'}
                    optionValue='IdOferta'
                    optionName='Nombre'
                    urlOptionsService={'ofertas/disponibles'}
                />
                }
                
                <Select
                    name="IdConvocatoria"
                    onChange={ handleChange }
                    value={ formData?.IdConvocatoria || '' }
                    label={dimension=='"true"'?'Etapas':'Convocatoria'}
                    optionValue='IdConvocatoria'
                    optionName='Nombre'
                    options={ convocatorias }
                />
                <SelectDataList
                    // input={input}
                    label={
                        dimension=='"true"'?'Coordinador del Programa'
                        :'Coordinador de la Actividad'
                    }
                    optionValue={"IdUsuario"}
                    optionName={"Nombre"}
                    onChangeHandler={handleChange}
                    name={"IdUsuario"}
                    endPoinLastUrl={"docente/full"}
                    value={formData.Docente}
                    valorActual={formData.IdUsuario}
                /> 
                
                <Select
                    name="IdEstadoOferta"
                    onChange={ handleChange }
                    value={ formData?.IdEstadoOferta || '' }
                    label="Estado Oferta"
                    optionValue='IdEstadoOferta'
                    optionName='nombre'
                    secondOptionName='PrimerApellidos'
                    options={ [
                    {IdEstadoOferta:'1',nombre:'Activo'},
                    {IdEstadoOferta:'2',nombre:'En Curso'},
                    {IdEstadoOferta:'3',nombre:'Notas Digitadas'},
                    {IdEstadoOferta:'4',nombre:'Cerrado'},
                    {IdEstadoOferta:'5',nombre:'Cancelado'},
                ] }
                    />
                <Input
                    label="Fecha Apertura Inscripciones"
                    type="date"
                    onChange={ handleChange }
                    name='FechaInicio'
                    value={ formData?.FechaInicio || '' }
                />
                <Input
                    label="Fecha Cierre Inscripciones"
                    type="date"
                    onChange={ handleChange }
                    name='FechaFin'
                    value={ formData?.FechaFin || '' }
                />
                <Input
                    label="Número de la Cohorte"
                    type="text"
                    onChange={ handleChange }
                    name='codigo_grupo'
                    value={ formData?.codigo_grupo || '' }
                />
                {itemOferta?.IdDimension==1&&
                    <Checkbox
                        onChange={ handleChange }
                        checked={formData.GestionEmpresas==1&&true}
                        name='GestionEmpresas'
                        value={formData.GestionEmpresas}
                    >Gestionable por Empresas</Checkbox>
                }
                
                <Checkbox
                    onChange={ handleChange }
                    checked={formData.VisiblePortafolio=="true"&&true||formData.VisiblePortafolio==true}
                    // value={formData.VisiblePortafolio}
                    name='VisiblePortafolio'
                >Visible en el Portafolio</Checkbox>
                    <>
                    <Select
                        name='Periodicidad'
                        value={formData.Periodicidad} 
                        onChange={ handleChange }
                        label="Periodicidad de la Admisión"
                        options={[{"Id":"Semestral","Nombre":"Semestral"},{"Id":"Intersemestral","Nombre":"Intersemestral"}]}
                        optionName="Nombre"
                        optionValue="Id"
                    />
                    
                    <InputFile
                        archivoActual={formData?.DocumentoCalendario}
                        label="Calendario"
                        name="Calendario"
                        completo={true}
                        required={formData.DocumentoCalendario==null?true:false}
                        onChange={ handleChange }
                        value={ formData?.Calendario || '' }
                    />
                    {/* input para cargar Guia */}
                    <InputFile
                        archivoActual={formData?.DocumentoGuia}
                        label="Guía"
                        name="Guia"
                        completo={true}
                        required={formData.DocumentoGuia==null?true:false}
                        onChange={ handleChange }
                        value={ formData?.Guia || '' }
                    />
                    {/* input para correo */}
                    <Input
                        label="Correo para más Información"
                        type="text"
                        onChange={ handleChange }
                        name='CorreoInformacion'
                        value={ formData?.CorreoInformacion || '' }
                    />
                    {/* input para lugar de realizacion */}
                    <Input
                        label="Lugar de Realización"
                        type="text"
                        onChange={ handleChange }
                        name='LugarRealizacion'
                        value={ formData?.LugarRealizacion || '' }
                    />
                    </>
                    <Input
                        label="Créditos"
                        type="text"
                        onChange={ handleChange }
                        name='Creditos'
                        value={ formData?.Creditos || '' }
                    />          
            </Modal.Body>
            <Modal.Footer>
                    <Button
                    variant="secondary"
                    onClick={()=>{
                        isCreate?handleClickAdd():close()
                    }}>
                        Cancelar
                    </Button>
                    <Button 
                        type='send'
                        loading={ loading }
                    >
                        { isCreate ? 'Aperturar' : 'Actualizar Apertura' }
                    </Button>
            </Modal.Footer>
        </form>
    </>
    )
}

export { AperturaForm }