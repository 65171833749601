import React from 'react'
import { BASE_URL } from '../../../services/api/config'
import { ListGroup, Modal } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile'

export default function ModalFormatosAdmision({
    modalDescargar,
    setModalDescargar,
    IdOferta,
    fasesLista,
    oferta,
}) {

  return (
    <Modal 
    show={modalDescargar} 
    onHide={()=>setModalDescargar(false)}
>
    <Modal.Header closeButton>
        <Modal.Title>Exportables y Formatos</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        <ul className="list-group">

            <li className="list-group-item d-flex justify-content-between align-items-center">
                <DowloadFile
                    nameFile={"Listado de Estado Aspirantes.xlsx"}
                    name={"Listado de Estado Aspirantes"}
                    endPoint={`${BASE_URL}/exportar/formatosIES/aspirantesExcel?IdOferta=${IdOferta}`}
                />
            </li>
            <ListGroup>
                <ListGroup.Item>
                    <DowloadFile
                        nameFile={"Listado de Preinscritos.xlsx"}
                        name={"Listado de Preinscritos"}
                        endPoint={`${BASE_URL}/formatosIES/exportar/preinscritos?IdOferta=${IdOferta}`}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                    <DowloadFile
                        nameFile={"Listado de Aspirantes.pdf"}
                        name={"Listado de Aspirantes"}
                        endPoint={`${BASE_URL}/formatosIES/exportar/aspirantes?IdOferta=${IdOferta}`}
                    />
                </ListGroup.Item>
                <ListGroup.Item>
                        <DowloadFile 
                        nameFile={"Inscritos.pdf"}
                        name={"Listado de Inscritos"}
                        endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesInscritos?IdOferta=${IdOferta}`}
                        />
                </ListGroup.Item>
                {oferta.IdConvocatoria&&
                    <ListGroup.Item>
                        <DowloadFile 
                        nameFile={"Admitidos a pruebas.pdf"}
                        name={"Admitidos a pruebas"}
                        endPoint={`${BASE_URL}/formatosIES/cursos/exportarAdmitidosPrueba?IdOferta=${IdOferta}`}
                        />
                    </ListGroup.Item>
                }
                <ListGroup.Item>
                        <DowloadFile 
                        nameFile={"Admitidos.pdf"}
                        name={"Listado de Admitidos"}
                        endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesAdmitidos?IdOferta=${IdOferta}`}
                        />
                </ListGroup.Item>
                <ListGroup.Item>
                        <DowloadFile 
                        nameFile={"Matriculados.pdf"}
                        name={"Reporte de Matriculados"}
                        endPoint={`${BASE_URL}/formatosIES/cursos/exportarAspirantesMatriculados?IdOferta=${IdOferta}`}
                        />
                </ListGroup.Item>
            </ListGroup>
        </ul>
        <h5 className='text-center p-2'>Exportar Admitidos por Etapas</h5>
        <ul className="list-group">
        {fasesLista.map((fase,key)=>
        <>
            {fase.ExportableHijos == "true" || fase.ExportableHijos == 1 
            ? 
                (
                    <>
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                            nameFile={`${fase.NombreExportable||fase.Nombre}.xlsx`}
                            name={`${fase.Nombre}.xlsx`}
                            endPoint={`${BASE_URL}/convocatoriaFase/exportarFasesAsociadas?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                        />
                        </ListGroup.Item>
                    </ListGroup>
                    {fase.IdFasePadre==null&&
                            <ListGroup>
                                <ListGroup.Item>
                                <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                />
                                </ListGroup.Item>
                            </ListGroup>
                        }
                    </>
                )
            :
                <>
                {fase.IdFasePadre==null&&
                    <ListGroup>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.xlsx`}
                                    name={`${fase.Nombre}.xlsx`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFaseExcel?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                        <ListGroup.Item>
                        <DowloadFile
                                    nameFile={`${fase.NombreExportable||fase.Nombre}.pdf`}
                                    name={`${fase.Nombre}.pdf`}
                                    endPoint={`${BASE_URL}/formatosIES/exportar/admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                    />
                        </ListGroup.Item>
                    </ListGroup>
                }
                </>
        }
        </>
        )}
        </ul>                
    </Modal.Body>
    </Modal>
  )
}
