import React, { useState } from 'react';

import { InputFile } from '../../inputFile/inputFile';
import { Input } from '../../input/input';
import { Message } from '../../Message/Message';
import { LoadingSpinner } from '../../LoadingSpinner/LoadingSpinner';
import { TextArea } from '../../../components/componentsForm/textarea/textarea';

import './Admission.css';
import { ReceiptUploaded } from '../../ReceiptUploaded/ReceiptUploaded';
import { Alert, Button, Modal, ModalHeader } from 'react-bootstrap';
import { FormService } from '../../../services/api/formService';
import { alerta } from '../../../helpers/alertas';
import { Form } from 'react-bootstrap';
import { Select } from '../../select/Select';
import { useAsync } from 'react-bootstrap-typeahead';
import { LocalStorage } from '../../../services';

const Admission = ({
    loadingFull,
    admissionType,
    nombreConvocatoria,
    announcement,
    approve,
    loading,
    isOpenModalDecline,
    onDecline,
    observation,
    handleChangeObservation,
    isOpenModalAccept,
    onAccept,
    handleSubmit,
    isEmpty,
    handleState,
    puntaje,
    setPuntaje,
    setFaseItem,
    isOpenModalRechazar,
    isOpenModalAprobar,
    onRechazar,
    onAprobar,
    mensajeRequisitos,
    tipoConvenio,
    tipoAlumno,
    tipoAreaPIC,
    regional,
    handleChangeMensaje,
    handleChangeConvenio,
    handleChangeTipoAlumno,
    handleChangeAreaPIC,
    handleChangeRegional,
    rechazarAdmitido,
    isCompletedAdmission,
    faseItem,
    editable,
    oferta,
    fetchDataAnnouncement,
    setTipoVinculacion,
    tipoVinculacion
}) => {
    const api=new FormService();
    const [showEditarPuntaje, setShowEditarPuntaje] = useState(false);
    const [faseAspirante,setFaseAspirante]=useState(null);
    const [convenio, setConvenio] = useState("")
   


    const storage= new LocalStorage()

    // oteber el objeto currentRole del localStorage
    const currentRole = JSON.parse(storage.getItem('currentRol'))
    const IdRol = currentRole?.IdRol;

    const actualizarPuntaje = async () => {
        const data = {
            IdConvocatoriaFase: faseItem.IdConvocatoriaFase,
            Puntaje: puntaje,
            Observaciones:observation
        };
        const IdAspiranteFase=faseAspirante?.fase.IdAspiranteFase
        const respuesta= await api.update("aspiranteFase",data,IdAspiranteFase);
        if(respuesta.respuesta=="exito"){
            setShowEditarPuntaje(false);
            fetchDataAnnouncement();
        }else{
            alerta("Error al actualizar puntaje","error");
        }
    };

    return (
        <>
        {isCompletedAdmission &&

            <div className="admission__content-container">


            {loadingFull ? (
                <LoadingSpinner />
            ) : (
                <>
                    <h5 className="admission__admission-type">
                        {admissionType == 'Convocatoria' ?
                            nombreConvocatoria
                        : 
                            admissionType
                        }
                    </h5>

                    {admissionType == 'Convocatoria' && (
                        <div className="admission__phase-container">
                            {announcement.map((item) => (
                                <div
                                    key={item.IdConvocatoriaFase}
                                    className="admission__phase"
                                >
                                    <section className="admission__phase-title-container">
                                        <h5>{item.Nombre}</h5>
                                    </section>
                                    <section className="admission__phase-content">
                                        <section className="admission__phase-info">
                                            <h5>{item.Descripcion}</h5>
                                                Presentar  entre <span>{item.FechaInicio}</span> y <span>{item.FechaFinal}</span>
                                                {item.fase != null ? 
                                                    <p className="fw-bold"> Calificada </p>
                                                : 
                                                    <p> Esperando Calificación </p>
                                                }
                                                {item.fase?.Aprobo == '1' && item.fase != null &&
                                                    <p>
                                                        <span className="fw-bold"> Estado: Aprobó</span>
                                                    </p>
                                                }
                                                {item.fase?.Aprobo == '2' && item.fase != null &&
                                                    <p>
                                                        <span className="fw-bold"> Estado: No Aprobó </span>
                                                    </p>
                                                }
                                                {item.fase?.Puntaje && (
                                                    <p className="fw-bold">
                                                        {/* no mostrar si e una fase padre */}
                                                        {item.ExportableHijos!="true"&&
                                                            <>
                                                                Puntaje:{' '}
                                                                <span className="fw-normal">
                                                                    {item.fase?.Puntaje}
                                                                </span>
                                                                <i className="fas fa-pencil-alt"></i>
                                                            </>
                                                         }

                                                        {IdRol!=43 && item.ExportableHijos!="true"&&
                                                            <>
                                                            {/* {item.EstadoFase=="1" 
                                                                ?*/}
                                                                <span
                                                                    onClick={()=>{                                                                
                                                                        setShowEditarPuntaje(true)
                                                                        setFaseAspirante(item)
                                                                    }}
                                                                    className="text-scondary"
                                                                    style={{ 
                                                                        cursor: 'pointer', 
                                                                        marginLeft: '5px',
                                                                        ':hover': { color: '#007bff' }    

                                                                    }}
                                                                >
                                                                    Editar Puntaje
                                                                </span>  
                                                                {/* :
                                                                null */}
                                                            {/* } */}
                                                            </> 
                                                        }                                                 
                                                        
                                                    </p>
                                                )}
                                                
                                                {item.fase?.Observaciones &&
                                                    <p>
                                                        <span className="fw-bold">Observaciones: </span>{item.fase ?.Observaciones}
                                                    </p>
                                                }
                                                {item.fase?.Aprobo != null && IdRol != 43 && item?.IdRespuesta == 1 &&
                                                    
                                                    <div className="d-flex">
                                                    <>
                                                        <span><b> Editar Estado </b></span>
                                                        <Button
                                                            type="danger"
                                                            onClick={() => {
                                                                onDecline(item);
                                                                setFaseItem(item);
                                                            }}
                                                        >
                                                            No Apto
                                                        </Button>
                                                        <Button
                                                            type="send"
                                                            onClick={() => {
                                                                onAccept(item);
                                                                setFaseItem(item);
                                                            }}
                                                        >
                                                            Apto
                                                        </Button>
                                                    </>
                                                    </div>
                                                }
                                                {item?.ExportableHijos=="true"&&
                                                    <p>
                                                       <span className="fw-bold">Puntaje Total: </span> {item?.PuntajeTotal}
                                                    </p>
                                                }

                                        </section>
                                        {(item.IdTipoFase == '1' && item.fase == null) && item.fase_actual==1 &&
                                                <>
                                                {item.EstadoFecha == '1' 
                                                    ?                                                
                                                    <form
                                                        onSubmit={(e) => handleSubmit(e, item) }
                                                        className="admission__phase-form"
                                                    >
                                                        <InputFile
                                                            label="Adjuntar documento"
                                                            name="Archivo"
                                                            accept=".pdf"
                                                        />
                                                        <section>
                                                            <Button type="link-primary">
                                                                Subir
                                                            </Button>
                                                        </section>
                                                    </form>
                                                    :
                                                    <Alert type="warning">
                                                        <p>
                                                            No se puede adjuntar documentos en este momento
                                                        </p>
                                                    </Alert>
                                                    }
                                                </>
                                        }
                                        {item.IdTipoFase == '1' && item.fase != null && (
                                                <div>
                                                    {item.fase.Archivo ?
                                                        <ReceiptUploaded
                                                            text="Documento adjuntado"
                                                            dateCreated={
                                                                item.fase
                                                                    .FechaCreacion
                                                            }
                                                            fileName={
                                                                item.fase.Archivo
                                                            }
                                                            
                                                        />
                                                        :
                                                        // en amarillo
                                                        <p className="text-warning">
                                                            No se ha adjuntado ningún documento
                                                        </p>
                                                    }
                                                </div>
                                        )}
                                        {(item.fase == null || item.fase.Aprobo==null || item.fase.Aprobo== "")&& item.fase_actual==1
                                         ? 
                                        item.IdRespuesta=="1" ?
                                            <div className="d-flex">
                                                {item.EstadoFecha==1
                                                ? <>
                                                    <Button
                                                        type="danger"
                                                        onClick={() => {
                                                            onDecline(item);
                                                            setFaseItem(item);
                                                        }}
                                                    >
                                                        No Apto
                                                    </Button>
                                                    <Button
                                                        type="send"
                                                        onClick={() => {
                                                            onAccept(item);
                                                            setFaseItem(item);
                                                        }}
                                                    >
                                                        Apto
                                                    </Button>
                                                </>
                                                :
                                                <Alert variant="warning">
                                                    <p>
                                                        La fecha para calificar esta fuera del rango permitdo.
                                                    </p>
                                                </Alert>
                                                }
                                            </div>                                           
                                        :
                                        <div>
                                            {item.EstadoFecha==1
                                                ? <>
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => {
                                                            onDecline(item);
                                                            setFaseItem(item);
                                                        }}
                                                    >
                                                        Rechazar
                                                    </Button>
                                                    {' '}
                                                    <Button
                                                        type="send"
                                                        onClick={() => {
                                                            onAccept(item);
                                                            setFaseItem(item);
                                                        }}
                                                    >
                                                        Aceptar
                                                    </Button>
                                                </>
                                                :
                                                <Alert variant="warning">                                                    
                                                    <p>
                                                        La fecha para calificar esta fuera del rango permitdo.
                                                    </p>
                                                </Alert>
                                            }
                                            
                                        </div>
                                        :item.fase==null?
                                        ('Aun no se ha calificado el punto anterior'):('')
                                    }
                                       
                                    </section>
                                </div>
                            ))}
                        </div>
                    )}
                    {oferta.modo_full==1&&
                    <>
                        {(isCompletedAdmission && editable) &&
                            <div style={{textAlign:"right"}} >
                                <Button variant='danger' onClick={onRechazar} loading={loading}>
                                    Rechazar inscrito
                                </Button>
                                {' '}
                                {/* <Button type="send" onClick={approve} loading={loading}> */}
                                <Button type="send" onClick={onAprobar} loading={loading}>
                                    Admitir inscrito
                                </Button>
                            </div>
                        }
                    </>
                    }
                    
                </>
            )}

            {/* modal para rechzar fase */}
            <Modal 
                // isOpen={isOpenModalDecline} 
                // close={onDecline}
                show={isOpenModalDecline}
                onHide={onDecline}
            >
                <Modal.Body>
                    <h5>
                        ¿Estás seguro de rechazar la presentación de este item?
                    </h5>
                    {faseItem.IdRespuesta=="2" &&
                        <Input
                            type="number"
                            // evitar mas de 100
                            onChange={(e) => {
                                setPuntaje(e);
                                if (e > 100) {
                                    setPuntaje(100);
                                }
                            }}
                            value={puntaje}
                            label="Puntaje"
                            required={true}
                            max={100}
                        />
                    }
                    <TextArea
                        label="Observación"
                        placeholder="Explica el motivo de rechazo"
                        name="Observacion"
                        value={observation}
                        onChange={handleChangeObservation}
                        required={true}
                    />

                    {isEmpty && (
                        <Message type="warning-white">
                            Describe el motivo de rechazo
                        </Message>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={onDecline}
                    >
                        Cancelar
                    </Button>
                        <Button
                            onClick={() => handleState(2)}
                            loading={loading}
                        >
                            Guardar
                        </Button>
                        
                    </Modal.Footer>
            </Modal>


            {/* modal para aprovar fase */}
            <Modal 
                show={isOpenModalAccept}
                onHide={onAccept}
            >
                <Modal.Body >
                    <h5>¿Estás seguro de aprobar este item?</h5>
                        {faseItem.IdRespuesta=="2"&&
                            <>
                                <Input
                                type="number"
                                onChange={(e) => {
                                    setPuntaje(e);
                                    if (e > 100) {
                                        setPuntaje(100);
                                    }
                                }}
                                value={puntaje}
                                label="Puntaje"
                                required={true}
                                max={100}
                                />
                                
                                 <TextArea
                                    label="Observaciones"
                                    placeholder="Escribe las observaciones"
                                    name="Observacion"
                                    value={observation}
                                    onChange={handleChangeObservation}
                                    required={true}
                                />                      
                            </>
                        }
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        variant="secondary" 
                        onClick={onAccept}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={() => handleState(1)}
                        loading={loading}
                    >
                        Enviar
                    </Button>
                    
                </Modal.Footer>
            </Modal>

            {/* modal para rechzar inscrito */}
            <Modal 
                // isOpen={isOpenModalRechazar}
                // close={onRechazar}
                show={isOpenModalRechazar}
                onHide={onRechazar}
            >
                <Modal.Body>
                    <h5>
                        ¿Estás seguro de rechazar este inscrito?
                    </h5>
                    <Input 
                        label='Describe el motivo'
                        placeholder='Describe el motivo de rechazo'
                        name='Observacion'
                        value={ mensajeRequisitos }
                        onChange={ handleChangeMensaje }
                    />
                    {
                        isEmpty && (
                            <Message type='warning-white'>
                                Describe el motivo de rechazo
                            </Message>
                        )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        type='send'
                        onClick={ rechazarAdmitido }
                        loading={ loading }
                    >
                        Rechazar
                    </Button>
                    <Button 
                        type='danger'
                        onClick={ onRechazar }
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
        </Modal>
        {/* Modal para aprobar Inscritos */}
            <Modal 
                show={isOpenModalAprobar}
                onHide={onAprobar}
            >
                <ModalHeader>
                    <h3>Aprobar Inscrito</h3>
                </ModalHeader>
                <Modal.Body>
                    <label htmlFor="">¿ Ingreso por Convenio ?</label>
                    <Form.Check 
                        type="switch"
                        name="Convenio"
                        id="Convenio"
                        label="Sí"
                        checked = {convenio}
                        value={convenio}
                        onChange={ (e) => {
                            setConvenio(e.target.checked)
                        }}
                    />
                    {convenio &&
                        <Select
                            id="IdTipoConvenio"
                            name="IdTipoConvenio"
                            onChange={ (e) => handleChangeConvenio(e) }
                            value={ tipoConvenio || null }
                            label="Convenio"
                            required={false}
                            optionValue="IdConvenio"
                            optionName="Descripcion"
                            urlOptionsService={`convenio/getConveniosActivos`}
                        />
                    }
                    {oferta.IdDimension==1 &&
                        <Select
                            id="IdTipoVinculacion"
                            name="IdTipoVinculacion"
                            onChange={(e) => setTipoVinculacion(e) }
                            value={ tipoVinculacion || null }
                            label="Tipo de Vinculación"
                            required={true}
                            optionValue="IdTipoVinculacion"
                            optionName="Descripcion"
                            urlOptionsService={"tipoVinculacion"}
                        />
                    }   

                    <Select
                        id="IdTipoAlumno"
                        name="IdTipoAlumno"
                        onChange={ (e) => handleChangeTipoAlumno(e) }
                        value={ tipoAlumno || null }
                        label="Tipo de Alumno"
                        required={true}
                        optionValue="IdTipoAlumno"
                        optionName="Nombre"
                        options={[
                            {"IdTipoAlumno": "1", "Nombre":"Interno"},
                            {"IdTipoAlumno": "2", "Nombre":"Externo"}
                        ]}
                    />
                    {tipoAlumno == 1 &&
                        <Select
                            id="IdTipoAlumno"
                            name="IdTipoAlumno"
                            onChange={(e) => handleChangeAreaPIC(e) }
                            value={ tipoAreaPIC || null }
                            label="Dependencia"
                            required={true}
                            optionValue="IdTipoAreaPic"
                            optionName="Nombre"
                            urlOptionsService={"tipoAreasPIC"}
                        /> 
                    }
                    
                    {tipoAlumno == 1 &&
                        <Select
                            id="IdRegional"
                            name="IdRegional"
                            onChange={(e) => handleChangeRegional(e) }
                            value={ regional || null }
                            label="Regional"
                            required={true}
                            optionValue="IdRegional"
                            optionName="NombreRegional"
                            urlOptionsService={"regionales"}
                        /> 
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button 
                        type='send'
                        onClick={ approve }
                        loading={ loading }
                    >
                        Aprobar
                    </Button>
                    <Button 
                        variant='danger'
                        onClick={ onAprobar }
                        loading={ loading }
                    >
                        Cancelar
                    </Button>
                </Modal.Footer>
        </Modal>
        {/* modal para editar puntaje */}
        <Modal
            show={showEditarPuntaje}
            onHide={setShowEditarPuntaje}
        >
            <Modal.Header closeButton>
                <Modal.Title>Editar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Input
                    type="number"
                    onChange={(e) => {
                        setPuntaje(e);
                        if (e > 100) {
                            setPuntaje(100);
                        }
                    }}
                    value={puntaje||faseAspirante?.fase.Puntaje }
                    label="Puntaje"
                    required={true}
                    max={100}
                />
                <TextArea
                    label="Observación"
                    placeholder="Explica el motivo de rechazo"
                    name="Observacion"
                    value={observation||faseAspirante?.fase.Observaciones }
                    onChange={handleChangeObservation}
                    required={true}s
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                  onClick={()=>{setShowEditarPuntaje(false)}}
                >
                    Cancelar
                </Button>
                <Button
                    onClick={()=>{
                        actualizarPuntaje()
                    }}
                >
                    Guardar
                </Button>
            </Modal.Footer>
        </Modal>
        </div>
        }
        </>
    );
};

export { Admission };
