// import React from 'react'

// import './input.css'

// const Input = ({
//     id,
//     label,
//     type, 
//     placeholder, 
//     autoComplete,
//     required = true,
//     name,
//     onChange, // return (value,name)
//     value,
//     className,
//     onBlur,
//     onPaste,
//     tabIndex,
//     disabled=false,
//     min=null,
//     max=null,
//     decimales=null
// }) => {
//     const classInput = `form-control ${className}`
//     const stepValue = decimales ? 1 / Math.pow(10, decimales) : null
//     return (
//         <div className="form-group m-1">
//             {
//                 label && (
//                     <label 
//                         htmlFor={ id }
//                         className="input__label"
//                     >
//                         { label }
//                     </label>
//                 )
//             }
//             <input
//                 id={ id }
//                 type={ type }
//                 placeholder={ placeholder }
//                 autoComplete={ autoComplete || 'off'}
//                 name={ name }
//                 onChange={ e => onChange(e.target.value, e.target.name) }
//                 onBlur={ onBlur }
//                 value={ value }
//                 required={ required ? 'required' : false }
//                 className={ classInput }
//                 disabled={disabled}
//                 onPaste={ onPaste }
//                 step={type=='number'?stepValue:null}
//                 min={min}
//                 max={max}
//                 tabIndex={tabIndex}
//                 style={
//                     type==='date'?{ borderStyle:'hidden',
//                     borderBottomStyle:'solid'}:{}
//                 }
//                 maxLength={max}
//             />
//         </div>
//     )
// }

// export { Input }

import React from 'react';
import './input.css';

const Input = ({
  id,
  label,
  type,
  placeholder,
  autoComplete,
  required = true,
  name,
  onChange,
  value,
  className,
  onBlur,
  onPaste,
  tabIndex,
  disabled = false,
  min = null,
  max = null,
  decimales = null,
}) => {
  const classInput = `form-control ${className}`;
  const stepValue = decimales ? 1 / Math.pow(10, decimales) : null;

  // Función para validar y formatear el valor con la cantidad de decimales especificada
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    let formattedValue = inputValue;

    if (type === 'number') {
      // Validar si se permite decimales
      if (decimales === null) {
        // Si no se permiten decimales, redondeamos al entero más cercano
        formattedValue = Math.round(parseFloat(inputValue)).toString();
      } else {
        // Si se permite decimales, utilizamos toFixed con la cantidad especificada
        formattedValue = parseFloat(inputValue).toFixed(decimales);
      }
      // Validar si el valor está dentro del rango (mínimo y máximo)
      const numericValue = parseFloat(formattedValue);

      if (!isNaN(numericValue)) {
        if (min !== null && numericValue < min) {
            numericValue = min.toString();
        } else if (max !== null && numericValue > max) {
            numericValue = max.toString();
        }
      }else{
        console.log('no es un numero')
      }
      formattedValue=numericValue.toString()
    }
    // Llamamos a la función onChange con el valor formateado
    onChange(formattedValue, name);
  };

  return (
    <div className="form-group m-1">
      {label && (
        <label htmlFor={id} className="input__label">
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        placeholder={placeholder}
        autoComplete={autoComplete || 'off'}
        name={name}
        onChange={handleInputChange} // Utilizamos la función handleInputChange en el evento onChange
        onBlur={onBlur}
        value={value}
        required={required ? 'required' : false}
        className={classInput}
        disabled={disabled}
        onPaste={onPaste}
        step={type === 'number' ? stepValue : null}
        min={min}
        max={max}
        tabIndex={tabIndex}
        style={type === 'date' ? { borderStyle: 'hidden', borderBottomStyle: 'solid' } : {}}
        maxLength={max}
      />
    </div>
  );
};

export { Input };

