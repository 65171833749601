import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { DynamicSearch } from '../../../../components/DynamicForm/DynamicSearch/DynamicSearch';
import { FormService } from '../../../../services/api/formService';
import TablePaginate from '../../../../components/TablePaginate/TablePaginate';
import { setSearchFields } from '../../../../shared/store/table/tableSlice';
import { useDispatch, useSelector } from 'react-redux';

const Cursos160=()=>{
    const urlCursos = 'instrumento/cursos160';
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()
    const[campos,setCampos]=useState(null);
    const[endPoint,setEndPoint]=useState(urlCursos);
    const api = new FormService();
    const dispatch = useDispatch();
    const searchFieldsRedux = useSelector(state => state.table.searchFields);
    const [searchFields2, setSearchFields2] = useState({...searchFieldsRedux});
    
    useEffect(() => {
        const fecthData = async () => {
            setLoading(true);
            setLoading(false);
        };
        fecthData();
    }, []);
    const onNavigateDetails = (programa)=>{
        console.log(programa)
        navigate(`${programa.IdCurso}`,{state:{programa}})
    }

    const handleSearchChange = event => {
        setSearchFields2({ ...searchFields2, [event.target.name]: event.target.value });
    };
    
    const handleSearch = () => {
        console.log(searchFields2);
        dispatch(setSearchFields({ ...searchFields2 }));
    };

    return (
            <>
            <Card>
                <Card.Title>
                    Programas
                </Card.Title>
            </Card>
            {campos&&
                <DynamicSearch camposForm={campos} ></DynamicSearch>
            }

            <div style={{ padding: '10px', }} >
                <Row>
                    <Col>
                        <Form.Group controlId="formHorizontalEmail">
                            <Form.Label>
                                Buscar:
                            </Form.Label>
                            <Form.Control
                                type="text"
                                name="Nombre"
                                onChange={handleSearchChange}
                                value={searchFields2.search}
                            />
                        </Form.Group>
                    </Col>
                    <Col
                        style={{
                            display: 'flex',
                            alignItems: 'flex-end',
                        }}
                    >
                        <Button variant="primary" onClick={handleSearch}>
                            Buscar
                        </Button>
                    </Col>
                </Row>
            </div>
            <TablePaginate 
                apiUrl={endPoint}
                tableHeaders={[
                    {title:'Id', attribute:'IdCurso'},
                    {title:'Nombre', attribute:'Nombre'},
                ]}
                rowActions={[
                    ({rowData})=>(
                        <button onClick={()=>onNavigateDetails(rowData)} className="btn btn-primary">Ver programa</button>
                    )    
                ]}
            />
        </>
    );
}


export{ Cursos160 };