import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { alerta, alertaSinBoton } from '../../../helpers/alertas'
import { FormService } from '../../../services/api/formService'
import { insertExtraDataViaHistoryForm } from '../../../shared/util/form'
import { onRefreshTableToggle, onCloseModals } from '../FormSlice'

export function useSubmitUpdate(
    endPointSubmit,
    extraData,
    isContentTypeFormData,
) {
 
    const camposForm = useSelector((state) => state.form.fields)
    const formSnapshot = useSelector((state) => state.form.formSnapshot)
    const itemSelected = useSelector((state) => state.form.itemSelected)
    const [statusUpdate, setStatusUpdate] = useState(' ')
    const [responseDataUpdate, setDataUpdate] = useState([])
    const dispatch = useDispatch()
    const location = useLocation()
    const formService = new FormService()
    const historyForm = useSelector((state) => state.form.historyForm)

    const fetchDataUpdate = async (formData) => {
        setStatusUpdate('Actulizando...')

        let dataRaw = Object.fromEntries(formData.entries())
        const obj = insertExtraDataViaHistoryForm(
            dataRaw,
            historyForm,
            location.pathname
        )
        dataRaw = { ...dataRaw, ...obj }

        let formDataCleaned = cleanFormData(camposForm, dataRaw)
        // try {
            let data2 = null
            
            if (extraData !== undefined && extraData !=='0') {
                
                if (formSnapshot.form_data =='1') {
                    data2 = await formService.updateWithFormData(
                        endPointSubmit,
                        formDataCleaned,
                        itemSelected[formSnapshot.llave_primaria]
                    ) 
                    
                }
                if (formSnapshot.form_data =='0') {
                    console.log(itemSelected)
                    data2 = await formService.update(
                        endPointSubmit,
                        // {...formDataCleaned },
                        { ...extraData, ...formDataCleaned },
                        itemSelected[formSnapshot.llave_primaria],
                        isContentTypeFormData
                    ) 
                }
            } else {
                if (formSnapshot.form_data =='1') {
                    data2 = await formService.updateWithFormData(
                        endPointSubmit,
                        formDataCleaned,
                        itemSelected[formSnapshot.llave_primaria]
                    ) 
                }
                if (formSnapshot.form_data =='0') {
                    data2 = await formService.update(
                        endPointSubmit,
                        { ...formDataCleaned },
                        itemSelected[formSnapshot.llave_primaria],
                        isContentTypeFormData
                    ) 
                }
            }

            setDataUpdate(data2)
            
            if(data2.respuesta){
                if(data2.respuesta=="exito"){
                    alerta(data2.mensaje)
                    dispatch(onCloseModals())
                    dispatch(onRefreshTableToggle()) 
                }else{
                    alerta(data2.mensaje,"error")
                }

            }else{
                alertaSinBoton('Completado!', 'success', 'bottom-end', 1500)
                dispatch(onCloseModals())
                dispatch(onRefreshTableToggle()) 
            }
           

            
        // } catch (err) {
        //     setDataUpdate(err)
        //     setStatusUpdate(
        //         'Error: ' +
        //             err?.message +
        //             '. LOG: ' +
        //             err.response?.data?.message
        //     )
        // }
        return statusUpdate;
    }

    const cleanFormData = (campos, formData) => {
        let formDataCleaned = {}
        for (let campo of campos) {
            if (campo.render_form =='1') {
                for (const property in formData) {
                    if (campo.name ==property)
                        formDataCleaned[property] = formData[property]
                }
            }
        }
        return formDataCleaned
    }
    return [fetchDataUpdate, statusUpdate, responseDataUpdate]
}

