import { useState, useEffect } from "react";
import { DynamicSearch } from "../../components/DynamicForm/DynamicSearch/DynamicSearch";
import { DynamicTable } from "../../components/DynamicForm/dynamicTable/DynamicTable";
import { FormService } from "../../services/api/formService";
import ApiDashboard from '../../services/api/dashboard';
import { BASE_URL } from "../../services/api/config";
import { alertaSinBoton, alertaTimer } from "../../helpers/alertas";
import { Card, Row, Col } from "react-bootstrap";
const endPointLastUrl="auditorias"

const Auditoria = () =>  {
    const [camposForm, setCamposForm] = useState(null);
    const [loading, setLoading] = useState(false);
    const [endPoint,setEndPoint]=useState("auditorias");
    const [urlExportarAuditoria, setUrlExportarAuditoria] = useState(`${BASE_URL}/auditorias/exportar`)
    const [dataTable, setDataTable] = useState([]);
    const [busqueda, setBusqueda] = useState()
    const [pagination, setPagination] = useState(
        {
            current_page:1,
            prev_page_url:null,
            next_page_url:null,
            per_page:0
        }
    )

    const fetchCamposFormForFilter = ()=>{
        
        const api = new FormService()
        api.getAll(`xcampos?id_formulario=110`)
        .then((res)=>{
            setCamposForm(res)
        })
      
    }

    const onSearch = (e) => {
        setEndPoint(`auditorias/consultaAuditoria${e}`)
        setUrlExportarAuditoria(`${BASE_URL}/auditorias/exportar${e}`)
        setBusqueda(e)
    }

    const onUpdate = (item) => {
        alertaSinBoton("No está disponible esta opción", "error", 1000, 2000)
    }
    
    const onDelete = (item) => {
        alertaSinBoton("No está disponible esta opción", "error", 1000, 2000)
    }

    useEffect(() => {
        
        const fecthData = async () => {
            fetchCamposFormForFilter(endPoint);
        };
        fecthData();
        
    }, []);

    return (
    <>
        <Card>
            <Card.Title>
                <Row>
                    <hr />
                </Row>
                <Row>
                    <Col>Auditoria: Acciones de usuarios</Col>
                </Row>
            </Card.Title>
        </Card>
        {(camposForm) &&
            
            <DynamicSearch onSearch={onSearch} camposForm={camposForm} />
        }
        <DynamicTable 
            titleColumn={['No', 'Fecha creación', 'No Documento', 'Acción', 'Usuario', 'Rol']}
            attributes={['FechaCreacion', 'NumeroDocumento', 'Accion', 'nombreUsuario', 'RolUsuario']}
            endPoint={endPoint}
            onActions={false}
            pagination={pagination}
            exportar={urlExportarAuditoria}
            busqueda={busqueda}
            //actualizarExterno={(e)=>onUpdate(e)}
            //elimimarExterno={(e)=>onDelete(e)}
        />
    </>);
}

export {Auditoria}