import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { DynamicFormContainer } from '../../components/DynamicForm/DynamicFormContainer'
import AreasSaa from './AreasSaa'
import FuncionesSaa from './FuncionesSaa'

export default function ParametricasSAA() {
  return (
    <Routes>
        <Route path="direcciones_saa" element={<DynamicFormContainer idForm="132"/>} />
        <Route path="tipos_de_capacitacion_saa" element={<DynamicFormContainer idForm="134"/>}/>
        <Route path="funciones" element={<DynamicFormContainer idForm="142"/>}/>
        <Route path="areas" element={<DynamicFormContainer idForm="143"/>}/>
        <Route path="funcionarios" element={<DynamicFormContainer idForm="113"/>} />

        <Route path="*" element={<DynamicFormContainer/>} />
    </Routes>
  )
}
