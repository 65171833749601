import React, { useState, useRef } from 'react'

import { Input } from '../input/input'
import { Button } from '../button/button'
import { Select } from '../../components/select/Select'
import { Message } from '../Message/Message'

import './FormSignUp.css'
import { Checkbox } from '../componentsForm/checkbox/checkbox'
import { FormService } from '../../services/api/formService'
import { alerta, alertaSinBoton } from '../../helpers/alertas'
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from 'react-router-dom'
import { current } from 'immer'

const FormSignUp = ({
    // handleSubmit,
    handleChangeConfirmPassword,
    confirmPassword,
    handleChange,
    loading,
    formData,
    list,
    // noMatch,
    checkMayorEdad,
    setCheckMayorEdad,
    checkHabeas,
    setCheckHabeas,
    navigate_habeas
}) => {

    const {
        PrimerNombres,
        SegundoNombre,
        PrimerApellidos,
        SegundoApellidos,
        email,
        password,
        Foto,
        NumeroDocumento,
        IdTipoIdentidad,
        FechaExpedicion,
        IdSexo,
        IdGrupoEtnico,
        FechaNacimiento,
        IdPais,
        // IdMunicipio,
        TelefonoContacto,
        // EmailInstitucional,
        Direccion,
        IdEstadoCivil,
        IdEstrato,
        IdTiposDiscapacidad,
        OrientacionSexual,
        IdRH,
        IdPaisExpedicion,
        IdZonaResidencial,
        IdComunidadIndigena,
        IdComunidadNegra,
        IdTalentoExcepcional,
        codigoSaber,
        IdDepartamento,
        IdCiudad
    } = formData

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordShownRepeat, setPasswordShownRepeat] = useState(false);
    const [presentaDiscapacidad, setPresentaDiscapacidad] = useState(false);
    const [departamentos,setDepartamentos]=useState([]);
    const [ciudades,setCiudades]=useState([]);
    const navigate = useNavigate()
    const [noMatch, setNoMatch] = useState(false)
    const [usuarioValidado, setUsuarioValidado] = useState(false);
    const captcha = useRef(null); 
    const [dataNew, setDataNew] = useState(formData)

    const handleClickShowPassword = () => {
        setPasswordShown(!passwordShown);
    }

    const handleClickShowPasswordRepeat = () => {
        setPasswordShownRepeat(!passwordShownRepeat);
    }
    
    const discapicadActiva=(e)=>{
        if(e==true){
            setPresentaDiscapacidad(true)
        }else{
            setPresentaDiscapacidad(false)
        }
    }

    const slectDinamico=async(name,value)=>{
        handleChange(name,value)
        if(value=="IdPais"){
            const formService = new FormService()
            const data_depart = await formService.getAll('departamentos?IdPaises='+name);
            setDepartamentos(data_depart)
        }
        console.log(value)
        if(value=="IdDepartamento"){
            console.log("..---..")
            const formService = new FormService()
            const data_ciudades = await formService.getAll('ciudad?IdDepartamento='+name);
            setCiudades(data_ciudades)
        }
        // if(value=="FechaNacimiento"){
        //     if(FechaExpedicion>FechaNacimiento){
        //         alerta("La fecha de expedición debe ser mayor a la de nacimiento","error")
        //     }
        // }
    }
    const isSymmectricPassword = (password, confirmPassword) => {
        return password === confirmPassword;
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        /* if(captcha.current.getValue()) { */
            if (isSymmectricPassword(formData.password, confirmPassword)) {    
                if(formData.password.length < 6) {
                    alertaSinBoton(' La contraseña debe contener mínimo 6 caracteres ', 'warning', 'center', 1000);
                    return;
                }            
                // setIsCreate(true);
                const api=new FormService()
        
                const respuesta= await api.create("register", formData)

                if(respuesta.respuesta=="exito"){
                    alertaSinBoton('Usuario creado', 'success', 'center', 1000)
                    setTimeout(() => { navigate('/') }, 1000)
                }else if(respuesta.respuesta=="error"){
                        alerta(respuesta.mensaje);
                }else{
                    const respuesta2 = JSON.parse(respuesta)
                    console.log(respuesta2.mensaje)
                        if(respuesta2.mensaje.email){
                            alertaSinBoton('El Correo ya se Encuentra Registrado', 'error', 'center', 2000)
                        }else if(respuesta2.mensaje.NumeroDocumento) {
                            alertaSinBoton('El Número de Documento ya Está Registrado', 'error', 'center', 2000)
                        }else {
                            alertaSinBoton('Falló al Crear Usuario', 'error', 'center', 2000)
                        }
                }

            } else {
                setNoMatch(true)
            }
        /* } else {
            alertaSinBoton("La casilla No soy un robot es requerida", "warning", "center", 2000);
        } */
    }
    // consultar preinscrito
    const handleBlur=async(e)=>{
        const {name,value}=e.target
        if(name=="NumeroDocumento"){
            const api=new FormService()
            const respuesta= await api.getAll("aspirantes/consultarPreinscrito?NumeroDocumento="+value)
            
            if(respuesta.respuesta=="exito"){
                setDataNew(respuesta.data)
                
                formData.PrimerNombres=respuesta.data.PrimerNombre
                formData.SegundoNombre=respuesta.data.SegundoNombre
                formData.PrimerApellidos=respuesta.data.PrimerApellido
                formData.SegundoApellidos=respuesta.data.SegundoApellido
                formData.email=respuesta.data.Email
                formData.TelefonoContacto=respuesta.data.Celular
                formData.IdTipoIdentidad=respuesta.data.IdTipoIdentidad
                formData.IdPaisExpedicion=respuesta.data.IdPaisExpedicion
                formData.NumeroDocumento=respuesta.data.NumeroDocumento
                formData.Direccion=respuesta.data.DireccionResidencia
            }

            const respuestaUser= await api.getAll("usuario/consultar?NumeroDocumento="+value)
            if(respuestaUser.respuesta == "exito") {
                alerta("Usted ya se encuentra registrado/a en nuestro sistema de información académico", "warning")
            }

        }
    }


    return (
        <form 
            onSubmit={ handleSubmit }
            className='form-sign-up'
        >
            <h2 className='text-center'>Información Personal</h2>
            <section className='form-sign-up__container'>
                <section className='form-sign-up__columnOne'>
                    <Input
                        label="Número de Documento *"
                        type="text"
                        value={ NumeroDocumento || dataNew?.NumeroDocumento || '' }
                        name='NumeroDocumento'
                        onChange={ handleChange }
                        onBlur={ handleBlur }
                        required={true}
                        tabIndex={1}
                    />
                    <Input
                        label='Primer Nombre *'
                        type='text'
                        name='PrimerNombres'
                        required={true}
                        value={ PrimerNombres||dataNew?.PrimerNombre || ''}
                        onChange={ handleChange }
                        tabIndex={3}
                    />
                    
                    <Input
                        label='Primer Apellido *'
                        required={true}
                        type='text'
                        name='PrimerApellidos'
                        value={ formData.PrimerApellidos || dataNew?.PrimerApellido || '' }
                        onChange={ handleChange }
                        tabIndex={5}
                    />
                    
                    <Input
                        label="Fecha de Expedición *"
                        type="date"
                        value={ FechaExpedicion || '' }
                        name='FechaExpedicion'
                        onChange={ handleChange }
                        required={true}
                        tabIndex={7}
                    />
                    <Input
                        label="Fecha de Nacimiento *"
                        type="date"
                        value={ FechaNacimiento || '' }
                        name='FechaNacimiento'
                        onChange={ handleChange }
                        required={true}
                        tabIndex={9}
                    />

                    {IdPais==1 &&
                        <Select
                        id="IdDepartamento"
                        name="IdDepartamento"
                        onChange={ slectDinamico }
                        value={ IdDepartamento || null }
                        label="Departamento *"
                        required={true}
                        optionValue="IdDepartamento"
                        optionName="Descripcion"
                        options={departamentos}
                    />
                    }
                    <Select
                        id="IdZonaResidencial"                        
                        label={'Zona Residencial *'}
                        required={true}
                        name={'IdZonaResidencial'}
                        optionValue={"IdZonaResidencial"}
                        value={IdZonaResidencial||null}
                        optionName={"Nombre"}
                        options={[{"IdZonaResidencial":"1","Nombre":"Rural"},{"IdZonaResidencial":"2","Nombre":"Urbana"}]}
                        onChange={handleChange}
                        tabIndex={11}
                    />
                    <Select
                        id="IdEstrato"
                        name="IdEstrato"
                        onChange={ handleChange }
                        value={IdEstrato || 0}
                        label="Estrato Socieconómico *"
                        optionValue="IdEstrato"
                        optionName="Descripcion"
                        required={true}
                        options={list.estracto}
                        tabIndex={13}
                    />
                    
                    <Select
                        id="IdRH"
                        name="IdRH"
                        onChange={ handleChange }
                        value={IdRH}
                        label="Grupo Sanguíneo - RH*"
                        optionValue="IdRH"
                        optionName="Nombre"
                        options={list.RH}
                        required={true}
                        tabIndex={15}
                    />
                    
                    <Select
                        id="IdGrupoEtnico"
                        name="IdGrupoEtnico"
                        onChange={ handleChange }
                        value={ IdGrupoEtnico || null }
                        label="Grupo Étnico *"
                        optionValue="IdGrupoEtnico"
                        optionName="Descripcion"
                        options={list.grupoEtnico}
                        required={true}
                        tabIndex={17}
                    />
                    <Select
                        id="IdComunidadNegra"
                        name="IdComunidadNegra"
                        onChange={ handleChange }
                        value={IdComunidadNegra || null}
                        label="Comunidades Negras *"
                        optionValue="IdComunidadNegra"
                        optionName="Nombre"
                        required={true}
                        options={list.comunidadesNegras}
                        tabIndex={19}
                    />
                    
                    <Select
                        id="IdEstadoCivil"
                        name="IdEstadoCivil"
                        onChange={ handleChange }
                        value={ IdEstadoCivil || null}
                        label="Estado Civil *"
                        required={true}
                        optionValue="IdEstadoCivil"
                        optionName="Descripcion"
                        options={list.estadoCivil}
                        tabIndex={21}
                    />
                    
                    <Checkbox
                        id="id_status"
                        name=""
                        checked={presentaDiscapacidad}
                        onChange={discapicadActiva}
                        tabIndex={23}
                    ></Checkbox>
                    <label>¿Presenta Discapacidad?</label>
                    
                    {
                        noMatch && (
                            <Message type='warning'>
                                Las contraseñas no coinciden
                            </Message>
                        )
                    }

                </section>
                <section className='form-sign-up__columnTwo'>
                    <Select
                        id="IdTipoIdentidad"
                        name="IdTipoIdentidad"
                        onChange={ handleChange }
                        value={ IdTipoIdentidad || dataNew?.IdTipoIdentidad || null }
                        label="Tipo de Documento *"
                        optionValue="IdTipoIdentidad"
                        optionName="Descripcion"
                        required={true}
                        options={list.tipoIdentidad}
                        tabIndex={2}
                    />
                    <Input
                        label='Segundo Nombre'
                        required={false}
                        type='text'
                        name='SegundoNombre'
                        value={ SegundoNombre || dataNew?.SegundoNombre || ''}
                        onChange={ handleChange }
                        tabIndex={4}
                    />
                    <Input
                        label='Segundo Apellido'
                        type='text'
                        required={false}
                        name='SegundoApellidos'
                        value={ SegundoApellidos || dataNew?.SegundoApellido || ''}
                        onChange={ handleChange }
                        tabIndex={6}
                    />
                    
                    <Select
                        id="IdPais"
                        name="IdPaisExpedicion"
                        onChange={ handleChange }
                        value={ IdPaisExpedicion || dataNew?.IdPaisExpedicion || null }
                        label="País de Expedición *"
                        required={true}
                        optionValue="Idpaises"
                        optionName="Descripcion"
                        options={list.paises}
                        tabIndex={8}
                    />
                    <Select
                        id="IdPais"
                        name="IdPais"
                        onChange={ slectDinamico }
                        value={ IdPais || null }
                        label="País de Nacimiento *"
                        required={true}
                        optionValue="Idpaises"
                        optionName="Descripcion"
                        options={list.paises}
                        tabIndex={10}
                    />
                    {IdDepartamento &&
                        <Select
                            id="IdCiudad"
                            name="IdCiudad"
                            onChange={ handleChange }
                            value={ IdCiudad || null }
                            label="Municipio *"
                            required={true}
                            optionValue="IdCiudad"
                            optionName="Descripcion"
                            options={ciudades}
                        />
                    }
                    <Input
                        label="Dirección de Residencia Actual *"
                        type="text"
                        required={true}
                        value={ Direccion || dataNew?.DireccionResidencia ||'' }
                        name='Direccion'
                        onChange={ handleChange }
                        tabIndex={12}
                    />
                    <Input
                        label='Número Celular *'
                        type='text'
                        required={true}
                        name='TelefonoContacto'
                        value={ TelefonoContacto ||dataNew?.Celular || '' }
                        onChange={ handleChange }
                        tabIndex={14}
                    />
                    <Select
                        id="IdSexo"
                        name="IdSexo"
                        onChange={ handleChange }
                        value={ IdSexo || null }
                        label="Sexo Biológico*"
                        optionValue="IdSexo"
                        required={true}
                        optionName="Descripcion"
                        options={list.sexoBiologico}
                        tabIndex={16}
                    />
                    {IdSexo === "4" ? (
                    <Input
                        label="Orientación Sexual *"
                        type="text"
                        value={ OrientacionSexual || '' }
                        name='OrientacionSexual'
                        onChange={ handleChange }
                        required={true}
                    />) : ("")}
                    <Select
                        id="IdComunidadIndigena"
                        name="IdComunidadIndigena"
                        onChange={ handleChange }
                        value={IdComunidadIndigena || null}
                        label="Comunidades Indigenas *"
                        optionValue="IdComunidadIndigena"
                        optionName="Nombre"
                        required={true}
                        options={list.comunidadesIndigenas}
                        tabIndex={18}
                    />
                    
                    
                    
                    {/* <Input
                        label="Código de Prueba Saber 11 *"
                        type="text"
                        info="Es el número que el ICFES asigna a quienes presenten la Prueba Saber 11, puede consultarlo en el desprendible del examen, o en la citación al mismo, lo visualiza en su examen con la nomenclatura AC o VG seguido de diez o doce dígitos, ejemplo: AC 200728975451, VG 2954872694"
                        required={true}
                        value={ codigoSaber || '' }
                        name='codigoSaber'
                        onChange={ handleChange }
                        tabIndex={20}
                    /> */}
                    <Select
                        id="IdTalentoExcepcional"                        
                        label={'Capacidad Excepcional *'}
                        required={true}
                        name={'IdTalentoExcepcional'}
                        optionValue={"IdTalentoExcepcional"}
                        value={IdTalentoExcepcional||null}
                        optionName={"Nombre"}
                        options={[
                            {"IdTalentoExcepcional":"1","Nombre":"No Aplica"},
                            {"IdTalentoExcepcional":"2","Nombre":"Talento Excepcional General"},
                            {"IdTalentoExcepcional":"3","Nombre":"Talento Excepcional Específico"}
                        ]}
                        onChange={handleChange}
                        tabIndex={20}
                    />
                    
                    {presentaDiscapacidad === true ? (
                    <Select
                        id="IdDiscapacidad"
                        name="IdTiposDiscapacidad"
                        onChange={ handleChange }
                        value={IdTiposDiscapacidad || ''}
                        label="Tipo de Discapacidad"
                        optionValue="IdTiposDiscapacidad"
                        optionName="Descripcion"
                        options={list.tiposDiscapacidad}
                        required={false}
                    />
                    ):("")}

                    
                </section>
            </section>
            <h2 className='text-center'>Datos de Acceso</h2>
            <section className='form-sign-up__container'>

                <section className='form-sign-up__columnOne'>
                        <div>
                                <Input
                                label='Correo *'
                                type='email'
                                name='email'
                                required={true}
                                value={ email || dataNew?.Email || ''}
                                onChange={ handleChange }
                                tabIndex={24}
                            />
                        </div>
                </section>
                <section className='form-sign-up__columnTwo'>

                <div className='d-flex align-items-center'>
                <Input
                        label='Contraseña *'
                        placeholder={'6 caracteres mínimo'}
                        type={passwordShown ? "text" : "password"}
                        name='password'
                        value={ password || ''}
                        onChange={ handleChange }
                        required={true}
                        tabIndex={25}
                    />
                    <div className='showPass' onClick={handleClickShowPassword} >
                        <i className="bi bi-eye-fill fs-4 ms-1 me-1"></i>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                <Input
                        label='Confirmar Contraseña *'
                        placeholder={'Repita la Contraseña'}
                        type={passwordShownRepeat ? "text" : "password"}
                        name='confirmPassword'
                        value={ confirmPassword || ''}
                        onChange={ handleChangeConfirmPassword }
                        required={true}
                        tabIndex={26}
                    />
                    <div className='showPass' onMouseDown={handleClickShowPasswordRepeat} >
                        <i className="bi bi-eye-fill fs-4 ms-1 me-1"></i>
                    </div>

                </div>                
                </section>
            </section>
            <section className='form-sign-up__button d-flex flex-column justify-content-center align-items-center'>
                <div>
                    <div className="d-flex align-items-center">
{/*                             <Checkbox
                            id='id_status'
                            name='status'
                            checked={checkMayorEdad}
                            onChange={setCheckMayorEdad} 
                            >
                                <Button className={'m-0 p-0'} isAnchor={true} to={'/maroria_edad'}>Confirmo que lei y acepto los terminos de mayoría de edad
                                </Button>
                            </Checkbox> */}
                    </div>
                </div>
                {/* <div>
                    <ReCAPTCHA
                        ref={captcha}
                        sitekey="6LfQ0_wjAAAAANT7LPIsZYNo4WcweTkVN99niCCU"
                        onChange={recatcha}
                    />
                </div> */}
                <Button 
                    type='send'
                    loading={ loading }
                >
                    Registrarse
                </Button>
            </section>
        </form>
    )
}

export { FormSignUp }