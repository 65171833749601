import React, { useState } from 'react'
import { Button, Card, Col, Form, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'
import DowloadFile from '../../../components/DowloadFile/DowloadFile';
import { BASE_URL } from '../../../services/api/config'
import { Select } from '../../../components/select/Select';


export default function FormatoCursoContinuada({IdOferta,oferta,fasesLista}) {
    const URL_EXPORT = `${BASE_URL}/exportar/`
    const [modalDescargar,setModalDescargar]=useState();
    const [fechaInicio,setFechaInicio]=useState(oferta.FechaInicioCurso);
    const [fechaFin,setFechaFin]=useState(oferta.FechaFinCurso);
    const [modalHorario,setModalHorario]=useState();
    const [modalAsistencia, setModalAsistencia] = useState();
    const [malla, setMalla] = useState()


return (
    <>
        <Row>
            <Col>
                <Card>
                    <Button 
                        variant="outline-primary"
                        onClick={()=>setModalAsistencia(true)}
                    >
                        Asistencias
                    </Button>
                    {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}`}>
                        <i className="bi bi-arrow-down"></i><br/>
                        Asistencias                                    
                        </a> */}
                </Card>
            </Col>
            <Col>
                <Card>
                    <DowloadFile
                        nameFile={"Calificaciones.pdf"}
                        name={"Calificaciones"}
                        endPoint={`${BASE_URL}/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}`}
                    />
                    {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/calificaciones?IdOferta=${IdOferta}`}>
                        <i className="bi bi-arrow-down"></i><br/>
                        Calificaciones                                    
                        </a> */}
                </Card>
            </Col>
            <Col>
                <Card>
                    <DowloadFile
                        nameFile={"Inscritos.xlsx"}
                        name={"Inscritos"}
                        endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}
                    />
                    {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/inscritos?IdOferta=${IdOferta}`}>
                        <i className="bi bi-arrow-down"></i><br/>
                        Inscritos                                    
                        </a> */}
                    </Card>
            </Col>
        </Row>
        <Row>
            <Col>
            <Card>
                <Button 
                    variant="outline-primary"
                    onClick={()=>setModalHorario(true)}
                >
                    Horario del Curso
                </Button>

                {/* <DowloadFile
                    nameFile={"Horario del Curso.pdf"}
                    name={"Horario del Curso"}
                    endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}`}
                /> */}
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}`}>
                    <i className="bi bi-arrow-down"></i><br/>
                    Horario del Curso                               
                    </a> */}
            </Card>
            </Col>
            <Col>
            <Card>
                <DowloadFile
                    nameFile={"Inscritos Eliminados.xlsx"}
                    name={"Inscritos Eliminados"}
                    endPoint={`${BASE_URL}/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}
                />
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}>
                    <i className="bi bi-arrow-down"></i><br/>
                    Inscritos Eliminados                                
                    </a> */}
            </Card>
            </Col>
            <Col>
                <Card>
                <DowloadFile
                    nameFile={"Estudiantes.xlsx"}
                    name={"Estudiantes"}
                    endPoint={`${BASE_URL}/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}
                />
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/estudiantes?IdOferta=${IdOferta}`}>
                    <i className="bi bi-arrow-down"></i><br/>
                    Estudiantes                                
                    </a> */}
                </Card>
            </Col>
        </Row>

        <Row>
            <Col>
            <Card>
                <DowloadFile
                    nameFile={"Informacion General del Curso.pdf"}
                    name={"Informacion General del Curso"}
                    endPoint={`${BASE_URL}/docente/sia?IdOferta=${IdOferta}`}
                />
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/docente/sia?IdOferta=${IdOferta}`}>
                    <i className="bi bi-arrow-down"></i><br/>
                    Formato SIA
                    </a> */}
            </Card>
            </Col>
            <Col>
                <Card>
                <DowloadFile
                    nameFile={"Promedios del Curso.pdf"}
                    name={"Promedios del Curso"}
                    endPoint={`${BASE_URL}/cursos/promedioCurso?IdOferta=${IdOferta}`}
                />
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/cursos/promedioCurso?IdOferta=${IdOferta}`}>
                    <i class="bi bi-arrow-down"></i><br/>
                    Promedios del Curso                                
                    </a> */}
                </Card>
            </Col>
            <Col>
            <Card>
                <Button onClick={()=>setModalDescargar(true)}>
                    Otros Formatos
                </Button>
                
                {/* <a target="_blank" style={{textAlign:'center'}} href={`${BASE_URL}/exportar/cursoFinalizado/inscritosEliminados?IdOferta=${IdOferta}`}>
                    <i class="bi bi-arrow-down"></i><br/>
                    Inscritos eliminados                                
                    </a> */}
            </Card>
            </Col>
        </Row>
                    <Modal 
                show={modalDescargar} 
                onHide={()=>setModalDescargar(false)}
            >
            <Modal.Header closeButton>
                <Modal.Title>Exportables y Formatos</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul className="list-group">
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">                        
                        <DowloadFile 
                            nameFile={"Listado de Apirantes.xlsx"}
                            name={"Listado de Apirantes"}
                            endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                        />
                        Listado de Aspirantes
                        <a target="blank" href={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}>
                        <i className="bi bi-file-arrow-down"></i>
                        </a>
                    </li>
                    <br></br> */}
                    <ListGroup>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Listado de Apirantes.pdf"}
                                name={"Listado de Apirantes"}
                                endPoint={`${URL_EXPORT}aspirantes?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <br></br>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Listado de Inscritos.pdf"}
                                name={"Inscritos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesInscritos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        {oferta.IdConvocatoria&&
                            <ListGroup.Item>
                                <DowloadFile 
                                    nameFile={"Admitidos a pruebas.pdf"}
                                    name={"Admitidos a pruebas"}
                                    endPoint={`${BASE_URL}/cursos/exportarAdmitidosPrueba?IdOferta=${IdOferta}`}
                                />
                            </ListGroup.Item>
                        }
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Admitidos.pdf"}
                                name={"Admitidos"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesAdmitidos?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <DowloadFile 
                                nameFile={"Matriculados.pdf"}
                                name={"Matriculados"}
                                endPoint={`${BASE_URL}/cursos/exportarAspirantesMatriculados?IdOferta=${IdOferta}`}
                            />
                        </ListGroup.Item>
                    </ListGroup>
                    {/* <li className="list-group-item d-flex justify-content-between align-items-center">
                        Pre inscritos
                        <a href={`${URL_EXPORT}preInscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Inscritos
                        <a href={`${URL_EXPORT}inscritos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Admitidos
                        <a href={`${URL_EXPORT}admitidos?IdOferta=${IdOferta}`}>
                            <i class="bi bi-file-arrow-down"></i>
                        </a>                       
                        </li>
                    <li className="list-group-item d-flex justify-content-between align-items-center">
                        Estudiantes
                        <a href={`${URL_EXPORT}matriculados?IdOferta=${IdOferta}`}>
                        <i class="bi bi-file-arrow-down"></i>
                        </a>
                    </li> */}
                </ul>
                <h5 className='text-center p-2'>Exporta Admitidos a Fases</h5>
                <ul className="list-group">
                {fasesLista.map((fase,key)=>
                    <ListGroup>
                    <ListGroup.Item>
                    <DowloadFile 
                                nameFile={`${fase.Nombre}.pdf`}
                                name={fase.Nombre}
                                endPoint={`${URL_EXPORT}admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}
                                />
                    </ListGroup.Item>
                    </ListGroup>
                    // <li key={key} className="list-group-item d-flex justify-content-between align-items-center">
                    //     {fase.Nombre}
                    //     <a target="_blank" href={`${URL_EXPORT}admitidosFase?IdConvocatoriaFase=${fase.IdConvocatoriaFase}&IdOferta=${IdOferta}`}>
                    //     <i className="bi bi-file-arrow-down"></i>
                    //     </a>
                    // </li>  
                )
                }
                </ul>                 
            </Modal.Body>
            </Modal>
            {/* filtra descarga del horario por fecha inicial y fecha fina en un modal    */}
            <Modal
                show={modalHorario}
                onHide={()=>setModalHorario(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Horario</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Fecha Inicial</Form.Label>
                            <Form.Control type="date" value={fechaInicio} onChange={(e)=>setFechaInicio(e.target.value)}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Fecha Final</Form.Label>
                            <Form.Control type="date" value={fechaFin} onChange={(e)=>setFechaFin(e.target.value)}/>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={()=>setModalHorario(false)}>
                        Cerrar
                    </Button> */}
                    {/* <Button variant="primary" onClick={()=>descargarHorario()}>
                        Descargar
                    </Button> */}
                    {fechaInicio&&fechaFin&&
                        <DowloadFile
                            nameFile={"Horario del Curso.pdf"}
                            name={"Horario del Curso"}
                            endPoint={`${BASE_URL}/cursos/exportarHorario?IdOferta=${IdOferta}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}`}
                        />
                    }
                    
                </Modal.Footer>
            </Modal>

            <Modal
                show={modalAsistencia}
                onHide={()=>setModalAsistencia(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Descarga Asistencia</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Select
                                id="IdMalla"
                                name="IdMalla"
                                value={malla}
                                onChange={ (e) => setMalla(e)}
                                label="Asignatura"
                                required={true}
                                optionValue="IdMalla"
                                optionName="Descripcion"
                                urlOptionsService={`asignaturasPrograma?IdOferta=${IdOferta}`}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" onClick={()=>setModalHorario(false)}>
                        Cerrar
                    </Button> */}
                    {/* <Button variant="primary" onClick={()=>descargarHorario()}>
                        Descargar
                    </Button> */}
                    {malla &&
                        <>
                        <DowloadFile 
                            nameFile={"Asistencias.pdf"}
                            name={"Asistencias"}
                            endPoint={`${BASE_URL}/exportar/cursoFinalizado/asistencias?IdOferta=${IdOferta}&IdMalla=${malla}`}
                        />
                        </>
                    }
                </Modal.Footer>
            </Modal>
    </>
    )
}
