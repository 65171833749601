import { useState } from "react"
import { Button, Table } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { Card } from "../../../components/card/Card"
import { ICON } from "../../../constants/icons"
import { useFetchGet } from "../../../hooks/useFetchGet"
import LocalStorage from "../../../services/localStorage/LocalStorage"
import { Title } from "../../../shared/components/title/Title"

function ListadoEvaluaciones (){

  const ls = new LocalStorage()
  const IdUsuario = ls.getAsJsonItem('usuario').usuario.IdUsuario
  const [evaluaciones,isLoading,error] = useFetchGet(`gestionInstrumentos/evaluacionesParaDocentes?IdUsuario=${IdUsuario}`)

  const [isOpenModalEvaluacion,setIsOpenModalEvaluacion] = useState(false)
  const [ itemSelected,setItemSelected] = useState({})
  const [isOpenModalAsignarEvaluacion,setIsOpenModalAsignarEvaluacion] = useState(false)

  const navigate = useNavigate()

  const handleOptionClick = (item) => {
    setItemSelected(item)
    setIsOpenModalEvaluacion(true)
    // navigate(`${item.IdOferta}/detalle_calendario`,{state:{coordinador,x:item}})
  }

  return(
    <>
    <Card>
      <Title>Autoevaluaciones y Coevaluaciones</Title>
    </Card>
    <Card>

    <Table striped bordered hover>
  <thead>
    <tr>
      <th>N°</th>
      <th>Nombre del Instrumento</th>
      <th>Evaluado</th>
      <th>Plazo (A/M/D)</th>
      <th>Realizado</th>
      <th>Realizar Evaluación</th>
    </tr>
  </thead>
  <tbody>
    {!!evaluaciones && evaluaciones.map((item,index)=>{
      return(
        <tr key={index} onClick={()=>handleOptionClick(item)}>
          <td>{index+1}</td>
          <td>{item.Formulario}</td>
          <td>{item.Nombre}</td>
          <td> {item.FechaActivo} / {item.FechaInactivo}</td>
          <td>{item.EstadoInstrumento==='1'?` ${ICON.CHECKED}` :` ${ICON.UNCHECKED}`}</td>
          <td>
            <Button
              onClick={(e)=>navigate(`${item.IdGestionInstrumento}`,{state:{evaluacion:item}})}
              variant="primary"
            > 
              Evaluar
            </Button>
          </td>
        </tr>
      )
    }
    )}
  </tbody>
</Table>
    </Card>
    </>
  )
}

export { ListadoEvaluaciones }