import React, { useState } from 'react'

import { useLocation } from "react-router-dom"
import { NavTag } from "../../../Applicant/NavTag/NavTag"
import { Card } from "../../../card/Card"
import { OfferHeader } from "../../../OfferHeader/OfferHeader"
import { Arrow } from "../../../arrow/Arrow"
import { ETAPAS_INSCRIPCION } from '../../../../constants/general'
import { Message } from '../../../Message/Message'
import { ProfileHeader } from '../../../ProfileHeader/ProfileHeader'
import './EnrollmentProcess.css'

const EnrollmentProcess = ({ 
  children, 
  isApplicant,
  isCompletedDocuments,
  isCompletedAdmission,
  isCompletedEnrollment,
  aspirante,
  item,
  respondioBienestar,
  consultaRespondioBienes,
  matricula,
}) => {

  const OPCION_RENDER = {
    DOCUMENTOS:1,
    ADMISION:2,
    MATRICULA:3
  }
  const [isInCompleted, setIsIncompleted] = useState(false)
  const childrenArray = React.Children.toArray(children);
  const { state: { offer, applicant } } = useLocation()

  const [windowSelector, setWindowSelector] = useState(() => {
    if(
      applicant?.EstadoEstudiante == 
      ETAPAS_INSCRIPCION.MATRICULA.ESTUDIANTE_TERMINADO
    ){
      return OPCION_RENDER.MATRICULA
    }
    if(
      applicant?.EstadoAdmision == 
      ETAPAS_INSCRIPCION.ADMISION.ADMISION_TERMINADO
    ){
      return OPCION_RENDER.ADMISION
    }
    return OPCION_RENDER.DOCUMENTOS
  }) 

  const onChangeWindow = (e) => {
    setWindowSelector(e)
  }

  const actualizar = async () => {
    setTimeout(() => {
        // consultaRespondioBienestar()
    }, 3000)
  }

  return (
    <>
      <header>
        <Arrow />
      </header>
      <main className='enrollment-process__main'>
        {
          isApplicant && (
            <OfferHeader 
              img={offer?.Repositorio}
              offerName={ offer?.Nombre || ''}
              offerDescription={ offer?.Observaciones || ''}
              IdOferta={offer.IdOferta}
              horas={offer.horas}
              oferta={offer}
            />
          )
        }
        {
          !isApplicant && (
            <ProfileHeader 
              profile={ applicant }
              isStudent={ false }
              isModuloFull={offer.modo_full}
              oferta={offer.IdOferta}
            />
          )
        }
        <Card>
          <NavTag
            onChangeWindow={ onChangeWindow }
            windowSelector={ windowSelector }
            isCompletedDocuments={ applicant?.EstadoRequisitos }
            isCompletedAdmission={ applicant?.EstadoAdmision }
            isCompletedEnrollment={ applicant?.EstadoEstudiante }
            setIsIncompleted={ setIsIncompleted }
            requiereInscripcion={ offer.requiere_inscripcion }
            isModuloFull={ offer.modo_full }
            lock={ isApplicant }
            matricula={ matricula }
          />
          {
            isInCompleted && (
              <Message type='warning'>
                Completa esta etapa para continuar
              </Message>
            )
          }
          <div className='applicant__content'>  
            {
              windowSelector == OPCION_RENDER.DOCUMENTOS && (
                <>
                  {applicant?.EstadoRequisitos == "1" && 
                    <Message type='ok'> Todos los documentos han sido aprobados </Message>
                  }
                  {applicant?.EstadoRequisitos == "2" && 
                  <Message type='error'> Rechazado por el siguiente motivo: {applicant?.mensajeRequisitos} </Message>
                  }
                  {applicant?.EstadoRequisitos == "0" && 
                  <Message type='warning'> En proceso </Message>
                  }
                  {
                    childrenArray[0]
                    ? childrenArray[0] 
                    : null 
                  }
                </>
              )
            }
            {
              windowSelector == OPCION_RENDER.ADMISION && (
                <>
                  {applicant.EstadoAdmision == "1" &&
                    <Message type='ok'> Proceso de admisión finalizado </Message>
                  }
                  {applicant.EstadoAdmision == "2" &&
                    <Message type='error'> No admitido por el siguiente motivo: {applicant?.mensajeAdmision} </Message>
                  }
                  {applicant.EstadoAdmision == "0" &&
                    <Message type='warning'> En proceso </Message>
                  }
                  {
                    childrenArray[1]
                    ? childrenArray[1] 
                    : null
                  }
                </>
              )
            }
            {
              windowSelector == OPCION_RENDER.MATRICULA && (
                <>
                  { applicant.EstadoEstudiante == "1" &&
                    <>
                    <Message type='ok'> proceso de matricula finalizado </Message>
                    </>
                  }
                  { applicant.EstadoEstudiante == "2" &&
                    <Message type='error'> Matricula rechazada por el siguiente motivo: {applicant?.mensajeEstudiante} </Message>
                  }
                  { applicant.EstadoEstudiante == "0" &&
                    <Message type='warning'> En proceso </Message>
                  }
                  <div className='justify-content-around'>
                    {
                      childrenArray[2]
                      ? childrenArray[2]
                      : null
                    }
                  </div>
                </>
              )
            }
          </div>  
        </Card>
      </main>
  </>)
}

export { EnrollmentProcess }